import SieauParameterDto from 'administration/dto/SieauParameterDto'
import { push } from '@lagunovsky/redux-react-router'
import { compact, groupBy, orderBy, template, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getYear } from 'utils/DateUtil'
import { getLocalStorageJson, setLocalStorageJson } from 'utils/FormUtils'
import { getSettingInt } from 'utils/SettingUtils'
import AgriAction from '../../agriAdministration/actions/AgriAction'
import { LOCAL_TERRITORY_FILTERS, volumesToShow } from '../../agriAdministration/constants/AgriConstants'
import ActionComponent from '../../components/ActionComponent'
import CartographyPanel from '../../components/map/CartographyPanel'
import Row from '../../components/react/Row'
import HomeAction from '../../home/actions/HomeAction'
import CityDto from '../../referencial/components/city/dto/CityDto'
import ManagementUnitAction from '../../referencial/components/managementUnit/actions/ManagementUnitAction'
import DtoManagementUnit from '../../referencial/components/managementUnit/dto/DtoManagementUnit'
import ToastrAction from '../../toastr/actions/ToastrAction'
import { hasValue } from '../../utils/NumberUtil'
import { formatMilliers, getI18nTitleData } from '../../utils/StringUtil'
import DtoTerritoryUgeVolume from '../dto/DtoTerritoryUgeVolume'
import ManagementUnitModal from './modal/ManagementUnitModal'
import TerritoryFilterPanel from './panel/TerritoryFilterPanel'
import TerritoryScenarioPanel from './panel/TerritoryScenarioPanel'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import Table from 'components/datatable/Table'
import { setStationPointScale } from 'components/map/mapTreatments/AddingLayers'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import ProgressBar from 'components/progress/ProgressBar'
import IAEauAction from 'iaeau/IAEauAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import Select from 'components/forms/Select'
import Checkbox from 'components/forms/Checkbox'

const styleCell = {
    width: '100%',
    textAlign: 'right',
}

// INFOS EN DUR
const idPiezo = 13

// VOLUMES AUP
const VOLUMES_AUP = 'aup'
const VOLUMES_PREFECTORAL = 'aupPrefectural'

const headers = {
    [VOLUMES_AUP]: ['nameUge', 'AUP', 'volumes', 'consos'],
    [VOLUMES_PREFECTORAL]: ['nameUge', 'volumePrefectural', 'volumes', 'consos'],
}

const headersAcc = {
    [VOLUMES_AUP]: ['nameUge', 'AUP', 'volumes'],
    [VOLUMES_PREFECTORAL]: ['nameUge', 'volumePrefectural', 'volumes'],
}

const emptyFilter = {
    volumesShow: 'asked',
    showWatersheds: false,
    showManagementUnits: true,
    showCities: false,
    showPtsPrel: false,
    pointsType: 1,
    stepTime: 2,
    scenarioYear: null,
}

class TerritoryDashboardApp extends ActionComponent {
    modelIsBusy = false
    state = {
        dataLoaded: false,
        filter: {
            ...(getLocalStorageJson(LOCAL_TERRITORY_FILTERS) || emptyFilter),
            dateShow: Date.now(),
            dateShowEnd: Date.now(),
        },
        managementUnitsFormatted: [],
        openModalUGE: false,
        ugeData: {},
        modelIsBusy: false,
        volumeType: VOLUMES_AUP,
        takeWaterTanks: false,
    }

    componentDidMount() {
        const { filter } = this.state
        const { applicationSettings } = this.props
        this.props.fetchManagementUnits().then(() => {
            this.setState({ filter: { ...filter, scenarioYear: getSettingInt(applicationSettings, 'RSEau_parametres_annee_scenario') } })
            this.props.fetchManagementUnitsVolumesByYear(filter.volumesShow, filter.dateShow, filter.dateShowEnd || Date.now()).then(() => {
                this.setDatas(filter)
            })
        })
        this.props.setTitle([{
            title: i18n.steering,
            href: 'territory',
        }, {
            title: i18n.territoryManagement,
            href: 'territory',
        }])
        this.props.getModels('piezometry', idPiezo)
        this.regularProgressUpdate()
    }

    regularProgressUpdate = () => {
        this.setState({ modelIsBusy: true })
        this.modelIsBusy = true
        this.recursiveFetchIAeauModel()
    }

    recursiveFetchIAeauModel = () => {
        if (!this.modelIsBusy) {
            return
        }
        this.props.fetchSetting('RSEau_model_is_busy').then(response => {
            if (response?.value === 'true') {
                if (!this.modelIsBusy) {
                    this.modelIsBusy = true
                    this.setState({ modelIsBusy: true })
                }
                setTimeout(() => this.recursiveFetchIAeauModel(), 5000)
            } else {
                this.setState({ modelIsBusy: false })
                this.modelIsBusy = false
            }
        })
    }

    onValidateFilter = (updatedFilter) => {
        const { filter, modelIsBusy } = this.state
        const { applicationSettings, iaeauModels } = this.props
        const newFilter = { ...filter, ...updatedFilter }
        if (newFilter.volumesShow === 'irrigEfficiency') {
            if (this.modelIsBusy || modelIsBusy) {
                this.props.warning(i18n.modelIsAlreadyRunning)
            } else {
                const yearScenario = getSettingInt(applicationSettings, 'RSEau_parametres_annee_scenario')
                if (!newFilter.scenarioYear) {
                    this.props.warning(i18n.pleaseCompleteAllRequiredField)
                } else if (yearScenario !== newFilter.scenarioYear) {
                    const model = iaeauModels.find((m) => m.typeModel === 'TERRITORY_AGRI') || {}
                    this.props.updateSieauParameters([{
                        parameter: 'RSEau_parametres_annee_scenario',
                        value: `${newFilter.scenarioYear}`,
                    }], true).then(() => IAEauAction.launchModel('piezometry', idPiezo, model))
                    this.modelIsBusy = true
                    this.setState({ modelIsBusy: true })
                }
            }
        }
        setLocalStorageJson(LOCAL_TERRITORY_FILTERS, newFilter)
        this.setState({
            overconsumption: [],
            toMonitor: [],
            compliantConsumption: [],
            withoutVolumes: [],
            filter: newFilter,
            dataLoaded: false,
        })
        this.props.toastrInfo(`${i18n.downloadData}...`)
        this.props.fetchManagementUnitsVolumesByYear(updatedFilter.volumesShow, updatedFilter.dateShow, updatedFilter.dateShowEnd || Date.now()).then(() => this.setDatas(updatedFilter))
    }

    setDatas = (filter) => {
        const { volumesSurveys, managementUnits, installationsWithGeo } = this.props
        const { volumeType, takeWaterTanks } = this.state
        const { dateShow, volumesShow } = filter

        const year = moment(dateShow).year()

        const groupByUge = groupBy(uniq(volumesSurveys).filter(({ volume }) => hasValue(volume)), 'idUge')
        const groupBySubUge = groupBy(uniq(volumesSurveys).filter(({ volume }) => hasValue(volume)), 'idSubUge')
        const groupedVolumes = volumesShow === 'consumed' ? groupByUge : { ...groupByUge, ...groupBySubUge }
        const groupedRequest = uniq(Object.keys(groupedVolumes).map(idSubUge => {
            const uge = groupedVolumes[idSubUge] || []
            const total = uge.map(({ volume }) => volume || 0).reduce((a, b) => a + b, 0)
            const totalTanks = uge.map(({ tanks }) => tanks || 0).reduce((a, b) => a + b, 0)
            if (uge.length > 1) {
                return {
                    ...uge[0],
                    idSubUge: '',
                    nameSubUge: '',
                    volume: total,
                    totalTanks,
                }
            }
            return {
                ...uge[0],
                volume: total,
                totalTanks,
            }
        }))
        const ugeWithVolumes = compact(groupedRequest.map((b) => {
            const ugeId = b.idSubUge || b.idUge
            const uge = managementUnits.find((u) => u.id === ugeId)
            if (uge) {
                const aupFound = orderBy(uge.link_aup.filter((u) => u.year <= year), 'year', 'desc')[0] || {}
                const volume = takeWaterTanks ? b.volume - b.totalTanks : b.volume
                const { layer, typeName, color, level, consos, markerIcon } = this.getColors(aupFound[volumeType], volume)
                return {
                    ...uge,
                    nameUge: {
                        value: `[${b.idSubUge || b.idUge}] ${b.nameSubUge || b.nameUge}`,
                    },
                    AUP: {
                        value: hasValue(aupFound[volumeType]) ? `${formatMilliers(aupFound[volumeType]) || 0} m3` : '',
                        style: styleCell,
                    },
                    volumes: {
                        value: hasValue(volume) ? `${formatMilliers(volume) || 0} m3` : '',
                        style: styleCell,
                    },
                    sortValueVolume: volume || 0,
                    sortValueAUP: aupFound[volumeType] || 0,
                    projection: 16,
                    layer,
                    typeName,
                    scale: 0.5,
                    level,
                    consos,
                    headers: headers[volumeType],
                    markerIcon,
                    popupContent: this.getPopupContent(color, `[${b.idSubUge || b.idUge}] ${b.nameSubUge || b.nameUge}`, aupFound[volumeType], volume),
                    // onClickPopup: () => this.openModalUGE(element),
                    onClickPopup: () => {},
                }
            }
            return null
        }))

        const ugeWithoutVolumes = compact(managementUnits.map((u) => {
            const id = u.managementCode
            if (!ugeWithVolumes.find(({ managementCode }) => id === managementCode)) {
                const aupFound = orderBy(u.link_aup.filter((uge) => uge.year <= year), 'year', 'desc')[0] || {}
                return {
                    ...u,
                    nameUge: {
                        value: `[${u.id || ''}] ${u.name || ''}`,
                    },
                    AUP: {
                        value: hasValue(aupFound[volumeType]) ? `${formatMilliers(aupFound[volumeType])} m3` : '',
                        style: styleCell,
                    },
                    volumes: {
                        value: '',
                    },
                    sortValueVolume: 0,
                    sortValueAUP: aupFound[volumeType] || 0,
                    projection: 16,
                    layer: 'POINT_GRAY',
                    markerIcon: 'pictures/markers/map_point_gray.png',
                    typeName: 'other',
                    scale: 0.5,
                    level: 1,
                    consos: i18n.UGwithoutVolumes,
                    headers: headers[volumeType],
                    popupContent: this.getPopupContent('lightgrey', `[${u.id}] ${u.name}`, aupFound[volumeType]),
                    // onClickPopup: () => this.openModalUGE(element),
                    onClickPopup: () => {},
                }
            }
            return null
        }))
        if (filter.showPtsPrel && installationsWithGeo.length > 5000) {
            this.props.warning(template(i18n.performanceDisplayMessage)({
                maxNbElements: 5000,
                nbElements: installationsWithGeo.length,
            }))
        }
        const managementUnitsFormatted = orderBy(uniq([ ...ugeWithVolumes, ...ugeWithoutVolumes ]), 'level')
        this.setState({ managementUnitsFormatted, dataLoaded: true })
        this.setActions({
            export: () => {
                return {
                    data: orderBy(uniq([ ...ugeWithVolumes, ...ugeWithoutVolumes ]), 'level', 'desc'),
                    exportType: 'xlsx',
                    titleFile: `${i18n.territoryManagement} - ${i18n.consos} - ${this.getVolumesLabel()} ${year}`,
                }
            },
        })
    }

    getVolumesLabel = () => {
        const { filter } = this.state
        const type = volumesToShow.find(({ value }) => value === filter.volumesShow)
        return i18n[type.i18n]
    }

    getPopupContent = (color, title, volumeAUP, volume) => (
        <div
            className='row no-margin valign-wrapper clickable'
            style={{
                background: `linear-gradient(
                        to right,
                        ${color},
                        ${color} 2rem,
                        white 2rem,
                        white 100%
                    )`,
                borderRadius: 10,
            }}
        >
            <div className='col s12' style={{ marginLeft: '2rem' }}>
                <Row>
                    <p className='bold'>{title}</p>
                </Row>
                <Row>
                    {`${this.state.volumeType === VOLUMES_AUP ? i18n.AUP : i18n.volumePrefectural} : ${volumeAUP ? `${formatMilliers(volumeAUP)} m3` : i18n.unknown}`}
                </Row>
                <Row>
                    {`${this.getVolumesLabel()} : ${volume ? `${formatMilliers(volume)} m3` : '0 m3'}`}
                </Row>
                <Row><i>{i18n.clickOnConsoTableForDetails}</i></Row>
            </div>
        </div>
    )

    getAccordeon = (elements, title, color, open, head) => (
        <div className='col s12 margin-bottom-2'>
            <AccordionMUI defaultExpanded={open}>
                <AccordionSummaryMUI
                    style={{
                        backgroundColor: color,
                        color: 'white',
                        minHeight: 60,
                        height: 60,
                        borderBottom: 'none',
                        fontSize: '22',
                    }}
                >
                    {title}<span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>&nbsp;{`(${elements.length} ${getI18nTitleData(i18n.element, i18n.elements, elements)})`}</span>
                </AccordionSummaryMUI>
                <AccordionDetailsMUI nopadding style={{ border: `2px solid ${color}` }}>
                    <Table
                        data={orderBy(elements, ['sortValueAUP', 'sortValueVolume', 'id'], ['desc', 'desc'])}
                        paging
                        nbPerPageLabel={ nbPerPageLabelTiny }
                        type={{ headers: head || headersAcc[this.state.volumeType] }}
                        customHeaders={{
                            volumes: this.getVolumesLabel(),
                        }}
                        sortable
                        initialSort
                        showTitle={false}
                        onClick={(e) => this.openModalUGE(e)}
                        onLineOver={(uge) => setStationPointScale({ ...uge, scale: 0.5 }, true)}
                        onLineOut={(uge) => setStationPointScale({ ...uge, scale: 0.5 }, false)}
                        color
                    />
                </AccordionDetailsMUI>
            </AccordionMUI>
        </div>
    )

    getContentResults = () => {
        const { managementUnitsFormatted, filter: { volumesShow } } = this.state
        if (volumesShow === 'irrigEfficiency') {
            if (this.state.modelIsBusy) {
                return <ProgressBar indeterminate withMessage message={i18n.calculationInProgressWithTime} />
            }
            return this.getAccordeon(managementUnitsFormatted, i18n.all, 'lightgrey', true, ['nameUge', 'volumes'])
        }

        const overconsumption = managementUnitsFormatted.filter(({ layer }) => layer === 'POINT_RED') // > 100%
        const toMonitor = managementUnitsFormatted.filter(({ layer }) => layer === 'POINT_ORANGE') // > 80%
        const compliantConsumption = managementUnitsFormatted.filter(({ layer }) => layer === 'POINT_GREEN' || !layer) // < 80%
        const withoutVolumes = managementUnitsFormatted.filter(({ layer }) => layer === 'POINT_GRAY')
        return (
            <>
                {this.getAccordeon(overconsumption, i18n.overrunAUP, 'red', overconsumption.length)}
                {this.getAccordeon(toMonitor, i18n.closeOverrunAUP, 'orange', !overconsumption.length && toMonitor.length)}
                {this.getAccordeon(compliantConsumption, i18n.compatibleAUP, 'green', !overconsumption.length && !toMonitor.length && compliantConsumption.length)}
                {this.getAccordeon(withoutVolumes, i18n.AUPtoComplete, 'lightgrey', !overconsumption.length && !toMonitor.length && !compliantConsumption.length && withoutVolumes.length)}
            </>
        )
    }

    getPanels = () => {
        const { filter, volumeType, takeWaterTanks } = this.state
        return [
            {
                id: 'criterias',
                icon: 'search',
                title: i18n.criterias,
                content: (
                    <TerritoryFilterPanel
                        filter={filter}
                        onValidate={(v) => this.onValidateFilter(v)}
                    />
                ),
            },
            {
                id: 'results',
                icon: 'list',
                title: i18n.results,
                content: (
                    <div className='row no-margin padding-top-1'>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                                col={5}
                                label={'Volumes de référence'}
                                value={volumeType}
                                options={[{
                                    value: VOLUMES_AUP,
                                    label: i18n.AUPVolumes,
                                }, {
                                    value: VOLUMES_PREFECTORAL,
                                    label: i18n.volumesPrefectural,
                                }]}
                                onChange={v => {
                                    this.setState({ volumeType: v }, () => {
                                        this.setDatas(filter)
                                    })
                                }}
                                noNullValue
                            />
                            <div
                                className='col s7'
                                onClick={() => {
                                    this.setState({ takeWaterTanks: !takeWaterTanks }, () => {
                                        this.setDatas(filter)
                                    })
                                }}
                            >
                                <Checkbox
                                    col={12}
                                    label={i18n.takeWaterTanks}
                                    checked={takeWaterTanks}
                                />
                            </div>
                        </Row>
                        {this.getContentResults()}
                    </div>
                ),
            },
            {
                id: 'scenarios',
                icon: 'rate_review',
                title: i18n.scenarios,
                content: <TerritoryScenarioPanel />,
            },
        ]
    }

    getColors = (volumeAUP, volume) => {
        if (!volumeAUP) {
            return { layer: 'POINT_GRAY', typeName: 'other', color: 'lightgrey', level: 1, consos: i18n.AUPtoComplete, markerIcon: 'pictures/markers/map_point_gray.png' }
        }
        if (volume > volumeAUP) {
            return { layer: 'POINT_RED', typeName: 'other', color: 'red', level: 4, consos: i18n.overrunAUP, markerIcon: 'pictures/markers/map_point_red.png' }
        }
        if (((volume / volumeAUP) * 100) >= 80) {
            return { layer: 'POINT_ORANGE', typeName: 'other', color: 'orange', level: 3, consos: i18n.closeOverrunAUP, markerIcon: 'pictures/markers/map_point_orange.png' }
        }
        return { layer: 'POINT_GREEN', typeName: 'other', color: 'green', level: 2, consos: i18n.compatibleAUP, markerIcon: 'pictures/markers/map_point_green.png' }
    }

    getData = () => {
        const { filter, managementUnitsFormatted } = this.state
        const { showCities, showManagementUnits, showPtsPrel, pointsType } = filter
        const { installationsWithGeo, cities } = this.props

        if (showPtsPrel) {
            switch (pointsType) {
                case 2:
                    return installationsWithGeo.filter((i) => i.installationType === 18 && i.sampleType === 2).slice(0, 5000)
                case 3:
                    return installationsWithGeo.filter((i) => i.installationType === 18 && i.sampleType === 1).slice(0, 5000)
                case 1: default:
                    return installationsWithGeo.filter((i) => i.installationType === 18).slice(0, 5000)
            }
        }
        if (showCities) {
            return cities.map((c) => {
                return { ...c, typeName: 'other', scale: 0.5 }
            })
        }
        if (showManagementUnits) {
            return managementUnitsFormatted
        }
        return []
    }

    getDefaultLayer = () => {
        const { filter } = this.state
        const { themeLayers, applicationSettings } = this.props
        switch (filter.pointsType) {
            case 2:
                const idThemeESU = getSettingInt(applicationSettings, 'themeCartoESU')
                return idThemeESU ? compact([themeLayers.find((t) => t.id === idThemeESU)]) : []
            case 3:
                const idThemeESO = getSettingInt(applicationSettings, 'themeCartoESO')
                return idThemeESO ? compact([themeLayers.find((t) => t.id === idThemeESO)]) : []
            case 1: default:
                return []
        }
    }

    openModalUGE = (ugeData) => {
        const { filter, managementUnitsFormatted } = this.state
        // WIP
        // const startDate = filter.volumesShow !== 'consumed' ? new Date(new Date(filter.dateShow).getFullYear(), 0, 1).getTime() : filter.dateShow
        // const endDate = filter.volumesShow !== 'consumed' ? filter.dateShow : filter.dateShowEnd
        this.props.fetchManagementUnitsDetailsVolumesByDate(ugeData.managementCode, filter.dateShow, filter.dateShowEnd, filter).then((results) => {
            const totalConsumed = filter.volumesShow === 'consumed' ?
                (managementUnitsFormatted.find((m) => m.managementCode === ugeData.managementCode) || {}).sortValueVolume :
                (results.pointsVolumes || []).map(({ consumedVolumes }) => consumedVolumes || 0).reduce((a, b) => a + b, 0)
            this.setState({ openModalUGE: true, ugeData: { ...ugeData, results, consumedVolumes: hasValue(totalConsumed) ? `${formatMilliers(totalConsumed) || 0} m3` : '' } })
        })
    }

    getModalUGE = () => {
        const { openModalUGE, ugeData, filter } = this.state
        if (openModalUGE) {
            return (
                <ManagementUnitModal
                    open={openModalUGE}
                    onClose={() => this.setState({ openModalUGE: false, ugeData: {} })}
                    data={ugeData}
                    year={getYear(filter.dateShow)}
                    filter={filter}
                />
            )
        }
        return null
    }

    render() {
        const { dataLoaded } = this.state
        return (
            <div className='row no-margin padding-left-1 padding-right-1'>
                <CartographyPanel
                    panels={this.getPanels()}
                    stationsPoints={dataLoaded ? this.getData() : []}
                    layers={ ['STATIONS_POINTS'] }
                    noStationPanel
                    showAllLayers={true}
                    popupStyle={{ padding: 0 }}
                    componentType='territory'
                    defaultLayers={this.getDefaultLayer()}
                />
                {this.getModalUGE()}
            </div>
        )
    }
}

TerritoryDashboardApp.propTypes = {
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    volumesSurveys: PropTypes.arrayOf(PropTypes.instanceOf(DtoTerritoryUgeVolume)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    push: PropTypes.func,
    setTitle: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchManagementUnitsVolumesByYear: PropTypes.func,
    fetchManagementUnitsDetailsVolumesByDate: PropTypes.func,
    toastrInfo: PropTypes.func,
}

const mapStateToProps = store => ({
    installationsWithGeo: store.InstallationReducer.installationsWithGeo,
    cities: store.CityReducer.cities,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    volumesSurveys: store.AgriReducer.volumesSurveys,
    themeLayers: store.HomeReducer.themeLayers,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    iaeauModels: store.IAEauReducer.iaeauModels,
})

const mapDispatchToProps = {
    push,
    setTitle: HomeAction.setTitle,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchManagementUnitsVolumesByYear: AgriAction.fetchManagementUnitsVolumesByYear,
    fetchManagementUnitsDetailsVolumesByDate: AgriAction.fetchManagementUnitsDetailsVolumesByDate,
    fetchForecastsUge: AgriAction.fetchForecastsUge,
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    fetchSetting: AdministrationAction.fetchSetting,
    getModels: IAEauAction.getModels,
    launchModel: IAEauAction.launchModel,
    toastrInfo: ToastrAction.info,
    warning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryDashboardApp)
