import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { isNil, template } from 'lodash'
import i18n from 'simple-react-i18n'
import ProgressCard from 'components/card/ProgressCard'
import { hasValue } from 'utils/NumberUtil'
import AEPCriterias from 'quality/components/AEPoverview/AEPCriterias'
import PropTypes from 'prop-types'
import { getBeginingOfTheYear, getEndOfTheYear } from 'utils/DateUtil'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import QualityAction from 'quality/actions/QualityAction'
import { exportFile } from 'utils/ExportDataUtil'
import ToastrAction from 'toastr/actions/ToastrAction'
import ExportAction from 'export/actions/ExportAction'
import AEPoverviewPanel from './AEPoverviewPanel'
import AEPwaterTypePanel from './AEPwaterTypePanel'
import AEPparametersPanel from './AEPparametersPanel'
import AEPnonCompliancesPanel from './AEPnonCompliancesPanel'
import useActions from 'utils/customHook/useActions'
import SimpleTabList from 'components/list/SimpleTabList'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import OperationAction from 'quality/actions/OperationAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import { calculateThresholdResult } from 'utils/AnalyseUtils'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import AdministrationAction from 'administration/actions/AdministrationAction'
import AccountAction from 'account/actions/AccountAction'

const OVERVIEW = 'overview'
const PARAMETERS = 'parameters'
const WATER_TYPE = 'waterType'
const NON_COMPLIANCES = 'nonCompliances'
const SEUILS_REF = 'SEUILS_REFERENCE'
const SEUILS_LIM = 'SEUILS_LIMITE'
const DEFAULT_SUPPORT = 'defaultSupport'
const DEFAULT_SUPPORT_VALUE = '-1'

const AEPTab = ({
    ids = [],
    filter = {},
    analysis = [],
    operations = [],
    isFirstSearchDone = false,
    dataLoaded = false,
    progress = 0,
    modelExport = [],
}) => {
    const {
        qualityThresholds,
    } = useSelector(store => ({
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const thresholdList = useMemo(() => {
        const referenceThreshold = filter.referenceThreshold && qualityThresholds.find(t => t.thresholdCode == filter.referenceThreshold)?.thresholds || []
        const limitThreshold = filter.limitThreshold && qualityThresholds.find(t => t.thresholdCode == filter.limitThreshold)?.thresholds || []
        return [
            ...referenceThreshold,
            ...limitThreshold,
        ]
    }, [filter.limitThreshold, filter.referenceThreshold, qualityThresholds])

    const formatedAnalysis = useMemo(() => {
        return analysis
            .filter(a => a.remark !== '0')
            .map(a => {
                const operation = operations.find(o => o.id === a.operation && o.qualitometer === a.qualitometer)
                return {
                    ...a,
                    ...calculateThresholdResult(a, thresholdList),
                    producer: operation?.producer,
                }
            })
    }, [analysis, operations, thresholdList])

    const onExport = (exportData) => {
        const {
            producer,
            year,
            support,
            limitThreshold,
            referenceThreshold,
        } = filter
        const criterias = {
            producer,
            startDate: getBeginingOfTheYear(year),
            endDate: getEndOfTheYear(year),
            support: support !== DEFAULT_SUPPORT_VALUE ? support : undefined,
            stations: ids,
            threshold: hasValue(limitThreshold) ? limitThreshold : referenceThreshold,
        }
        exportFile(exportData, false, {}, false, {}, false, criterias, modelExport)
    }

    return (
        <SimpleTabList
            defaultTab={OVERVIEW}
            tabs={[
                {
                    constant: OVERVIEW,
                    label: i18n.overview,
                    icon: 'playlist_add_check',
                },
                {
                    constant: PARAMETERS,
                    label: i18n.parameter,
                    icon: 'bubble_chart',
                },
                {
                    constant: WATER_TYPE,
                    label: i18n.waterType,
                    icon: 'format_list_bulleted',
                },
                {
                    constant: NON_COMPLIANCES,
                    label: i18n.noncompliances,
                    icon: 'warning',
                },
            ]}
        >
            {
                tab => {
                    if (!isFirstSearchDone) {
                        return (
                            <div className='row no-margin padding-top-1 padding-left-3 padding-bottom-1' >
                                <h5>{i18n.pleaseSearch}</h5>
                            </div>
                        )
                    }
                    if (!dataLoaded) {
                        return (
                            <ProgressCard progress={progress} />
                        )
                    }
                    if (dataLoaded && !analysis.length) {
                        return (
                            <div className='row no-margin padding-top-1 padding-left-3 padding-bottom-1' >
                                <h5>{template(i18n.noAnalysisForYear)({ year: filter.year })}</h5>
                            </div>
                        )
                    }
                    return (
                        <div style={{ padding: '10px' }}>
                            {
                                tab === OVERVIEW && (
                                    <AEPoverviewPanel
                                        filter={filter}
                                        onExport={onExport}
                                        thresholdList={thresholdList}
                                        operations={operations}
                                        analysis={formatedAnalysis}
                                    />
                                )
                            }
                            {
                                tab === PARAMETERS && (
                                    <AEPparametersPanel
                                        filter={filter}
                                        onExport={onExport}
                                        thresholdList={thresholdList}
                                        analysis={formatedAnalysis}
                                    />
                                )
                            }
                            {
                                tab === WATER_TYPE && (
                                    <AEPwaterTypePanel
                                        filter={filter}
                                        onExport={onExport}
                                        thresholdList={thresholdList}
                                        analysis={formatedAnalysis}
                                    />
                                )
                            }
                            {
                                tab === NON_COMPLIANCES && (
                                    <AEPnonCompliancesPanel
                                        filter={filter}
                                        onExport={onExport}
                                        operations={operations}
                                        thresholdList={thresholdList}
                                        analysis={formatedAnalysis}
                                    />
                                )
                            }
                        </div>
                    )
                }
            }
        </SimpleTabList>
    )
}

AEPTab.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.shape({})),
    filter: PropTypes.shape({}),
    analysis: PropTypes.arrayOf(PropTypes.shape({})),
    operations: PropTypes.arrayOf(PropTypes.shape({})),
    isFirstSearchDone: PropTypes.bool,
    dataLoaded: PropTypes.bool,
    progress: PropTypes.number,
    modelExport: PropTypes.arrayOf(PropTypes.shape({})),
}

const AEPOverview = ({
    ids = [],
}) => {
    const dispatch = useDispatch()

    const {
        accountUserSettings,
        applicationSettings,
    } = useSelector(store => ({
        accountUserSettings: store.AccountReducer.accountUserSettings,
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    useActions(() => ({}), [])

    const thresholdRefSettings = useApplicationSetting(SEUILS_REF, setting => setting ? parseInt(setting) : undefined)
    const thresholdRefAccountSettings = useAccountSetting(SEUILS_REF, setting => setting ? parseInt(setting) : undefined)

    const thresholdLimSettings = useApplicationSetting(SEUILS_LIM, setting => setting ? parseInt(setting) : undefined)
    const thresholdLimAccountSettings = useAccountSetting(SEUILS_LIM, setting => setting ? parseInt(setting) : undefined)

    const defaultSupport = useAccountSetting(DEFAULT_SUPPORT, setting => setting || undefined)

    const [filter, setFilter] = useState({
        support: defaultSupport,
        year: moment().year() - 1,
        producer: undefined,
        referenceThreshold: thresholdRefAccountSettings ?? thresholdRefSettings,
        limitThreshold: thresholdLimAccountSettings ?? thresholdLimSettings,
    })
    const [isFirstSearchDone, setFirstSearchDone] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)
    const [modelExport, setModelExport] = useState([])

    const [operations, setOperations] = useState([])
    const [analysis, setAnalysis] = useState([])

    const {
        progress: criteriasProgress,
        isLoaded,
    } = useProgressDispatch(() => [
        dispatch(ParameterAction.fetchParameters()),
        dispatch(QualityAction.fetchRemarks()),
        dispatch(ContributorAction.fetchProducers()),
        dispatch(QualityAction.fetchQualifications()),
        dispatch(QualityAction.fetchStatus()),
        dispatch(ContributorAction.fetchLaboratories()),
        dispatch(ContributorAction.fetchDeterminators()),
    ])

    useEffect(() => {
        const controller = new AbortController()
        if (ids.length) {
            setOperations([])
            dispatch(OperationAction.getAllOperation(ids, controller.signal)).then(op => setOperations(op.map(o => new DtoOperation(o))))
        }
        return () => {
            controller.abort()
        }
    }, [ids])

    useEffect(() => {
        if (!accountUserSettings.length) {
            dispatch(AccountAction.fetchAccountUserSettings())
        }
        if (!applicationSettings.length) {
            dispatch(AdministrationAction.fetchSettings())
        }
        dispatch(ExportAction.getEnvironmentModelsByType('qualityResearch')).then(modelList => setModelExport(modelList))
    }, [])

    const onValidate = useCallback((newFilter) => {
        const {
            support,
            year,
            producer,
            referenceThreshold,
            limitThreshold,
        } = newFilter
        if (!hasValue(referenceThreshold) && !hasValue(limitThreshold)) {
            dispatch(ToastrAction.error(i18n.noThresholdSelectedError))
            return
        }
        dispatch(AdministrationAction.updateSetting(DEFAULT_SUPPORT, newFilter.support || DEFAULT_SUPPORT_VALUE))
        setDataLoaded(false)
        setFirstSearchDone(true)
        setProgress(0)
        setFilter(newFilter)
        const criterias = {
            lightMode: true,
            stations: ids,
            startDate: getBeginingOfTheYear(year),
            endDate: getEndOfTheYear(year),
            support: support !== DEFAULT_SUPPORT_VALUE ? support : undefined,
            producers: isNil(producer) ? undefined : [producer],
        }
        AnalysisAction.getAnalysis(criterias).then(an => {
            setAnalysis(an.map(a => new DtoAnalysisLight(a)))
            setDataLoaded(true)
        })
    }, [dispatch, ids])

    if (!isLoaded) {
        return <ProgressCard progress={criteriasProgress} />
    }

    return (
        <div className='padding-left-1 padding-right-1'>
            <div className='row no-margin'>
                <AEPCriterias
                    onValidate={onValidate}
                    defaultFilter={filter}
                    operations={operations}
                />
                <AEPTab
                    ids={ids}
                    filter={filter}
                    operations={operations}
                    analysis={analysis}
                    isFirstSearchDone={isFirstSearchDone}
                    dataLoaded={dataLoaded}
                    progress={progress}
                    modelExport={modelExport}
                />
            </div>
        </div>
    )
}

AEPOverview.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default AEPOverview
export {
    AEPTab,
    OVERVIEW,
    PARAMETERS,
    WATER_TYPE,
    NON_COMPLIANCES,
    SEUILS_REF,
    SEUILS_LIM,
    DEFAULT_SUPPORT,
    DEFAULT_SUPPORT_VALUE,
}