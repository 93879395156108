import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Job from '../../../import/dto/DtoJob'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import JobAlert from './JobAlert'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../store/AppStore'


class AlertPluvio extends Component {
    componentDidMount() {
        this.props.fetchPluviometers()
        this.props.fetchPluviometerAllThresholds()
        if (!this.props.pluviometryDataTypes.length) {
            AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { modeCumulPluvio: 'SUM_PERSO', nbHoursCumulPerso: 24, ...newFilters }
    }

    render() {
        const formatThresholds = this.props.pluviometerThresholds.map(t => {
            const dt = this.props.pluviometryDataTypes.find(d => d.id === parseInt(t.dataType ?? '1'))
            const sign = t.isOverrunThreshold === '1' ? '>' : '<'
            return {
                ...t,
                title: `${t.title} (${dt?.label ?? ''} ${sign} ${t.value} ${dt?.unit ?? ''})`,
                key: `${t.dataType ?? '1'}:${t.id}`,
            }
        })
        return (
            <JobAlert
                stationType='pluviometry'
                filters={this.getFilters()}
                job={this.props.job}
                isEditMode={this.props.isEditMode}
                stations={this.props.pluviometers}
                thresholds={formatThresholds}
                onChangeEmails={this.props.onChangeEmails}
                onChangeContactsIds={this.props.onChangeContactsIds}
                onChangeStation={this.props.onChangeStation}
                onChangeAlertTypes={this.props.onChangeAlertTypes}
                onChangeFilters={this.onChangeFilters}
            />
        )
    }
}

AlertPluvio.propTypes = {
    onChangeJob: PropTypes.func,
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    pluviometers: PropTypes.arrayOf(PropTypes.object),
    pluviometerThresholds: PropTypes.arrayOf(PropTypes.object),
    fetchPluviometers: PropTypes.func,
    fetchPluviometerAllThresholds: PropTypes.func,
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    pluviometers: store.PluviometryReducer.pluviometers.map(({ id, code, name }) => ({
        id,
        code,
        name: `[${code}] ${name}`,
    })),
    pluviometerThresholds: store.PluviometryReducer.pluviometerAllThresholds,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
})

const mapDispatchToProps = {
    fetchPluviometers: PluviometryAction.fetchPluviometers,
    fetchPluviometerAllThresholds: PluviometryAction.fetchPluviometerAllThresholds,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertPluvio)