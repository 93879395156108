import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import React, { useEffect, useState } from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../../components/ActionComponent'
import Other from '../../../../../components/actions/Other'
import Table from '../../../../../components/datatable/Table'
import DtoFile from '../../../../../components/file/dto/DtoFile'
import Icon from '../../../../../components/icon/Icon'
import SieauAction from '../../../../../components/sieau/SieauAction'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import UnitDto from '../../../../../referencial/components/unit/dto/UnitDto'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../../../store/AppStore'
import { getAccreditationPicto, getResultFormat } from '../../../../../utils/AnalyseUtils'
import { setConfirmationModal } from '../../../../../utils/FormUtils'
import { getLabel } from '../../../../../utils/StoreUtils'
import OperationAction from '../../actions/OperationAction'
import DtoIndice from '../../dto/DtoIndice'
import OperationIndexModal from './OperationIndexModal'
import QualityAction from 'quality/actions/QualityAction'
import useBoolean from 'utils/customHook/useBoolean'
import useAbortController from 'utils/customHook/useAbortController'
import { isUndefined, max, min } from 'lodash'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { Grid } from '@mui/material'
import { StyledFieldSet } from 'components/StyledElements'
import moment from 'moment'
import { YEAR, getIntervalFormatter } from 'quality/constants/ChartConstant'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'
import useActions from 'utils/customHook/useActions'

const headers = ['nullValue', 'nullValue3', 'parameter', 'result', 'comment']

const Graph = ({
    indexes = [],
    parameter,
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const defaultTimestamp = moment().valueOf()

    const allDate = indexes.map(i => i.sampleDate ?? defaultTimestamp)

    const minDate = moment(min(allDate))
    const maxDate = moment(max(allDate))
    const chartMinDate = minDate.startOf(YEAR)
    const chartMaxDate = maxDate.endOf(YEAR)

    const {
        formatter,
        interval,
    } = getIntervalFormatter(chartMaxDate, chartMinDate)

    const name = parameters.find(p => p.code === parameter)?.displayName ?? ''

    const options = {
        series: [{
            type: 'bar',
            name,
            data: indexes.map(i => [i.sampleDate, i.result]),
            barMaxWidth: 10,
        }],
        xAxis: [{
            type: 'time',
            boundaryGap: true,
            axisLabel: {
                formatter,
                rotate: 50,
            },
            splitLine: {
                show: true,
            },
            interval,
            min: chartMinDate.valueOf(),
            max: chartMaxDate.valueOf(),
        }],
        yAxis: [{
            type: 'value',
            minInterval: 1,
            showSplitLine: true,
        }],
        grid: {
            top: '10',
            left: '10',
            right: '10',
            bottom: '50',
            containLabel: true,
            height: 290,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                animation: false,
                label: {
                    backgroundColor: '#505765',
                },
            },
        },
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge
            lazyUpdate
            style={{ height: 300 }}
        />
    )
}

Graph.propTypes = {
    indexes: PropTypes.arrayOf(PropTypes.shape({})),
    parameter: PropTypes.string,
}

const IndexesPopin = ({
    isOpen = false,
    close = () => {},
    parameter,
    qualitometer,
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        parameters,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const [indexes, setIndexes] = useState([])
    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean(false)

    useEffect(() => {
        const code = qualitometers.find(q => q.id === qualitometer)?.code
        if (isUndefined(parameter) || isUndefined(code) || !isOpen) {
            return
        }

        notLoaded()
        setIndexes([])
        initController()

        const indexesFilter = {
            lightMode: true, // Option[Boolean] = None,

            parameters: [parameter], // Option[Seq[String]] = None,
            stations: [code], // Option[Seq[Int]] = None
        }

        QualityAction.getIndices(indexesFilter, controllerRef.current.signal)
            .then(setIndexes)
            .finally(loaded)

        // eslint-disable-next-line consistent-return
        return () => controllerRef.current.abort()
    }, [dispatch, parameter, qualitometer, isOpen])

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: undefined,
                    maxHeight: undefined,
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {parameters.find(p => p.code === parameter)?.name ?? ''}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={close} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <StyledFieldSet>
                            <div style={{ position: 'relative' }}>
                                {(!isLoaded) && (<CircularProgressWithLabel />)}
                                <Graph indexes={indexes} parameter={parameter}/>
                            </div>
                        </StyledFieldSet>
                    </Grid>
                </Grid>
            </DialogContentMUI>
        </DialogMUI>
    )
}

IndexesPopin.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    parameter: PropTypes.string,
    qualitometer: PropTypes.number,
}

const IndexesTable = ({
    isEditMode = false,
    onDelete = () => {},
    onAlter = () => {},
    openPopin = () => {},
}) => {
    const {
        indices,
        parameters,
    } = useSelector(store => ({
        indices: store.OperationReducer.operationIndices,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const data = indices.map(i => {
        return {
            nullValue: getAccreditationPicto(i.accreditation === 1),
            nullValue3: i.calculated ? <Icon size='small' icon='wifi' tooltip={'Open Data'} /> : '',
            parameter: getLabel(parameters, i.parameter, 'labelWithCode'),
            result: getResultFormat(i),
            comment: i.comment,
            color: i.calculated ? '#b8d2ff' : '#fff',
            indice: i,
        }
    })
    return (
        <Table
            showTitle={false}
            data={data}
            type={{ headers }}
            condensed
            coloredLine
            sortable
            paging
            alterable={isEditMode}
            onAlter={onAlter}
            deletable={isEditMode}
            onDelete={onDelete}
            onClick={openPopin}
            nbPerPageLabel={nbPerPageLabel}
        />
    )
}

const OperationHydrobioIndicesPanel = () => {
    const {
        accountUser,
        hydrobioOperation,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    useActions(() => {
        if (![4, 10, 11, 13, 27].includes(hydrobioOperation.support) || accountUser.consultant === '1') return {}
        return {
            calculateIndexes: {
                qualitometer: hydrobioOperation.qualitometer,
                operations: [hydrobioOperation.id],
                support: hydrobioOperation.support,
            },
        }
    })

    return (
        <IndexesTable />
    )
}

IndexesTable.propTypes = {
    isEditMode: PropTypes.bool,
    onDelete: PropTypes.func,
    onAlter: PropTypes.func,
    openPopin: PropTypes.func,
}

class OperationIndicesPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            info: {},
            isOpen: false,
        }
    }


    componentDidMount() {
        if (!this.props.noAction) {
            this.setReadMode()
        }
    }

    setReadMode = () => {
        this.setState({ editMode: false })
        const actions = {
            importFile: {
                onClick: this.props.uploadFile,
                format: '',
                tooltip: i18n.importAnalysisFile,
            },
            edit: this.setEditMode,
        }
        if (this.props.files.length) {
            actions.other = {
                other: (
                    <Other
                        className='clickable'
                        tooltip={i18n.operation}
                        icon='attach_file'
                        onClick={this.props.getOperationFiles}
                    />
                ),
            }
        }
        this.setActions(actions)
    }

    setEditMode = () => {
        this.setState({ editMode: true })
        const actions = {
            importFile: {
                onClick: this.props.uploadFile,
                format: '',
                tooltip: i18n.importAnalysisFile,
            },
            cancel: this.setReadMode,
            new: () => this.setEditModal({ qualitometerId: this.props.qualitometerId, operationId: this.props.operationId }, true),
        }
        if (this.props.files.length) {
            actions.other = {
                other: (
                    <Other
                        className='clickable dropdown-button'
                        tooltip={i18n.operation}
                        icon='attach_file'
                        onClick={this.props.getOperationFiles}
                    />
                ),
            }
        }
        this.setActions(actions)
    }

    setEditModal = (indice, isNew) => {
        const buttons = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.cancel}</a>
                <a id='saveIndex' className='waves-effect waves-teal btn-flat'>{i18n.register}</a>
            </div>
        )
        const newIndex = { ...indice, qualitometerId: this.props.qualitometerId, operationId: this.props.operationId }
        const popup = {
            id: 'indexModal',
            header: i18n.edit,
            actions: buttons,
            fixedFooter: true,
            dismissible: false,
            className: 'hugeModal hightHeightModal',
            content: <OperationIndexModal index={newIndex} isNew={isNew} />,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    onDelete = (indice) => setConfirmationModal(() => {
        AppStore.dispatch(OperationAction.updateOperationIndex(indice, 'DELETE'))
    })

    render() {
        return (
            <>
                <IndexesTable
                    isEditMode={this.state.editMode}
                    onDelete={({ indice }) => this.onDelete(indice)}
                    onAlter={({ indice }) => this.setEditModal(indice, false)}
                    openPopin={({ indice }) => this.setState({ info: {
                        parameter: `${indice.parameter}`,
                        qualitometer: indice.qualitometerId,
                    }, isOpen: true })}
                />
                <IndexesPopin
                    isOpen={this.state.isOpen}
                    close={() => this.setState({ info: {}, isOpen: false })}
                    parameter={this.state.info.parameter}
                    qualitometer={this.state.info.qualitometer}
                />
            </>
        )
    }
}

OperationIndicesPanel.propTypes = ({
    noAction: PropTypes.bool,
    indices: PropTypes.arrayOf(PropTypes.instanceOf(DtoIndice)),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    qualitometerId: PropTypes.number,
    operationId: PropTypes.number,
    calcules: PropTypes.arrayOf(PropTypes.bool),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    getOperationFiles: PropTypes.func,
})

const mapStateToProps = store => ({
    indices: store.OperationReducer.operationIndices,
    parameters: store.ParameterReducer.parameters,
    units: store.UnitReducer.units,
})

export default connect(mapStateToProps)(OperationIndicesPanel)
export {
    OperationHydrobioIndicesPanel,
}
