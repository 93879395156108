import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'


const TabSelectThreshold = ({
    stations = [],
    thresholds = [],
    stationsSelected = [],
    onChangeStation = () => {},
    onChange = () => {},
    onDeleteStation = () => {},
    onDuplicateStation = () => {},
    disabled = true,
    actions= [],
    exportButtonOnHeader= false,
    stationType = 'hydrometry'
}) => {
    const getLine = (id, thresholdId, thresholdTypeId, timeLinitMissingData, thresholdList, index) => ({
        station: (
            <Select
                options={stations}
                onChange={(_, value) => onChangeStation(value, index)}
                value={id}
                keyValue='id'
                clearFunction
                disabled={disabled}
                divInputStyle={ { width: 400 } }
            />
        ),
        threshold: thresholdList.length ? (
            <Select
                options={thresholdList}
                value={thresholdId && thresholdTypeId ? `${thresholdTypeId}:${thresholdId}` : undefined}
                onChange={v => onChange('threshold', v, index)}
                keyLabel={'title'}
                keyValue={'key'}
                nullLabel='&nbsp;'
                disabled={disabled}
                style={{ minWidth: '250px' }}
                divInputStyle={ { width: 400 } }
            />
        ) : (
            <div className='col s4 no-margin halign-wrapper'>
                <div className='col s12'>
                    <span >{i18n.noThresholds}</span>
                </div>
            </div>
        ),
        timeLinitMissingData: (
            <NumberField
                min={1}
                value={timeLinitMissingData}
                onChange={v => onChange('timeLinitMissingData', v, index)}
                disabled={disabled}
            />
        ),
    })

    const getList = () => stationsSelected.map(({ id, code, thresholdId, thresholdTypeId, timeLinitMissingData }, i) => {
        const thresholdList = stationType === 'hydrometry' ? (thresholds.filter(t => id.toString() === t.stationId)) : code ? thresholds.filter(t => t.code && t.code.toLowerCase() === code.toLowerCase()) : []
        return getLine(id, thresholdId, thresholdTypeId, timeLinitMissingData, thresholdList, i)
    })

    const exportData = stationsSelected.map(p => ({
        code: p.code,
        id: p.id,
        timeLinitMissingData: p.timeLinitMissingData,
        headers: ['code', 'id', 'timeLinitMissingData'],
    }))

    return (
        <div className='row no-margin card no-box-shadow transparent'>
            <div className='col s12 no-padding'>
                <div className='job-parameter-table'>
                    <Table
                        title={i18n.stations}
                        data={getList()}
                        type={{ headers: ['station', 'threshold', 'timeLinitMissingData'] }}
                        nbPerPageLabel={nbPerPageLabelShort}
                        deletable={!disabled}
                        onDelete={onDeleteStation}
                        duplicable={!disabled}
                        onDuplicate={onDuplicateStation}
                        sortable
                        condensed
                        paging
                        overflow='visible'
                        exportButtonOnHeader={ exportButtonOnHeader }
                        exportData={ exportData }
                        actions={ actions }
                        activeHeader
                        customWidthHeaders={{
                            station: '40%',
                            threshold: '30%',
                            timeLinitMissingData: '30%',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

TabSelectThreshold.propTypes = {
    stationType: PropTypes.string,
    stations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
    })),
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        title: PropTypes.string,
    })),
    stationsSelected: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
    })),
    onChangeStation: PropTypes.func,
    onChange: PropTypes.func,
    onDeleteStation: PropTypes.func,
    onDuplicateStation: PropTypes.func,
    disabled: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        onClick: PropTypes.func,
        color: PropTypes.string,
        iconName: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
    })),
    exportButtonOnHeader: PropTypes.bool,
}

export default TabSelectThreshold