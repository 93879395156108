import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import useTitle from '../../../utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getSortedTableData } from '../../../components/datatable/TableUtils'
import SieauAction from '../../../components/sieau/SieauAction'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import PerimeterDto from '../../dto/PerimeterDto'
import useActions from '../../../utils/customHook/useActions'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import PerimetersStepper from './components/PerimetersStepper'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import { capitalize, compact, sum } from 'lodash'
import PerimetersThunk from '../../thunk/PerimetersThunk'
import { PERIMETERS_STATES_COLORS } from '../constants/PerimetersConstants'
import useSandreList from '../../../utils/customHook/useSandreList'
import ContributorThunk from '../../../referencial/components/contributor/actions/ContributorThunk'
import MultiContributorsAutocomplete
    from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import PerimetersImportStepper from '../perimeters/components/PerimetersImportStepper'
import { exportFile } from '../../../utils/ExportDataUtil'
import { push } from '@lagunovsky/redux-react-router'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import { WhiteCard } from 'components/styled/Card'
import UpdatePanel from 'components/updatePanel/UpdatePanel'
import ReferencialAction from 'referencial/action/ReferencialAction'
import PerimeterStatus from '../../../components/PerimeterStatus'
import Card from 'components/card/Card'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import { useParams } from 'react-router'

const PERIMETER_HEADER = ['name', 'type', 'zoning', 'cities', 'nbParcels', 'nbOwners', 'nbOperators', 'status']

const PerimetersTable = ({
    perimeters = [],
    setPerimeter,
    readMode,
}) => {
    const {
        selectedSearchValues,
        citiesIndex,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const { perimeter: searchValues } = selectedSearchValues
    const perimeterTypes = useSandreList('PERIMETRES.TYPE')

    const dispatch = useDispatch()

    const lexiconStatus = useSandreList('PARCELLES.STATUT')

    const perimetersFormated = perimeters.map(perimeter => {
        const perimeterCities = perimeter.cities?.map(p => capitalize(citiesIndex[p]?.name))

        return {
            id: perimeter.id,
            folder: perimeter.folder,
            name: perimeter.name,
            type: perimeterTypes.find(pt => pt.code === perimeter.perimeterType)?.name || perimeter.perimeterType || '',
            perimeterType: perimeter.perimeterType,
            zoning: perimeter.zoning,
            cities: perimeterCities?.join(', ') ?? '',
            nbParcels: perimeter.parcels,
            nbOwners: (perimeter.privateOwners + perimeter.companyOwners),
            nbOperators: '',
            status: (
                <PerimeterStatus
                    color={PERIMETERS_STATES_COLORS[perimeter.status] || PERIMETERS_STATES_COLORS[0]}
                    label={lexiconStatus.find(s => s.code === perimeter.status)?.name ?? ''}
                />
            ),
        }
    })

    const initialSort = searchValues?.columnName && { column: searchValues.columnName, sort: searchValues.sort }

    const stationsHash = stations => stations.map(s => s.code).join('')

    const onTableSort = (columnName, sort) => {
        const sortedStations = getSortedTableData(perimetersFormated, { column: columnName, sort }, false)
        if (!searchValues || !searchValues.stations || stationsHash(searchValues.stations) !== stationsHash(sortedStations)) {
            dispatch(SieauAction.update('selectedSearchValues', 'perimeter', {
                stations: sortedStations,
                columnName,
                sort,
            }))
        }
    }

    const exportData = () => {
        exportFile({
            data: perimeters.length ? perimeters.map(d => ({
                identifier: { value: d.id },
                name: { value: d.name || '' },
                type: perimeterTypes.find(pt => pt.code === d.perimeterType).label || d.perimeterType || '',
                zoning: { value: d.zoning || '' },
                cities: { value: '' },
                nbParcels: { value: d.parcels.toString() },
                nbOwners: { value: sum([d.privateOwners, d.companyOwners]).toString() },
                nbOperators: { value: '' },
                status: { value: '' },
                headers: ['identifier', ...PERIMETER_HEADER],
            })) : [],
            exportType: 'xlsx',
            titleFile: i18n.perimeters,
        })
    }

    return (
        <Table
            title={i18n.perimeters}
            data={perimetersFormated}
            paging
            actions={[{
                iconName: 'download',
                tooltip: i18n.export,
                color: 'black',
                onClick: () => exportData(),
            }, !readMode && {
                iconName: 'note_add',
                tooltip: i18n.add,
                color: 'black',
                onClick: () => {
                    setPerimeter({ id: -1 })
                },
            }]}
            initialSort={initialSort}
            nbPerPageLabel={nbPerPageLabel}
            deletable={!readMode}
            onSort={onTableSort}
            type={{ headers: PERIMETER_HEADER }}
            customHeaders={{
                nbParcels: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbParcelsReturnLine}</span>),
                nbOwners: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbOwnersReturnLine}</span>),
                nbOperators: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbOperatorsReturnLine}</span>),
            }}
            onClick={setPerimeter}
            condensed
            sortable
            invertedHeaderStyle
            round
        />
    )
}

PerimetersTable.propTypes = {
    perimeters: PropTypes.arrayOf(PerimeterDto),
    setPerimeter: PropTypes.func,
    readMode: PropTypes.bool,
}

const PerimeterDashboard = () => {
    const { id } = useParams()

    const {
        cities,
        contributors,
        perimetersFolder,
        perimeters,
        sandreCodes,
        perimeterFolderLastEvent,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
        perimeters: store.PerimetersReducer.perimeters,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        perimeterFolderLastEvent: store.PerimetersReducer.perimeterFolderLastEvent,
    }), shallowEqual)

    const parsedId = parseInt(id)

    const lexiconStatus = useSandreList('PARCELLES.STATUT')
    const lexiconStates = useSandreList('PARCELLES.ETAT')

    const [perimeterFolder, setPerimeterFolder] = useState({})

    const [readMode, setReadMode] = useState(true)
    const [importStepperIsOpen, setImportStepperIsOpen] = useState(false)
    const [exportOpen, setExportOpen] = useState(false)
    const [perimeter, setPerimeter] = useState({})

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        dispatch(PerimetersThunk.getPerimeters(id)),
        !cities.length && dispatch(CityAction.fetchCities()),
        !perimetersFolder.length && dispatch(PerimetersThunk.getPerimetersFolders()),
        !sandreCodes.length && dispatch(ReferencialAction.fetchSandreCodes()),
        !contributors.length && dispatch(ContributorThunk.fetchContributors()),
        !perimeterFolderLastEvent?.perimeterFolderId && dispatch(PerimetersThunk.getPerimeterFolderLastEvent(parsedId)),
    ]), [])

    useEffect(() => {
        if (perimetersFolder.length) {
            const findedPerimeterFolder = perimetersFolder.find(pf => pf.id === parsedId) || {}
            setPerimeterFolder(findedPerimeterFolder)
        }
    }, [parsedId, perimetersFolder])

    useTitle(() => [{
        title: i18n.perimetersFolder,
        href: 'perimeter',
    }, {
        title: perimeterFolder.id ? perimeterFolder.name : id,
        href: `perimeter/${id}/dashboard`,
    }], [id, perimeterFolder])

    useActions(() => !readMode ? ({
        import: () => setImportStepperIsOpen(true),
        save: () => {
            dispatch(PerimetersThunk.updatePerimetersFolder({
                id: parsedId,
                name: perimeterFolder.name,
                collectivity: perimeterFolder.collectivity,
            })).then(() => {
                dispatch(PerimetersThunk.getPerimetersFolders())
                setReadMode(true)
            })
        },
        cancel: () => setReadMode(true),
        delete: () => dispatch(PerimetersThunk.deletePerimetersFolder(parsedId)).then(() => {
            dispatch(push('/perimeter/dashboard'))
        }),
    }) : ({
        export: () => setExportOpen(true),
        edit: () => setReadMode(false),
    }), [readMode, parsedId, perimeterFolder])

    const exportFormat = [{
        type: i18n.excelFile,
        callback: () => {},
    }, {
        type: i18n.csvFile,
        callback: () => {},
    }]

    return isLoaded ? (
        <Grid container columnSpacing={2} alignItems={'flex-start'} sx={{ padding: '0.75rem 1rem 0 0' }}>
            <Grid container item xs={9} direction='column' rowSpacing={1}>
                <Grid item>
                    <WhiteCard round cardContentStyle={{ marginTop: '8px' }}>
                        <Grid container alignItems='center' sx={{ padding: '1rem' }} rowSpacing={1} columnSpacing={2}>
                            <Grid item xs={8}>
                                <Input
                                    title={i18n.name}
                                    value={perimeterFolder.name}
                                    onChange={name => setPerimeterFolder(prev => ({ ...prev, name }))}
                                    disabled={readMode}
                                    clearFunction
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <MultiContributorsAutocomplete
                                    label={i18n.collectivity}
                                    onChange={collectivity => setPerimeterFolder(prev => ({ ...prev, collectivity }))}
                                    values={perimeterFolder.collectivity}
                                    options={contributors}
                                    disabled={readMode}
                                />
                            </Grid>
                            <Grid container item xs={8} alignItems={'center'} columnSpacing={1}>
                                <Grid item xs={6}>
                                    <Select
                                        options={lexiconStates}
                                        label={i18n.stateOfProgressOfProcedure}
                                        nullLabel='&nbsp;'
                                        value={perimeterFolder.state}
                                        disabled={readMode}
                                        noSort
                                        onChange={state => setPerimeterFolder(prev => ({ ...prev, state }))}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <PerimeterStatus
                                        color={PERIMETERS_STATES_COLORS[perimeterFolder.status] || PERIMETERS_STATES_COLORS[0]}
                                        label={lexiconStatus.find(s => s.code === perimeterFolder.status)?.name ?? ''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </WhiteCard>
                </Grid>
                <Grid item>
                    <PerimetersTable
                        perimeters={perimeters}
                        setPerimeter={setPerimeter}
                        readMode={readMode}
                    />
                </Grid>
            </Grid>
            <Grid container direction='column' item xs={3} rowSpacing={1.25}>
                <Grid item>
                    <UpdatePanel
                        updateDate={perimeterFolder.updateDate}
                        updateLogin={perimeterFolder.updateLogin}
                        round
                    />
                </Grid>
                {/* <Grid container item>
                    <AlertPanel
                         comment={i18n.p1PerimeterNotHaveDeclaredOwners}
                         icon='warning'
                         color='red'
                     />
                 </Grid> */}
                {!!perimeterFolderLastEvent?.comment && (
                    <Grid item>
                        <Card noMargin cardStyle={{ width: '100%', padding: '10px', borderRadius: MAIN_RADIUS }}>
                            <span>{perimeterFolderLastEvent.comment}</span>
                        </Card>
                    </Grid>
                )}
            </Grid>
            <PerimetersImportStepper
                open={importStepperIsOpen}
                setOpen={setImportStepperIsOpen}
            />
            <ExportFileModal
                data={[{
                    name: i18n.plotsList,
                    formats: exportFormat,
                }, {
                    name: i18n.ownersList,
                    formats: exportFormat,
                }, {
                    name: i18n.operatorsList,
                    formats: exportFormat,
                }]}
                open={exportOpen}
                onClose={() => setExportOpen(false)}
            />
            <PerimetersStepper
                perimeterFolderId={parsedId}
                perimeter={perimeter}
                setPerimeter={setPerimeter}
                readMode={readMode}
            />
        </Grid>
    ) : (
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            <ProgressCard progress={progress} />
        </Grid>
    )
}

export default PerimeterDashboard