import { push } from '@lagunovsky/redux-react-router'
import { orderBy, pick } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { H_HYDRO_EXPORT } from '../../../account/constants/AccessRulesConstants'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import Select from '../../../components/forms/Select'
import SelectStationModal from '../../../components/modal/SelectStationModal'
import ExportAction from '../../../export/actions/ExportAction'
import {
    CSV_EXPORT,
    EXCEL_EXPORT,
    EXPORT_JOB_STATUS,
    EXPORT_STATIONS_MEASURES,
    EXPORT_STATIONS_MODELS,
    EXPORT_TYPE_OPTIONS,
    MODEL_EXPORT,
} from '../../../export/constants/ExportConstants'
import HomeAction from '../../../home/actions/HomeAction'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import { nbPerPageLabelTiny } from '../../../referencial/constants/ReferencialConstants'
import StationAction from '../../../station/actions/StationAction'
import StationsExcelExportPanel from '../../../station/components/StationsExcelExportPanel'
import StationsModelExportPanel from '../../../station/components/StationsModelExportPanel'
import DtoExportJob from '../../../station/dto/DtoExportJob'
import { getDateExport, getFullDate, shortenHumanize } from '../../../utils/DateUtil'
import { getDuration, getLoadingBar, getStatusIcon, getStatusLabel } from '../../../utils/ExportJobUtils'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getCurrentLang } from '../../../utils/LangUtils'
import { nFormatter } from '../../../utils/NumberUtil'
import { getLogin } from '../../../utils/SettingUtils'
import HydrometryAction from '../../actions/HydrometryAction'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import { setConfirmationModal } from '../../../utils/FormUtils'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import { Box } from '@mui/material'

class HydrometricStationsExportApp extends ActionComponent {
    isCheckingProgress = false

    state = {
        exportType: undefined,
        model: 0,
        tmpFilter: {
            startDate: undefined,
            endDate: undefined,
            group: 'BRUTE',
            withStatusQualificationCodes: false,
            exportDir: 'EXPORT/tmp',
        },
        selectStationIsOpen: false,
        selectedStations: [],
        dataType: 0,
    }

    setTitle = () => {
        this.props.setTitle([{
            title: i18n.hydrometry,
            href: 'hydrometry',
        }, {
            title: i18n.export,
            href: 'hydrometry',
        }])
    }

    recursiveFetchExportJob = () => {
        if (!this.isCheckingProgress) {
            return
        }
        this.props.fetchExportJobsTmp('hydrometry').then(response => {
            if (response.some(e => e.status === EXPORT_JOB_STATUS.WAITING || e.status === EXPORT_JOB_STATUS.IN_PROGRESS)) {
                setTimeout(() => this.recursiveFetchExportJob(), 5000)
            } else {
                this.isCheckingProgress = false
            }
        })
    }

    regularProgressUpdate = () => {
        this.isCheckingProgress = true
        this.recursiveFetchExportJob()
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_HYDRO_EXPORT)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.setTitle()
        this.props.fetchEnvironmentModelsByType('hydrometry')
        this.props.fetchHydrometryDataTypes()
        this.regularProgressUpdate()
    }

    componentWillUnmount() {
        this.isCheckingProgress = false
    }

    onChangeFilter = (value) => {
        this.setState(({ tmpFilter }) => ({ tmpFilter: { ...tmpFilter, ...value } }))
    }

    onChange = (value) => {
        this.setState(value)
    }

    onCloseModal = () => {
        this.setState({ selectStationIsOpen: false })
    }

    onApplyExport = (type) => {
        const { tmpFilter, dataType, selectedStations } = this.state
        const { waitStart, waitStop, hydrometryDataTypes } = this.props
        waitStart()
        const selectedDataTypes = hydrometryDataTypes.filter(d => !dataType || d.id === dataType)
        const exportData = {
            exportData: selectedStations.map(h => ({
                station: pick({ ...h, stationType: h.typeName }, ['id', 'code', 'name', 'stationType']),
                exportType: type,
                filename: `${h.code}_${getDateExport()}`,
            })),
            startDate: tmpFilter.startDate,
            group: tmpFilter.group,
            endDate: tmpFilter.endDate,
            status: tmpFilter.status,
            qualifications: tmpFilter.qualifications,
            dataTypes: selectedDataTypes.map(({ id, label }) => ({ id, name: label })),
            withStatusQualificationCodes: tmpFilter.withStatusQualificationCodes,
            exportDir: tmpFilter.exportDir,
        }
        if (exportData.exportData.length === 0) {
            this.props.toastrWarning(i18n.noDataOverSelectedStationsAndPeriod)
            waitStop()
            return
        }
        if (this.state.scheduledOnJob && !this.state.name) {
            this.props.toastrWarning('Veuillez choisir un nom pour l\'export programmé.')
            waitStop()
            return
        }
        const exportTmpObj = {
            id: 0,
            exportType: EXPORT_STATIONS_MEASURES,
            login: getLogin(),
            creationDate: 0,
            status: EXPORT_JOB_STATUS.WAITING,
            stationType: 'hydrometry',
            params: JSON.stringify(exportData),
            scheduledOnJob: this.state.scheduledOnJob,
            name: this.state.name,
        }
        this.props.runExportTmp(exportTmpObj).then(() => {
            if (!this.isCheckingProgress) {
                this.regularProgressUpdate()
            }
        })
        waitStop()
    }
    onApplyExportModel = (modelFileName) => {
        const { selectedStations } = this.state
        const { waitStart, waitStop } = this.props
        const fileNameSplit = modelFileName.split('.')
        const type = fileNameSplit[fileNameSplit.length - 1]
        waitStart()
        const exportData = {
            exportData: selectedStations.map(h => ({
                station: pick({ ...h, stationType: h.typeName }, ['id', 'code', 'name', 'stationType']),
                exportType: type,
                filename: `${h.code}_${getDateExport()}`,
            })),
            model: modelFileName,
        }

        if (exportData.exportData.length === 0) {
            this.props.toastrWarning(i18n.noDataOverSelectedStationsAndModel)
            waitStop()
            return
        }
        const exportTmpObj = {
            id: 0,
            exportType: EXPORT_STATIONS_MODELS,
            login: getLogin(),
            creationDate: 0,
            status: EXPORT_JOB_STATUS.WAITING,
            stationType: 'hydrometry',
            params: JSON.stringify(exportData),
        }
        this.props.runExportTmp(exportTmpObj).then(() => {
            if (!this.isCheckingProgress) {
                this.regularProgressUpdate()
            }
        })
        waitStop()
    }
    getExportPanel = () => {
        const { exportType, tmpFilter, selectedStations, model } = this.state
        const { typeEnvironmentModels } = this.props
        const modelTypesOptions = typeEnvironmentModels.filter(t => !t.includes('.xls') || !t.includes('.doc')).map((t, i) => ({ value: i, name: t }))
        if (exportType === MODEL_EXPORT) {
            return (
                <StationsModelExportPanel
                    onApplyExportModel = {this.onApplyExportModel}
                    selectedStations={selectedStations}
                    model={model}
                    modelTypesOptions={modelTypesOptions}
                    onChange={this.onChange}
                    stationType='hydrometry'
                />
            )
        } else if ([CSV_EXPORT, EXCEL_EXPORT].includes(exportType)) {
            return (
                <StationsExcelExportPanel
                    onChangeFilter={this.onChangeFilter}
                    onChange={this.onChange}
                    onApplyExport={() => this.onApplyExport(exportType === EXCEL_EXPORT ? 'xlsx' : 'csv')}
                    selectedStations={selectedStations}
                    tmpFilter={tmpFilter}
                    dataTypes={this.props.hydrometryDataTypes}
                    selectedDataType={this.state.dataType}
                    stationType={STATION_TYPE_NAME.hydrometry}
                />
            )
        }
        return null
    }

    render() {
        const toData = (exports) => orderBy(exports.map(e => ({
            ...e,
            status: { color: 'white', value: getStatusIcon(e.status, 50), setTooltip: () => (<div className='row no-margin valign-wrapper'>{getStatusIcon(e.status, 20)}<div className='padding-left-1'/>{getStatusLabel(e.status)}</div>) },
            type: { value: e.fileType },
            creationDate: { value: getFullDate(e.creationDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            updateDate: { value: getFullDate(e.updateDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            author: { value: e.login },
            exportName: { value: i18n[e.exportType] },
            fileLabel: { value: e.filePath },
            progression: { value: getLoadingBar(e.status, e.progress, e.filePath) },
            size: { value: nFormatter(e.fileSize) },
            duration: { value: e.status === EXPORT_JOB_STATUS.FINISHED || e.status === EXPORT_JOB_STATUS.ERROR ? shortenHumanize((moment.utc(moment(e.updateDate).diff(moment(e.creationDate))).valueOf() || 0), { language: getCurrentLang()[0] }) : getDuration(e.creationDate) },
        })), 'updateDate', 'desc')
        const dataCurrentExports = toData(this.props.exportJobsTmp.filter(e => !e.scheduledOnJob))
        const dataScheduledExports = toData(this.props.exportJobsTmp.filter(e => e.scheduledOnJob))
        return (
            <div className='row no-margin padding-1'>
                <Card title={ i18n.export }>
                    <div className='card-content'>
                        <div className='row valign-wrapper padding-top-1 no-margin'>
                            <Select
                                col={ 3 }
                                label={ i18n.exportName }
                                options={ EXPORT_TYPE_OPTIONS }
                                onChange={ v => this.setState({ exportType: v }) }
                                value={ this.state.exportType }
                            />
                            <Checkbox
                                col={ 3 }
                                label={ i18n.autoProg }
                                checked={ this.state.scheduledOnJob }
                                onChange={ v => this.setState({ scheduledOnJob: v }) }
                            />
                            <Input
                                col={ 3 }
                                title={ i18n.name }
                                value={ this.state.name }
                                onChange={ v => this.setState({ name: v }) }
                            />
                            <div className='col s3'/>
                        </div>
                        { this.getExportPanel() }
                        <SelectStationModal
                            onClose={ this.onCloseModal }
                            selectStationIsOpen={ this.state.selectStationIsOpen }
                            selectedStations={ this.state.selectedStations }
                            stationType='hydrometry'
                            onChangeSelectedStation={ tmpStations => this.setState({ selectedStations: tmpStations }) }
                        />
                    </div>
                </Card>
                <Box sx={{ paddingTop: '2rem' }} />
                <Table
                    title={ i18n.exportsInProgress }
                    data={ dataCurrentExports }
                    type={ { headers: ['status', 'fileLabel', 'duration', 'size', 'creationDate', 'updateDate', 'exportName', 'progression'] } }
                    color
                    sortable
                    paging
                    nbPerPageLabel={ nbPerPageLabelTiny }
                />
                <Box sx={{ paddingTop: '2rem' }} />
                <Table
                    title={ i18n.exportsScheduled }
                    data={ dataScheduledExports }
                    type={ { headers: ['status', 'name', 'fileLabel', 'duration', 'size', 'creationDate', 'updateDate', 'exportName', 'progression'] } }
                    color
                    sortable
                    paging
                    nbPerPageLabel={ nbPerPageLabelTiny }
                    deletable onDelete={ e => setConfirmationModal(() => {
                        StationAction.deleteExportTmp(e.exportTmp)
                        this.regularProgressUpdate()
                    }) }
                />
            </div>
        )
    }
}

HydrometricStationsExportApp.propTypes = ({
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    setTitle: PropTypes.func,
    fetchEnvironmentModelsByType: PropTypes.func,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    exportModel: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    toastrWarning: PropTypes.func,
    exportJobsTmp: PropTypes.arrayOf(PropTypes.instanceOf(DtoExportJob)),
    fetchExportJobsTmp: PropTypes.func,
    fetchHydrometryDataTypes: PropTypes.func,
    runExportTmp: PropTypes.func,
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    push: PropTypes.func,
})

const mapStateToProps = store => ({
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    exportJobsTmp: store.StationReducer.exportJobsTmp,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchHydrometryDataTypes: HydrometryAction.fetchHydrometryDataTypes,
    fetchEnvironmentModelsByType: ExportAction.fetchEnvironmentModelsByType,
    runExportTmp: ExportAction.runExportTmp,
    exportModel: ExportAction.exportModel,
    toastrWarning: ToastrAction.warning,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    fetchExportJobsTmp: StationAction.fetchExportJobsTmp,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometricStationsExportApp)
