/* eslint-disable consistent-return */
import humanize from 'humanize-duration'
import { isNaN, isNull, isUndefined } from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import i18n from 'simple-react-i18n'
import { capitalizeFirstLetter } from './StringUtil'

const moment = extendMoment(Moment)
const TIMEZONE_INTEGRATION = 'TIMEZONE_INTEGRATION'
const TIMEZONE_LOCAL = 'TIMEZONE_LOCAL'

const getDate = (timestamp, format = 'DD/MM/YYYY') => {
    if (isUndefined(timestamp) || isNull(timestamp)) {
        return ''
    }
    return moment(timestamp).format(format)
}

const getFullDate = timestamp => getDate(timestamp, 'DD/MM/YYYY HH:mm:ss')
const getFullDateMini = timestamp => getDate(timestamp, 'DD/MM/YYYY HH:mm')
const getHour = timestamp => getDate(timestamp, 'HH:mm:ss')
const getMiniHour = timestamp => getDate(timestamp, 'HH:mm')
const getMiniDate = timestamp => getDate(timestamp, 'DD/MM/YY')
const getArticleFormat = timestamp => getDate(timestamp, 'DD MMM YYYY')
const getYearDate = timestamp => getDate(timestamp, 'YYYY')
const getMonthMiniYear = timestamp => getDate(timestamp, 'MM/YY')
const getMonthYear = timestamp => getDate(timestamp, 'MM/YYYY')
const getFullMonthYear = timestamp => {
    const dateStr = getDate(timestamp, 'MMMM YYYY')
    return dateStr.charAt(0).toUpperCase() + dateStr.slice(1)
}
const getWeekYear = timestamp => getDate(timestamp, 'ww/YYYY')
const getDayMonth = timestamp => getDate(timestamp, 'DD/MM')
const getDayHour = timestamp => getDate(timestamp, 'DD/MM/YYYY:HH')
const getMiniYear = timestamp => getDate(timestamp, 'YY')

const getDateExport = () => moment().format('YYYYMMDDHHmmss')

const getDateWithHour = (date, hour) => {
    if (date && hour) {
        const mhour = moment(hour)
        return moment(date).hour(mhour.hour()).minute(mhour.minute()).second(mhour.second())
    }
    if (date) {
        return moment(date)
    }
    return
}

const getDateWithHourString = (date, hour) => {
    const result = getDateWithHour(date, hour)
    return result ? result.format('DD/MM/YYYY HH:mm:ss') : ''
}

const convertValidHour = (hour) => {
    if (hour) {
        return moment(moment(hour).utcOffset(moment().utcOffset()).year(2000).format()).valueOf()
    }
    return null
}

const getHourValue = (hour) => {
    if (hour) {
        const mhour = moment(hour)
        return moment().hour(mhour.hour()).minute(mhour.minute()).second(mhour.second())
    }
    return null
}

const getYearTimeStamp = (year) => { // 'YYYY'
    return moment(year).format('x') // timestamp du premier jour de l'année
}

const getDayMonthYearTimeStamp = (monthDayYear) => { // MM/DD/YYYY
    return moment(monthDayYear).format('x') // timestamp du jour à minuit
}

const getDecadeYear = (date) => {
    if (date) {
        const m = moment(date)
        return `${m.month()*3 + Math.trunc((m.date() - Math.trunc(m.date()/31) -1)/10)+1}/${m.year()}`
    }
    return ''
}

const getYear = (date) => {
    return moment(date).year()
}

const getYearOrString = (date, string, format = 'DD/MM/YYYY') => {
    const year = moment(date, format).year()
    return year ? ((year === getYear(new Date())) ? string : year) : i18n.unknownDate
}

const getDayDiff = (date1, date2) => {
    return moment(date1).diff(date2, 'days')
}

const enumerateBetweenDates = (start, end, format = 'days') => {
    return Array.from(moment().range(moment(start).startOf(format).valueOf(), moment(end).endOf(format).valueOf()).by(format))
}

const getDay = (dayNumber) => {
    return capitalizeFirstLetter(moment().isoWeekday(dayNumber).format('dddd'))
}

const getNowInstant = () => getHour(moment().valueOf())

const shortenHumanize = (humanized) => humanize(humanized).replace('minutes', 'min').replace('minute', 'min')
    .replace('secondes', 's').replace('seconde', 's').replace('seconds', 's').replace('second', 's')

const getBeginingOfTheYear = year => {
    if (isUndefined(year) || isNull(year) || isNaN(year)) {
        return
    }
    return moment().year(year).month(0).date(1).valueOf()
}

const getEndOfTheYear = year => {
    if (isUndefined(year) || isNull(year) || isNaN(year)) {
        return
    }
    return moment().year(year).month(11).date(31).valueOf()
}

const getStartYear = timestamp => {
    if (!timestamp) {
        return
    }
    return moment(timestamp).month(0).date(1).hour(0).minute(0).second(0).milliseconds(0)
}

const getEndYear = timestamp => {
    if (!timestamp) {
        return
    }
    return moment(timestamp).month(11).date(31).hour(23).minute(59).second(59).milliseconds(999)
}
const getStartMonth = timestamp => {
    if (!timestamp) {
        return
    }
    return moment(timestamp).date(1).hour(0).minute(0).second(0).milliseconds(0)
}

const getStartDay = timestamp => {
    if (!timestamp) {
        return
    }
    return moment(timestamp).hour(0).minute(0).second(0).milliseconds(0)
}

const getEndDay = timestamp => {
    if (!timestamp) {
        return
    }
    return moment(timestamp).hour(23).minute(59).second(59).milliseconds(999)
}

const getTimestamp = (date, format = 'DD/MM/YYYY') => date && moment(date, format).valueOf()

const getMonthList = () => [
    { value: 1, name: i18n.january },
    { value: 2, name: i18n.february },
    { value: 3, name: i18n.march },
    { value: 4, name: i18n.april },
    { value: 5, name: i18n.may },
    { value: 6, name: i18n.june },
    { value: 7, name: i18n.july },
    { value: 8, name: i18n.august },
    { value: 9, name: i18n.september },
    { value: 10, name: i18n.october },
    { value: 11, name: i18n.november },
    { value: 12, name: i18n.december },
]

const getDuration = (start, end, mode = 'days') => Math.trunc(moment.duration(moment(end).diff(moment(start))).as(mode))

export {
    getFullDate,
    getArticleFormat,
    getDate,
    getMiniDate,
    getDayDiff,
    getYearDate,
    getYear,
    getHour,
    getMiniHour,
    getDateExport,
    getYearOrString,
    getMonthYear,
    getWeekYear,
    getMonthMiniYear,
    getFullMonthYear,
    enumerateBetweenDates,
    getMiniYear,
    getDayMonth,
    getFullDateMini,
    getDay,
    getDateWithHour,
    getHourValue,
    convertValidHour,
    getDateWithHourString,
    getNowInstant,
    shortenHumanize,
    getDayHour,
    getDecadeYear,
    getMonthList,
    getBeginingOfTheYear,
    getEndOfTheYear,
    getYearTimeStamp,
    getStartYear,
    getEndYear,
    getDayMonthYearTimeStamp,
    getTimestamp,
    getStartDay,
    getEndDay,
    getStartMonth,
    getDuration,

    TIMEZONE_INTEGRATION,
    TIMEZONE_LOCAL,
}
