import { Grid2 } from '@mui/material'
import UserAction from 'administration/components/user/actions/UserAction'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'
import { maxBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getBookmarks } from 'utils/StationUtils'
import { getUserBookmarksByStationType } from 'utils/UserUtil'
import useSandreList from 'utils/customHook/useSandreList'

const CatchmentForm = ({
    catchment = {},
    setCatchment = () => { },
    readMode = true,
}) => {
    const dispatch = useDispatch()
    const {
        departments,
        userBookmarks,
        accountUser,
    } = useSelector(store => ({
        departments: store.CityReducer.departments,
        userBookmarks: store.UserReducer.userBookmarks,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const stateFiles = useSandreList(SANDRE.ETAT_DOSSIER)
    const statePlans = useSandreList(SANDRE.ETAT_PLAN)
    const agencies = useSandreList(SANDRE.AGENCES)
    const sdages = useSandreList('CAPTAGES.SDAGE')
    const sampleTypes = useSandreList('PREL_AGRI.TYPE_PRELEVEMENT')

    const lastSDAGE = maxBy(catchment.sdages, 'startDate')

    const booleanList = useMemo(() => [{
        id: true,
        name: i18n.yes,
    }, {
        id: false,
        name: i18n.no,
    }], [])

    const setBookmark = (deleteBookmark = false) => {
        if (deleteBookmark) {
            dispatch(UserAction.deleteBookmark({ identifiant: catchment.code, login: accountUser.login, stationType: 11 }))
        } else {
            dispatch(UserAction.createBookmark({ identifiant: catchment.code, login: accountUser.login, stationType: 11 }))
        }
    }

    return (
        <Grid2 container columnSpacing={2} style={{ padding: '10' }}>
            <Grid2 size='auto'>
                {getBookmarks(catchment.code, getUserBookmarksByStationType(userBookmarks, 'catchment'), true, setBookmark)}
            </Grid2>
            <Grid2 size='grow'>
                <Input
                    title={i18n.nameOfTheWork}
                    value={catchment.name}
                    readMode={readMode}
                    onChange={v => setCatchment(prev => ({ ...prev, name: v }))}
                    data-cy='name'
                />
            </Grid2>
            <Grid2 size={4}>
                <Input
                    title={i18n.identifier}
                    value={catchment.code}
                    readMode={readMode}
                    maxLength={25}
                    onChange={v => setCatchment(prev => ({ ...prev, code: v }))}
                    data-cy='code'
                />
            </Grid2>
            <Grid2 size={4}>
                <Select
                    label={i18n.department}
                    value={catchment.department}
                    options={departments}
                    onChange={v => setCatchment(prev => ({ ...prev, department: v }))}
                    readMode={readMode}
                    displayWithCode
                    data-cy='department'
                />
            </Grid2>
            <Grid2 size={4}>
                <Select
                    options={agencies}
                    label={i18n.pond}
                    nullLabel='&nbsp;'
                    onChange={v => setCatchment(prev => ({ ...prev, pond: v }))}
                    value={catchment.pond}
                    readMode={readMode}
                    data-cy='pond'
                />
            </Grid2>
            <Grid2 size={4}>
                <Input
                    title={i18n.watermass}
                    value={catchment.meCode}
                    readMode={readMode}
                    onChange={v => setCatchment(prev => ({ ...prev, meCode: v }))}
                    data-cy='meCode'
                />
            </Grid2>
            <Grid2 size={4}>
                <Select
                    options={sampleTypes}
                    label={i18n.originOfTheResource}
                    nullLabel='&nbsp;'
                    value={catchment.sampleType}
                    readMode={readMode}
                    onChange={v => setCatchment(prev => ({ ...prev, sampleType: v }))}
                    data-cy='sampleType'
                />
            </Grid2>
            <Grid2 size={4}>
                <Input
                    title={i18n.catchmentFeedingAreaNumber}
                    value={catchment.aacNum}
                    readMode={readMode}
                    onChange={v => setCatchment(prev => ({ ...prev, aacNum: v }))}
                    data-cy='aacNum'
                />
            </Grid2>
            <Grid2 size={4}>
                <Select
                    options={booleanList}
                    label={i18n.grenelleWork}
                    nullLabel='&nbsp;'
                    value={catchment.grenelleWork}
                    readMode={readMode}
                    noSort
                    onChange={v => setCatchment(prev => ({ ...prev, grenelleWork: v }))}
                    data-cy='grenelleWork'
                />
            </Grid2>
            <Grid2 size={4}>
                <Select
                    options={sdages}
                    label={i18n.sdageWork}
                    nullLabel='&nbsp;'
                    value={lastSDAGE?.codeSDAGE}
                    readMode={true}
                    noSort
                />
            </Grid2>
            <Grid2 size={4}>
                <Select
                    options={booleanList}
                    label={i18n.abandonedCatchment}
                    nullLabel='&nbsp;'
                    value={catchment.abandonedCatchment}
                    readMode={readMode}
                    noSort
                    onChange={v => setCatchment(prev => ({ ...prev, abandonedCatchment: v }))}
                    data-cy='abandonedCatchment'
                />
                <Select
                    options={booleanList}
                    label={i18n.waiverNonCompliance}
                    nullLabel='&nbsp;'
                    value={catchment.derogationConformity}
                    readMode={readMode}
                    noSort
                    onChange={v => setCatchment(prev => ({ ...prev, derogationConformity: v }))}
                    data-cy='derogationConformity'
                />
            </Grid2>
            <Grid2 size={4}>
                <Textarea
                    title={i18n.reasonAbandonment}
                    value={catchment.causeAbandonment}
                    readMode={readMode}
                    maxLength={255}
                    onChange={v => setCatchment(prev => ({ ...prev, causeAbandonment: v }))}
                    data-cy='causeAbandonment'
                />
            </Grid2>
            <Grid2 size={4}>
                <Textarea
                    title={i18n.agriOrientation}
                    value={catchment.agriOrientation}
                    readMode={readMode}
                    maxLength={255}
                    onChange={v => setCatchment(prev => ({ ...prev, agriOrientation: v }))}
                    data-cy='agriOrientation'
                />
            </Grid2>
            <Grid2 size={12}>
                <StyledFieldSet>
                    <StyledLegend>{i18n.stagesOfStudiesAndTechnicalData}</StyledLegend>
                    <Grid2 container columnSpacing={2}>
                        <Grid2 size={4}>
                            <Textarea
                                value={catchment.problematic}
                                title={i18n.problematic}
                                readMode={readMode}
                                maxLength={255}
                                onChange={v => setCatchment(prev => ({ ...prev, problematic: v }))}
                                data-cy='problematic'
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <Select
                                options={stateFiles}
                                label={i18n.aacDelineationStudy}
                                nullLabel='&nbsp;'
                                value={catchment.stateStudy}
                                readMode={readMode}
                                onChange={v => setCatchment(prev => ({ ...prev, stateStudy: v }))}
                                data-cy='stateStudy'
                            />
                            <Select
                                options={stateFiles}
                                label={i18n.territorialDiagnosisOfPressures}
                                nullLabel='&nbsp;'
                                value={catchment.stateDiagnosticPressure}
                                readMode={readMode}
                                onChange={v => setCatchment(prev => ({ ...prev, stateDiagnosticPressure: v }))}
                                data-cy='stateDiagnosticPressure'
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <Select
                                options={statePlans}
                                label={i18n.actionPlan}
                                nullLabel='&nbsp;'
                                value={catchment.stateActionPlan}
                                readMode={readMode}
                                onChange={v => setCatchment(prev => ({ ...prev, stateActionPlan: v }))}
                                data-cy='stateActionPlan'
                            />
                            <NumberField
                                title={i18n.aacArea}
                                value={catchment.aacArea}
                                readMode={readMode}
                                onChange={v => setCatchment(prev => ({ ...prev, aacArea: v }))}
                                data-cy='aacArea'
                            />
                        </Grid2>
                    </Grid2>
                </StyledFieldSet>
            </Grid2>
        </Grid2>
    )
}

CatchmentForm.propTypes = {
    catchment: PropTypes.shape({}),
    setCatchment: PropTypes.func,
    readMode: PropTypes.bool,
}

export default CatchmentForm