import AdministrationAction from 'administration/actions/AdministrationAction'
import { LIST, MAP } from 'catchment/constants/CatchmentConstant'
import Table from 'components/datatable/Table'
import { getSortedTableData } from 'components/datatable/TableUtils'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import SieauAction from 'components/sieau/SieauAction'
import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import CatchmentPointsMap from '../CatchmentPointsMap'
import useSandreList from 'utils/customHook/useSandreList'
import Checkbox from 'components/forms/Checkbox'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { Button, Grid } from '@mui/material'
import { getBookmarks } from 'utils/StationUtils'
import TabList from '../../../components/list/TabList'
import Card from 'components/card/Card'

const CatchmentTable = ({
    catchments = [],
}) => {
    const dispatch = useDispatch()
    const {
        catchmentPoints,
        contributors,
        departments,
        selectedSearchValues,
        userBookmarks,
    } = useSelector(store => ({
        catchmentPoints: store.CatchmentReducer.catchmentPoints,
        contributors: store.ContributorReducer.contributors,
        departments: store.CityReducer.departments,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        userBookmarks: store.UserReducer.userBookmarks,
    }), shallowEqual)

    const catchmentBookmarks = useMemo(() => {
        return userBookmarks.filter(b => b.stationType === 11).map(b => b.identifiant)
    }, [userBookmarks])

    const catchmentFormated = catchments.map(catchment => {
        const points = catchmentPoints.filter(p => p.id === catchment.id)
        const mainPoint = points.find(p => p.mainPoint)
        const buildingOwner = mainPoint?.owner || (!!points.length && points.every(p => p.owner === points[0].owner) && points[0].owner)
        return {
            nullValue: getBookmarks(catchment.code, catchmentBookmarks),
            id: catchment.id,
            department: getLabel(departments, catchment.department, 'labelWithCode'),
            code: catchment.code,
            catchment: catchment.name,
            mainCatchmentPoint: mainPoint?.code,
            nbPoint: points.length,
            grenelle: catchment.grenelleWork ? i18n.yes : i18n.no,
            buildingOwner: buildingOwner ? getLabel(contributors, buildingOwner, 'labelDisplay', 'id') : '',
            aac: catchment.aacNum,
        }
    })

    const { catchment: searchValues } = selectedSearchValues
    const initialSort = searchValues?.columnName ? { column: searchValues.columnName, sort: searchValues.sort } : null

    const stationsHash = stations => stations.map(s => s.code).join('')

    const onTableSort = (columnName, sort) => {
        const sortedStations = getSortedTableData(catchmentFormated, { column: columnName, sort }, false)
        if (!searchValues || !searchValues.stations || stationsHash(searchValues.stations) !== stationsHash(sortedStations)) {
            dispatch(SieauAction.update('selectedSearchValues', 'catchment', { stations: sortedStations, columnName, sort }))
        }
    }

    return (
        <Table
            title={i18n.catchments}
            data={catchmentFormated}
            paging
            initialSort={initialSort}
            nbPerPageLabel={nbPerPageLabel}
            onSort={onTableSort}
            type={{ headers: ['nullValue', 'department', 'code', 'catchment', 'mainCatchmentPoint', 'nbPoint', 'grenelle', 'buildingOwner', 'aac'] }}
            onClick={s => dispatch(push(`/station/catchment/${s.id}`))}
            condensed
            sortable
            id='catchments_table'
            invertedHeaderStyle
        />
    )
}

CatchmentTable.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.shape({})),
}

const CatchmentsDashboardApp = () => {
    const {
        catchments,
        catchmentPoints,
        selectedSearchValues,
    } = useSelector(store => ({
        catchments: store.CatchmentReducer.catchments,
        catchmentPoints: store.CatchmentReducer.catchmentPoints,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState(selectedSearchValues.catchment?.searchValue || '')
    const [tmpSearch, setTmpSearch] = useState(searchValue)
    const [sdage, setSDAGE] = useState()
    const [panel, setPanel] = useState(LIST)
    const [displayActiveSDAGE, setdisplayActiveSDAGE] = useState(false)
    const [resultFilter, setResultFilter] = useState([])
    const [filter, setFilter] = useState(-1)

    const dispatch = useDispatch()

    const sdages = useSandreList('CAPTAGES.SDAGE')

    useTitle(() => [{
        title: i18n.catchments,
        href: 'catchment',
    }, {
        title: i18n.dashboard,
        href: 'catchment',
    }], [])

    useActions(() => ({
        new: () => dispatch(push('/station/catchment/new/description')),
    }), [])

    const catchmentsFiltered = useMemo(() => {
        const listCatchments = filter === -1 ? catchments : resultFilter
        const searchValueFormated = searchAllCharacters(searchValue)
        const searchActiveSDAGE = displayActiveSDAGE ? listCatchments.filter(({ activeSdage }) => activeSdage) : listCatchments
        const searchValueFilter = searchValueFormated ? searchActiveSDAGE.filter(({ name, code, aacNum }) => searchAllCharacters(`${name}${code}${aacNum}`).includes(searchValueFormated)) : searchActiveSDAGE
        return hasValue(sdage) ? searchValueFilter.filter(({ usedSdage }) => usedSdage === sdage) : searchValueFilter
    }, [searchValue, displayActiveSDAGE, catchments, resultFilter, filter, sdage])

    return (
        <Grid margin={'30 10 100 15'}>
            <Card round noMargin={false} cardStyle={{ width: '80%' }}>
                <Grid container item alignItems='center' justifyContent={'space-between'}>
                    <Grid container item xs={10} spacing={2} padding={'10px'} alignItems='center'>
                        <Grid item xs={2}>
                            <Input
                                tooltip={i18n.searchThenEnter}
                                title={i18n.search}
                                value={tmpSearch}
                                onChange={(v) => setTmpSearch(v)}
                                onEnterKeyPress={v => {
                                    setTmpSearch(v)
                                    setSearchValue(v)
                                    dispatch(AdministrationAction.setSelectedSearchValues('catchment', { searchValue: v }))
                                }}
                                clearFunction
                                data-cy='search'
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SimpleFilterSelect
                                stationType={STATION_TYPE_NAME.catchment}
                                onChange={(results, newFilter) => {
                                    setFilter(newFilter)
                                    setResultFilter(results)
                                }}
                                stations={catchments}
                                data-cy='filter'
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                options={sdages}
                                label={i18n.sdage}
                                nullLabel='&nbsp;'
                                value={sdage}
                                noSort
                                onChange={setSDAGE}
                                data-cy='sdage'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Checkbox
                                label={i18n.displayActiveSDAGE}
                                checked={displayActiveSDAGE}
                                onChange={setdisplayActiveSDAGE}
                                data-cy='activeSDAGE'
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'} padding={'10px'}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setSearchValue(tmpSearch)
                                setTmpSearch(tmpSearch)
                                dispatch(AdministrationAction.setSelectedSearchValues('catchment', { tmpSearch }))
                            }}
                            data-cy='submit'
                        >
                            {i18n.search}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
            <Grid style={{ marginTop: -50, marginBottom: panel === LIST ? 0 : 60 }}>
                <TabList
                    onChangeTab={setPanel}
                    tabs={[
                        {
                            value: LIST,
                            label: i18n.table,
                            icon: 'list',
                        },
                        {
                            value: MAP,
                            label: i18n.map,
                            icon: 'map',
                        },
                    ]}
                >
                    {
                        panel === LIST && (
                            <CatchmentTable catchments={catchmentsFiltered}/>
                        )
                    }
                    {
                        panel === MAP && (
                            <CatchmentPointsMap catchmentPoints={catchmentPoints}/>
                        )
                    }
                </TabList>
            </Grid>
        </Grid>
    )
}

export default CatchmentsDashboardApp