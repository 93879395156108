import { push } from '@lagunovsky/redux-react-router'
import { compact, groupBy, keys, orderBy, uniq } from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from 'utils/FormUtils'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ActionComponent from 'components/ActionComponent'
import Table from 'components/datatable/Table'
import ProgressBar from 'components/progress/ProgressBar'
import { AccordionMUI, AccordionSummaryMUI, AccordionDetailsMUI } from 'components/styled/Accordions'
import HomeAction from 'home/actions/HomeAction'
import InstallationAction from 'installation/actions/InstallationAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { hasValue } from 'utils/NumberUtil'
import { getSettingInt, getUser } from 'utils/SettingUtils'
import { formatMilliers, getI18nTitleData, searchAllCharacters } from 'utils/StringUtil'
import VolumesModal from './modal/VolumesModal'
import TabList from 'components/list/TabList'
import FormFilterVolumes from './panels/FormFilterVolumes'
import ToastrAction from 'toastr/actions/ToastrAction'
import DtoTankDeclaration from 'survey/dto/DtoTankDeclaration'
import { Grid } from '@mui/material'
import MoreVert from 'components/buttons/MoreVert'
import ExportFileModal from 'components/modal/ExportFileModal'
import { downloadURI, formatData } from 'utils/ExportDataUtil'
import ExportAction from 'export/actions/ExportAction'

const headers = [
    'point',
    'name',
    'city',
    'codeParcelle',
    'lieuDit',
    'pumpValue',
    'requestedVolume',
    'askedLowWaterVolume',
    'allocatedVolume',
    'attributedLowWaterVolume',
    'authorizedVolume',
    'authorizedLowWaterVolume',
    'usageValue',
    'tankValue',
]

const headersExport = [
    'point',
    'name',
    'city',
    'codeParcelle',
    'lieuDit',
    'pump',
    'requestedVolume',
    'askedLowWaterVolume',
    'allocatedVolume',
    'attributedLowWaterVolume',
    'authorizedVolume',
    'authorizedLowWaterVolume',
    'usage',
    'detention',
]

const styleCell = {
    width: '60%',
    textAlign: 'right',
}

class ExploitationVolumesApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            idExploitation: parseInt(props.params.id),
            dataLoaded: false,
            pointsLoaded: false,
            readMode: true,
            openModalVolume: false,
            selectedVolumes: null,
            exploitationVolumes: [],
            sortBy: 'askedYear',
            filter: {},
            modalData: { open: false },
        }
    }

    componentDidMount() {
        const { idExploitation } = this.state
        const { exploitation } = this.props
        this.setReadOnlyMode()
        this.props.fetchDeclarationsByExploitationId(idExploitation)
        this.props.fetchExploitationVolumes(idExploitation).then(() => {
            if (exploitation && exploitation.idExploitation !== idExploitation) {
                this.props.fetchExploitation(idExploitation).then(() => {
                    this.setExploitation(this.props.exploitation)
                })
            } else {
                this.setExploitation(exploitation)
            }
        })
    }

    setEditMode = () => {
        const { exploitation } = this.props
        this.setState({ readMode: false })
        const actions = {
            cancel: () => {
                this.setExploitation(exploitation)
                this.setReadOnlyMode()
            },
            save: () => this.onSaveVolumes(),
            new: () => this.setState({ selectedVolumes: undefined, openModalVolume: true }),
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ readMode: true })
        if (!(getUser().consultant === '1')) {
            this.setActions({
                edit: () => this.setEditMode(),
                export: () => this.onExportAll(),
            })
        } else {
            this.setActions({})
        }
    }

    onExportAll = () => {
        const { exploitationVolumes, exploitation, installationsWithGeo, citiesIndex, usages, cultures } = this.props
        const dataFormatted = exploitationVolumes?.length ? orderBy(exploitationVolumes, ['askedYear', 'idInstallation'], ['desc', 'asc']).map((v) => {
            const pumpSitu = exploitation.link_materiel.filter((s) =>
                s.siteType === 7 &&
                v.idInstallation === s.siteCode &&
                (!s.situationDate || s.situationDate < moment(`31/12/${v.askedYear}`, 'DD/MM/YYYY').valueOf()) &&
                (!s.situationEndDate || s.situationEndDate > moment(`01/01/${v.askedYear}`, 'DD/MM/YYYY').valueOf()),
            )
            const point = installationsWithGeo.find((i) => i.id === v.idInstallation)
            if (point) {
                const linkPoint = exploitation.link_samplings.find((p) => p.idInstallation === v.idInstallation)
                const city = citiesIndex[point.townCode] || {}
                const usage = v?.link_usages?.idUsage ? (usages.find((u) => u.idUsage === v.link_usages.idUsage) || {}).description : ''
                const culture = v?.link_usages?.idCulture && (cultures.find((c) => c.id === v.link_usages.idCulture) || {}).name
                return {
                    year: v.askedYear,
                    point: point.code,
                    name: point.name,
                    city: city.labelWithCode,
                    codeParcelle: `${point.parcel || ''} ${point.section || ''}`,
                    lieuDit: point.location,
                    pump: pumpSitu.length ? this.getPumpValue(pumpSitu, true) : '',
                    requestedVolume: hasValue(v.askedVolume) ? `${formatMilliers(v.askedVolume) || 0} m3` : '-',
                    allocatedVolume: hasValue(v.attributedVolume) ? `${formatMilliers(v.attributedVolume) || 0} m3` : '-',
                    authorizedVolume: hasValue(v.authorizedVolume) ? `${formatMilliers(v.authorizedVolume) || 0} m3` : '-',
                    usage: `${usage}${culture ? ` ${culture}` : ''}`,
                    detention: linkPoint?.link_sampleTanks?.length ? this.getTanksValue(linkPoint.link_sampleTanks, true) : '',
                }
            }
            return null
        }) : []
        if (dataFormatted.length) {
            dataFormatted[0].headers = ['year', ...headersExport]
        }
        return {
            data: dataFormatted,
            exportType: 'xlsx',
            titleFile: `${exploitation.codification} - ${i18n.consos}`,
        }
    }

    getTanksValue = (linkTanks, exportValue) => {
        if (exportValue) {
            return linkTanks.map((t) => ` ${t.id} (${formatMilliers(t.capacity)} m3)`).toString()
        }
        const tooltip = (
            <div>
                {linkTanks.map((t) => <><span>{`${t.id} : ${formatMilliers(t.capacity) || 0} m3`}</span><br /></>)}
            </div>
        )
        const total = linkTanks.reduce((acc, t) => acc + t.capacity, 0) || 0
        return <span {...sieauTooltip(tooltip, null, 'right')}>{`${formatMilliers(total)} m3`}</span>
    }

    setAllVolumesData = (volume, exploitation) => {
        const { installationsWithGeo, citiesIndex, usages, cultures, agriTanksTypes, applicationSettings } = this.props
        const minVolumeEligibilityTank = getSettingInt(applicationSettings, 'minVolumeEligibilityTank')
        const point = installationsWithGeo.find((i) => i.id === volume.idInstallation)
        if (point) {
            const linkPoint = exploitation.link_samplings.find((p) => p.idInstallation === volume.idInstallation)
            const city = citiesIndex[point.townCode] || {}
            const pumpSitu = exploitation.link_materiel.filter((s) =>
                s.siteType === 7 &&
                volume.idInstallation === s.siteCode &&
                (!s.situationDate || s.situationDate < moment(`31/12/${volume.askedYear}`, 'DD/MM/YYYY').valueOf()) &&
                (!s.situationEndDate || s.situationEndDate > moment(`01/01/${volume.askedYear}`, 'DD/MM/YYYY').valueOf()),
            )
            const usage = volume?.link_usages?.idUsage ? (usages.find((u) => u.idUsage === volume.link_usages.idUsage) || {}).description : ''
            const culture = volume?.link_usages?.idCulture && (cultures.find((c) => c.id === volume.link_usages.idCulture) || {}).name
            const tooltip = culture ? sieauTooltip(culture, null, 'bottom') : null
            const tanksPoint = compact((linkPoint?.link_sampleTanks || []).map((retenue) => {
                const updatedRetenue = (volume.link_tanks || []).find((o) => o.idTank === retenue.idTank)
                const retenueDecla = updatedRetenue ? {
                    ...retenue,
                    ...new DtoTankDeclaration(updatedRetenue),
                } : retenue
                const type = agriTanksTypes.find((t) => t.id === retenueDecla.tankType) || {}
                return type.eligibility && retenueDecla.capacity >= minVolumeEligibilityTank ? retenueDecla : null
            }))
            return {
                pointData: point,
                cityData: city,
                volumeData: { ...volume, idExploitation: exploitation.idExploitation },
                askedYear: volume.askedYear,
                point: point.code,
                name: point.name,
                city: city.labelWithCode,
                codeParcelle: `${point.parcel || ''} ${point.section || ''}`,
                lieuDit: point.location,
                pump: pumpSitu.length ? this.getPumpValue(pumpSitu, true) : '',
                pumpValue: pumpSitu.length ? this.getPumpValue(pumpSitu) : '',
                requestedVolume: hasValue(volume.askedVolume) ? `${formatMilliers(volume.askedVolume) || 0} m3` : '-',
                requestedVolumeValue: volume.askedVolume,
                askedLowWaterVolume: hasValue(volume.askedLowWaterVolume) ? `${formatMilliers(volume.askedLowWaterVolume) || 0} m3` : '-',
                askedLowWaterVolumeValue: volume.askedLowWaterVolume,
                allocatedVolume: hasValue(volume.attributedVolume) ? `${formatMilliers(volume.attributedVolume) || 0} m3` : '-',
                allocatedVolumeValue: volume.attributedVolume,
                attributedLowWaterVolume: hasValue(volume.attributedLowWaterVolume) ? `${formatMilliers(volume.attributedLowWaterVolume) || 0} m3` : '-',
                attributedLowWaterVolumeValue: volume.attributedLowWaterVolume,
                authorizedVolume: hasValue(volume.authorizedVolume) ? `${formatMilliers(volume.authorizedVolume) || 0} m3` : '-',
                authorizedVolumeValue: volume.authorizedVolume,
                authorizedLowWaterVolume: hasValue(volume.authorizedLowWaterVolume) ? `${formatMilliers(volume.authorizedLowWaterVolume) || 0} m3` : '-',
                authorizedLowWaterVolumeValue: volume.authorizedLowWaterVolume,
                usage: `${usage}${culture ? ` ${culture}` : ''}`,
                usageValue: <span {...tooltip}>{usage}</span>,
                detention: tanksPoint?.length ? this.getTanksValue(tanksPoint, true) : '',
                tankValue: tanksPoint?.length ? this.getTanksValue(tanksPoint) : '',
            }
        }
        return null
    }

    setVolumes = (exploitation, volumes) => {
        const exploitationVolumes = compact(volumes.map((v) => {
            return this.setAllVolumesData(v, exploitation)
        }))
        this.setState({ pointsLoaded: true, exploitationVolumes })
    }

    setExploitation = (exploitation) => {
        const { installationsWithGeo, exploitationVolumes } = this.props
        if (!installationsWithGeo.length) {
            this.props.fetchInstallationsWithGeo().then(() => {
                this.setVolumes(exploitation, exploitationVolumes)
            })
        } else {
            this.setVolumes(exploitation, exploitationVolumes)
        }
        this.props.fetchContributor(exploitation.operatorCode).then(() => {
            const { contributor } = this.props
            this.props.setTitle([
                {
                    title: i18n.folders,
                    href: 'dossiers',
                },
                {
                    title: `${contributor.name} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`,
                    href: `dossiers/${exploitation.idExploitation}/dashboard`,
                },
                {
                    title: i18n.volumes,
                    href: `dossiers/${exploitation.idExploitation}/volumes`,
                },
            ])
            this.setState({ dataLoaded: true })
        })
    }

    onSaveVolumes = () => {
        const { idExploitation, exploitationVolumes } = this.state
        const { exploitation } = this.props
        this.setReadOnlyMode()
        this.setState({ dataLoaded: false })
        this.props.updateExploitationVolumes(idExploitation, exploitationVolumes.map((v) => v.volumeData)).then(() => {
            this.props.fetchExploitationVolumes(idExploitation).then(() => {
                this.setExploitation(exploitation)
            })
        })
    }

    getPumpValue = (pumpSitu, exportValue) => {
        const { variousMateriels } = this.props
        if (pumpSitu.length > 1) {
            if (exportValue) {
                return pumpSitu.map((p) => {
                    const matFound = variousMateriels.find(({ id }) => id === p.idVarious) || {}
                    return ` ${matFound.reference || i18n.unknown}`
                }).toString()
            }
            const tooltip = (
                <div>
                    {pumpSitu.map((p) => {
                        const matFound = variousMateriels.find(({ id }) => id === p.idVarious) || {}
                        return <><span>{matFound.reference}</span><br /></>
                    })}
                </div>
            )
            return <span {...sieauTooltip(tooltip, null, 'right')}>{`${pumpSitu.length} ${i18n.pumps}`}</span>
        }
        const matFound = variousMateriels.find(({ id }) => id === pumpSitu[0].idVarious)
        if (matFound) {
            return matFound.reference
        }
        return i18n.unknown
    }

    getFilteredData = (volumes) => {
        const { filter } = this.state
        const { searchValue, year, usage } = filter
        const usageFiltered = usage ? volumes.filter((v) => v.volumeData.link_usages && v.volumeData.link_usages.idUsage === usage) : volumes
        const yearFiltered = year ? usageFiltered.filter((v) => v.askedYear === year) : usageFiltered
        const includesValue = searchAllCharacters(searchValue || '')
        return yearFiltered.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (volume) => {
        return searchAllCharacters(headersExport.map(key => volume[key]))
    }

    getNotification = (idExploitation, year) => {
        this.props.toastrInfo(i18n.loadingDocument)
        this.props.getNotifEdition(idExploitation, year).then((json) => {
            if (json) {
                downloadURI(json.targetPath)
            }
        })
    }

    exportData = (data, type, headersToAdd, title) => {
        const dataWithHeader = data.length > 0 ? [{ ...data[0], headers: headersToAdd }, ...data.slice(1)] : []
        const dataFormatted = formatData(dataWithHeader)
        this.props.export(dataFormatted, type, title)
    }

    getExportModal = () => {
        const { modalData: { open, years, data, title }, idExploitation } = this.state
        const { exploitation } = this.props
        const declarationsActions = orderBy(years.map((year) => ({
            name: `${i18n.notification} ${year}`,
            year,
            group: 2,
            formats: [{
                type: 'pdf',
                callback: () => this.getNotification(idExploitation, year),
            }],
        })), 'year', 'desc')
        const dataExport = [{
            name: i18n.syntheticExport,
            group: 1,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => this.exportData(data, 'xlsx', headersExport, `${exploitation.codification} - ${i18n.volumes} ${title}`),
            }, {
                type: i18n.csv,
                callback: () => this.exportData(data, 'csv', headersExport, `${exploitation.codification} - ${i18n.volumes} ${title}`),
            }],
        }, ...declarationsActions]
        return open && (
            <ExportFileModal
                open={open}
                onClose={() => this.setState({ modalData: { open: false } })}
                data={dataExport}
                groups={[{
                    value: 1,
                    name: i18n.table,
                }, {
                    value: 2,
                    name: i18n.notifications,
                }]}
            />
        )
    }

    getCollapsibleTables = () => {
        const { exploitationVolumes, sortBy, readMode } = this.state
        if (exploitationVolumes.length) {
            const groupValue = sortBy === 'totals' ? 'askedYear' : sortBy
            const volumesGroupByYear = groupBy(exploitationVolumes, groupValue)
            return orderBy(keys(volumesGroupByYear).map((key) => key), [key => key], groupValue === 'askedYear' ? 'desc' : 'asc').map(key => {
                const groupedByPoint = groupBy(volumesGroupByYear[key], 'point')
                const volumes = sortBy === 'totals' ? keys(groupedByPoint).map((point) => {
                    const askedVolumeValueFiltered = groupedByPoint[point].filter((v) => hasValue(v.requestedVolumeValue))
                    const askedLowWaterVolumeValueFiltered = groupedByPoint[point].filter((v) => hasValue(v.askedLowWaterVolumeValue))
                    const attributedVolumeValueFiltered = groupedByPoint[point].filter((v) => hasValue(v.allocatedVolumeValue))
                    const attributedLowWaterVolumeValueFiltered = groupedByPoint[point].filter((v) => hasValue(v.attributedLowWaterVolumeValue))
                    const authorizedVolumeValueFiltered = groupedByPoint[point].filter((v) => hasValue(v.authorizedVolumeValue))
                    const authorizedLowWaterVolumeValueFiltered = groupedByPoint[point].filter((v) => hasValue(v.authorizedLowWaterVolumeValue))
                    const askedVolumeValue = askedVolumeValueFiltered.length ? askedVolumeValueFiltered.reduce((acc, v) => acc + v.requestedVolumeValue, 0) : null
                    const askedLowWaterVolumeValue = askedLowWaterVolumeValueFiltered.length ? askedLowWaterVolumeValueFiltered.reduce((acc, v) => acc + v.askedLowWaterVolumeValue, 0) : null
                    const attributedVolumeValue = attributedVolumeValueFiltered.length ? attributedVolumeValueFiltered.reduce((acc, v) => acc + v.allocatedVolumeValue, 0) : null
                    const attributedLowWaterVolumeValue = attributedLowWaterVolumeValueFiltered.length ? attributedLowWaterVolumeValueFiltered.reduce((acc, v) => acc + v.attributedLowWaterVolumeValue, 0) : null
                    const authorizedVolumeValue = authorizedVolumeValueFiltered.length ? authorizedVolumeValueFiltered.reduce((acc, v) => acc + v.authorizedVolumeValue, 0) : null
                    const authorizedLowWaterVolumeValue = authorizedLowWaterVolumeValueFiltered.length ? authorizedLowWaterVolumeValueFiltered.reduce((acc, v) => acc + v.authorizedLowWaterVolumeValue, 0) : null
                    const tooltip = sieauTooltip(groupedByPoint[point].map((v) => `${v.usage}\n`), null, 'bottom')
                    return {
                        ...groupedByPoint[point][0],
                        requestedVolume: hasValue(askedVolumeValue) ? `${formatMilliers(askedVolumeValue) || 0} m3` : '-',
                        askedLowWaterVolume: hasValue(askedLowWaterVolumeValue) ? `${formatMilliers(askedLowWaterVolumeValue) || 0} m3` : '-',
                        allocatedVolume: hasValue(attributedVolumeValue) ? `${formatMilliers(attributedVolumeValue) || 0} m3` : '-',
                        attributedLowWaterVolume: hasValue(attributedLowWaterVolumeValue) ? `${formatMilliers(attributedLowWaterVolumeValue) || 0} m3` : '-',
                        authorizedVolume: hasValue(authorizedVolumeValue) ? `${formatMilliers(authorizedVolumeValue) || 0} m3` : '-',
                        authorizedLowWaterVolume: hasValue(authorizedLowWaterVolumeValue) ? `${formatMilliers(authorizedLowWaterVolumeValue) || 0} m3` : '-',
                        usageValue: <span {...tooltip}>{i18n.various}</span>,
                    }
                }) : volumesGroupByYear[key]
                const data = orderBy(this.getFilteredData(volumes), ['askedYear', 'townCode', 'city', 'codeParcelle', 'point'], ['desc', 'asc']).map((v) => ({
                    year: { value: v.askedYear },
                    modalData: { ...v.volumeData, pointInfos: { ...v.pointData, city: `${v.cityData.code || ''} - ${v.cityData.name || ''}` } },
                    nullValue: { value: readMode ? null : (<i className='material-icons clickable'>edit</i>) },
                    point: { value: v.point },
                    name: { value: v.name },
                    city: { value: v.city },
                    codeParcelle: { value: v.codeParcelle },
                    lieuDit: { value: v.lieuDit },
                    pump: { value: v.pump },
                    pumpValue: { value: v.pumpValue },
                    requestedVolume: {
                        value: v.requestedVolume,
                        style: styleCell,
                    },
                    askedLowWaterVolume: {
                        value: v.askedLowWaterVolume,
                        style: styleCell,
                    },
                    allocatedVolume: {
                        value: v.allocatedVolume,
                        style: styleCell,
                    },
                    attributedLowWaterVolume: {
                        value: v.attributedLowWaterVolume,
                        style: styleCell,
                    },
                    authorizedVolume: {
                        value: v.authorizedVolume,
                        style: styleCell,
                    },
                    authorizedLowWaterVolume: {
                        value: v.authorizedLowWaterVolume,
                        style: styleCell,
                    },
                    usage: { value: v.usage },
                    usageValue: { value: v.usageValue },
                    detention: { value: v.detention },
                    tankValue: { value: v.tankValue },
                }))
                const title = key.length ? key : i18n.unknown
                const actions = readMode ? [{
                    iconName: 'file_download',
                    tooltip: i18n.export,
                    onClick: () => this.setState({ modalData: { open: true, years: uniq(data.map((d) => d?.year?.value)), data, title } }),
                }] : []
                const headersTable = !['askedYear', 'totals'].includes(sortBy) ? ['nullValue', 'year', ...headers] : ['nullValue', ...headers]
                return (
                    <div className='padding-1'>
                        <AccordionMUI defaultExpanded>
                            <AccordionSummaryMUI>
                                <Grid container justifyContent='space-between'>
                                    {`${title} (${data.length} ${getI18nTitleData(i18n.element, i18n.elements, data)})`}
                                    <div onClick={e => e.stopPropagation()}>
                                        <MoreVert links={ actions }/>
                                    </div>
                                </Grid>
                            </AccordionSummaryMUI>
                            <AccordionDetailsMUI nopadding>
                                <Table
                                    showTitle={false}
                                    data={data}
                                    paging
                                    nbPerPageLabel={ nbPerPageLabel }
                                    type={{ headers: headersTable }}
                                    customHeaders={{
                                        pumpValue: i18n.pump,
                                        usageValue: i18n.usage,
                                        tankValue: i18n.detention,
                                        codeParcelle: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.codeParcelleWrap}</span>,
                                        requestedVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.requestedVolumeWrap}</span>,
                                        askedLowWaterVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.askedLowWaterVolumeWrap}</span>,
                                        allocatedVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.allocatedVolumeWrap}</span>,
                                        attributedLowWaterVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.attributedLowWaterVolumeWrap}</span>,
                                        authorizedVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.authorizedVolumeWrap}</span>,
                                        authorizedLowWaterVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.authorizedLowWaterVolumeWrap}</span>,
                                    }}
                                    editable={!readMode}
                                    onClick={(s) => sortBy === 'totals' ? () => {} : this.setState({ selectedVolumes: s.modalData, openModalVolume: true })}
                                    condensed
                                    sortable
                                    initialSort
                                    deletable={!readMode}
                                    onDelete={(s) => this.onDeleteVolumes(s.modalData)}
                                    color
                                />
                            </AccordionDetailsMUI>
                        </AccordionMUI>
                    </div>
                )
            })
        }
        return (
            <div className='padding-1'>
                <h5>{i18n.noDataToDisplay}</h5>
            </div>
        )
    }

    getModalVolume = () => {
        const { installationsWithGeo } = this.props
        const { selectedVolumes, openModalVolume, readMode } = this.state
        return (
            <VolumesModal
                title={!selectedVolumes && i18n.addVolumes}
                volumes={selectedVolumes}
                newVolumes={!selectedVolumes}
                points={installationsWithGeo}
                open={openModalVolume}
                onCancel={() => this.setState({ openModalVolume: false, selectedVolumes: null }) }
                onValidate={this.onValidateVolumes}
                disabled={readMode}
            />
        )
    }

    onValidateVolumes = (updatedVolumes) => {
        const { exploitation } = this.props
        const { exploitationVolumes, selectedVolumes, idExploitation } = this.state
        const volumesToCompare = selectedVolumes || updatedVolumes
        if (updatedVolumes.idInstallation && updatedVolumes.askedYear) {
            const volumesWithData = this.setAllVolumesData(updatedVolumes, exploitation)
            const updatedExploitationVolumes = [
                ...exploitationVolumes.filter((v) => v.volumeData.idInstallation !== volumesToCompare.idInstallation || v.volumeData.askedYear !== volumesToCompare.askedYear || v.volumeData.idProvisionalUsage !== volumesToCompare.idProvisionalUsage),
                { ...volumesWithData, idExploitation },
            ]
            this.setState({ exploitationVolumes: updatedExploitationVolumes, openModalVolume: false, selectedVolumes: null })
        } else {
            this.props.warning(i18n.pleaseCompleteAllRequiredField)
        }
    }

    onDeleteVolumes = (selectedVolumes) => {
        const { exploitationVolumes } = this.state
        this.setState({
            exploitationVolumes: exploitationVolumes.filter((v) => v.volumeData.idInstallation !== selectedVolumes.idInstallation ||
                v.volumeData.askedYear !== selectedVolumes.askedYear ||
                v.volumeData.idProvisionalUsage !== selectedVolumes.idProvisionalUsage,
            ),
        })
    }

    render() {
        const { dataLoaded, pointsLoaded, openModalVolume, modalData: { open } } = this.state
        return (
            <div className='row no-margin padding-1' style={{ paddingBottom: 80 }}>
                <FormFilterVolumes
                    onChange={obj => this.setState({ filter: obj })}
                />
                <TabList
                    onChangeTab={(v) => this.setState({ sortBy: v })}
                    tabs={[{
                        value: 'askedYear',
                        label: i18n.perYear,
                        icon: 'insert_invitation',
                    }, {
                        value: 'point',
                        label: i18n.byPoint,
                        icon: 'room',
                    }, {
                        value: 'usage',
                        label: i18n.byUsage,
                        icon: 'opacity',
                    }, {
                        value: 'totals',
                        label: i18n.totals,
                        icon: 'functions',
                    }]}
                >
                    {dataLoaded && pointsLoaded ? this.getCollapsibleTables() : (
                        <div className='padding-1'>
                            <ProgressBar indeterminate withMessage />
                        </div>
                    )}
                </TabList>
                {openModalVolume && this.getModalVolume()}
                {open && this.getExportModal()}
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        contributor: store.ContributorReducer.contributor,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        surveys: store.AgriReducer.surveys,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        usages: store.UsageReducer.usages,
        cultures: store.CultureReducer.cultures,
        agriTanksTypes: store.AgriReducer.agriTanksTypes,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        declarationsExploitation: store.AgriReducer.declarationsExploitation,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    fetchExploitationVolumes: AgriAction.fetchExploitationVolumes,
    updateExploitationVolumes: AgriAction.updateExploitationVolumes,
    fetchDeclarationsByExploitationId: AgriAction.fetchDeclarationsByExploitationId,
    getNotifEdition: AgriAction.getNotifEdition,
    fetchContributor: ContributorAction.fetchContributor,
    fetchInstallationsWithGeo: InstallationAction.fetchInstallationsWithGeo,
    export: ExportAction.export,
    setTitle: HomeAction.setTitle,
    warning: ToastrAction.warning,
    toastrInfo: ToastrAction.info,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploitationVolumesApp)
