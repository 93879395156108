import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import SieauApp from './home/components/SieauApp'
import AppStore, { history } from 'store/AppStore'
import fr from 'assets/i18n/properties_fr.json'
import en from 'assets/i18n/properties_en.json'
import i18n from 'simple-react-i18n'
import { ThemeProvider } from '@mui/material/styles'
import BubbleHelp from './home/components/BubbleHelp'
import SitePlanApp from './home/components/SitePlanApp'
import ResetPasswordApp from './home/components/ResetPasswordApp'
import Login from './home/components/Login'
import BoundaryError from 'log/components/BoundaryError'
import AuthenticatedComponent from 'AuthenticatedComponent'
import UnauthorizedApp from 'unAuthorized/components/UnauthorizedApp'
import 'material-icons/iconfont/material-icons.css'
import { Theme } from 'utils/constants/Theme'
import { render } from 'react-dom'
import ComponentWithNavObject from 'ComponentWithNavObject'
import AdministrationApp from 'administration/components/AdministrationApp'
import AlertApp from 'alerting/components/AlertApp'
import CatchmentApp from 'catchment/components/catchment/CatchmentApp'
import CatchmentsApp from 'catchment/components/catchments/CatchmentsApp'
import CmsEventForm from 'events/components/CmsEventForm'
import ExploitationsApp from 'exploitations/components/ExploitationsApp'
import ContentsApp from 'home/components/contents/ContentsApp'
import GlobalEventsApp from 'home/components/globalEvents/GlobalEventsApp'
import ArrestsApp from 'home/components/prefectoralArrest/ArrestsApp'
import VigilancesApp from 'home/components/vigilance/VigilancesApp'
import HydrometryCampaignApp from 'hydrometry/components/campaign/HydrometryCampaignApp'
import JobContentApp from 'import/components/content/JobContentApp'
import InstallationCampaignApp from 'installation/components/campaign/InstallationCampaignApp'
import InstallationsApp from 'installation/components/installations/components/InstallationsApp'
import MaterielApp from 'materiel/components/MaterielApp'
import ConversationMessages from 'messages/components/ConversationMessages'
import DashboardMessages from 'messages/components/DashboardMessages'
import PGSSEApp from 'pgsse/PGSSEApp'
import PiezometryCampaignApp from 'piezometry/components/campaign/PiezometryCampaignApp'
import PiezometersApp from 'piezometry/components/piezometers/PiezometersApp'
import PluviometryCampaignApp from 'pluviometry/components/campaign/PluviometryCampaignApp'
import QualityCampaignApp from 'quality/components/campaign/QualityCampaignApp'
import QualityOptionsApp from 'quality/components/options/QualityOptionsApp'
import QualitometersApp from 'quality/components/qualitometers/QualitometersApp'
import ReferencialApp from 'referencial/components/ReferencialApp'
import StationApp from 'station/components/StationApp'
import StationsApp from 'station/components/StationsApp'
import SurveyApp from 'survey/components/survey/SurveyApp'
import PlanificationApp from 'survey/components/PlanificationApp'
import TerritoryApp from 'territory/components/TerritoryApp'
import MaterielOptionsApp from 'materiel/components/administration/MaterielOptionsApp'
import JobTableExecutions from 'import/components/table/JobTableExecutions'
import ActivityRouterApp from 'referencial/components/activity/components/ActivityRouterApp'
import AquiferRouterApp from 'referencial/components/aquifers/components/AquiferRouterApp'
import WatershedRouterApp from 'referencial/components/watershed/components/WatershedRouterApp'
import CityRouterApp from 'referencial/components/city/components/CityRouterApp'
import ContactRouterApp from 'referencial/components/contact/components/ContactRouterApp'
import ContactGroupRouterApp from 'referencial/components/contact/components/ContactGroupRouterApp'
import CultureRouterApp from 'referencial/components/cultures/components/CultureRouterApp'
import CulturesFamilyRouterApp from 'referencial/components/culturesFamilies/components/CulturesFamilyRouterApp'
import HydrogeologicalEntityRouterApp from 'referencial/components/hydrogeologicalEntity/components/HydrogeologicalEntityRouterApp'
import FractionRouterApp from 'referencial/components/fraction/components/FractionRouterApp'
import ContributorRouterApp from 'referencial/components/contributor/components/ContributorRouterApp'
import LexiconRouterApp from 'referencial/components/lexicons/LexiconRouterApp'
import WatermassRouterApp from 'referencial/components/watermass/components/WatermassRouterApp'
import SandreCodeRouterApp from 'referencial/components/sandreCodes/SandreCodeRouterApp'
import ParameterRouterApp from 'referencial/components/parameter/components/ParameterRouterApp'
import ParameterGroupRouterApp from 'referencial/components/parameter/components/ParameterGroupRouterApp'
import CountryRouterApp from 'referencial/components/country/components/CountryRouterApp'
import NetworkRouterApp from 'referencial/components/network/components/NetworkRouterApp'
import SupportRouterApp from 'referencial/components/support/components/SupportRouterApp'
import TaxonRouterApp from 'referencial/components/taxon/components/TaxonRouterApp'
import MatTypeRouterApp from 'referencial/components/matTypes/MatTypeRouterApp'
import UnitRouterApp from 'referencial/components/unit/components/UnitRouterApp'
import ManagementUnitRouterApp from 'referencial/components/managementUnit/components/ManagementUnitRouterApp'
import MethodRouterApp from 'referencial/components/methods/components/MethodRouterApp'
import HydrometersApp from 'hydrometry/components/hydrometers/HydrometersApp'
import ContributorGroupRouterApp from 'referencial/components/contributor/components/ContributorGroupRouterApp'
import UserRouterApp from 'administration/components/user/components/UserRouterApp'
import ResourceApp from 'resource/components/ResourceApp'
import CultureRPGRouterApp from 'referencial/components/cultures/components/CultureRPGRouterApp'
import SectorRouterApp from 'referencial/components/sector/components/SectorRouterApp'
import PARApp from 'survey/components/par/PARApp'
import ScenarioApp from 'survey/components/par/ScenarioApp'
import PerimetersApp from 'perimeters/PerimetersApp'
import DashboardApp from 'home/components/DashboardApp'
import AccountUserApp from 'account/components/AccountUserApp'
import AlertMapApp from 'alerting/components/dashboard/AlertMapApp'
import FollowApp from 'alerting/components/follow/FollowApp'
import AdminAlertApp from 'administration/components/alert/AdminAlertApp'
import AdminModelApp from 'administration/components/alert/AdminModelApp'
import AlertNewCmsModal from 'alerting/components/map/AlertNewCmsModal'
import DistributionUnitsDashboard from 'distributionUnit/components/dashboard/DistributionUnitsDashboard'
import DistributionUnitsCounterManager from 'distributionUnit/components/DistributionUnitsCounterManager'
import AdminUDIApp from 'administration/components/udi/AdminUDIApp'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import PluviometersDashboard from 'pluviometry/components/dashboard/PluviometersDashboard'
import PluviometersExportApp from 'pluviometry/components/export/PluviometersExportApp'
import PluvioIntegrationApp from 'pluviometry/components/integration/PluvioIntegrationApp'
import AdminPluviometerApp from 'administration/components/pluviometer/AdminPluviometerApp'
import PluviometryCampaigns from 'pluviometry/components/campaign/PluviometryCampaigns'
import PluviometryGraphicApp from 'pluviometry/components/graphic/PluviometryGraphicApp'
import ResourcesDashboard from 'resource/components/dashboard/ResourcesDashboard'
import ResourcesOptionApp from 'resource/components/ResourcesOptionApp'
import ProductionUnitsDashboard from 'productionUnit/components/dashboard/ProductionUnitsDashboard'
import AdminProductionUnitApp from 'productionUnit/components/AdminProductionUnitApp'
import ExploitationsListApp from 'exploitations/components/ExploitationsListApp'
import ServicesManagerApp from 'exploitations/components/ServicesManagerApp'
import ExploitationsServicesSettingsApp from 'exploitations/components/ExploitationsServicesSettingsApp'
import ExploitationDashboardApp from 'exploitations/components/ExploitationDashboardApp'
import ExploitationWaterTurnsApp from 'exploitations/components/ExploitationWaterTurnsApp'
import ExploitationServiceApp from 'exploitations/components/ExploitationServiceApp'
import ExploitationVolumesApp from 'exploitations/components/ExploitationVolumesApp'
import ExploitationMaterielsApp from 'exploitations/components/ExploitationMaterielsApp'
import ExploitationDocumentsApp from 'exploitations/components/ExploitationDocumentsApp'
import ExploitationConsumptionsApp from 'exploitations/components/ExploitationConsumptionsApp'
import ExploitationEventsApp from 'exploitations/components/ExploitationEventsApp'
import ExploitationsSettingsApp from 'exploitations/components/ExploitationsSettingsApp'
import HydrometryCampaignDashboard from 'hydrometry/components/campaign/HydrometryCampaignDashboard'
import HydrometryCampaignTrackingApp from 'hydrometry/components/campaign/HydrometryCampaignTrackingApp'
import HydrometersDashboardApp from 'hydrometry/components/hydrometers/HydrometersDashboardApp'
import HydrometricStationsExportApp from 'hydrometry/components/export/HydrometricStationsExportApp'
import HydroIntegrationOverviewApp from 'hydrometry/components/bilanIntegration/HydroIntegrationOverviewApp'
import HydrometryGraphicApp from 'hydrometry/components/overlayGraph/HydrometryGraphicApp'
import HydrometryCampaigns from 'hydrometry/components/campaign/HydrometryCampaigns'
import MapSituationAdminApp from 'station/components/mapSituation/MapSituationAdminApp'
import MapSituationApp from 'station/components/mapSituation/MapSituationApp'
import AdminHydrometricStationApp from 'administration/components/hydrometricStation/AdminHydrometricStationApp'
import InstallationCampaignDashboard from 'installation/components/campaign/InstallationCampaignDashboard'
import InstallationCampaignTrackingApp from 'installation/components/campaign/InstallationCampaignTrackingApp'
import InstallationCampaignValidationApp from 'installation/components/campaign/InstallationCampaignValidationApp'
import InstallationVisitSummary from 'installation/components/campaign/InstallationVisitSummary'
import InstallationVisitOperatorFeedback from 'installation/components/campaign/InstallationVisitOperatorFeedback'
import InstallationVisitApp from 'installation/components/campaign/InstallationVisitApp'
import InstallationsDashboardApp from 'installation/components/installations/components/InstallationsDashboardApp'
import InstallationCounterApp from 'installation/components/installations/components/InstallationCounterApp'
import InstallationCampaigns from 'installation/components/campaign/InstallationCampaigns'
import InstallationsOptions from 'installation/components/options/InstallationsOptions'
import TerritoryActivities from 'installation/components/installations/components/TerritoryActivities'
import ImportCard from 'import/components/content/ImportCard'
import PerimeterDashboard from 'perimeters/components/perimeter/PerimeterDashboard'
import PerimetersDashboard from 'perimeters/components/perimeters/PerimetersDashboard'
import PerimetersDeeds from 'perimeters/components/perimeters/PerimetersDeeds'
import MaterielTypeApp from 'materiel/components/materielType/MaterielTypeApp'
import AdministrationMaterielApp from 'materiel/components/administration/AdministrationMaterielApp'
import MaterielRuleApp from 'materiel/components/administration/MaterielRuleApp'
import MaterielDashboardApp from 'materiel/components/dashboard/MaterielDashboardApp'
import InventoryApp from 'materiel/components/inventory/InventoryApp'
import CentralApp from 'materiel/components/central/components/CentralApp'
import CentralsApp from 'materiel/components/central/components/CentralsApp'
import TelecomApp from 'materiel/components/telecom/components/TelecomApp'
import TelecomsApp from 'materiel/components/telecom/components/TelecomsApp'
import PowerSupplyApp from 'materiel/components/powerSupply/components/PowerSupplyApp'
import PowerSuppliesApp from 'materiel/components/powerSupply/components/PowerSuppliesApp'
import SensorApp from 'materiel/components/sensor/components/SensorApp'
import SensorsApp from 'materiel/components/sensor/components/SensorsApp'
import SimApp from 'materiel/components/sim/components/SimApp'
import SimsApp from 'materiel/components/sim/components/SimsApp'
import VariousMaterielApp from 'materiel/components/variousMateriel/components/VariousMaterielApp'
import VariousMaterielsApp from 'materiel/components/variousMateriel/components/VariousMaterielsApp'
import EquipmentApp from 'materiel/components/equipment/components/EquipmentApp'
import EquipmentsApp from 'materiel/components/equipment/components/EquipmentsApp'
import SubscriptionApp from 'materiel/components/subscription/components/SubscriptionApp'
import SubscriptionsApp from 'materiel/components/subscription/components/SubscriptionsApp'
import PGSSEDashboard from 'pgsse/components/dashboard/PGSSEDashboard'
import DangersManager from 'pgsse/components/Dangers/DangersManager'
import PiezometersDashboardApp from 'piezometry/components/piezometers/PiezometersDashboardApp'
import PiezometersExportApp from 'piezometry/components/export/PiezometersExportApp'
import PiezometryIntegrationApp from 'piezometry/components/integration/components/PiezometryIntegrationApp'
import PiezoExportOverviewApp from 'piezometry/components/exportOverview/PiezoExportOverviewApp'
import PiezometryCampaigns from 'piezometry/components/campaign/PiezometryCampaigns'
import AdminPiezometerApp from 'administration/components/piezometer/AdminPiezometerApp'
import PiezometryGraphicApp from 'piezometry/components/graphic/PiezometryGraphicApp'
import PiezometryDiagnosticsApp from 'piezometry/components/diagnostics/PiezometryDiagnosticsApp'
import PiezometryCampaignDashboard from 'piezometry/components/campaign/PiezometryCampaignDashboard'
import PiezometryCampaignTrackingApp from 'piezometry/components/campaign/PiezometryCampaignTrackingApp'
import PiezometryCampaignMaterielsApp from 'piezometry/components/campaign/PiezometryCampaignMaterielsApp'
import PluviometryCampaignDashboard from 'pluviometry/components/campaign/PluviometryCampaignDashboard'
import PluviometryCampaignTrackingApp from 'pluviometry/components/campaign/PluviometryCampaignTrackingApp'
import QualitometersDashboardApp from 'quality/components/qualitometers/QualitometersDashboardApp'
import QualityResearchApp from 'quality/components/qualityResearch/QualityResearchApp'
import QualityCampaigns from 'quality/components/campaign/QualityCampaigns'
import QualityIntegrationOverviewApp from 'quality/components/integrationOverview/components/new/QualityIntegrationOverviewApp'
import AEPoverviewApp from 'quality/components/AEPoverview/quality/AEPoverviewApp'
import QualityGraphicApp from 'quality/components/graphic/QualityGraphicApp'
import QualitometersExportApp from 'quality/components/export/QualitometersExportApp'
import AdminQualitometerApp from 'administration/components/qualitometer/AdminQualitometerApp'
import QualityCampaignDashboard from 'quality/components/campaign/QualityCampaignDashboard'
import QualityCampaignTrackingApp from 'quality/components/campaign/QualityCampaignTrackingApp'
import QualityCampaignFileApp from 'quality/components/campaign/QualityCampaignFileApp'
import HabilitationComponent from 'HabilitationComponent'
import QualityIndicatorsApp from 'administration/components/qualitometer/qualityIndicators/QualityIndicatorsApp'
import QualitySelectionApp from 'administration/components/qualitometer/selection/QualitySelectionApp'
import QualityThresholdApp from 'administration/components/qualitometer/threshold/QualityThresholdApp'
import { H_QUALITO_OPTIONS_INDICATOR, H_QUALITO_OPTIONS_SELECTION, H_QUALITO_OPTIONS_THRESHOLD } from 'account/constants/AccessRulesConstants'
import CatchmentDashboardApp from 'catchment/components/catchment/dashboard/CatchmentDashboardApp'
import CatchmentDescriptionApp from 'catchment/components/catchment/CatchmentDescriptionApp'
import CatchmentMapApp from 'catchment/components/catchment/CatchmentMapApp'
import CatchmentPcMonitoringApp from 'catchment/components/catchment/CatchmentPcMonitoringApp'
import CatchmentAEPApp from 'catchment/components/catchment/CatchmentAEPApp'
import CatchmentsDashboardApp from 'catchment/components/catchments/CatchmentsDashboardApp'
import CatchmentsOverviewApp from 'catchment/components/catchmentsOverview/CatchmentsOverviewApp'
import CatchmentsCompareApp from 'catchment/components/CatchmentsCompare/CatchmentsCompareApp'
import CatchmentsOptionApp from 'catchment/components/catchments/CatchmentsOptionApp'
import ResourceDashboardApp from 'resource/components/ResourceDashboardApp'
import ResourcePcMonitoringApp from 'resource/components/ResourcePcMonitoringApp'
import ResourceAEPApp from 'resource/components/ResourceAEPApp'
import StationDescriptionApp from 'station/components/description/StationDescriptionApp'
import PictureStationApp from 'station/components/picture/PictureStationApp'
import SurveysDashboardApp from 'survey/components/survey/SurveysDashboardApp'
import PARsListApp from 'survey/components/par/PARsListApp'
import SurveyDashboardApp from 'survey/components/survey/SurveyDashboardApp'
import SurveyStatsApp from 'survey/components/survey/SurveyStatsApp'
import SurveyValidationApp from 'survey/components/survey/SurveyValidationApp'
import SurveyExportApp from 'survey/components/survey/SurveyExportApp'
import SurveySettingsApp from 'survey/components/survey/SurveySettingsApp'
import DeclarationStepperApp from 'survey/components/declaration/DeclarationStepperApp'
import DeclarationPointDetails from 'survey/components/declaration/DeclarationPointDetails'
import DeclarationPointUsages from 'survey/components/declaration/DeclarationPointUsages'
import PARDashboardApp from 'survey/components/par/PARDashboardApp'
import PAREventsApp from 'survey/components/par/PAREventsApp'
import ScenarioDashboardApp from 'survey/components/par/ScenarioDashboardApp'
import TerritoryDashboardApp from 'territory/components/TerritoryDashboardApp'
import WaterTurnsManagerApp from 'exploitations/components/WaterTurnsManagerApp'
import TerritoryRestrictionsApp from 'territory/components/TerritoryRestrictionsApp'
import TerritorySettingsApp from 'territory/components/TerritorySettingsApp'
import ActivityApp from 'referencial/components/activity/components/ActivityApp'
import SettingApp from 'administration/components/setting/SettingApp'
import AdminStatsApp from 'administration/components/stats/components/AdminStatsApp'
import UsersApp from 'administration/components/user/components/UsersApp'
import ThemeApp from 'administration/components/cartography/ThemeApp'
import CartographyApp from 'administration/components/cartography/CartographyApp'
import AdministrationHomePageApp from 'administration/components/homepage/AdministrationHomePageApp'
import AdministrationContentsApp from 'administration/components/contents/AdministrationContentsApp'
import ProblemSolutionApp from 'administration/components/problemSolution/ProblemSolutionApp'
import ModelApp from 'administration/components/models/ModelApp'
import AdminParameterApp from 'administration/components/parameter/AdminParameterApp'
import SuperAdminPanel from 'administration/components/superAdmin/components/SuperAdminPanel'
import CorrectV6ParentApp from 'administration/components/superAdmin/components/correctV6/CorrectV6ParentApp'
import UserApp from 'administration/components/user/components/UserApp'
import { PANEL_ACCESS, PANEL_FILE, PANEL_MODULES, PANEL_STATISTICS } from 'administration/components/user/constants/UserConstants'
import { PANEL_MAP } from 'components/sieau/SieauConstants'
import AquiferApp from 'referencial/components/aquifers/components/AquiferApp'
import WatershedApp from 'referencial/components/watershed/components/WatershedApp'
import WatershedMapApp from 'referencial/components/watershed/components/WatershedMapApp'
import CityApp from 'referencial/components/city/components/CityApp'
import CityMapApp from 'referencial/components/city/components/CityMapApp'
import ContactApp from 'referencial/components/contact/components/ContactApp'
import ContactGroupApp from 'referencial/components/contact/components/ContactGroupApp'
import CultureApp from 'referencial/components/cultures/components/CultureApp'
import CultureRPGApp from 'referencial/components/cultures/components/CultureRPGApp'
import CulturesFamilyApp from 'referencial/components/culturesFamilies/components/CulturesFamilyApp'
import HydrogeologicalEntityApp from 'referencial/components/hydrogeologicalEntity/components/HydrogeologicalEntityApp'
import FractionApp from 'referencial/components/fraction/components/FractionApp'
import ContributorApp from 'referencial/components/contributor/components/ContributorApp'
import ContributorGroupApp from 'referencial/components/contributor/components/ContributorGroupApp'
import LexiconApp from 'referencial/components/lexicons/LexiconApp'
import WatermassApp from 'referencial/components/watermass/components/WatermassApp'
import WatermassMapApp from 'referencial/components/watermass/components/WatermassMapApp'
import WatermassStatesApp from 'referencial/components/watermass/components/WatermassStatesApp'
import MethodApp from 'referencial/components/methods/components/MethodApp'
import SandreCodeApp from 'referencial/components/sandreCodes/SandreCodeApp'
import ParameterMapApp from 'referencial/components/parameter/components/ParameterMapApp'
import ParameterSourcePanel from 'referencial/components/parameter/components/ParameterSourcePanel'
import ParameterApp from 'referencial/components/parameter/components/ParameterApp'
import ParameterGroupApp from 'referencial/components/parameter/components/ParameterGroupApp'
import CountryApp from 'referencial/components/country/components/CountryApp'
import NetworkApp from 'referencial/components/network/components/NetworkApp'
import SupportApp from 'referencial/components/support/components/SupportApp'
import TaxonApp from 'referencial/components/taxon/components/TaxonApp'
import TaxonCarto from 'referencial/components/taxon/components/TaxonCarto'
import MatTypeApp from 'referencial/components/matTypes/MatTypeApp'
import UnitApp from 'referencial/components/unit/components/UnitApp'
import ManagementUnitApp from 'referencial/components/managementUnit/components/ManagementUnitApp'
import SectorApp from 'referencial/components/sector/components/SectorApp'
import ReferencialDashboardApp from 'referencial/components/dashboard/components/ReferencialDashboardApp'
import ParametersApp from 'referencial/components/parameter/components/ParametersApp'
import TaxonsApp from 'referencial/components/taxon/components/TaxonsApp'
import MethodsApp from 'referencial/components/methods/components/MethodsApp'
import SupportsApp from 'referencial/components/support/components/SupportsApp'
import ContributorsApp from 'referencial/components/contributor/components/ContributorsApp'
import CitiesApp from 'referencial/components/city/components/CitiesApp'
import AquifersApp from 'referencial/components/aquifers/components/AquifersApp'
import ContactsApp from 'referencial/components/contact/components/ContactsApp'
import HydrogeologicalEntitiesApp from 'referencial/components/hydrogeologicalEntity/components/HydrogeologicalEntitiesApp'
import NetworksApp from 'referencial/components/network/components/NetworksApp'
import FractionsApp from 'referencial/components/fraction/components/FractionsApp'
import UnitsApp from 'referencial/components/unit/components/UnitsApp'
import WatermassesApp from 'referencial/components/watermass/components/WatermassesApp'
import WatershedsApp from 'referencial/components/watershed/components/WatershedsApp'
import SandreCodesApp from 'referencial/components/sandreCodes/SandreCodesApp'
import LexiconsApp from 'referencial/components/lexicons/LexiconsApp'
import RPGApp from 'referencial/components/cultures/components/RPGApp'
import MatTypesApp from 'referencial/components/matTypes/MatTypesApp'
import ManagementUnitsApp from 'referencial/components/managementUnit/components/ManagementUnitsApp'
import CountriesApp from 'referencial/components/country/components/CountriesApp'
import CulturesApp from 'referencial/components/cultures/components/CulturesApp'
import ActivitiesApp from 'referencial/components/activity/components/ActivitiesApp'
import SectorsApp from 'referencial/components/sector/components/SectorsApp'
import DistributionUnitPcMonitoringApp from 'distributionUnit/components/DistributionUnitPcMonitoringApp'
import DistributionUnitAEPApp from 'distributionUnit/components/DistributionUnitAEPApp'
import DistributionUnitAssociatedGraphic from 'distributionUnit/components/DistributionUnitAssociatedGraphic'
import DistributionUnitCounterManager from 'distributionUnit/components/DistributionUnitCounterManager'
import ProductionUnitPcMonitoringApp from 'productionUnit/components/ProductionUnitPcMonitoringApp'
import ProductionUnitAEPApp from 'productionUnit/components/ProductionUnitAEPApp'
import ProductionUnitAssociatedGraphic from 'productionUnit/components/ProductionUnitAssociatedGraphic'
import InstallationPcMonitoringApp from 'installation/components/InstallationPcMonitoringApp'
import InstallationAEPApp from 'installation/components/InstallationAEPApp'
import StationDashboardApp from 'station/components/dashboard/StationDashboardApp'
import StationMapApp from 'station/components/dashboard/StationMapApp'
import StationEventsApp from 'station/components/event/StationEventsApp'
import StationEventApp from 'station/components/event/StationEventApp'
import PiezometerEventForm from 'piezometry/components/event/PiezometerEventForm'
import PluvioSuiviGraphApp from 'pluviometry/components/followUp/PluvioSuiviGraphApp'
import PluvioSuiviTableApp from 'pluviometry/components/followUpTable/PluvioSuiviTableApp'
import PluviometryValidationApp from 'pluviometry/components/validation/PluviometryValidationApp'
import MaterielStationApp from 'station/components/materiel/MaterielStationApp'
import SituationDetailApp from 'station/components/materiel/situationDetail/SituationDetailApp'
import PcMonitoringApp from 'station/components/suivipc/suiviPC2/PcMonitoringApp'
import OperationsListApp from 'quality/components/operation/components/list/OperationsListApp'
import StationOperationApp from 'quality/components/operation/components/StationOperationApp'
import HydrobioOperationApp from 'quality/components/hydrobio/operation/HydrobioOperationApp'
import HydrobioOperationsApp from 'quality/components/hydrobio/operation/HydrobioOperationsApp'
import HydrobioMonitoringApp from 'quality/components/hydrobio/monitoring/HydrobioMonitoringApp'
import QualitometerStatesApp from 'quality/components/states/QualitometerStatesApp'
import HydrometerSuiviApp from 'hydrometry/components/followUp/components/HydrometerSuiviApp'
import HydroSuiviTableApp from 'hydrometry/components/followUpTable/HydroSuiviTableApp'
import HydrometerObservationsApp from 'hydrometry/components/observation/HydrometerObservationsApp'
import HydrometryValidationApp from 'hydrometry/components/validation2/HydrometryValidationApp'
import IAEauModelsApp from 'station/components/iaeauModels/IAEauModelsApp'
import IAEauModelApp from 'station/components/iaeauModels/IAEauModelApp'
import InstallationFollowUpApp from 'installation/components/installationDefault/InstallationFollowUpApp'
import STEPTerritoryApp from 'installation/components/step/components/STEPTerritoryApp'
import STEPPollutionSearchApp from 'installation/components/step/components/STEPPollutionSearchApp'
import InstallationsExport from 'installation/components/step/InstallationsExport'
import PiezoSuiviChart2 from 'piezometry/components/suivi/components/PiezoSuiviChart2'
import PiezometerSuiviTableApp from 'piezometry/components/suiviTable/PiezometerSuiviTableApp'
import PiezometryValidationApp from 'piezometry/components/validation/PiezometryValidationApp'
import TerritorySettingsParamsApp from 'territory/components/TerritorySettingsParamsApp'
import TerritorySettingsCulturesApp from 'territory/components/TerritorySettingsCulturesApp'
import RSEauApplication from 'territory/components/RSEauApplication'
import UsageApp from 'referencial/components/usage/components/UsageApp'
import UsagesApp from 'referencial/components/usage/components/UsagesApp'
import TerritoryTanksTypesApp from 'territory/components/TerritoryTanksTypesApp'
import TerritoryTanksTypeApp from 'territory/components/TerritoryTanksTypeApp'
import CatchmentDocumentApp from 'catchment/components/catchment/CatchmentDocumentApp'

i18n.addLang(fr, ['fr', 'FR'])
i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

render(
    <ThemeProvider theme={Theme}>
        <Provider store={AppStore}>
            <ReduxRouter history={history}>
                <ComponentWithNavObject Component={BubbleHelp} />
                <BoundaryError>
                    <Routes>
                        <Route path='login' element={<Login />} />
                        <Route path='reset' element={<ComponentWithNavObject Component={ResetPasswordApp} />} />
                        <Route path='sitePlan' element={<SitePlanApp />} />
                        <Route path='unauthorized' element={<UnauthorizedApp />} />
                        <Route path='/' element={<AuthenticatedComponent Component={SieauApp} />}>
                            <Route index element={<ComponentWithNavObject Component={DashboardApp} />} />

                            <Route path='/account'>
                                <Route index element={<ComponentWithNavObject Component={AccountUserApp} />} />
                                <Route path='/account/update' element={<ComponentWithNavObject Component={AccountUserApp} />} />
                            </Route>

                            <Route path='/arrests' element={<ComponentWithNavObject Component={ArrestsApp} />} />

                            <Route path='/alert' element={<ComponentWithNavObject Component={AlertApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={AlertMapApp} />} />
                                <Route path='follow/:id/:type' element={<ComponentWithNavObject Component={FollowApp} />} />
                                <Route path='alertOptions' element={<ComponentWithNavObject Component={AdminAlertApp} />} />
                                <Route path='modelOptions' element={<ComponentWithNavObject Component={AdminModelApp} />} />
                                <Route path='newCms' element={<ComponentWithNavObject Component={AlertNewCmsModal} />} />
                            </Route>

                            <Route path='/contents/:categoryId/:contentId/:lang' element={<ComponentWithNavObject Component={CmsEventForm} />} />

                            <Route path='/contents/:categoryId/:contentId' element={<ComponentWithNavObject Component={CmsEventForm} />} />

                            <Route path='/contents/:categoryId' element={<ComponentWithNavObject Component={ContentsApp} />} />

                            <Route path='/distributionUnit' element={<ComponentWithNavObject Component={StationsApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={DistributionUnitsDashboard} />} />
                                <Route path='counters' element={<ComponentWithNavObject Component={DistributionUnitsCounterManager} />} />
                                <Route path='options' element={<ComponentWithNavObject Component={AdminUDIApp} />} />
                                <Route path='develop' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                            </Route>

                            <Route path='/pluviometry' element={<ComponentWithNavObject Component={StationsApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={PluviometersDashboard} />} />
                                <Route path='export' element={<ComponentWithNavObject Component={PluviometersExportApp} />} />
                                <Route path='develop' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                                <Route path='integrationOverview' element={<ComponentWithNavObject Component={PluvioIntegrationApp} />} />
                                <Route path='pluviometryOptions' element={<ComponentWithNavObject Component={AdminPluviometerApp} />} />
                                <Route path='campaign' element={<ComponentWithNavObject Component={PluviometryCampaigns} />} />
                                <Route path='graphic' element={<ComponentWithNavObject Component={PluviometryGraphicApp} />} />
                                {
                                    /*
                                    <Route path='/pluviometry/mapSituations/settings' element={<ComponentWithNavObject Component={MapSituationAdminApp}/>} />
                                    <Route path='/pluviometry/mapSituations' element={<ComponentWithNavObject Component={MapSituationApp} />} />
                                    */
                                }
                            </Route>

                            <Route path='/resource' element={<ComponentWithNavObject Component={StationsApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ResourcesDashboard} />} />
                                <Route path='settings' element={<ComponentWithNavObject Component={ResourcesOptionApp} />} />
                            </Route>

                            <Route path='/productionUnit' element={<ComponentWithNavObject Component={StationsApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ProductionUnitsDashboard} />} />
                                <Route path='options' element={<ComponentWithNavObject Component={AdminProductionUnitApp} />} />
                                <Route path='develop' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                            </Route>

                            <Route path='/dossiers' element={<ComponentWithNavObject Component={ExploitationsApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ExploitationsListApp} />} />
                                <Route path='services' element={<ComponentWithNavObject Component={ServicesManagerApp} />} />
                                <Route path='settings' element={<Navigate exact path='' to='parameters' />} />
                                <Route path='settings/parameters' element={<ComponentWithNavObject Component={ExploitationsSettingsApp} />} />
                                <Route path='settings/services' element={<ComponentWithNavObject Component={ExploitationsServicesSettingsApp} />} />
                            </Route>

                            <Route path='/dossiers/:id' element={<ComponentWithNavObject Component={ExploitationsApp} />}>
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ExploitationDashboardApp} />} />
                                <Route path='waterTurns' element={<ComponentWithNavObject Component={ExploitationWaterTurnsApp} />} />
                                <Route path='services' element={<ComponentWithNavObject Component={ExploitationServiceApp} />} />
                                <Route path='volumes' element={<ComponentWithNavObject Component={ExploitationVolumesApp} />} />
                                <Route path='materiels' element={<ComponentWithNavObject Component={ExploitationMaterielsApp} />} />
                                <Route path='documents' element={<ComponentWithNavObject Component={ExploitationDocumentsApp} />} />
                                <Route path='consumptions' element={<ComponentWithNavObject Component={ExploitationConsumptionsApp} />} />
                                <Route path='events' element={<ComponentWithNavObject Component={ExploitationEventsApp} />} />
                            </Route>

                            {/* crash */}
                            <Route path='/globalEvents' element={<ComponentWithNavObject Component={GlobalEventsApp} />} />

                            <Route path='/hydrometry' element={<ComponentWithNavObject Component={HydrometersApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={HydrometersDashboardApp} />} />
                                <Route path='export' element={<ComponentWithNavObject Component={HydrometricStationsExportApp} />} />
                                <Route path='integrationOverview' element={<ComponentWithNavObject Component={HydroIntegrationOverviewApp} />} />
                                <Route path='graphic' element={<ComponentWithNavObject Component={HydrometryGraphicApp} />} />
                                <Route path='campaign' element={<ComponentWithNavObject Component={HydrometryCampaigns} />} />
                                <Route path='mapSituations/settings' element={<ComponentWithNavObject Component={MapSituationAdminApp}/>} />
                                <Route path='mapSituations' element={<ComponentWithNavObject Component={MapSituationApp}/>} />
                                <Route path='hydrometryOptions' element={<ComponentWithNavObject Component={AdminHydrometricStationApp} />} />
                            </Route>

                            <Route path='/hydrometry/campaign/:id' element={<ComponentWithNavObject Component={HydrometryCampaignApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={HydrometryCampaignDashboard} />} />
                                <Route path='tracking' element={<ComponentWithNavObject Component={HydrometryCampaignTrackingApp} />} />
                            </Route>

                            <Route path='/installation/campaign/:id' element={<ComponentWithNavObject Component={InstallationCampaignApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={InstallationCampaignDashboard} />} />
                                <Route path='tracking' element={<ComponentWithNavObject Component={InstallationCampaignTrackingApp} />} />
                                <Route path='validation' element={<ComponentWithNavObject Component={InstallationCampaignValidationApp} />} />
                                <Route path='visit/summary/:idInstallation' element={<ComponentWithNavObject Component={InstallationVisitSummary} />} />
                                <Route path='visit/:idInstallation/operatorFeedback' element={<ComponentWithNavObject Component={InstallationVisitOperatorFeedback} />} />
                                <Route path='visit/:idInstallation/:step' element={<ComponentWithNavObject Component={InstallationVisitApp} />} />
                            </Route>

                            <Route path='/installation' element={<ComponentWithNavObject Component={InstallationsApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={InstallationsDashboardApp} />} />
                                <Route path='counter' element={<ComponentWithNavObject Component={InstallationCounterApp} />} />
                                <Route path='campaign' element={<ComponentWithNavObject Component={InstallationCampaigns} />} />
                                <Route path='installationOptions' element={<ComponentWithNavObject Component={InstallationsOptions} />} />
                                <Route path='territoryActivities' element={<ComponentWithNavObject Component={TerritoryActivities} />} />
                                <Route path='develop' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                            </Route>

                            <Route path='/job' element={<ComponentWithNavObject Component={JobTableExecutions} />} />

                            <Route path='/import/:id' element={<ComponentWithNavObject Component={JobContentApp} />}>
                                <Route index element={<ComponentWithNavObject Component={ImportCard} /> }/>
                            </Route>

                            <Route path='/perimeter' element={<ComponentWithNavObject Component={PerimetersApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={PerimetersDashboard} />} />
                                <Route path='deeds' element={<ComponentWithNavObject Component={PerimetersDeeds} />} />
                            </Route>

                            <Route path='/perimeter/:id' element={<ComponentWithNavObject Component={PerimetersApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={PerimeterDashboard} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                                <Route path='events' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                                <Route path='documents' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                            </Route>

                            <Route path='/materiel' element={<ComponentWithNavObject Component={MaterielApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={MaterielDashboardApp} />} />
                                <Route path='inventory' element={<ComponentWithNavObject Component={InventoryApp} />} />
                                <Route path='central/:id' element={<ComponentWithNavObject Component={CentralApp} />} />
                                <Route path='central' element={<ComponentWithNavObject Component={CentralsApp} />} />
                                <Route path='telecom/:id' element={<ComponentWithNavObject Component={TelecomApp} />} />
                                <Route path='telecom' element={<ComponentWithNavObject Component={TelecomsApp} />} />
                                <Route path='powerSupply/:id' element={<ComponentWithNavObject Component={PowerSupplyApp} />} />
                                <Route path='powerSupply' element={<ComponentWithNavObject Component={PowerSuppliesApp} />} />
                                <Route path='sensor/:id' element={<ComponentWithNavObject Component={SensorApp} />} />
                                <Route path='sensor' element={<ComponentWithNavObject Component={SensorsApp} />} />
                                <Route path='sim/:id' element={<ComponentWithNavObject Component={SimApp} />} />
                                <Route path='sim' element={<ComponentWithNavObject Component={SimsApp} />} />
                                <Route path='variousMateriel/:id' element={<ComponentWithNavObject Component={VariousMaterielApp} />} />
                                <Route path='variousMateriel' element={<ComponentWithNavObject Component={VariousMaterielsApp} />} />
                                <Route path='equipment/:id' element={<ComponentWithNavObject Component={EquipmentApp} />} />
                                <Route path='equipment' element={<ComponentWithNavObject Component={EquipmentsApp} />} />
                                <Route path='subscription/:id' element={<ComponentWithNavObject Component={SubscriptionApp} />} />
                                <Route path='subscription' element={<ComponentWithNavObject Component={SubscriptionsApp} />} />
                            </Route>

                            <Route path='/materiel/administration' element={<ComponentWithNavObject Component={MaterielOptionsApp} />}>
                                <Route index element={<Navigate exact path='' to='materiel' />} />
                                <Route path='type' element={<ComponentWithNavObject Component={MaterielTypeApp} />} />
                                <Route path='materiel' element={<ComponentWithNavObject Component={AdministrationMaterielApp} />} />
                                <Route path='rule' element={<ComponentWithNavObject Component={MaterielRuleApp} />} />
                            </Route>

                            <Route path='/messages' element={<ComponentWithNavObject Component={DashboardMessages} />} />

                            <Route path='/messages/:login' element={<ComponentWithNavObject Component={ConversationMessages} />} />

                            <Route path='/pgsse' element={<ComponentWithNavObject Component={PGSSEApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={PGSSEDashboard} />} />
                                <Route path='dangers' element={<ComponentWithNavObject Component={DangersManager} />} />
                            </Route>

                            <Route path='/piezometry' element={<ComponentWithNavObject Component={PiezometersApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={PiezometersDashboardApp} />} />
                                <Route path='export' element={<ComponentWithNavObject Component={PiezometersExportApp} />} />
                                <Route path='develop' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                                <Route path='integrationOverview' element={<ComponentWithNavObject Component={PiezometryIntegrationApp} />} />
                                <Route path='exportOverview' element={<ComponentWithNavObject Component={PiezoExportOverviewApp} />} />
                                <Route path='campaign' element={<ComponentWithNavObject Component={PiezometryCampaigns} />} />
                                <Route path='piezometryOptions' element={<ComponentWithNavObject Component={AdminPiezometerApp} />} />
                                <Route path='graphic' element={<ComponentWithNavObject Component={PiezometryGraphicApp} />} />
                                <Route path='mapSituations/settings' element={<ComponentWithNavObject Component={MapSituationAdminApp} />} />
                                <Route path='mapSituations' element={<ComponentWithNavObject Component={MapSituationApp} />} />
                                <Route path='diagnostics' element={<ComponentWithNavObject Component={PiezometryDiagnosticsApp} />} />
                            </Route>

                            <Route path='/piezometry/campaign/:id' element={<ComponentWithNavObject Component={PiezometryCampaignApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={PiezometryCampaignDashboard} />} />
                                <Route path='tracking' element={<ComponentWithNavObject Component={PiezometryCampaignTrackingApp} />} />
                                <Route path='materiel' element={<ComponentWithNavObject Component={PiezometryCampaignMaterielsApp} />} />
                            </Route>

                            <Route path='/pluviometry/campaign/:id' element={<ComponentWithNavObject Component={PluviometryCampaignApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={PluviometryCampaignDashboard} />} />
                                <Route path='tracking' element={<ComponentWithNavObject Component={PluviometryCampaignTrackingApp} />} />
                            </Route>

                            <Route path='/quality' element={<ComponentWithNavObject Component={QualitometersApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={QualitometersDashboardApp} />} />
                                <Route path='research' element={<ComponentWithNavObject Component={QualityResearchApp} />} />
                                <Route path='campaign' element={<ComponentWithNavObject Component={QualityCampaigns} />} />
                                <Route path='integrationOverview' element={<ComponentWithNavObject Component={QualityIntegrationOverviewApp} />} />
                                <Route path='conformityOverview' element={<ComponentWithNavObject Component={AEPoverviewApp} />} />
                                <Route path='graphic' element={<ComponentWithNavObject Component={QualityGraphicApp} />} />
                                <Route path='export' element={<ComponentWithNavObject Component={QualitometersExportApp} />} />
                                <Route path='develop' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                            </Route>

                            <Route path='/quality/qualityOptions' element={<ComponentWithNavObject Component={QualityOptionsApp} />}>
                                <Route index element={<Navigate exact path='' to='generalOptions' />} />
                                <Route path='generalOptions' element={<AdminQualitometerApp />} />
                                <Route path='indicators' element={<HabilitationComponent Component={QualityIndicatorsApp} habilitation={H_QUALITO_OPTIONS_INDICATOR} />} />
                                <Route path='selection' element={<HabilitationComponent Component={QualitySelectionApp} habilitation={H_QUALITO_OPTIONS_SELECTION} />} />
                                <Route path='threshold' element={<HabilitationComponent Component={QualityThresholdApp} habilitation={H_QUALITO_OPTIONS_THRESHOLD} />} />
                            </Route>

                            <Route path='/quality/campaign/:id' element={<ComponentWithNavObject Component={QualityCampaignApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={QualityCampaignDashboard} />} />
                                <Route path='tracking' element={<ComponentWithNavObject Component={QualityCampaignTrackingApp} />} />
                                <Route path='file' element={<ComponentWithNavObject Component={QualityCampaignFileApp} />} />
                            </Route>

                            <Route path='/catchment' element={<CatchmentsApp />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<CatchmentsDashboardApp />} />
                                <Route path='overview' element={<CatchmentsOverviewApp />} />
                                <Route path='compare' element={<CatchmentsCompareApp />} />
                                <Route path='option' element={<CatchmentsOptionApp />} />
                            </Route>

                            <Route path='/station/catchment/:id' element={<CatchmentApp />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<CatchmentDashboardApp />} />
                                <Route path='description' element={<CatchmentDescriptionApp />} />
                                <Route path='map' element={<CatchmentMapApp />} />
                                <Route path='document' element={<CatchmentDocumentApp />} />
                                <Route path='pcMonitoring' element={<CatchmentPcMonitoringApp />} />
                                <Route path='conformityOverview' element={<CatchmentAEPApp />} />
                            </Route>

                            <Route path='/station/resource/:id' element={<ResourceApp />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ResourceDashboardApp />} />
                                <Route path='description' element={<ComponentWithNavObject Component={StationDescriptionApp} stationTypes={['resource']} />} />
                                <Route path='picture' element={<ComponentWithNavObject Component={PictureStationApp} stationTypes={['resource']} />} />
                                <Route path='pcMonitoring' element={<ResourcePcMonitoringApp />} />
                                <Route path='conformityOverview' element={<ResourceAEPApp />} />
                            </Route>

                            <Route path='/station/distributionUnit/:id' element={<ComponentWithNavObject Component={StationApp} type='distributionUnit' />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={StationDashboardApp} stationTypes={['distributionUnit']} />} />
                                <Route path='description' element={<ComponentWithNavObject Component={StationDescriptionApp} stationTypes={['distributionUnit']} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={StationMapApp} stationTypes={['distributionUnit']} />} />
                                <Route path='picture' element={<ComponentWithNavObject Component={PictureStationApp} stationTypes={['distributionUnit']} />} />
                                <Route path='event' element={<ComponentWithNavObject Component={StationEventsApp} stationTypes={['distributionUnit']} />} />
                                <Route path='event/:code' element={<ComponentWithNavObject Component={StationEventApp} type='distributionUnit' />} />
                                <Route path='pcMonitoring' element={<DistributionUnitPcMonitoringApp />} />
                                <Route path='conformityOverview' element={<DistributionUnitAEPApp />} />
                                <Route path='graphic' element={<DistributionUnitAssociatedGraphic />} />
                                <Route path='counters' element={<DistributionUnitCounterManager />} />
                            </Route>

                            <Route path='/station/productionUnit/:id' element={<ComponentWithNavObject Component={StationApp} type='productionUnit' />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={StationDashboardApp} stationTypes={['productionUnit']} />} />
                                <Route path='description' element={<ComponentWithNavObject Component={StationDescriptionApp} stationTypes={['productionUnit']} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={StationMapApp} stationTypes={['productionUnit']} />} />
                                <Route path='picture' element={<ComponentWithNavObject Component={PictureStationApp} stationTypes={['productionUnit']} />} />
                                <Route path='event' element={<ComponentWithNavObject Component={StationEventsApp} stationTypes={['productionUnit']} />} />
                                <Route path='event/:code' element={<ComponentWithNavObject Component={StationEventApp} type='productionUnit' />} />
                                <Route path='pcMonitoring' element={<ProductionUnitPcMonitoringApp />} />
                                <Route path='conformityOverview' element={<ProductionUnitAEPApp />} />
                                <Route path='graphic' element={<ProductionUnitAssociatedGraphic />} />
                            </Route>

                            <Route path='/station/installation/:id' element={<ComponentWithNavObject Component={StationApp} type='installation' />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={StationDashboardApp} stationTypes={['installation']} />} />
                                <Route path='description' element={<ComponentWithNavObject Component={StationDescriptionApp} stationTypes={['installation']} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={StationMapApp} stationTypes={['installation']} />} />
                                <Route path='picture' element={<ComponentWithNavObject Component={PictureStationApp} stationTypes={['installation']} />} />
                                <Route path='event' element={<ComponentWithNavObject Component={StationEventsApp} stationTypes={['installation']} />} />
                                <Route path='event/:code' element={<ComponentWithNavObject Component={StationEventApp} type='installation' />} />
                                <Route path='installationFollowUp' element={<InstallationFollowUpApp />} />
                                <Route path='pcMonitoring' element={<InstallationPcMonitoringApp />} />
                                <Route path='conformityOverview' element={<InstallationAEPApp />} />
                                <Route path='materiel' element={<ComponentWithNavObject Component={MaterielStationApp} type='installation' />} />
                                <Route path='materiel/:materialType' element={<ComponentWithNavObject Component={SituationDetailApp} type='installation' />} />
                                <Route path='STEPTerritory' element={<STEPTerritoryApp />} />
                                <Route path='STEPPollutionSearch' element={<STEPPollutionSearchApp />} />
                                <Route path='export' element={<InstallationsExport stationTypes={['installation']} />} />
                                {/* <Route path='installationAEPIndicators' element={<InstallationAEPIndicatorsApp />} /> */}
                            </Route>

                            <Route path='/station/pluviometry/:id' element={<ComponentWithNavObject Component={StationApp} type='pluviometry' />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={StationDashboardApp} stationTypes={['pluviometry']} />} />
                                <Route path='description' element={<ComponentWithNavObject Component={StationDescriptionApp} stationTypes={['pluviometry']} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={StationMapApp} stationTypes={['pluviometry']} />} />
                                <Route path='picture' element={<ComponentWithNavObject Component={PictureStationApp} stationTypes={['pluviometry']} />} />
                                <Route path='event' element={<ComponentWithNavObject Component={StationEventsApp} stationTypes={['pluviometry']} />} />
                                <Route path='event/:code' element={<ComponentWithNavObject Component={StationEventApp} type='pluviometry' />} />
                                <Route path='pluviometricFollowUp' element={<PluvioSuiviGraphApp />} />
                                <Route path='pluviometricSuiviTable' element={<PluvioSuiviTableApp />} />
                                <Route path='validationPluvio' element={<PluviometryValidationApp />} />
                                <Route path='materiel' element={<ComponentWithNavObject Component={MaterielStationApp} type='pluviometry' />} />
                                <Route path='materiel/:materialType' element={<ComponentWithNavObject Component={SituationDetailApp} type='pluviometry' />} />
                            </Route>

                            <Route path='/station/quality/:id' element={<ComponentWithNavObject Component={StationApp} type='quality' />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={StationDashboardApp} stationTypes={['quality']} />} />
                                <Route path='description' element={<ComponentWithNavObject Component={StationDescriptionApp} stationTypes={['quality']} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={StationMapApp} stationTypes={['quality']} />} />
                                <Route path='picture' element={<ComponentWithNavObject Component={PictureStationApp} stationTypes={['quality']} />} />
                                <Route path='event' element={<ComponentWithNavObject Component={StationEventsApp} stationTypes={['quality']} />} />
                                <Route path='event/:code' element={<ComponentWithNavObject Component={StationEventApp} type='quality' />} />
                                <Route path='operation' element={<OperationsListApp />} />
                                <Route path='operation/:code' element={<ComponentWithNavObject Component={StationOperationApp} />} />
                                <Route path='suiviPC' element={<PcMonitoringApp />} />
                                <Route path='qualityStates' element={<QualitometerStatesApp />} />
                                <Route path='hydrobioOperation' element={<HydrobioOperationsApp />} />
                                <Route path='hydrobioOperation/:idOperation' element={<HydrobioOperationApp />} />
                                <Route path='hydrobioMonitoring' element={<HydrobioMonitoringApp />} />
                                <Route path='materiel' element={<ComponentWithNavObject Component={MaterielStationApp} type='quality' />} />
                                <Route path='materiel/:materialType' element={<ComponentWithNavObject Component={SituationDetailApp} type='quality' />} />
                            </Route>

                            <Route path='/station/hydrometry/:id' element={<ComponentWithNavObject Component={StationApp} type='hydrometry' />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={StationDashboardApp} stationTypes={['hydrometry']} />} />
                                <Route path='description' element={<ComponentWithNavObject Component={StationDescriptionApp} stationTypes={['hydrometry']} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={StationMapApp} stationTypes={['hydrometry']} />} />
                                <Route path='picture' element={<ComponentWithNavObject Component={PictureStationApp} stationTypes={['hydrometry']} />} />
                                <Route path='event' element={<ComponentWithNavObject Component={StationEventsApp} stationTypes={['hydrometry']} />} />
                                <Route path='event/:code' element={<ComponentWithNavObject Component={StationEventApp} type='hydrometry' />} />
                                <Route path='hydrometricFollowUp' element={<HydrometerSuiviApp />} />
                                <Route path='hydroSuiviTable' element={<HydroSuiviTableApp />} />
                                <Route path='observations' element={<HydrometerObservationsApp />} />
                                <Route path='validationHydro' element={<HydrometryValidationApp />} />
                                <Route path='materiel' element={<ComponentWithNavObject Component={MaterielStationApp} type='hydrometry' />} />
                                <Route path='materiel/:materialType' element={<ComponentWithNavObject Component={SituationDetailApp} type='hydrometry' />} />
                                <Route path='iaeauModels' element={<IAEauModelsApp stationType='hydrometry' />} />
                                <Route path='iaeauModels/:modelId' element={<IAEauModelApp stationType='hydrometry' />} />
                            </Route>

                            <Route path='/station/piezometry/:id' element={<ComponentWithNavObject Component={StationApp} type='piezometry' />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={StationDashboardApp} stationTypes={['piezometry']} />} />
                                <Route path='description' element={<ComponentWithNavObject Component={StationDescriptionApp} stationTypes={['piezometry']} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={StationMapApp} stationTypes={['piezometry']} />} />
                                <Route path='picture' element={<ComponentWithNavObject Component={PictureStationApp} stationTypes={['piezometry']} />} />
                                <Route path='event' element={<ComponentWithNavObject Component={StationEventsApp} stationTypes={['piezometry']} />} />
                                <Route path='event/:code' element={<ComponentWithNavObject Component={PiezometerEventForm} />} />
                                <Route path='piezometricFollowUp' element={<PiezoSuiviChart2 />} />
                                <Route path='piezoSuiviTable' element={<PiezometerSuiviTableApp />} />
                                <Route path='validation' element={<ComponentWithNavObject Component={PiezometryValidationApp} />} />
                                <Route path='materiel' element={<ComponentWithNavObject Component={MaterielStationApp} type='piezometry' />} />
                                <Route path='materiel/:materialType' element={<ComponentWithNavObject Component={SituationDetailApp} type='piezometry' />} />
                                <Route path='iaeauModels' element={<IAEauModelsApp stationType='piezometry' />} />
                                <Route path='iaeauModels/:modelId' element={<IAEauModelApp stationType='piezometry' />} />
                            </Route>

                            <Route path='/planning' element={<ComponentWithNavObject Component={PlanificationApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={SurveysDashboardApp} />} />
                                <Route path='surveys' element={<ComponentWithNavObject Component={SurveysDashboardApp} />} />
                                <Route path='par' element={<ComponentWithNavObject Component={PARsListApp} />} />
                            </Route>

                            <Route path='/survey/:id' element={<ComponentWithNavObject Component={SurveyApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={SurveyDashboardApp} />} />
                                <Route path='stats' element={<ComponentWithNavObject Component={SurveyStatsApp} />} />
                                <Route path='validation' element={<ComponentWithNavObject Component={SurveyValidationApp} />} />
                                <Route path='export' element={<ComponentWithNavObject Component={SurveyExportApp} />} />
                                <Route path='settings' element={<ComponentWithNavObject Component={SurveySettingsApp} />} />
                                <Route path='declaration/:idDeclaration/stepper' element={<ComponentWithNavObject Component={DeclarationStepperApp} />} />
                                <Route path='declaration/:idDeclaration/point/:idPoint' element={<ComponentWithNavObject Component={DeclarationPointDetails} />} />
                                <Route path='declaration/:idDeclaration/usages/:idPoint/:type' element={<ComponentWithNavObject Component={DeclarationPointUsages} />} />
                            </Route>

                            <Route path='/par/:id' element={<ComponentWithNavObject Component={PARApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={PARDashboardApp} />} />
                                <Route path='events' element={<ComponentWithNavObject Component={PAREventsApp} />} />
                            </Route>

                            <Route path='/scenario/:id' element={<ComponentWithNavObject Component={ScenarioApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ScenarioDashboardApp} />} />
                            </Route>

                            <Route path='/territory' element={<ComponentWithNavObject Component={TerritoryApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={TerritoryDashboardApp} />} />
                                <Route path='waterTurns' element={<ComponentWithNavObject Component={WaterTurnsManagerApp} />} />
                                <Route path='restrictions' element={<ComponentWithNavObject Component={TerritoryRestrictionsApp} />} />
                                <Route path='settings' element={<ComponentWithNavObject Component={TerritorySettingsApp} />}>
                                    <Route index element={<Navigate exact path='' to='parameters' />} />
                                    <Route path='parameters' element={<ComponentWithNavObject Component={TerritorySettingsParamsApp} />} />
                                    <Route path='cultures' element={<ComponentWithNavObject Component={TerritorySettingsCulturesApp} />} />
                                    <Route path='rseau' element={<ComponentWithNavObject Component={RSEauApplication} />} />
                                    <Route path='usage/:id' element={<ComponentWithNavObject Component={UsageApp} />} />
                                    <Route path='usages' element={<ComponentWithNavObject Component={UsagesApp} />} />
                                    <Route path='tanks' element={<ComponentWithNavObject Component={TerritoryTanksTypesApp} />} />
                                    <Route path='tank/:id' element={<ComponentWithNavObject Component={TerritoryTanksTypeApp} />} />
                                </Route>
                            </Route>

                            <Route path='/vigilances' element={<ComponentWithNavObject Component={VigilancesApp} />} />

                            <Route path='/referencial/activity/:code' element={<ComponentWithNavObject Component={ActivityRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ActivityApp} />} />
                            </Route>

                            <Route path='/referencial/aquifer/:code' element={<ComponentWithNavObject Component={AquiferRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<AquiferApp />} />
                            </Route>

                            <Route path='/referencial/watershed/:code' element={<ComponentWithNavObject Component={WatershedRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={WatershedApp} />} />
                                <Route path='map' element={<WatershedMapApp />} />
                            </Route>

                            <Route path='/referencial/city/:code' element={<ComponentWithNavObject Component={CityRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<CityApp />} />
                                <Route path='map' element={<CityMapApp />} />
                            </Route>

                            <Route path='/referencial/contact/:code' element={<ComponentWithNavObject Component={ContactRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ContactApp} />} />
                            </Route>

                            <Route path='/referencial/contactGroup/:code' element={<ComponentWithNavObject Component={ContactGroupRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ContactGroupApp />} />
                            </Route>

                            <Route path='/referencial/culture/:code' element={<ComponentWithNavObject Component={CultureRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={CultureApp} />} />
                            </Route>

                            <Route path='/referencial/cultureRPG/:code' element={<ComponentWithNavObject Component={CultureRPGRouterApp} />} >
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={CultureRPGApp} />} />
                            </Route>

                            <Route path='/referencial/culturesFamily/:code' element={<ComponentWithNavObject Component={CulturesFamilyRouterApp} />} >
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={CulturesFamilyApp} />} />
                            </Route>

                            <Route path='/referencial/hydrogeologicalEntity/:code' element={<ComponentWithNavObject Component={HydrogeologicalEntityRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<HydrogeologicalEntityApp />} />
                            </Route>

                            <Route path='/referencial/fraction/:code' element={<ComponentWithNavObject Component={FractionRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={FractionApp} />} />
                            </Route>

                            <Route path='/referencial/contributor/:code' element={<ComponentWithNavObject Component={ContributorRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ContributorApp />} />
                            </Route>

                            <Route path='/referencial/contributorGroup/:code' element={<ComponentWithNavObject Component={ContributorGroupRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ContributorGroupApp />} />
                            </Route>

                            <Route path='/referencial/lexicon/:field/:code' element={<ComponentWithNavObject Component={LexiconRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<LexiconApp />} />
                            </Route>

                            <Route path='/referencial/watermass/:code' element={<ComponentWithNavObject Component={WatermassRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<WatermassApp />} />
                                <Route path='map' element={<WatermassMapApp />} />
                                <Route path='state' element={<WatermassStatesApp />} />
                            </Route>

                            <Route path='/referencial/method/:code' element={<ComponentWithNavObject Component={MethodRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={MethodApp} />} />
                            </Route>

                            <Route path='/referencial/sandreCode/:field/:code' element={<ComponentWithNavObject Component={SandreCodeRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={SandreCodeApp} />} />
                            </Route>

                            <Route path='/referencial/parameter/:code' element={<ComponentWithNavObject Component={ParameterRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ParameterApp />} />
                                <Route path='map' element={<ParameterMapApp />} />
                                <Route path='source' element={<ComponentWithNavObject Component={ParameterSourcePanel} />} />
                            </Route>

                            <Route path='/referencial/parameterGroup/:code' element={<ComponentWithNavObject Component={ParameterGroupRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ParameterGroupApp />} />
                            </Route>

                            <Route path='/referencial/country/:code' element={<ComponentWithNavObject Component={CountryRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={CountryApp} />} />
                            </Route>

                            <Route path='/referencial/network/:code' element={<ComponentWithNavObject Component={NetworkRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={NetworkApp} />} />
                            </Route>

                            <Route path='/referencial/support/:code' element={<ComponentWithNavObject Component={SupportRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<SupportApp />} />
                            </Route>

                            <Route path='/referencial/taxon/:code' element={<ComponentWithNavObject Component={TaxonRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<TaxonApp />} />
                                <Route path='map' element={<ComponentWithNavObject Component={TaxonCarto} />} />
                            </Route>

                            <Route path='/referencial/materielType/:field/:code' element={<ComponentWithNavObject Component={MatTypeRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<MatTypeApp />} />
                            </Route>

                            <Route path='/referencial/unit/:code' element={<ComponentWithNavObject Component={UnitRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={UnitApp} />} />
                            </Route>

                            <Route path='/referencial/managementUnit/:code' element={<ComponentWithNavObject Component={ManagementUnitRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ManagementUnitApp} />} />
                            </Route>

                            <Route path='/referencial/sector/:code' element={<ComponentWithNavObject Component={SectorRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<SectorApp />} />
                            </Route>

                            <Route path='/referencial' element={<ComponentWithNavObject Component={ReferencialApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={ReferencialDashboardApp} />} />
                                <Route path='parameter' element={<ComponentWithNavObject Component={ParametersApp} />} key='parameter' />
                                <Route path='taxon' element={<ComponentWithNavObject Component={TaxonsApp} />} />
                                <Route path='method' element={<ComponentWithNavObject Component={MethodsApp} />} />
                                <Route path='support' element={<ComponentWithNavObject Component={SupportsApp} />} />
                                <Route path='contributor' element={<ComponentWithNavObject Component={ContributorsApp} />} />
                                <Route path='city' element={<ComponentWithNavObject Component={CitiesApp} />} />
                                <Route path='aquifer' element={<ComponentWithNavObject Component={AquifersApp} />} />
                                <Route path='contact' element={<ComponentWithNavObject Component={ContactsApp} />} />
                                <Route path='hydrogeologicalEntity' element={<ComponentWithNavObject Component={HydrogeologicalEntitiesApp} />} />
                                <Route path='network' element={<ComponentWithNavObject Component={NetworksApp} />} />
                                <Route path='fraction' element={<ComponentWithNavObject Component={FractionsApp} />} />
                                <Route path='unit' element={<ComponentWithNavObject Component={UnitsApp} />} />
                                <Route path='watermass' element={<ComponentWithNavObject Component={WatermassesApp} />} />
                                <Route path='watershed' element={<ComponentWithNavObject Component={WatershedsApp} />} />
                                <Route path='sandreCodes' element={<ComponentWithNavObject Component={SandreCodesApp} />} />
                                <Route path='lexicons' element={<ComponentWithNavObject Component={LexiconsApp} />} />
                                <Route path='rpg' element={<ComponentWithNavObject Component={RPGApp} />} />
                                <Route path='materielTypes' element={<ComponentWithNavObject Component={MatTypesApp} />} />
                                <Route path='managementUnit' element={<ComponentWithNavObject Component={ManagementUnitsApp} />} />
                                <Route path='country' element={<ComponentWithNavObject Component={CountriesApp} />} />
                                <Route path='culture' element={<ComponentWithNavObject Component={CulturesApp} />} />
                                <Route path='activity' element={<ComponentWithNavObject Component={ActivitiesApp} />} />
                                <Route path='develop' element={<ComponentWithNavObject Component={DevelopmentApp} />} />
                                <Route path='sector' element={<ComponentWithNavObject Component={SectorsApp} />} />
                            </Route>

                            <Route path='/administration/user/:login' element={<ComponentWithNavObject Component={UserRouterApp} />}>
                                <Route index element={<Navigate exact path='' to='dashboard' />} />
                                <Route path='dashboard' element={<ComponentWithNavObject Component={UserApp} view={PANEL_FILE} />} />
                                <Route path='modules' element={<ComponentWithNavObject Component={UserApp} view={PANEL_MODULES} />} />
                                <Route path='access' element={<ComponentWithNavObject Component={UserApp} view={PANEL_ACCESS} />} />
                                <Route path='map' element={<ComponentWithNavObject Component={UserApp} view={PANEL_MAP} />} />
                                <Route path='statistics' element={<ComponentWithNavObject Component={UserApp} view={PANEL_STATISTICS} />} />
                            </Route>

                            <Route path='/administration' element={<ComponentWithNavObject Component={AdministrationApp} />}>
                                <Route index element={<Navigate exact path='' to='user' />} />
                                <Route path='settings' element={<ComponentWithNavObject Component={SettingApp} />} />
                                <Route path='stats' element={<ComponentWithNavObject Component={AdminStatsApp} />} />
                                <Route path='user' element={<ComponentWithNavObject Component={UsersApp} />} />
                                <Route path='cartography/:id' element={<ComponentWithNavObject Component={ThemeApp} />} />
                                <Route path='cartography' element={<ComponentWithNavObject Component={CartographyApp} />} />
                                <Route path='homepage' element={<ComponentWithNavObject Component={AdministrationHomePageApp} />} />
                                <Route path='contents' element={<ComponentWithNavObject Component={AdministrationContentsApp} />} />
                                <Route path='problemSolution' element={<ComponentWithNavObject Component={ProblemSolutionApp} />} />
                                <Route path='models' element={<ComponentWithNavObject Component={ModelApp} />} />
                                <Route path='parameters' element={<ComponentWithNavObject Component={AdminParameterApp} />} />
                                <Route path='superAdmin/SuperAdminPanel' element={<ComponentWithNavObject Component={SuperAdminPanel} />} />
                                <Route path='correctV6' element={<ComponentWithNavObject Component={CorrectV6ParentApp} />} />
                            </Route>

                            {/* ? */}
                            {/* <Route path='/documents' element={<ComponentWithNavObject Component={DocumentsApp} />} /> */}
                            {/* <Route path='/station/:type/:id/associated' element={<ComponentWithNavObject Component={StationAssociatedStationApp} {...propsToPass} />} /> */}
                        </Route>
                    </Routes>
                </BoundaryError>
            </ReduxRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('app'),
)

/*
const container = document.getElementById('app')
const root = createRoot(container)
root.render(
    <ThemeProvider theme={Theme}>
        <Provider store={AppStore}>
            <ReduxRouter history={history}>
                <BoundaryError>
                    <>
                        <BubbleHelp />
                        <Routes>
                            <Route path='/login' element={<Login />} />
                            <Route path='/reset' element={<ComponentWithNavObject Component={ResetPasswordApp} />} />
                            <Route path='/sitePlan' element={<SitePlanApp />} />
                            <Route path='/unauthorized' element={<UnauthorizedApp />} />
                            <AuthenticatedRoute element={<SieauApp />} />
                        </Routes>
                    </>
                </BoundaryError>
            </ReduxRouter>
        </Provider>
    </ThemeProvider>,
)
*/