import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ContactAction from '../actions/ContactAction'
import ContactDto from '../dto/ContactDto'

const MultiContactsAutocomplete = ({
    col,
    className='',
    multiple = false,
    limit = 1,
    onChange = () => {},
    label,
    values,
    disabled = false,
    options = [],
    obligatory,
    additionalKeysFilter = [],
    keyLabel = 'name',
    keyValue = 'id',
    'data-cy': datacy,
}) => {
    const {
        contactsGroups,
    } = useSelector(store => ({
        contactsGroups: store.ContactReducer.contactsGroups,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [data, setData] = useState(multiple ? [] : {})

    useEffect(() => {
        if (!contactsGroups.length) {
            dispatch(ContactAction.fetchContactsGroups())
        }
    }, [])

    useEffect(() => {
        if (!multiple && values) {
            setData(values)
        } else if (multiple && values) {
            setData([ ...data, ...values ])
        }
    }, [options, multiple, values])

    const contactsGroupsFormatted = useMemo(() => contactsGroups.map(cg => ({ ...cg, elements: cg.contacts })), [contactsGroups])

    const handleChange = (value) => {
        setData(value)
        if (value?.length > 0 || !multiple) {
            onChange(value)
        } else {
            onChange(undefined)
        }
    }

    return (
        <SuperMultiAutocomplete
            className={className}
            options={options}
            optionsGroups={contactsGroupsFormatted}
            keyLabel={keyLabel}
            keyValue={keyValue}
            label={label ?? i18n.contacts}
            col={col}
            onChange={handleChange}
            values={data}
            multiple={multiple}
            groupIcon='domain'
            additionalKeysFilter={[ ...additionalKeysFilter, 'groupTitle' ]}
            limit={limit}
            groupsCanBeSelected={multiple}
            disabled={disabled}
            obligatory={obligatory}
            data-cy={datacy}
        />
    )
}

MultiContactsAutocomplete.propTypes = {
    col: PropTypes.number,
    className: PropTypes.string,
    multiple: PropTypes.bool,
    limit: PropTypes.number,
    onChange: PropTypes.func,
    label: PropTypes.string,
    values: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.number,
        PropTypes.string,
    ]),
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    obligatory: PropTypes.bool,
    additionalKeysFilter: PropTypes.arrayOf(PropTypes.string),
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    'data-cy': PropTypes.string,
}

export default MultiContactsAutocomplete