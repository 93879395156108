import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { push } from '@lagunovsky/redux-react-router'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import { maxBy, omit } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { v4 as uuidv4 } from 'uuid'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import Checkbox from '../../../components/forms/Checkbox'
import DatePicker from '../../../components/forms/DatePicker'
import NumberField from '../../../components/forms/NumberField'
import RadioButtons from '../../../components/forms/RadioButtons'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Icon from '../../../components/icon/Icon'
import SelectEmail from '../../../components/modal/SelectEmail'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import EventsAction from '../../../events/actions/EventsAction'
import { EVENT_TYPES } from '../../../events/constants/EventsConstants'
import DtoEvent from '../../../events/dto/DtoEvent'
import DiagnosticActionDto from '../../../events/dto/piezometryDiagnostic/DiagnosticActionDto'
import DiagnosticDto from '../../../events/dto/piezometryDiagnostic/DiagnosticDto'
import DiagnosticLinkEventTypeDto from '../../../events/dto/piezometryDiagnostic/DiagnosticLinkEventTypeDto'
import DiagnosticLinkMaterialDto from '../../../events/dto/piezometryDiagnostic/DiagnosticLinkMaterialDto'
import SolutionDto from '../../../events/dto/piezometryDiagnostic/SolutionDto'
import SolutionLinkProblemDto from '../../../events/dto/piezometryDiagnostic/SolutionLinkProblemDto'
import InstallationAction from '../../../installation/actions/InstallationAction'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoProductionUnitEvent from '../../../productionUnit/dto/DtoProductionUnitEvent'
import { getCampaignType } from '../../../utils/CampaignUtil'
import { getDate, getDateWithHour, getHour } from '../../../utils/DateUtil'
import { getUser } from '../../../utils/SettingUtils'
import { execByType, getStation, getStationTitle } from '../../../utils/StationUtils'
import { deleteKeys, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import StationAction from '../../actions/StationAction'
import { STATION_TYPE_NAME } from '../../constants/StationConstants'
import DtoCentral from '../../dto/materiel/DtoCentral'
import DtoEquipment from '../../dto/materiel/DtoEquipment'
import DtoPowerSupply from '../../dto/materiel/DtoPowerSupply'
import DtoSensor from '../../dto/materiel/DtoSensor'
import DtoSim from '../../dto/materiel/DtoSim'
import DtoTelecom from '../../dto/materiel/DtoTelecom'
import DtoVariousMateriel from '../../dto/materiel/DtoVariousMateriel'
import DiagnosticSolutionModal from './modal/DiagnosticSolutionModal'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'

const propsToFetch = {
    qualitometer: false,
    piezometer: false,
    installation: false,
    installationEvent: false,
    hydrometricStation: false,
    contacts: true,
    productionUnit: false,
}

const $ = window.$

class StationEventApp extends ActionComponent {
    state = {
        event: {},
        diagnosticSolution: {},
        diagnosticSolutions: [],
        disabled: true,
        isPopupEmailOpen: false,
        mailContacts: [],
        mailContributors: [],
        mailCities: [],
        mailReceivers: [],
    }

    getInitialEvent = () => {
        const { dateParsed, campaignCodeParsed } = queryString.parse(this.props.location.search)
        const date = dateParsed ? parseInt(dateParsed) : 0
        const campaignCode = campaignCodeParsed ? parseInt(campaignCodeParsed) : undefined
        return {
            code: parseInt(this.props.params.id),
            eventDate: date || moment().valueOf(),
            date: date || moment().valueOf(),
            endDate: moment().valueOf(),
            eventHour: moment().valueOf(),
            eventType: 'G',
            graph: '0',
            chgtpiles: '0',
            bankExport: '0',
            razstation: '0',
            campaignCode,
        }
    }

    setTitle = (props, event) => {
        const station = getStation(props, props.type)
        this.props.forceFetch('title', [{
            title: i18n[props.type],
            href: props.type,
        }, {
            title: getStationTitle(station),
            href: `station/${props.type}/${props.params.id}`,
        }, {
            title: i18n.journal,
            href: `station/${props.type}/${props.params.id}/event`,
        }, {
            title: props.params.code === '0' ? i18n.new : `${i18n.event} ${i18n.fromDate} ${getDate(event.date)}`,
            href: `station/${props.type}/${props.params.id}/event/${props.params.code}`,
        }])
    }

    onChangeEvent = (ref, value) => {
        if (value == -1 || value === null) {
            const event = deleteKeys(this.state.event, [ref])
            this.setState({ event })
        } else {
            const event = {
                ...this.state.event,
                [ref]: value,
            }
            this.setState({ event })
        }
    }

    onChangeEventType = (type) => {
        const event = execByType(type, {
            installation: () => this.props.installationEvent,
            productionUnit: () => this.props.productionUnitEvent,
            default: () => this.props.event,
        })
        this.setState({
            event: {
                ...this.state.event,
                eventType: type,
                date: moment().valueOf(),
                eventDate: moment().valueOf(),
                endDate: moment().valueOf(),
                eventHour: moment().valueOf(),
            },
            diagnosticSolutions: type === event.eventType ? this.props.eventPiezometerActions : [],
        })
    }

    changeSelected = (problem, isAlreadySelected = undefined) => {
        if (isAlreadySelected) {
            this.props.removeSelectedProblem(problem)
        } else {
            this.props.addSelectedProblem(problem)
        }
    }

    getSharedTypes = type => {
        const types = [
            {
                id: 'email',
                onClick: this.handleShare,
                icon: 'email',
                label: i18n.email,
            },
        ]
        const availablePostsTypes = [
            STATION_TYPE_NAME.piezometer,
            STATION_TYPE_NAME.piezometry,
            STATION_TYPE_NAME.quality,
            STATION_TYPE_NAME.qualitometer,
        ]
        if (availablePostsTypes.includes(type)) {
            const station = {
                ...getStation(this.props, this.props.type),
            }
            const idContributors = (station.contributors || []).map(c => c.idContributor)
            const fbContributor = this.props.contributors.find(c => idContributors.includes(c.id) && c.name.toLowerCase() === 'facebook')
            if (fbContributor) {
                return [
                    {
                        id: 'facebook',
                        onClick: this.handleShare,
                        img: 'assets/pictures/socialNetworks/logo_facebook.png',
                        label: 'Facebook',
                    },
                    ...types,
                ]
            }
        }
        return types
    }

    handleShare = socialNetwork => {
        switch (socialNetwork.id) {
            case 'facebook':
                const popup = {
                    id: uuidv4(),
                    header: i18n.sharingConfirmation,
                    actions: (
                        <div>
                            <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a>
                            <a
                                className='waves-effect waves-teal btn-flat modal-close btn font-white'
                                onClick={() => {
                                    const { code, id } = this.props.event
                                    this.props.postFacebook(this.props.type, code, id)
                                }}
                            >
                                {i18n.validate}
                            </a>
                        </div>
                    ),
                }
                this.props.setPopup(popup)
                break
            case 'email':
                this.setState({ isPopupEmailOpen: true })
                break
            default:
        }
    }

    setEditMode = () => {
        const { type } = this.props.params
        const event = execByType(type, {
            installation: () => this.props.installationEvent,
            productionUnit: () => this.props.productionUnitEvent,
            default: () => this.props.event,
        })
        const actions = {
            save: () => {
                $('.tooltipped').tooltip('remove')
                execByType(type, {
                    installation: () => this.props.updateInstallationEvent(this.props.params.id, this.props.params.code, this.state.event, () => this.setReadOnlyMode()),
                    productionUnit: () => this.props.updateProductionUnitEvent(this.props.params.id, this.props.params.code, this.state.event, () => this.setReadOnlyMode()),
                    piezometry: () => {
                        const { endDate, date, eventHour } = this.state.event
                        const eventWithDiagnostic = {
                            event: {
                                ...this.state.event,
                                endDate: endDate || getDateWithHour(date, eventHour).valueOf(),
                            },
                            diagnostics: this.state.diagnosticSolutions,
                        }
                        this.props.updatePiezometryEventWithDiagnostic(this.props.params.id, this.props.params.code, eventWithDiagnostic)
                    },
                    default: () => this.props.updateEvent(type, this.props.params.id, this.props.params.code, this.state.event).then(() => this.setReadOnlyMode()),
                })
            },
            cancel: () => {
                $('.tooltipped').tooltip('remove')
                this.setState({ event, diagnosticSolutions: [...this.props.eventPiezometerActions] })
                this.setReadOnlyMode()
            },
            clear: () => this.setState({ event: this.getInitialEvent(), diagnosticSolutions: [] }),
            delete: this.onDeleteEvent,
            share: this.getSharedTypes(type),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'clear', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        this.setState({ disabled: true })
        const actions = {
            edit: () => {
                $('.tooltipped').tooltip('remove')
                this.setState({ disabled: false })
                this.setEditMode()
            },
            delete: this.onDeleteEvent,
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['delete', 'edit']))
        } else {
            this.setActions(actions)
        }
    }

    onEventAdded = (id) => {
        this.props.push(`/station/${this.props.type}/${this.props.params.id}/event/${id}`)
        this.setReadOnlyMode()
    }

    onDeleteEvent = () => {
        execByType(this.props.type, {
            installation: () => {
                this.props.deleteInstallationEvent(this.props.params.id, this.props.installationEvent.number, () => {
                    this.props.push(`/station/${this.props.type}/${this.props.params.id}/event/`)
                })
            },
            productionUnit: () => {
                this.props.deleteProductionUnitEvent(this.props.params.id, this.props.productionUnitEvent.number, () => {
                    this.props.push(`/station/${this.props.type}/${this.props.params.id}/event/`)
                })
            },
            default: () => {
                this.props.deleteEvent(this.props.type, this.props.params.id, this.props.event.number, () => {
                    this.props.push(`/station/${this.props.type}/${this.props.params.id}/event/`)
                })
            },
        })
    }

    componentDidUpdate = () => {
        if ((this.props.type === 'productionUnit' || this.props.type === 'distributionUnit') && this.state.event.eventType !== 'C') {
            this.setState({ event: { ...this.state.event, eventType: 'C' } })
        }
    }

    componentDidMount() {
        this.props.fetchProblems()
        this.props.fetchSolutions()
        this.props.fetchProblemSolutionLinks()
        // this.props.fetch(propsToFetch)
        this.props.fetchPiezometerDiagnostics()
        this.props.fetchPiezometerDiagnosticsLinkMaterials()
        this.props.fetchPiezometerDiagnosticsLinkEventTypes()
        this.props.fetchPiezometerSolutions()
        this.props.fetchPiezometerSolutionsLinkProblems()
        if (this.props.params.code === '0') {
            this.setState({ event: this.getInitialEvent(), disabled: false })
            const actions = {
                save: () => {
                    $('.tooltipped').tooltip('remove')
                    execByType(this.props.type, {
                        installation: () => this.props.addInstallationEvent(this.props.params.id, this.state.event, this.onEventAdded),
                        productionUnit: () => this.props.addProductionUnitEvent(this.props.params.id, this.state.event, this.onEventAdded),
                        piezometry: () => {
                            const { endDate, date, eventHour } = this.state.event
                            const eventWithDiagnostic = {
                                event: {
                                    ...this.state.event,
                                    endDate: endDate || getDateWithHour(date, eventHour).valueOf(),
                                },
                                diagnostics: this.state.diagnosticSolutions,
                            }
                            this.props.createPiezometryEventWithDiagnostic(this.props.params.id, eventWithDiagnostic)
                        },
                        default: () => this.props.addEvent(this.props.type, this.props.params.id, this.state.event).then(this.onEventAdded),
                    })
                },
            }
            if (getUser().consultant === '1') {
                this.setActions(omit(actions, ['clear', 'save']))
            } else {
                this.setActions(actions)
            }
            if (getStation(this.props, this.props.type).name) {
                this.setTitle(this.props)
            }
        } else {
            execByType(this.props.type, {
                installation: () => this.props.fetchInstallationEvent(this.props.params.id, this.props.params.code),
                productionUnit: () => this.props.fetchProductionUnitEvent(this.props.params.id, this.props.params.code),
                default: () => this.props.fetch('event', this.props.type, this.props.params.id, this.props.params.code),
            })
        }
    }

    componentWillUnmount() {
        this.props.resetEventAction()
        this.props.resetEventInstallation()
    }

    componentWillReceiveProps(nextProps) {
        execByType(this.props.type, {
            installation: () => {
                if (!this.props.installationEvent.id && nextProps.installationEvent.id) {
                    this.setState({ event: nextProps.installationEvent })
                    this.setTitle(nextProps, nextProps.installationEvent)
                    this.setReadOnlyMode()
                }
            },
            productionUnit: () => {
                if (!this.props.productionUnitEvent.id && nextProps.productionUnitEvent.id) {
                    this.setState({ event: nextProps.productionUnitEvent })
                    this.setTitle(nextProps, nextProps.productionUnitEvent)
                    this.setReadOnlyMode()
                }
            },
            default: () => {
                if (!this.props.event.id && nextProps.event.id) {
                    this.setState({ event: nextProps.event })
                    this.setTitle(nextProps, nextProps.event)
                    this.setReadOnlyMode()
                }
            },
        })
        if (this.props.eventPiezometerActions.length !== nextProps.eventPiezometerActions.length) {
            this.setState({ diagnosticSolutions: [...nextProps.eventPiezometerActions, ...this.state.diagnosticSolution] })
        }
        const station = getStation(this.props, this.props.type)
        const nextStation = getStation(nextProps, nextProps.type)
        if (!station.name && nextStation.name && this.props.params.code === '0') {
            this.setTitle(nextProps)
        }
    }

    getDiagnosticActionList = (action) => {
        return this.state.diagnosticSolutions.filter(d => d.actionId !== action.actionId)
    }

    addDiagnosticSolution = (action) => {
        if (this.state.diagnosticSolution.diagnostic && this.state.diagnosticSolution.diagnostic.codeDiagnostic && this.state.diagnosticSolution.solution && this.state.diagnosticSolution.solution.codeSolution) {
            const id = action.actionId || (this.state.diagnosticSolutions.length ? maxBy(this.state.diagnosticSolutions, 'actionId').actionId + 1 : 1)
            const diagnosticSolution = {
                diagnosticCode: this.state.diagnosticSolution.diagnostic.codeDiagnostic,
                solutionCode: this.state.diagnosticSolution.solution.codeSolution,
                actionId: id,
            }
            const list = this.getDiagnosticActionList(action)
            this.setState({
                diagnosticSolutions: [...list, diagnosticSolution],
            })
        } else {
            this.props.error(i18n.error)
        }
    }

    getFilterDiagnostics = () => {
        const stationMaterielType = [
            this.props.stationCentral.length && 1,
            this.props.stationSensor.length && 2,
            this.props.stationPowerSupply.length && 3,
            this.props.stationSim.length && 4,
            this.props.stationVariousMateriel.length && 5,
            this.props.stationTelecom.length && 6,
            this.props.stationEquipment.length && 7,
        ].filter(t => !!t)

        const filter1 = stationMaterielType.length ?
            this.props.eventPiezometerDiagnostics.filter(diag => this.props.eventPiezometerDiagnosticsLinkMaterials.find(l => l.codeDiagnostic === diag.codeDiagnostic && stationMaterielType.includes(l.materialType)))
            : this.props.eventPiezometerDiagnostics
        const eventType = EVENT_TYPES.find(e => e.code === this.state.event.eventType) || {}
        return filter1.filter(diag => this.props.eventPiezometerDiagnosticsLinkEventTypes.find(l => l.codeDiagnostic === diag.codeDiagnostic && l.eventType === eventType.id))
    }

    getPopupDiagnosticAction = (action = {}) => {
        const modalAction = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a>
                <a className='waves-effect waves-teal btn-flat modal-close' onClick={() => this.addDiagnosticSolution(action)}>{i18n.validate}</a>
            </div>
        )
        this.setState({ diagnosticSolution: {} })
        const diagnosticsFilters = this.getFilterDiagnostics()
        const popup = {
            id: uuidv4(),
            header: i18n.problems,
            actions: modalAction,
            dismissible: false,
            content: <DiagnosticSolutionModal diagnostics={diagnosticsFilters}
                solutions={this.props.eventPiezometerSolutions}
                linkDiagnosticSolution={this.props.eventPiezometerSolutionsLinkProblems}
                addDiagnosticSolution={obj => this.setState({ diagnosticSolution: { ...this.state.diagnosticSolution, ...obj } })}
                action={action}
            />,
        }
        this.props.setPopup(popup)
    }

    getTableIntervention = (disabled) => {
        if (this.props.type === STATION_TYPE_NAME.piezometry && this.state.diagnosticSolutions.length) {
            const headers = ['diagnostic', 'nullValue', 'action']
            const data = this.state.diagnosticSolutions.reduce((acc, elem) => {
                const diagnostic = this.props.eventPiezometerDiagnostics.find(d => elem.diagnosticCode === d.codeDiagnostic) || {}
                const solution = this.props.eventPiezometerSolutions.find(s => elem.solutionCode === s.codeSolution) || {}
                return [
                    ...acc,
                    {
                        diagnosticCode: elem.diagnosticCode,
                        solutionCode: elem.solutionCode,
                        actionId: elem.actionId,
                        diagnostic: diagnostic.description,
                        action: solution.description,
                        nullValue: <Icon icon='arrow_forward' size={'small'} />,
                    },
                ]
            }, [])
            return (
                <Table showTitle={false} sortable
                    type={{ headers }}
                    data={data}
                    alterable={disabled.active}
                    onAlter={this.getPopupDiagnosticAction}
                    deletable={disabled.active}
                    onDelete={this.onDeleteAction}
                />
            )
        }
        return null
    }

    onDeleteAction = (action) => this.setState({ diagnosticSolutions: this.state.diagnosticSolutions.filter(d => action.actionId !== d.actionId) })

    getInstallationDiagnosticForm(disabled) {
        if (this.props.type === STATION_TYPE_NAME.installation) {
            const form = [
                <Checkbox col={3} ref='graph' label={i18n.diagnosticDone} checked={this.state.event.diagnostic} onChange={(diagnosticDone) => {
                    this.setState({
                        event: {
                            ...this.state.event,
                            diagnostic: diagnosticDone,
                            diagnosticDeadline: diagnosticDone ? moment().year(moment().year() + 10).valueOf() : this.state.event.diagnosticDeadline,
                        },
                    })
                }} {...disabled}
                />,
            ]
            if (this.state.event.diagnostic) {
                form.push(
                    <DatePicker id='diagnosticDeadline' title={i18n.diagnosticDeadline} col={3} value={this.state.event.diagnosticDeadline} mustHaveValue onChange={(_, v) => this.onChangeEvent('diagnosticDeadline', v)} {...disabled} />
                )
            }
            return form
        }
        return null
    }

    getUpdateInfo = () => {
        return this.props.event.updateDate && this.props.event.updateLogin ? (
            <div className='padding-bottom-1'>
                <Card maxWidth={1200}>
                    <div className='row no-margin padding-left-5'>
                        {`${i18n.lastUpdateOn} ${getDate(this.props.event.updateDate)} ${i18n.atHour} ${getHour(this.props.event.updateDate)} ${i18n.by} ${this.props.event.updateLogin}`}
                    </div>
                </Card>
            </div>
        ) : null
    }

    getCampaigns = (type) => {
        const campaigns = execByType(this.props.type, {
            pluviometry: () => this.props.pluviometryCampaigns,
            hydrometry: () => this.props.hydrometryCampaigns,
            quality: () => this.props.qualityCampaigns,
            installation: () => this.props.installationCampaigns,
            default: () => undefined,
        })
        const campaignStations = execByType(this.props.type, {
            pluviometry: () => this.props.pluviometryCampaignStations,
            hydrometry: () => this.props.hydrometryCampaignStations,
            quality: () => this.props.qualityCampaignStations,
            installation: () => this.props.installationCampaignStations,
            default: () => undefined,
        })

        if (!campaigns && !campaignStations) {
            return undefined
        }

        const { id: idStation } = getStation(this.props, this.props.type)
        const campaignStationsFiltered = campaignStations.filter(({ stationId }) => stationId === idStation).map(({ campaignId }) => campaignId)
        const campaignsFiltered = campaignStationsFiltered.map(campaignId => campaigns.find(({ id }) => campaignId === id)).filter(c => !!c)
        return campaignsFiltered.filter(({ statut }) => statut === 2).filter(({ campaignType }) => type === campaignType)
    }

    getCampaignsField = disabled => {
        const type = getCampaignType(this.state.event.eventType || 'C')
        const campaigns = this.getCampaigns(type)
        return !!campaigns && (type === 1 || type === 2) && (
            <Select
                label={i18n.campaign}
                value={this.state.event.campaignCode}
                col={5}
                options={campaigns}
                onChange={id => this.onChangeEvent('campaignCode', id)}
                {...disabled}
            />
        )
    }

    render() {
        if (this.state.event.date) {
            const {
                mailContacts,
                mailContributors,
                mailCities,
                mailReceivers,
                isPopupEmailOpen,
            } = this.state
            const eventTypeList = [{ code: 'G', name: i18n.preventive }, { code: 'M', name: i18n.curative }, { code: 'P', name: i18n.toPlan }, { code: 'S', name: i18n.toMonitor }, { code: 'C', name: i18n.comment }]
            const disabled = { disabled: this.state.disabled, active: !this.state.disabled }
            const eventSelection = this.state.event.eventType || 'C'
            const eventTypeAuthorization = (() => {
                if (eventSelection === 'G' || eventSelection === 'M') {
                    const panel = execByType(this.props.type, {
                        installation: () => <div className='row no-margin padding-top-1 padding-bottom-1' ><NumberField title={i18n.staticLevel} floatValue value={this.state.event.ns} col={2} onChange={v => this.onChangeEvent('ns', v)} {...disabled} /></div>,
                        default: () => (
                            <div>
                                <div className='row no-margin padding-top-1 padding-bottom-1' >
                                    <Checkbox col={3} ref='chgtpiles' label={i18n.chgtpiles} checked={this.state.event.chgtpiles === '1'} onChange={(e) => this.onChangeEvent('chgtpiles', e ? '1' : '0')} {...disabled} />
                                    <Checkbox col={3} ref='razstation' label={i18n.razstation} checked={this.state.event.razstation === '1'} onChange={(e) => this.onChangeEvent('razstation', e ? '1' : '0')} {...disabled} />
                                </div>
                                <div className='row no-margin padding-top-1 padding-bottom-1' >
                                    <NumberField title={i18n.volt} floatValue value={this.state.event.volt} col={2} onChange={v => this.onChangeEvent('volt', v)} {...disabled} />
                                    <NumberField title={i18n.valeurcalee} floatValue value={this.state.event.valeurcalee} col={2} onChange={v => this.onChangeEvent('valeurcalee', v)} {...disabled} />
                                    <NumberField title={i18n.sondeTemperature} floatValue value={this.state.event.sondeTemperature} col={2} onChange={v => this.onChangeEvent('sondeTemperature', v)} {...disabled} />
                                    <NumberField title={i18n.centralTemperature} floatValue value={this.state.event.centralTemperature} col={2} onChange={v => this.onChangeEvent('centralTemperature', v)} {...disabled} />
                                    <NumberField title={i18n.staticLevel} floatValue value={this.state.event.ns} col={2} onChange={v => this.onChangeEvent('ns', v)} {...disabled} />
                                    <NumberField title={i18n.centralLevel} floatValue value={this.state.event.nc} col={2} onChange={v => this.onChangeEvent('nc', v)} {...disabled} />
                                </div>
                            </div>
                        ),
                    })
                    return (
                        <div>
                            <Card title={i18n.preventiveData} maxWidth={1200} {...disabled}>
                                {panel}
                            </Card>
                        </div>
                    )
                }
                return null
            })()

            const actions = [
                {
                    iconName: 'node_add',
                    onClick: () => this.getPopupDiagnosticAction(),
                },
            ]

            const cardIntervention = (this.state.event.eventType && this.state.event.eventType !== 'C') && this.props.type === 'piezometry' ? (
                <Card title={i18n.intervention} {...disabled} maxWidth={1200} actions={actions}>
                    {this.getTableIntervention(disabled)}
                </Card>
            ) : null

            const isUnit = this.props.type === 'productionUnit' || this.props.type === 'distributionUnit'

            return (
                <div className='padding-top-1'>
                    {this.getUpdateInfo()}
                    <Card maxWidth={1200}>
                        <div className='row no-margin' >
                            <Card displayIf={!isUnit} title={i18n.interventionType} maxWidth={1200} {...disabled}>
                                <div className='row no-margin padding-top-1 padding-bottom-1' >
                                    <RadioButtons
                                        elements={eventTypeList}
                                        col={12}
                                        title={i18n.type}
                                        selected={this.state.event.eventType || 'C'}
                                        onChange={type => this.onChangeEventType(type)}
                                        {...disabled}
                                    />
                                </div>
                            </Card>
                            <Card title={i18n.event} maxWidth={1200} {...disabled}>
                                <div className='row no-margin padding-top-1' >
                                    <SimpleDatePicker
                                        label={i18n.date}
                                        col={2}
                                        value={this.state.event.date}
                                        obligatory
                                        onChange={(v) => this.setState({ event: { ...this.state.event, date: v || undefined, eventDate: v || undefined } })}
                                        {...disabled}
                                    />
                                    <NumberField title={i18n.hour} value={this.state.event.eventHour ? moment(this.state.event.eventHour).hour() : ''} col={2}
                                        onChange={v => this.onChangeEvent('eventHour', moment(this.state.event.eventHour).hour(v).valueOf())} {...disabled}
                                    />
                                    <NumberField title={i18n.minute} value={this.state.event.eventHour ? moment(this.state.event.eventHour).minutes() : ''} col={2}
                                        onChange={v => this.onChangeEvent('eventHour', moment(this.state.event.eventHour).minutes(v).valueOf())} {...disabled}
                                    />
                                    <SimpleDatePicker
                                        label={i18n.endDate}
                                        col={2}
                                        value={this.state.event.endDate}
                                        onChange={(v) => this.setState({ event: { ...this.state.event, endDate: v || undefined } })}
                                        {...disabled}
                                    />
                                    <Select options={this.props.contacts}
                                        label={i18n.referent}
                                        col={4}
                                        value={this.state.event.contactCode}
                                        nullLabel={i18n.none}
                                        onChange={(v) => this.onChangeEvent('contactCode', v)} {...disabled}
                                    />
                                </div>
                                <div className='row no-margin padding-top-1' >
                                    <Textarea title={i18n.comment} value={this.state.event.comment} col={12} onChange={v => this.onChangeEvent('comment', v)} {...disabled} />
                                </div>
                                {
                                    this.props.type !== 'distributionUnit' && (
                                        <div className='row no-margin padding-bottom-1 padding-top-1' >
                                            {this.getCampaignsField(disabled)}
                                            <div className='row no-margin padding-top-1'>
                                                {this.props.type !== 'installation' && [
                                                    <Checkbox col={3} ref='graph' label={i18n.displayGraph} checked={this.state.event.graph === '1'} onChange={(e) => this.onChangeEvent('graph', e ? '1' : '0')} {...disabled} />,
                                                    <Checkbox col={4} ref='bankExport' label={i18n.bankExport} checked={this.state.event.bankExport === '1'} onChange={(e) => this.onChangeEvent('bankExport', e ? '1' : '0')} {...disabled} />,
                                                ]}
                                                {this.getInstallationDiagnosticForm(disabled)}

                                            </div>
                                        </div>
                                    )
                                }
                            </Card>
                            {eventTypeAuthorization}
                            <Card title={i18n.photos} className='padding-top-1' maxWidth={1200}{...disabled}>
                                <div/>
                            </Card>
                            {cardIntervention}
                        </div>
                    </Card>
                    <Dialog
                        onClose={() => this.setState({ isPopupEmailOpen: false })}
                        fullWidth
                        maxWidth='md'
                        open={isPopupEmailOpen}
                        // autoScrollBodyContent={true}
                    >
                        <DialogContent>
                            <SelectEmail
                                mailContacts={mailContacts}
                                mailContributors={mailContributors}
                                mailCities={mailCities}
                                mailReceivers={mailReceivers}
                                onChangeEmails={mails => this.setState(mails)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ isPopupEmailOpen: false })} variant='outlined' >
                                {i18n.close}
                            </Button>
                            <Button onClick={() => {
                                const {
                                    id, // stationId
                                    code, // eventId
                                } = this.props.params
                                this.props.sendEventByMail(this.props.type, id, code, [...mailContacts, ...mailContributors, ...mailReceivers, ...mailCities])
                                this.setState({ isPopupEmailOpen: false })
                            }} variant='contained' color='primary'
                            >
                                {i18n.send}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div >
            )
        }
        return null
    }
}

StationEventApp.propTypes = getPropTypes(propsToFetch, {
    event: PropTypes.instanceOf(DtoEvent),
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.object,
    }).isRequired,
    distributionUnit: PropTypes.instanceOf(DtoDistributionUnit),
    pluviometer: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    stationCentral: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentral)),
    stationPowerSupply: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupply)),
    stationSensor: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensor)),
    stationSim: PropTypes.arrayOf(PropTypes.instanceOf(DtoSim)),
    stationTelecom: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecom)),
    stationVariousMateriel: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    stationEquipment: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipment)),
    productionUnitEvent: PropTypes.instanceOf(DtoProductionUnitEvent),
    contributors: PropTypes.arrayOf(PropTypes.object),
    eventPiezometerDiagnostics: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticDto)),
    eventPiezometerDiagnosticsLinkMaterials: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticLinkMaterialDto)),
    eventPiezometerDiagnosticsLinkEventTypes: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticLinkEventTypeDto)),
    eventPiezometerSolutions: PropTypes.arrayOf(PropTypes.instanceOf(SolutionDto)),
    eventPiezometerSolutionsLinkProblems: PropTypes.arrayOf(PropTypes.instanceOf(SolutionLinkProblemDto)),
    eventPiezometerActions: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticActionDto)),
})


const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    pluviometer: store.PluviometryReducer.pluviometer,
    event: store.EventsReducer.event,
    stationCentral: store.StationReducer.stationCentral,
    stationPowerSupply: store.StationReducer.stationPowerSupply,
    stationSensor: store.StationReducer.stationSensor,
    stationSim: store.StationReducer.stationSim,
    stationTelecom: store.StationReducer.stationTelecom,
    stationVariousMateriel: store.StationReducer.stationVariousMateriel,
    stationEquipment: store.StationReducer.stationEquipment,
    productionUnitEvent: store.ProductionUnitReducer.productionUnitEvent,
    contributors: store.ContributorReducer.contributors,
    eventPiezometerDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
    eventPiezometerDiagnosticsLinkMaterials: store.EventsReducer.eventPiezometerDiagnosticsLinkMaterials,
    eventPiezometerDiagnosticsLinkEventTypes: store.EventsReducer.eventPiezometerDiagnosticsLinkEventTypes,
    eventPiezometerSolutions: store.EventsReducer.eventPiezometerSolutions,
    eventPiezometerSolutionsLinkProblems: store.EventsReducer.eventPiezometerSolutionsLinkProblems,
    eventPiezometerActions: store.EventsReducer.eventPiezometerActions,
    distributionUnit: store.DistributionUnitReducer.distributionUnit,
    pluviometryCampaigns: store.PluviometryReducer.pluviometryCampaigns,
    pluviometryCampaignStations: store.PluviometryReducer.pluviometryCampaignStations,
    hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
    hydrometryCampaignStations: store.HydrometryReducer.hydrometryCampaignStations,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
    qualityCampaignStations: store.QualityReducer.qualityCampaignStations,
    installationCampaigns: store.InstallationReducer.installationCampaigns,
    installationCampaignStations: store.InstallationReducer.installationCampaignStations,
})

const mapDispatchToProps = {
    sendEventByMail: EventsAction.sendEventByMail,
    forceFetch: SieauAction.forceFetch,
    removeSelectedProblem: StationAction.removeSelectedProblem,
    addSelectedProblem: StationAction.addSelectedProblem,
    postFacebook: EventsAction.postFacebook,
    setPopup: SieauAction.setPopup,
    updateInstallationEvent: InstallationAction.updateInstallationEvent,
    updateProductionUnitEvent: ProductionUnitAction.updateProductionUnitEvent,
    updatePiezometryEventWithDiagnostic: EventsAction.updatePiezometryEventWithDiagnostic,
    push,
    deleteInstallationEvent: InstallationAction.deleteInstallationEvent,
    deleteProductionUnitEvent: ProductionUnitAction.deleteProductionUnitEvent,
    deleteEvent: EventsAction.deleteEvent,
    fetchProblems: StationAction.fetchProblems,
    fetchSolutions: StationAction.fetchSolutions,
    fetchProblemSolutionLinks: StationAction.fetchProblemSolutionLinks,
    fetch: SieauAction.fetch,
    fetchPiezometerDiagnostics: EventsAction.fetchPiezometerDiagnostics,
    fetchPiezometerDiagnosticsLinkMaterials: EventsAction.fetchPiezometerDiagnosticsLinkMaterials,
    fetchPiezometerDiagnosticsLinkEventTypes: EventsAction.fetchPiezometerDiagnosticsLinkEventTypes,
    fetchPiezometerSolutions: EventsAction.fetchPiezometerSolutions,
    fetchPiezometerSolutionsLinkProblems: EventsAction.fetchPiezometerSolutionsLinkProblems,
    addInstallationEvent: InstallationAction.addInstallationEvent,
    addProductionUnitEvent: ProductionUnitAction.addProductionUnitEvent,
    createPiezometryEventWithDiagnostic: EventsAction.createPiezometryEventWithDiagnostic,
    fetchInstallationEvent: InstallationAction.fetchInstallationEvent,
    fetchProductionUnitEvent: ProductionUnitAction.fetchProductionUnitEvent,
    resetEventAction: EventsAction.resetEvent,
    resetEventInstallation: InstallationActionConstant.RESET_INSTALLATION_EVENT,
    addEvent: EventsAction.addEvent,
    updateEvent: EventsAction.updateEvent,
    error: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationEventApp)
