import React, { useState } from 'react'
import { push } from '@lagunovsky/redux-react-router'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import SearchTable from 'referencial/components/SearchTable'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import ProgressCard from 'components/card/ProgressCard'
import { PATH_REFERENCIAL_CONTRIBUTORS_GROUP_NEW } from 'home/constants/RouteConstants'
import ContributorAction from '../actions/ContributorAction'

const headers = ['id', 'name', 'siren', 'numberOfMembers']

const ContributorsGroupsApp = () => {
    const {
        contributorsGroups,
    } = useSelector(store => ({
        contributorsGroups: store.ContributorReducer.contributorsGroups,
    }), shallowEqual)

    const [dataLoaded, setDataLoaded] = useState(false)
    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(ContributorAction.fetchContributorsGroups()).then(() => setDataLoaded(true))
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: 'referencial',
    }, {
        title: i18n.contributorsGroups,
        href: '/referencial/contributor',
    }], [])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_CONTRIBUTORS_GROUP_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.contributorsGroups,
                    },
                },
            },
        }
    }, [exportData])

    const contributorsGroupsFormatted = useMemo(() => contributorsGroups.map(val => ({
        ...val,
        id: val.code,
        numberOfMembers: `${val.numberOfMembers}`,
    })), [contributorsGroups])

    if (!dataLoaded) {
        return <ProgressCard indeterminate />
    }

    return (
        <div className='row no-margin'>
            <div className='row'>
                <SearchTable
                    title={i18n.contributorsGroups}
                    sessionKey={REFERENCIAL_TYPE_NAME.contactGroup}
                    data={contributorsGroupsFormatted}
                    setExportData={setExportData}
                    headers={headers}
                    onClick={contributorGroup => dispatch(push(`/referencial/contributorGroup/${contributorGroup.id}/dashboard`))}
                />
            </div>
        </div>
    )
}

export default ContributorsGroupsApp