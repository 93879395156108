import { push } from '@lagunovsky/redux-react-router'
import { template } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserAction from '../../../administration/components/user/actions/UserAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import UpdatePanel from '../../../components/updatePanel/UpdatePanel'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactItem from '../../../referencial/components/contact/dto/ContactItem'
import WatermassDto from '../../../referencial/components/watermass/dto/WatermassDto'
import WatershedDto from '../../../referencial/components/watershed/dto/WatershedDto'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import StationAltimetrySystemsPanel from '../../../station/components/link/StationAltimetrySystemsPanel'
import StationContactsPanel from '../../../station/components/link/StationContactsPanel'
import StationDespoliationPanel from '../../../station/components/link/StationDespoliationPanel'
import StationLandmarkPanel from '../../../station/components/link/StationLandmarkPanel'
import StationLithologyPanel from '../../../station/components/link/StationLithologyPanel'
import StationLocationPanel from '../../../station/components/link/StationLocationPanel'
import StationMeasureMethodPanel from '../../../station/components/link/StationMeasureMethodPanel'
import StationNetworkPanel from '../../../station/components/link/StationNetworkPanel'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { NEW, STATION_QUALITOMETER_NAMES_HYDRO } from '../../../station/constants/StationConstants'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLogin } from '../../../utils/SettingUtils'
import { getBookmarks, getLinks } from '../../../utils/StationUtils'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import HydrometryAction from '../../actions/HydrometryAction'
import HydrometerDto from '../../dto/DtoHydrometricStation'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import UserDto from '../../../administration/components/user/dto/UserDto'
import WatermassAction from '../../../referencial/components/watermass/actions/WatermassAction'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import StationPointPrelPanel from '../../../station/components/link/StationPointPrelPanel'
import StationTempRefPanel from '../../../station/components/link/StationTempRefPanel'

class HydrometryDescriptionPanel extends ActionComponent {
    state = {
        hydrometer: { ...this.props.hydrometer },
        readMode: true,
    }

    componentDidMount() {
        this.props.fetchWatermasses()
        this.props.fetchWatersheds()
        if (this.props.hydrometer.id) {
            this.setState({ hydrometer: this.props.hydrometer })
        }
        if (this.props.id === NEW) {
            this.setEditMode()
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.hydrometer.id && prevProps.hydrometer.id) {
            this.setState({ hydrometer: this.props.hydrometer })
        }
    }

    onSave = () => {
        if (!this.state.hydrometer.code) {
            this.props.toastrError(template(i18n.fieldNotSet)({ field: i18n.code }))
        } else if (this.props.id === NEW && this.props.hydrometers.find(q => q.code === this.state.hydrometer.code)) {
            this.props.toastrError(i18n.codeMeteoFranceAlreadyExists)
        } else if (this.props.id === NEW) {
            this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
        } else {
            this.props.updateHydrometer(this.state.hydrometer)
            this.setReadOnlyMode()
        }
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            delete: () => this.props.deleteHydrometer(this.props.hydrometer.id, () => this.props.push('/hydrometry')),
            save: () => this.onSave(),
            cancel: () => {
                if (this.props.id === NEW) {
                    this.props.push('/hydrometry')
                } else {
                    this.setState({
                        hydrometer: this.props.hydrometer,
                    })
                    this.setReadOnlyMode()
                }
            },
            links: getLinks(this.props.hydrometer, this.props),
            arrowNav: getStationArrowNav('hydrometry', this.props.hydrometers, this.props.hydrometer.id, s => this.props.push(`/station/hydrometry/${s.id}/description`)),
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        const { hydrometer, hydrometers, user, typeEnvironmentModels } = this.props
        this.setState({ readMode: true })
        if (user.consultant === '1') {
            this.setActions({
                exportmodel: () => ({
                    stationId: hydrometer.id.toString(),
                    stationCode: hydrometer.code,
                    stationType: hydrometer.typeName,
                    environmentModels: typeEnvironmentModels,
                }),
                links: getLinks(hydrometer, this.props),
                arrowNav: getStationArrowNav('hydrometry', hydrometers, hydrometer.id, s => this.props.push(`/station/hydrometry/${s.id}/description`)),
            })
        } else {
            this.setActions({
                exportmodel: () => ({
                    stationId: hydrometer.id.toString(),
                    stationCode: hydrometer.code,
                    stationType: hydrometer.typeName,
                    environmentModels: typeEnvironmentModels,
                }),
                edit: () => this.setEditMode(),
                delete: () => this.props.deleteHydrometer(hydrometer.id, () => this.props.push('/hydrometry')),
                links: getLinks(hydrometer, this.props),
                arrowNav: getStationArrowNav('hydrometry', hydrometers, hydrometer.id, s => this.props.push(`/station/hydrometry/${s.id}/description`)),
            })
        }
    }

    getUpdatePanel = () => {
        if (this.state.hydrometer.updateLogin || this.state.hydrometer.updateDate) {
            return (
                <UpdatePanel updateLogin={this.state.hydrometer.updateLogin}
                    updateDate={this.state.hydrometer.updateDate}
                />
            )
        }
        return null
    }

    onChangeHydrometer = (value) => this.setState({ hydrometer: { ...this.state.hydrometer, ...value } })

    onChangeWork = (value) => {
        const { hydrometer } = this.state
        const hydrometerWork = hydrometer.link_work[0] || {}
        this.onChangeHydrometer({
            link_work: [{
                ...hydrometerWork,
                idStation: hydrometer.id,
                ...value,
            }],
        })
    }

    onChangeGeoData = (value) => {
        const { hydrometer } = this.state
        const linkGeo = hydrometer.link_geo && hydrometer.link_geo[0] || {}
        this.onChangeHydrometer({ link_geo: [{ ...linkGeo, idStation: hydrometer.id, ...value }] })
    }

    setBookmark = (deleteBookmark = false) => {
        const { hydrometer } = this.state
        if (deleteBookmark) {
            this.props.deleteBookmark({ identifiant: hydrometer.code, login: getLogin(), stationType: 4 })
        } else {
            this.props.createBookmark({ identifiant: hydrometer.code, login: getLogin(), stationType: 4 })
        }
    }

    render() {
        const { hydrometer = {}, readMode } = this.state
        const mode = { readMode, editMode: !readMode }
        const params = {
            station: hydrometer,
            onChange: this.onChangeHydrometer,
            readMode: this.state.readMode,
        }
        const work = hydrometer.link_work[0] || {}
        const geoData = hydrometer.link_geo && hydrometer.link_geo[0] || {}

        return (
            <div className='row margin-left-2 padding-top-1'>
                <div className='col s9' style={{ marginBottom: '100px' }}>
                    <Card title={i18n.description}>
                        <div className='row no-margin padding-top-1'>
                            <div className='col s12'>
                                <Row>
                                    <Input col={6}
                                        value={hydrometer.code}
                                        title={i18n.code}
                                        disabled={this.props.id !== NEW}
                                        onChange={v => this.onChangeHydrometer({ code: v })}
                                        {...mode}
                                    />
                                    <Input col={5}
                                        value={hydrometer.name}
                                        title={i18n.name}
                                        onChange={v => this.onChangeHydrometer({ name: v })}
                                        {...mode}
                                    />
                                    <div className='col s1'>
                                        {getBookmarks(hydrometer.code, getUserBookmarksByStationType(this.props.userBookmarks, 4), true, this.setBookmark)}
                                    </div>
                                </Row>
                                <Row className={`${!readMode && 'padding-bottom-1' || ''}`}>
                                    <SimpleDatePicker
                                        col={6}
                                        value={hydrometer.creationDate}
                                        id='creation'
                                        label={i18n.creationDate}
                                        onChange={(v) => this.onChangeHydrometer({ creationDate: v })}
                                        {...mode}
                                    />
                                    <SimpleDatePicker
                                        col={6}
                                        value={hydrometer.closeDate}
                                        id='close'
                                        label={i18n.closeDate}
                                        onChange={(v) => this.onChangeHydrometer({ closeDate: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Select
                                        col={6}
                                        value={hydrometer.stationType}
                                        label={i18n.stationTypeLabel}
                                        options={STATION_QUALITOMETER_NAMES_HYDRO}
                                        onChange={v => this.onChangeHydrometer({ stationType: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row className={`${!readMode && 'padding-bottom-1' || ''}`}>
                                    <Textarea col={12}
                                        value={hydrometer.comment}
                                        title={i18n.comment}
                                        onChange={v => this.onChangeHydrometer({ comment: v })}
                                        {...mode}
                                    />
                                </Row>
                            </div>
                        </div>
                    </Card>
                    <Row className='padding-top-1'>
                        <Card title={i18n.context}>
                            <div className={`row no-margin ${!readMode && 'padding-top-1 padding-bottom-1' || ''}`} style={{ paddingLeft: '5px' }}>
                                <Select
                                    col={12}
                                    value={hydrometer.link_watermasses[0] && hydrometer.link_watermasses[0].watermassCode}
                                    label={i18n.watermass}
                                    options={this.props.watermasses}
                                    displayWithCode
                                    onChange={v => this.onChangeHydrometer({ link_watermasses: [{ idStation: hydrometer.id, watermassCode: v }] })}
                                    readMode={readMode}
                                />
                                <Select
                                    col={12}
                                    value={hydrometer.watershedCode}
                                    label={i18n.watershed}
                                    options={this.props.watersheds}
                                    keyValue='id'
                                    onChange={v => this.onChangeHydrometer({ watershedCode: v })}
                                    readMode={readMode}
                                />
                                <Select
                                    col={12}
                                    value={hydrometer.link_lithologicType[0] && hydrometer.link_lithologicType[0].lithologyType}
                                    label={i18n.lithologyType}
                                    options={getSandreList(this.props.sandreCodes, SANDRE.LITHOLOGY_TYPE)}
                                    nullLabel='&nbsp;'
                                    onChange={v => this.onChangeHydrometer({ link_lithologicType: [{ idStation: hydrometer.id, lithologyType: v }] })}
                                    readMode={readMode}
                                />

                            </div>
                            <StationLithologyPanel {...params} />
                        </Card>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationPiezoContributorPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationAltimetrySystemsPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationLandmarkPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationNetworkPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationDespoliationPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationMeasureMethodPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationPointPrelPanel { ...params } st='hydrometry'/>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationTempRefPanel { ...params } st='hydrometry'/>
                    </Row>
                    <Row className='padding-top-1'>
                        <Card title={i18n.technicalCharacteristics}>
                            <div className={`row no-margin ${!readMode && 'padding-top-1 padding-bottom-1' || ''}`} style={{ paddingLeft: '5px' }}>
                                <Row>
                                    <Input
                                        col={6}
                                        title={i18n.stationType}
                                        value={work.stationType}
                                        onChange={v => this.onChangeWork({ stationType: v })}
                                        readMode={readMode}
                                    />
                                    <Input
                                        col={6}
                                        title={i18n.phone}
                                        value={work.phone}
                                        onChange={v => this.onChangeWork({ phone: v })}
                                        readMode={readMode}
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        col={6}
                                        title={i18n.sensorType}
                                        value={work.sensorType}
                                        onChange={v => this.onChangeWork({ sensorType: v })}
                                        readMode={readMode}
                                    />
                                    <Input
                                        col={6}
                                        title={i18n.serialNumber}
                                        value={work.serialNumber}
                                        onChange={v => this.onChangeWork({ serialNumber: v })}
                                        readMode={readMode}
                                    />
                                </Row>
                                <Row>
                                    <Textarea
                                        col={12}
                                        title={i18n.comment}
                                        value={work.comment}
                                        onChange={v => this.onChangeWork({ comment: v })}
                                        readMode={readMode}
                                    />
                                </Row>
                            </div>
                        </Card>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationLocationPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationContactsPanel {...params} />
                    </Row>
                </div>
                <div className='col s3' style={{ marginBottom: '100px' }}>
                    <StationUpdatePanel station={hydrometer} />
                    <Card title={i18n.contact} className='margin-top-1' noMargin={false}>
                        <Row className={`${!readMode && 'padding-top-1' || ''}`}>
                            <Select
                                col={12}
                                value={hydrometer.contactCode}
                                options={this.props.contacts}
                                keyValue='id'
                                onChange={v => this.onChangeHydrometer({ contactCode: v })}
                                readMode={readMode}
                            />
                        </Row>
                    </Card>
                    <StationMapDashboardPanel noMarkerTooltip station={hydrometer}
                        type={'hydrometry'}
                    />
                    <Card title={i18n.localisation} className='margin-top-1' noMargin={false}>
                        <Row className={`${!readMode && 'padding-top-1' || ''}`}>
                            <Input col={12} title={i18n.address} value={hydrometer.address} onChange={v => this.onChangeHydrometer({ address: v })} {...mode} />
                        </Row>
                        <Row>
                            <Select col={12} value={hydrometer.townCode} label={i18n.city} options={this.props.cities} keyValue='id' displayWithCode
                                onChange={v => this.onChangeHydrometer({ townCode: v })} {...mode}
                            />
                        </Row>
                        <Row displayIf={hydrometer.declarationTownCode !== hydrometer.townCode || !readMode}>
                            <Select col={12} value={hydrometer.declarationTownCode} label={i18n.declarationTownCode} options={this.props.cities} keyValue='id' displayWithCode
                                onChange={v => this.onChangeHydrometer({ declarationTownCode: v })} {...mode}
                            />
                        </Row>
                        <Row>
                            <Input col={12} value={hydrometer.countryCode} title={i18n.countryCode} onChange={v => this.onChangeHydrometer({ countryCode: v })} {...mode} />
                        </Row>
                        <Row>
                            <Textarea col={12} noMargin={false} classname={!readMode && 'margin-bottom-1' || ''} title={i18n.localisation} value={hydrometer.localization} onChange={v => this.onChangeHydrometer({ localization: v })} {...mode} />
                        </Row>
                        <Row>
                            <NumberField col={6} title='X' value={hydrometer.x} floatValue onChange={v => this.onChangeHydrometer({ x: v })} {...mode} />
                            <NumberField col={6} title='Y' value={hydrometer.y} floatValue onChange={v => this.onChangeHydrometer({ y: v })} {...mode} />
                        </Row>
                        <Row>
                            <Select col={12} label={i18n.projection} value={hydrometer.projection}
                                options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)}
                                onChange={v => this.onChangeHydrometer({ projection: v })} {...mode} integerValue
                            />
                        </Row>
                        <Row>
                            <NumberField col={12} value={hydrometer.altitude} title={i18n.altitudeM} floatValue onChange={ v => this.onChangeHydrometer({ altitude: v })} {...mode} />
                        </Row>
                        <Row>
                            <Input col={6} title={i18n.ignMap} value={geoData.ignMap} onChange={v => this.onChangeGeoData({ ignMap: v })} {...mode} />
                            <Input col={6} title={i18n.geologicalMap} value={geoData.geologicalMap} onChange={v => this.onChangeGeoData({ geologicalMap: v })} {...mode} />
                        </Row>
                        <Row>
                            <Input col={6} title={i18n.section} value={geoData.parcel} onChange={v => this.onChangeGeoData({ parcel: v })} {...mode} />
                            <Input col={6} title={i18n.parcel} value={geoData.section} onChange={v => this.onChangeGeoData({ section: v })} {...mode} />
                        </Row>
                    </Card>
                    <LinkedStationsPanel noMargin={false} className='margin-top-1' onReMount={this.props.onRemount} station={hydrometer} {...mode} />
                </div>
            </div>
        )
    }
}

HydrometryDescriptionPanel.propTypes = {
    user: PropTypes.instanceOf(UserDto),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactItem)),
    hydrometers: PropTypes.arrayOf(PropTypes.instanceOf(HydrometerDto)),
    hydrometer: PropTypes.instanceOf(HydrometerDto),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    watermasses: arrayOf(WatermassDto),
    watersheds: arrayOf(WatershedDto),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = store => ({
    user: store.AccountReducer.accountUser,
    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,
    hydrometers: store.HydrometryReducer.hydrometricStations,
    hydrometer: store.HydrometryReducer.hydrometricStation,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    watersheds: store.WatershedReducer.watersheds,
    watermasses: store.WatermassReducer.watermasses,
    userBookmarks: store.UserReducer.userBookmarks,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const mapDispatchToProps = {
    push,
    toastrInfo: ToastrAction.info,
    toastrError: ToastrAction.error,
    updateHydrometer: HydrometryAction.updateHydrometer,
    deleteBookmark: UserAction.deleteBookmark,
    createBookmark: UserAction.createBookmark,
    deleteHydrometer: HydrometryAction.deleteHydrometer,
    fetchWatermasses: WatermassAction.fetchWatermasses,
    fetchWatersheds: WatershedAction.fetchWatersheds,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometryDescriptionPanel)
