/* eslint-disable max-nested-callbacks */
import { NITRATES_CODE } from 'catchment/constants/CatchmentConstant'
import MessageCard from 'components/card/MessageCard'
import ProgressCard from 'components/card/ProgressCard'
import { groupBy, intersection, mean, round, sumBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getBeginingOfTheYear, getEndOfTheYear, getYear } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import PropTypes from 'prop-types'
import { getStationName } from 'catchment/utils/CatchmentUtil'
import QualityChart from 'components/echart/QualityChart'
import { Grid2 } from '@mui/material'
import moment from 'moment'
import useApplicationSetting, { listStringParser } from 'utils/customHook/useApplicationSetting'
import { WhiteCard } from 'components/styled/Card'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import AnalysisAction from 'quality/actions/AnalysisAction'
import { AREA } from 'quality/constants/ChartConstant'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'
import { filterQuantification } from 'utils/AnalyseUtils'

const PesticidesChartAnnualEvolution = ({
    analysis = {},
    thresholds = [],
    name = '',
    unit = '',
    startPeriod,
    endPeriod,
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const dataAnalysisFormated = useMemo(() => Object.keys(analysis).map(key => {
        const { qualitometer: qualitometerId } = analysis[key][0]
        const qualitometer = qualitometers.find(q => q.id === qualitometerId)
        const yearGrounp = groupBy(analysis[key], a => getYear(a.sampleDate))
        return {
            name: getStationName(qualitometer),
            unit,
            dataList: Object.keys(yearGrounp).map(year => {
                const operationGroup = groupBy(yearGrounp[year], a => a.operation)
                const average = mean(Object.keys(operationGroup).map(operationId => sumBy(operationGroup[operationId], 'result')))
                return { date: moment(year, 'YYYY').startOf('year').valueOf(), value: round(average, 5) }
            }),
        }
    }), [analysis, qualitometers, unit])

    const nameWithUnit = `${name} (${unit})`
    return (
        <QualityChart
            exportName={nameWithUnit}
            grids={[{ height: 250 }]}
            xAxis={[{ min: startPeriod, max: endPeriod }]}
            yAxis={[{
                name: nameWithUnit,
                min: 0,
                nameGap: dataAnalysisFormated.every(data => !data.dataList.length) ? 25 : 50,
            }]}
            series={dataAnalysisFormated}
            thresholds={thresholds}

            roundValue={5}

            defaultDisplayLine={false}
            defaultStateThreshold={AREA}

            withToolTypeLine
            withToolTypeBar
            withArea
            withToolThreshold
        />
    )
}

PesticidesChartAnnualEvolution.propTypes = {
    analysis: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight))),
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        color: PropTypes.string,
    })),
    areaColor: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    unit: PropTypes.string,
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
}

const ChartAnnualEvolution = ({
    analysis = {},
    thresholds = [],
    name = '',
    unit = '',
    startPeriod,
    endPeriod,
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const analysisFormated = useMemo(() => Object.keys(analysis).map(key => {
        const { qualitometer: qualitometerId } = analysis[key][0]
        const qualitometer = qualitometers.find(q => q.id === qualitometerId)
        const yearGrounp = groupBy(analysis[key], a => getYear(a.sampleDate))
        return {
            name: getStationName(qualitometer),
            unit,
            dataList: Object.keys(yearGrounp).map(year => {
                const total = sumBy(yearGrounp[year], 'result')
                const averageTotal = total / yearGrounp[year].length
                return { date: moment(year, 'YYYY').startOf('year').valueOf(), value: round(averageTotal, 5) }
            }),
        }
    }), [analysis, qualitometers, unit])

    const nameWithUnit = `${name} (${unit})`
    return (
        <QualityChart
            exportName={nameWithUnit}
            grids={[{ height: 250 }]}
            xAxis={[{ min: startPeriod, max: endPeriod }]}
            yAxis={[{ name: nameWithUnit, min: 0 }]}
            series={analysisFormated}
            thresholds={thresholds}

            roundValue={5}

            defaultDisplayLine={false}
            defaultStateThreshold={AREA}

            withToolTypeLine
            withToolTypeBar
            withArea
            withToolThreshold
        />
    )
}

ChartAnnualEvolution.propTypes = {
    analysis: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight))),
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        color: PropTypes.string,
    })),
    areaColor: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    unit: PropTypes.string,
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
}

const CatchmentAnnualEvolution = () => {
    const {
        catchment,
        qualitometers,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)


    const startPeriod = useStartPeriod(getBeginingOfTheYear)
    const endPeriod = useEndPeriod(getEndOfTheYear)
    const excludeProducers = useApplicationSetting('CATCHMENT_PRODUCERS_EXCLUDED', setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])

    const nitratesThreshold = useApplicationSetting('CATCHMENT_NITRATES_THRESHOLD', setting => setting ? parseFloat(setting) : undefined)
    const pesticideThreshold1 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_1', setting => setting ? parseFloat(setting) : undefined)
    const pesticideThreshold2 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_2', setting => setting ? parseFloat(setting) : undefined)

    const pesticides = useApplicationSetting('CATCHMENT_PESTICIDES', listStringParser)
    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)

    const qualitometersLinked = useMemo(() => {
        return catchment.catchmentPoints?.map(point => {
            return qualitometers.find(q => q.code === point.codeWithoutDesignation)
        }).filter(q => !!q) || []
    }, [catchment.catchmentPoints, qualitometers])

    const qualitoIds = useMemo(() => qualitometersLinked.map(q => q.id), [qualitometersLinked])

    const [nitratesAnalysis, setNitratesAnalysis] = useState([])

    const { isLoaded: isNitratesLoaded } = useProgressDispatch(cancelRef => [
        AnalysisAction.getAnalysis({
            lightMode: true,
            stations: qualitoIds,
            startDate: startPeriod,
            endDate: endPeriod,
            excludedProducers: excludeProducers,
            parameters: [NITRATES_CODE],
            support: '3', // support 'eau'
        }).then(json => !cancelRef.current && setNitratesAnalysis(json?.map(j => new DtoAnalysisLight(j)))),
    ])

    const nitratesAnalysisGroup = useMemo(() => {
        const filteredNitratesAnalysis = nitratesAnalysis.filter(({ sampleDate, result, qualitometer }) => hasValue(sampleDate) && hasValue(result) && qualitometers.some(q => q.id === qualitometer))
        return groupBy(filteredNitratesAnalysis, 'qualitometer')
    }, [nitratesAnalysis, qualitometers])


    const [pesticidesAnalysis, setPesticidesAnalysis] = useState([])

    const { isLoaded: isPesticidesLoaded } = useProgressDispatch(cancelRef => [
        AnalysisAction.getAnalysis({
            lightMode: true,
            stations: qualitoIds,
            startDate: startPeriod,
            endDate: endPeriod,
            excludedProducers: excludeProducers,
            parameters: listSumPesticides?.length ? intersection(pesticides, listSumPesticides) : pesticides,
            support: '3', // support 'eau'
        }).then(json => !cancelRef.current && setPesticidesAnalysis(json?.map(j => new DtoAnalysisLight(j)))),
    ])

    const pesticidesAnalysisGroup = useMemo(() => {
        const filteredPesticidesAnalysis = pesticidesAnalysis.filter(({ sampleDate, result, qualitometer }) => hasValue(sampleDate) && hasValue(result) && qualitometers.some(q => q.id === qualitometer))
        const quantifiedAnalysis = filterQuantification(filteredPesticidesAnalysis)
        return groupBy(quantifiedAnalysis, 'qualitometer')
    }, [pesticidesAnalysis, qualitometers])

    return (
        <WhiteCard title={i18n.annualEvolutionCompared} data-cy='annual_evolution'>
            {
                (!isPesticidesLoaded && !isNitratesLoaded) && (
                    <ProgressCard indeterminate />
                )
            }
            {
                isPesticidesLoaded && isNitratesLoaded && (
                    <>
                        {
                            !!nitratesAnalysis.length || !!pesticidesAnalysis.length ? (
                                <Grid2 container spacing={2} style={{ padding: '0 5' }}>
                                    <Grid2 size={6}>
                                        <ChartAnnualEvolution
                                            analysis={nitratesAnalysisGroup}
                                            thresholds={[{
                                                dataList: [{ value: nitratesThreshold }],
                                                colorList: ['#00FF00', '#FF0000'],
                                            }]}
                                            name={`${i18n.average} ${i18n.nitrates}`}
                                            unit='mg/L'
                                            startPeriod={startPeriod}
                                            endPeriod={endPeriod}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <PesticidesChartAnnualEvolution
                                            analysis={pesticidesAnalysisGroup}
                                            thresholds={[{
                                                dataList: [{ value: pesticideThreshold1 }, { value: pesticideThreshold2 }],
                                                colorList: ['#00FF00', '#FFA500', '#FF0000'],
                                            }]}
                                            name={`${i18n.average} ${i18n.sumPesticides}`}
                                            unit='µg/L'
                                            startPeriod={startPeriod}
                                            endPeriod={endPeriod}
                                        />
                                    </Grid2>
                                </Grid2>
                            ) : (
                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                            )
                        }
                    </>
                )
            }
        </WhiteCard>
    )
}

export default CatchmentAnnualEvolution