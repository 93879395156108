import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Icon } from '@mui/material'
import { styled } from '@mui/styles'
import PropTypes from 'prop-types'

export const DialogMUI = styled(({ PaperProps: { sx, ...othenPaperProps } = {}, ...other }) => (
    <Dialog
        fullWidth
        maxWidth='lg'
        PaperProps={{
            sx: {
                minHeight: '90vh',
                maxHeight: '90vh',
                ...sx,
            },
            ...othenPaperProps,
        }}
        {...other}
    />
))({})

export const DialogTitleMUI = styled(({ ...other }) => (
    <DialogTitle
        {...other}
    />))(({ theme }) => ({
    backgroundColor: theme.palette.primary.grey,
    color: 'white',
    borderBottom: 'solid 1px grey',
    fontSize: '20px',
    lineHeight: '20px',
}))

export const DialogContentMUI = styled(({ ...other }) => (
    <DialogContent
        {...other}
    />))({
    backgroundColor: '#f0f0f0',
})

export const DialogActionsMUI = styled(({ ...other }) => (
    <DialogActions
        {...other}
    />))({
    borderTop: 'solid 1px grey',
})

const DefaultDialogTitle = ({
    title = '',
    onClose = '',
}) => {
    return (
        <DialogTitle>
            <Grid2 container justifyContent='space-between' alignItems='center'>
                <Grid2 size='grow'>
                    {title}
                </Grid2>
                <Grid2 size='auto'>
                    <Icon className='clickable' sx={{ color: 'white', fontSize: '2rem' }} onClick={onClose}>close</Icon>
                </Grid2>
            </Grid2>
        </DialogTitle>
    )
}

DefaultDialogTitle.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
}

export {
    DefaultDialogTitle,
}