import { ceil, floor, max, min, padStart } from 'lodash'
import moment from 'moment'
import { getTabColorsBySize } from '../../utils/AnalyseUtils'
import { hasValue, log10Ceil, log10Floor, round } from '../../utils/NumberUtil'
import { getMeasureValue } from '../../utils/PiezometryUtils'
import { getComponentWithId, removeNullKeys } from '../../utils/StoreUtils'
import Line from './series/Line'
import { getThresholdColorHtml } from '../../utils/ColorUtil'
import { HISTO, H_24, H_48, J30, J365, J7, J90, ONE_MONTH, ONE_WEEK, ONE_YEAR } from 'alerting/constants/ChartFollowContants'
import { MONTH, WEEK, YEAR } from 'quality/constants/ChartConstant'

const Hours24 = 86400000
const Hours48 = 172800000
const Days3 = 259200000
const Days7 = 604800000
const Days30 = 2592000000
const Days90 = 7776000000
const Days365 = 31536000000

const getOptions = (obj, defaultOptions, treatments) => {
    const optionsList = Object.keys(obj).map(attr => {
        if (obj[attr] !== null) {
            if (treatments[attr]) {
                return treatments[attr]()
            }
            const nullObj = {}
            nullObj[attr] = obj[attr]
            return nullObj
        }
        return {}
    })
    return Reflect.apply(Object.assign, null, [].concat([defaultOptions], optionsList))
}

const checkBarPatch = (minDateMoment, value) => {
    // if (minDateMoment.valueOf() > value) {
    //     const component = getComponentWithId('.sieauChart')
    //     if (component) {
    //         const instance = component.getInstance()
    //         if (instance) {
    //             const options = instance.getOption()
    //             const xAxis = options.xAxis.map(axis => ({
    //                 ...axis,
    //                 min: minDateMoment.valueOf() + (minDateMoment.valueOf() - value),
    //             }))
    //             setTimeout(() => {
    //                 instance.setOption({ xAxis })
    //             }, 200)
    //         }
    //     }
    // }
}

const getChartDate = (minDate, maxDate, timeline = H_24) => {
    switch (timeline) {
        case H_24:
        case H_48:
            return {
                minDate: moment(minDate).add(1, 'h').startOf('hour').valueOf(),
                maxDate: moment(maxDate).add(1, 'h').startOf('hour').valueOf(),
            }
        case J7:
        case J30:
        case J90:
            return {
                minDate: moment(minDate).valueOf(),
                maxDate: moment(maxDate).endOf('day').valueOf(),
            }
        case J365:
            return {
                minDate: moment(minDate).endOf('month').valueOf(),
                maxDate: moment(maxDate).endOf('month').valueOf(),
            }
        case HISTO:
            return {
                minDate: moment(minDate).startOf('year').valueOf(),
                maxDate: moment().endOf('year').valueOf(),
            }
        default:
            return {
                minDate,
                maxDate,
            }
    }
}

const getSubstractTime = (newTime) => {
    switch (newTime) {
        case H_24:
            return moment().subtract(24, 'hours').valueOf()
        case H_48:
            return moment().subtract(48, 'hours').valueOf()
        case J7:
            return moment().subtract(7, 'days').valueOf()
        case J30:
            return moment().subtract(30, 'days').valueOf()
        case J90:
            return moment().subtract(90, 'days').valueOf()
        case J365:
            return moment().subtract(1, 'years').valueOf()
        default:
            return moment().valueOf()
    }
}

// use barWidth on bar charts only
const getAxisIntervalFormatter = (maxDate, minDate, barWidth) => {
    const duration = moment.duration(maxDate.diff(minDate))
    if (duration.asMonths() >= 13) {
        const aYear = 3600 * 24 * 1000 * 365.5

        return {
            interval: (() => {
                if (duration.years() >= 30) {
                    return 4 * aYear
                }
                if (duration.years() >= 20) {
                    return 2 * aYear
                }
                return aYear
            })(),
            formatter: barWidth ? ((value) => {
                checkBarPatch(minDate, value)
                return moment(value).year()
            }) : (value => {
                if (moment(value).valueOf() > moment(value).month(11).date(10).valueOf()) { // arrondi à l'année proche
                    return moment(value).add(1, 'month').year()
                }
                return moment(value).year()
            }),
        }
    }
    if (duration.asMonths() >= 11) {
        return {
            interval: 3600 * 24 * 1000 * (duration.asDays() / duration.asMonths()),
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                return moment(value).format('DD/MM')
            },
        }
    }
    if (duration.asMonths() >= 2) {
        return {
            interval: 3600 * 24 * 1000 * (duration.asDays() / 13),
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                return moment(value).format('DD/MM')
            },
        }
    }
    if (duration.asDays() >= 20) {
        return {
            interval: 3600 * (duration.asHours() / (duration.days() / 2)) * 1000,
            formatter: (value) => moment(value).format('DD/MM'),
        }
    }
    if (duration.asDays() >= 5) {
        return {
            interval: 3600 * (duration.asHours() / duration.days()) * 1000,
            formatter: (value) => moment(value).format('DD/MM'),
        }
    }
    if (duration.asDays() > 1) {
        return {
            interval: 3600 * 4 * 1000,
            formatter: (value) => {
                const momentValue = moment(value)
                if (moment().add(1, 'h').startOf('hour').hour() === momentValue.hour()) {
                    return `${momentValue.format('DD/MM')}\n${momentValue.format('HH:mm')}`
                }
                return momentValue.format('HH:mm')
            },
        }
    }
    return {
        interval: 3600 * 2 * 1000,
        formatter: (value) => moment(value).format('HH:mm'),
    }
}

const getMaxInterval = (choice) => {
    switch (choice) {
        case WEEK: return 1000 * 3600 * 24 * 7
        case MONTH: return 1000 * 3600 * 24 * 30.5
        case YEAR: return 1000 * 3600 * 24 * 365.25
        default: return undefined
    }
}

const getTimeAxisInterval = (choice) => {
    switch (choice) {
        case ONE_WEEK:
            return 3600 * 1000 * 24
        case ONE_MONTH:
            return 3600 * 1000 * 24 * 12
        case ONE_YEAR:
            return 3600 * 1000 * 24 * 365.5
        default:
            return undefined
    }
}

const getMaxProvisionalDate = (choice, minDate, measureMaxDate) => {
    switch (choice) {
        case H_24:
            return minDate + Hours24 + Hours24
        case H_48:
            return minDate + Hours48 + Hours24
        case J7:
            return minDate + Days7 + Days3
        case J30:
            return minDate + Days30 + Days30
        case J90:
            return minDate + Days90 + Days30
        case J365:
            return minDate + Days365 + Days90
        case HISTO:
            return measureMaxDate
        default:
            return measureMaxDate
    }
}

const getMaxY = (value, pad, maxValueInData) => {
    if (value < maxValueInData) {
        return getMaxY(value + pad, pad, maxValueInData)
    }
    return value
}

const yAutomaticScaleValues = (values, minRoundPrecision = 1) => {
    if (!values.length) {
        return {}
    }

    const minY = min(values)
    const maxY = max(values)
    const diff = maxY - minY
    if (diff < 1) {
        const baseObj = { min: floor(minY, minRoundPrecision), max: ceil(maxY, minRoundPrecision) }
        return { ...baseObj, splitNumber: 4, interval: round((baseObj.max - baseObj.min) / 5) }
    }
    if (diff < 5) {
        const baseObj = { min: floor(minY), max: ceil(maxY) }
        return { ...baseObj, splitNumber: 4, interval: round((baseObj.max - baseObj.min) / 5) }
    }
    if (diff < 10) {
        const tmpMin = floor(minY, -1) + (minY % 10 > 5 ? 5 : 0)
        const baseObj = { min: tmpMin, max: getMaxY(tmpMin + 10, 10, maxY) }
        return { ...baseObj, splitNumber: 4, interval: round((baseObj.max - baseObj.min) / 5) }
    }
    const tmpMin = floor(minY, -1)
    const pad = [10, 20, 40, 50, 80, 100, 120, 150, 200, 250, 400, 500, 800, 1000, 1200, 1400, 1500, 2000, 2500, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000, 10000000].find(maxDiff => diff < maxDiff)
    const baseObj = { min: tmpMin, max: getMaxY(tmpMin + pad, pad, maxY) }
    return { ...baseObj, splitNumber: 4, interval: round((baseObj.max - baseObj.min) / 5) }
}

const automaticValues = (...listOfList) => listOfList.flat().map(v => v.value[1])

const yAutomaticScale = (...listOfList) => yAutomaticScaleValues(automaticValues(listOfList.flat()))

const yAutomaticScaleTab = (...listOfList) => {
    const values = listOfList.flat().map(v => v[1])
    return yAutomaticScaleValues(values)
}

const getBarWidth = (nbElements) => {
    if (nbElements > 5000) {
        return 1
    }
    if (nbElements > 1000) {
        return 2
    }
    if (nbElements > 600) {
        return 3
    }
    if (nbElements > 100) {
        return 4
    }
    if (nbElements > 50) {
        return 6
    }
    if (nbElements > 20) {
        return 8
    }
    if (nbElements > 10) {
        return 15
    }
    return 25
}

const setYOptionsPiezo = (optionOrNull, typeId, automaticScale, displayCote, lastLandmark, groundRefAlti, minAxisY = undefined, maxAxisY = undefined) => {
    const option = optionOrNull || {}
    const minValue = hasValue(option.minYear) && typeId === -1 ? round(getMeasureValue({ NGF: option.minYear }, displayCote, lastLandmark, groundRefAlti), 2) : (hasValue(option.minYear) ? option.minYear : automaticScale.min)
    const maxValue = hasValue(option.maxYear) && typeId === -1 ? round(getMeasureValue({ NGF: option.maxYear }, displayCote, lastLandmark, groundRefAlti), 2) : (hasValue(option.maxYear) ? option.maxYear : automaticScale.max)

    const usedMin = minValue < maxValue ? minValue : maxValue
    const usedMax = minValue < maxValue ? maxValue : minValue

    return removeNullKeys({
        min: minAxisY ?? usedMin,
        max: maxAxisY ?? usedMax,
        splitNumber: (option.intervalYear || 5) - 1,
        interval: hasValue(usedMax) && hasValue(usedMin) ? (usedMax - usedMin) / (((option.intervalYear || 5) - 1) + 1) : null,
        axisLabel: { formatter: v => round(v, 2) },
    })
}

const setYOptions = (optionOrNull, automaticScale) => {
    const option = { ...(optionOrNull || {}), min: optionOrNull?.min ?? optionOrNull?.minYear, max: optionOrNull?.max ?? optionOrNull?.maxYear }
    const minValue = hasValue(option.min) ? option.min : automaticScale.min
    const maxValue = hasValue(option.max) ? option.max : automaticScale.max

    const usedMin = minValue < maxValue ? minValue : maxValue
    const usedMax = minValue < maxValue ? maxValue : minValue
    return removeNullKeys({
        min: usedMin,
        max: usedMax,
        splitNumber: (option.intervalYear || 5) - 1,
        interval: hasValue(usedMax) && hasValue(usedMin) ? (usedMax - usedMin) / (((option.interval || 5) - 1) + 1) : null,
        axisLabel: { formatter: v => round(v, 2) },
    })
}

const setLogOptions = (logActive, values = []) => {
    if (logActive && values.length) {
        const minVal = min(values)
        const maxVal = max(values)
        return {
            min: log10Floor(minVal),
            max: log10Ceil(maxVal),
            interval: undefined,
            axisLabel: { formatter: v => v },
        }
    }
    return {}
}

const getMarkLineThreshold = (threshold = {}) => {
    const nbThresholds = [1, 2, 3, 4].filter(nb => hasValue(threshold[`threshold${nb}`])).length + 1
    const colorTab = getTabColorsBySize()[nbThresholds]
    return [{
        yAxis: threshold.threshold1,
        lineStyle: {
            color: threshold.threshold1 ? colorTab[1] : null,
        },
    }, {
        yAxis: threshold.threshold2,
        lineStyle: {
            color: threshold.threshold2 ? colorTab[2] : null,
        },
    }, {
        yAxis: threshold.threshold3,
        lineStyle: {
            color: threshold.threshold1 ? colorTab[3] : null,
        },

    }, {
        yAxis: threshold.threshold4,
        lineStyle: {
            color: threshold.threshold1 ? colorTab[4] : null,
        },
    }].filter(m => hasValue(m.yAxis))
}

const getMonthThresholdData = (threshold, startDate, endDate, bandCorrection, isPiezo, objNGF) => {
    let current = startDate
    let built = []
    let lastValue = 0
    const b = bandCorrection ? 1000 : 0
    while (current <= endDate) {
        const month = moment(current).month() + 1
        const monthKey = `month${padStart(month.toString(), 2, '0')}`
        const newValueNGF = threshold[monthKey] || lastValue || 0
        const newValueDepth = round(objNGF.landmarkValue - threshold[monthKey]) || round(objNGF.landmarkValue - lastValue) || round(objNGF.landmarkValue - 0)
        const newValue = objNGF.isNGF ? newValueNGF : newValueDepth
        lastValue = newValue
        built = [
            ...built,
            { value: [moment(current).startOf('month').valueOf(), newValue + b], isPiezo, symbolSize: 0 },
            { value: [moment(current).endOf('month').valueOf(), newValue + b], isPiezo, symbolSize: 0 },
        ]
        current = moment(current).add(1, 'month').valueOf()
    }
    return built
}

const getMonthThreshold = (threshold, startDate, endDate, override = {}, bandCorrection = false, isPiezo = false, objNGF = {}) => {
    return Line({
        name: threshold.name || '',
        data: getMonthThresholdData(threshold, startDate, endDate, bandCorrection, isPiezo, objNGF),
        // isPiezo: true,
        connectNulls: false,
        color: getThresholdColorHtml(threshold),
        lineStyle: {
            type: 'dashed',
            width: 1,
        },
        ...override,
    })
}

const getMarkAreaThreshold = (threshold = {}) => {
    const nbThresholds = [1, 2, 3, 4].filter(nb => threshold[`threshold${nb}`] || threshold[`threshold${nb}`] === 0).length + 1
    const colorTab = getTabColorsBySize()[nbThresholds]
    const markArea = [{
        0: {
            yAxis: threshold.threshold1,
            itemStyle: {
                opacity: 0.2,
                color: threshold.threshold1 ? colorTab[0] : null,
            },
        },
        1: { yAxis: 0 },
    }, {
        0: {
            yAxis: threshold.threshold2,
            itemStyle: {
                opacity: 0.2,
                color: threshold.threshold2 ? colorTab[1] : null,
            },
        },
        1: { yAxis: threshold.threshold1 },
    }, {
        0: {
            yAxis: threshold.threshold3,
            itemStyle: {
                opacity: 0.2,
                color: threshold.threshold1 ? colorTab[2] : null,
            },
        },
        1: { yAxis: threshold.threshold2 },
    }, {
        0: {
            yAxis: threshold.threshold4,
            itemStyle: {
                opacity: 0.2,
                color: threshold.threshold1 ? colorTab[3] : null,
            },
        },
        1: { yAxis: threshold.threshold3 },
    }].filter(m => hasValue(m[0].yAxis) && hasValue(m[1].yAxis))
    if (!markArea.length) {
        return {
            data: [],
        }
    }
    return [
        ...markArea,
        {
            0: {
                yAxis: 1000000,
                itemStyle: {
                    opacity: 0.2,
                    color: 'red',
                },
            },
            1: { yAxis: markArea[markArea.length - 1][0].yAxis },
        },
    ]
}

const toEchartsData = (measures, stationObj = null, stationCode = null, unit= null, symbolSize = 0) => {
    return measures.flatMap(obj => {
        if (obj.initialPoint === 1) {
            return [
                { value: [moment(obj.date).subtract(1, 'second').valueOf(), null, obj, stationObj] },
                { value: [obj.date, obj.value, obj, stationObj], symbolSize: 5, stationCode, unit },
            ]
        }
        return [{ value: [obj.date, obj.value, obj, stationObj], stationCode, unit: unit ?? obj.unit, symbolSize, symbol: obj.symbol }]
    })
}

const toEchartsDataFromTab = (measures, stationObj = null, stationCode = null) => {
    return measures.flatMap(obj => {
        if (obj[4] === 1) {
            return [
                { value: [moment(obj[0]).subtract(1, 'second').valueOf(), null, obj, stationObj] },
                { value: [obj[0], obj[1], obj, stationObj], symbolSize: 5, stationCode },
            ]
        }
        return [{ value: [obj[0], obj[1], obj, stationObj], symbolSize: 0, stationCode }]
    })
}


const formatLog10 = analysis => analysis.map(a => ({ ...a, result: Math.log10(a.result) }))

const fullScreenIcon = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gwaEBYYft7EIgAAAh9JREFUSMfdlj9rFFEUxX8jmwjZGJLGxICxMBL9ABZ+hzm7rtHGSjsLCxtt/VMKi4q9ICiIbNzITRd7C7+AqE0UjRZilNgYdWzuk7ezMy9r64WBx9x7z73v3DfnTSbpJHAWKBi0DPhgZpdImKQ7wHxN/oNMUkHazpnZ/Rrw88C9VPKeindbwFd/XgKrify+x3yJngFrVCR9NrPFqMtUg1tmdiyKfQPMDPAUUbQNTDqXn4AFYMfM2GUGAGPAW2DWuQ9YAxTdAHoeMOsJY6kdRODvIvCeYw3NoGlmZ0YtUgLfH8Ado1k5ZEl4wEqpyHhcxNd7q8DdV8QFLgDPgK6Zkec5Zna6VGQtnoWv16rA3dcF1h17+LSEtaTHkgpJtysouuW+R3X5SWu1WiF4EqDdbv/15XkefBMjA/5/Fs1hukxRRN/0yBTFQRHH/cSQ77qvlxpyQ9JFYBloA9/CUZO06u8K4GhFT0vuW5b0xMxORcd0StJTYCXWomvA9Qrwj8ChWJe8w3FgI5KIfijiWFfLX3Lm4P0S+JDo+foHcNBjCqDjO6FOTa8AJ4COJ2x65z/rFNW7bbiczIWdAM+Bm+UC312kAvgC8Osf5HojKhKwBi6coICvzWypJIC14O7bAeYlvQKOxGpadaPNSdr29SZw3K/RKpuR9MI7B/g9yp085R00gUWglWCoAxyO4veVAzJJeeK35b2ZXd5lBl3gQE3+wz+wZvJoBRE1rQAAAABJRU5ErkJggg=='

const exportPictureIcon = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAWRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIj4KICAgICAgICAgPHhtcDpDcmVhdG9yVG9vbD5BZG9iZSBJbWFnZVJlYWR5PC94bXA6Q3JlYXRvclRvb2w+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpqMDBXAAABiElEQVRIDbVV7XGCQBCFgwIowXSQdKAdmAoyVhAECgj/GeZSgXYQU4GWYAexg5D/fOQt4zInB4qH7My5H9y+d3e7d1rWxGITfhiGy6qqNjC9B/NJ2/d9TwjxMwF4vVbhuu7zVODEIGqaCX/ckdgS+X8YbxgzDE2MCVC3RZIkB0JEHWVfHY2OCB13YHAikFJmUJ9kt8WIoA1yzTcisG17jmOh7lOF6qDJmBrsgyBYgywry/IdyDMNHYEhBDvM+8agm66KB2eDelggUeMX9s0jwurWaZpukUXjbrlFsEWHnAiViKBqm/yhcpUAoDEDUSvCf2V/qO4loF7n1TMY/CPshpTj13QvgeM4nUCoxweRt0BjFJrINekkIAD1praz8H2FWHaOr4i0KIqFEmtSOgn6Vs9ZdHSoxwuNc4fVzwV2QcQXYkdRNMfEvRI9IelJ8e8ycfm+kLDkpK4dxPzRRGOxtAs+Pv0PBxN2JsCcQ+0Mu3lZtacC7/ovtsnzR2uR5/kRKM2WRiO2AP4B0TKNi/pEGVoAAAAASUVORK5CYII='

const exportExcelIcon = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAWRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIj4KICAgICAgICAgPHhtcDpDcmVhdG9yVG9vbD5BZG9iZSBJbWFnZVJlYWR5PC94bXA6Q3JlYXRvclRvb2w+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpqMDBXAAABBUlEQVRIDe2U0Q2CMBCGbeMAjuAIjAAjMIFhg4bwjixAHcENHEFHcBQmAP8jYspBKQV9MOESaO96939peyB2DkvTVDdNE0ylaa0j2/rettDFSVwIEXa+7yh9C3zzN4DzxP7/iIS5R/S8gn8wY5if8BxZjLsFD9R1fYFVve8AwYeU8o5kDuH13M9ZICFxivV2QAGlVLAQQuVkSVmW13aG1wBACysgPXHSGgUshAzEJwGekFFxJ2AmxCpO9c4PDd3wRHdFyG27goreVuEvG5sX2i2Yo/UOzCSas4uvCEpwnsf92QADcoN4PEecwzZ/9AQ+d5BlWYizpf/Qt6xAh52dbbqW9nPACyJpV919J488AAAAAElFTkSuQmCC'

const crossChartSymbol = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH4wQJDAwsQ/vwTQAAASxJREFUOMuNkzHOgkAQhT8sAKOdGyu8gKUVCdTGxLvoSWykMF7CmNBwA70ABkJvB4m0RsdCQc3Pv7jJNrOzb+bNe4PsGMyG7Fnlrojwy81XuAxne3YyYDnhCCQ26sz60gpyWeMq7DOQMFkeKQI8RfcZQGWEMv0XIGSqIAOSLupMUHiICOUWX0FGhxTLSRpBQqaORUKHFFTGtvRFhHdCxHxkk9YJH3Sebb8K2KOUSObV23eViHnVYjWTL86o7PPzX4CazmsmZi/umcQ151fbWgARodzgOSYxhpEaBimmE7MpvabcDg1HrtzugrwDd+EqNxqTdTpb/VPf4qTziV7nbel/zaTBJ606t/mkVec2n5C36Kz3Se6yGHPQ6az1yXhxoF7noPB+XeciwKvW+QHHTfw6YfQz0QAAAABJRU5ErkJggg=='

const horizontalHistogramIcon = 'path://m 0 10 v 80 h 4 v -80 h -4 m 20 0 h 70 v 20 h -70 v -20 m 0 30 h 40 v 20 h -40 v -20 m 0 30 h 55 v 20 h -55 v -20 z'
const histogramIcon = 'path://m 10 100 h 80 v -4 h -80 v 4 m 0 -20 v -70 h 20 v 70 h -20 m 30 0 v -40 h 20 v 40 h -20 m 30 0 v -55 h 20 v 55 h -20 z'
const lineIcon = 'path://m 10 100 h 80 v -4 h -80 v 4 m 0 -60 l 9 3 l 16 -33 l 18 70 l 10 -40 l 10 20 l 17 -10 v 2 l -17 10 l -10 -20 l -10 40 l -18 -70 l -16 33 l -9 -3 v -2 z'
const stackIcon = 'path://m 10 30 l 50 -20 l 30 20 l -50 20 l -30 -20 m 0 13 l 30 20 l 50 -20 l -12 -8 m -57 2 l -11 6 m 0 13 l 30 20 l 50 -20 l -12 -8 m -57 2 l -11 6 m 0 13 l 30 20 l 50 -20 l -12 -8 m -57 2 l -11 6 z'
const quantificationIcon = 'path://M 50 250 A 50 80 0 1 1 250 250 A 50 80 0 1 1 50 250 M 375 50 L 425 50 L 425 375 L 475 375 L 400 450 L 325 375 L 375 375 L 375 50 M 60 250 A 50 80 0 1 1 240 250 A 50 80 0 1 1 60 250 M 390 70 L 410 70 L 410 390 L 440 390 L 400 430 L 360 390 L 390 390 L 390 70 Z'
const logIcon = 'path://m 0 25 v 45 h 25 v -5 h -20 v -40 h -5 m 35 0 v 45 h 25 v -45 h -25 m 5 5 h 15 v 35 h -15 v -35 m 30 -5 v 45 h 30 v -25 h -15 v 5 h 10 v 15 h -20 v -35 h 25 v -5 h -30 z '
const log10Icon = 'path://m 0 0 v 45 h 25 v -5 h -20 v -40 h -5 m 35 0 v 45 h 25 v -45 h -25 m 5 5 h 15 v 35 h -15 v -35 m 30 -5 v 45 h 30 v -25 h -15 v 5 h 10 v 15 h -20 v -35 h 25 v -5 h -30 m -45 55 h 15 v 45 h -5 v -40 h -10 v -5 m 30 0 v 45 h 25 v -45 h -25 m 5 5 v 35 h 15 v -35 h -15 z '
// const thresholdIcon = 'path://m 0 66 h 100 v 2 h -100 v -2 m 10 -20 l 9 3 l 16 -33 l 18 70 l 10 -40 l 10 6 l 17 -15 v 2 l -17 15 l -10 -6 l -10 40 l -18 -70 l -16 33 l -9 -3 v -2 z '
const thresholdIcon = 'path://m 0 40 h 100 v 2 h -100 v -2 m 10 20 l 9 3 l 16 -53 l 18 70 l 10 -20 l 10 6 l 17 -15 v 2 l -17 15 l -10 -6 l -10 20 l -18 -70 l -16 53 l -9 -3 v -2 z '
const chartSymbol = 'path://M 21.20,15.00 C 21.20,15.00 25.20,9.70 25.20,9.70M 13.80,7.70 C 13.80,7.70 19.20,14.30 19.20,14.30M 7.80,15.70 C 7.80,15.70 11.20,7.70 11.20,7.70M 2.50,17.70 C 2.50,17.70 4.50,17.70 4.50,17.70M 27.20,10.30 C 28.30,10.30 29.20,9.40 29.20,8.30 29.20,7.20 28.30,6.30 27.20,6.30 26.10,6.30 25.20,7.20 25.20,8.30 25.20,9.40 26.10,10.30 27.20,10.30 Z M 19.20,18.30 C 20.30,18.30 21.20,17.40 21.20,16.30 21.20,15.20 20.30,14.30 19.20,14.30 18.10,14.30 17.20,15.20 17.20,16.30 17.20,17.40 18.10,18.30 19.20,18.30 Z M 12.50,8.30 C 13.60,8.30 14.50,7.40 14.50,6.30 14.50,5.20 13.60,4.30 12.50,4.30 11.40,4.30 10.50,5.20 10.50,6.30 10.50,7.40 11.40,8.30 12.50,8.30 Z M 6.50,19.00 C 7.60,19.00 8.50,18.10 8.50,17.00 8.50,15.90 7.60,15.00 6.50,15.00 5.40,15.00 4.50,15.90 4.50,17.00 4.50,18.10 5.40,19.00 6.50,19.00 Z M 1.80,25.00 C 1.80,25.00 29.20,25.00 29.20,25.00M 1.80,1.00 C 1.80,1.00 1.80,25.00 1.80,25.00'
const chartLine = 'path://M 20 15 L 26 9 M 12 6 L 20 15 M 7 17 L 12 6 M 2.5 17.7 L 7 17 Z M 1.8 25 C 1.8 25 29.2 25 29.2 25 M 1.8 1 C 1.8 1 1.8 25 1.8 25'
const legendSymbol = 'path://m 15 18 a 7 7 0 1 0 1 0 l -1 0 m 20 0 l 55 0 l 0 14 l -55 0 l 0 -14 m -20 25 a 7 7 0 1 0 1 0 l -1 0 m 20 0 l 55 0 l 0 14 l -55 0 l 0 -14 m -20 25 a 7 7 0 1 0 1 0 l -1 0 m 20 0 l 55 0 l 0 14 l -55 0 l 0 -14 z '
const inversePluvio = 'path://M 12 6 L 20 15 M 7 17 L 12 6 M 2.5 17.7 L 7 17 Z M 1.8 25 L 22 25 M 22 1 L 22 25 M 28 1 L 24 3 L 27 3 L 27 23 L 24 23 L 28 25 L 32 23 L 29 23 L 29 3 L 32 3 L 28 1'


const noFolderSymbol = 'path://m871-202-71-71v-367H434l-80-80-80-80h114l80 80h332q33 0 56.5 23.5T880-640v400q0 11-2 20.5t-7 17.5ZM819-28 687-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800l80 80h-80v480h447L28-820l56-56L876-84l-57 56ZM368-480Zm209-17Z'
const noDataSymbol = 'path://M120-160v-80h480v80H120Zm520-280q-83 0-141.5-58.5T440-640q0-83 58.5-141.5T640-840q83 0 141.5 58.5T840-640q0 83-58.5 141.5T640-440Zm-520-40v-80h252q7 22 16 42t22 38H120Zm0 160v-80h376q23 14 49 23.5t55 13.5v43H120Zm500-280h40v-160h-40v160Zm20 80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Z'

export {
    getOptions,
    yAutomaticScale,
    yAutomaticScaleTab,
    yAutomaticScaleValues,
    setYOptionsPiezo,
    setYOptions,
    setLogOptions,
    formatLog10,
    fullScreenIcon,
    exportPictureIcon,
    exportExcelIcon,
    crossChartSymbol,
    chartSymbol,
    horizontalHistogramIcon,
    histogramIcon,
    lineIcon,
    stackIcon,
    quantificationIcon,
    log10Icon,
    logIcon,
    thresholdIcon,
    getMarkLineThreshold,
    getMarkAreaThreshold,
    legendSymbol,
    inversePluvio,
    chartLine,
    getBarWidth,
    toEchartsData,
    toEchartsDataFromTab,
    getMonthThreshold,
    getTimeAxisInterval,
    getMaxProvisionalDate,
    getMaxInterval,
    getAxisIntervalFormatter,
    noFolderSymbol,
    noDataSymbol,
    getChartDate,
    getSubstractTime,
    automaticValues,
}
