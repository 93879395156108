import { Button, Grid, MobileStepper } from '@mui/material'
import Icon from 'components/icon/Icon'
import ConfirmModal from 'components/modal/ConfirmModal'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { maxBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import JobAction from '../../../../../import/actions/JobAction'
import DtoJobParameters from '../../../../../import/dto/DtoJobParameters'
import DtoQualitometerLight from '../../../../../quality/dto/DtoQualitometerLight'
import { getDate, getTimestamp } from '../../../../../utils/DateUtil'
import { hasValue } from '../../../../../utils/NumberUtil'
import { getLogin } from '../../../../../utils/SettingUtils'
import { searchAllCharacters } from '../../../../../utils/StringUtil'
import FormPopupExportEDILABO from '../stepExportEDILABO/FormPopupExportEDILABO'
import ValidateParameterTab from '../stepExportEDILABO/ValidateParameterTab'
import ValidateStationTab from '../stepExportEDILABO/ValidateStationTab'
import useListIndexed from 'utils/customHook/useListIndexed'


const FORM = 0
const STATION = 1
const PARAM = 2

const nbSteps = 3

const PopupEDILABO = ({
    isOpen = false,
    onClose = () => { },
    campaign,
    selectedStations,
    selectedParameters,
    stations,
}) => {
    const dispatch = useDispatch()

    const {
        parameters,
        edilaboFiles,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        edilaboFiles: store.CampaignReducer.edilaboFiles,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)

    const [step, setStep] = useState(FORM)
    const [paramEDILABO, setParamEDILABO] = useState(() => {
        const lastFile = maxBy(edilaboFiles, 'updateDate')
        const exportPath = lastFile ? lastFile.href.split('EXPORT/EDILABO/')[1].replace(lastFile.label, '') : ''
        return {
            exportPath,
            startDate: getTimestamp(campaign.beginningApplication),
            endDate: getTimestamp(campaign.endingApplication),
            codifications: [],
            sender: hasValue(campaign.contributorCode) ? parseInt(campaign.contributorCode) : undefined,
            receiver: (() => {
                const samplerCode = hasValue(campaign.samplerCode) ? parseInt(campaign.samplerCode) : undefined
                const recipientCode = hasValue(campaign.recipientCode) ? parseInt(campaign.recipientCode) : undefined
                switch (campaign.requestType) {
                    case '1':
                        return samplerCode
                    case '2':
                        return recipientCode
                    case '3':
                        return recipientCode || samplerCode
                    default:
                        return undefined
                }
            })(),
        }
    })
    const [tmpSelectedStations, setTmpSelectedStations] = useState(selectedStations)
    const [tmpSelectedParameters, setTmpSelectedParameters] = useState(selectedParameters)
    const [selection, setSelection] = useState()

    const onExport = () => {
        const {
            sender,
            receiver,
            requestName,
            startDate,
            endDate,
            exportPath,
            codifications,
        } = paramEDILABO
        const newJob = {
            jobType: 'edilaboExport',
            login: getLogin(),
            name: `EDILABO_DAI_${campaign.id}_${getDate(moment().valueOf())}`,
            parameters: new DtoJobParameters({
                routingKey: 'export.edilabo.default',
                jobType: 'edilaboExport',
                path: exportPath,
                parameters: [JSON.stringify({
                    idC: campaign.id,
                    sender,
                    receiver,
                    startDate,
                    endDate,
                    requestName,
                    stations: tmpSelectedStations,
                    codifications,
                    parameters: tmpSelectedParameters.map(p => p.parameterCode),
                })],
            }),
        }
        dispatch(JobAction.addJob(newJob, false)).then((json = { error: true }) => {
            if (!json.error) {
                dispatch(JobAction.executeJob(json.id))
                onClose()
            }
        })
    }

    const isNextDisabled = useMemo(() => {
        const {
            sender,
            receiver,
            requestName,
            startDate,
            endDate,
        } = paramEDILABO
        return (step === STATION && !tmpSelectedStations.length)
            || (step === FORM && (!hasValue(sender) || !hasValue(receiver) || !requestName || !startDate || !endDate))
            || step === PARAM
    }, [paramEDILABO, step, tmpSelectedStations.length])

    const isExportDataComplete = useMemo(() => {
        return !!tmpSelectedStations.length
            && !!tmpSelectedParameters.length
            && tmpSelectedParameters.every(p => hasValue(p.fractionCode) && hasValue(p.unitCode) && hasValue(p.supportCode))
    }, [tmpSelectedParameters, tmpSelectedStations.length])

    return (
        <>
            <DialogMUI
                open={isOpen}
                fullWidth
                maxWidth='xl'
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                        <Grid item >
                            {`${i18n.step} ${step + 1}/${nbSteps}: ${i18n.EDILABODAI}`}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    {
                        step === FORM && (
                            <FormPopupExportEDILABO
                                paramEDILABO={paramEDILABO}
                                onChange={obj => setParamEDILABO(prevParam => ({ ...prevParam, ...obj }))}
                            />
                        )
                    }
                    {
                        step === STATION && (
                            <ValidateStationTab
                                stations={stations.filter(s => !!selectedStations.find(stationId => stationId === s.id))}
                                selectedStations={tmpSelectedStations}
                                codifications={paramEDILABO.codifications}
                                onChangeCodifications={newCodi => setParamEDILABO(p => ({ ...p, codifications: [...p.codifications.filter(c => c.stationId !== newCodi.stationId), newCodi] }))}
                                onChangeSelectedStation={setTmpSelectedStations}
                            />
                        )
                    }
                    {
                        step === PARAM && (
                            <ValidateParameterTab
                                parameters={selectedParameters.filter(({ parameterCode }) => indexedParameters[parameterCode]?.active).map(selectedParam => {
                                    const parameter = indexedParameters[selectedParam.parameterCode] ?? {}
                                    return {
                                        ...parameter,
                                        ...selectedParam,
                                        labelSearch: searchAllCharacters(parameter.code + parameter.name),
                                    }
                                })}
                                selectedParameters={tmpSelectedParameters}
                                parameterListCode={selection}
                                selections={campaign.selections}
                                onChangeSelection={(tmpSelection, tmpParameters) => {
                                    setTmpSelectedParameters(tmpParameters)
                                    setSelection(tmpSelection)
                                }}
                                onChangeSelectedParameter={setTmpSelectedParameters}
                            />
                        )
                    }
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item xs={1} />
                        <Grid item xs={6}>
                            <MobileStepper
                                variant='progress'
                                steps={nbSteps}
                                position='static'
                                activeStep={step}
                                sx={{ height: 10 }}
                                nextButton={
                                    <Button
                                        size='small'
                                        onClick={() => {
                                            setStep(s => s + 1)
                                        }}
                                        disabled={isNextDisabled}
                                        variant='outlined'
                                        sx={{
                                            '&:focus': {
                                                outline: 'none',
                                                backgroundColor: 'white',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgb(53, 96, 159, 0.04)',
                                            },
                                        }}
                                    >
                                        {i18n.next}
                                        <Icon size='small' icon='keyboard_arrow_right' />
                                    </Button>
                                }
                                backButton={
                                    <Button
                                        size='small'
                                        onClick={() => setStep(s => s - 1)}
                                        disabled={step === FORM}
                                        variant='outlined'
                                        sx={{
                                            '&:focus': {
                                                outline: 'none',
                                                backgroundColor: 'white',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgb(53, 96, 159, 0.04)',
                                            },
                                        }}
                                    >
                                        <Icon size='small' icon='keyboard_arrow_left' />
                                        {i18n.previous}
                                    </Button>
                                }
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={() => {
                                    if (isExportDataComplete) {
                                        onExport()
                                    }
                                    setConfirmModalOpen(true)
                                }}
                                disabled={step !== PARAM || !tmpSelectedParameters.length}
                                variant='contained'
                            >
                                {i18n.export}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                subTitle={i18n.someDatasAreIncompleteAndWillNotBeTakenIntoAccountInTheExport}
                onValidate={onExport}
                onClose={() => setConfirmModalOpen(false)}
            />
        </>
    )
}

PopupEDILABO.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    campaign: PropTypes.shape({
        id: PropTypes.number,
        beginningApplication: PropTypes.string,
        endingApplication: PropTypes.string,
        recipientCode: PropTypes.string,
        contributorCode: PropTypes.string,
        samplerCode: PropTypes.string,
        requestType: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.string),
    }),
    selectedStations: PropTypes.arrayOf(PropTypes.number),
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),

}


export default PopupEDILABO
