import Card from 'components/card/Card'
import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getStationArrowNav } from 'utils/ActionUtils'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import CatchmentForm from '../CatchmentForm'
import CatchmentPointsTable from '../CatchmentPointsTable'
import CatchmentAnnualEvolution from './CatchmentAnnualEvolution'
import { Grid2 } from '@mui/material'
import CatchmentFollowUpPluvio from './CatchmentFollowUpPluvio'
import { CardFollowUpNitrates } from 'quality/components/qualityComponents/FollowUpNitrates'
import useApplicationSetting, { listStringParser } from 'utils/customHook/useApplicationSetting'
import { getBeginingOfTheYear, getEndOfTheYear } from 'utils/DateUtil'
import { CardFollowUpPesticides } from 'quality/components/qualityComponents/FollowUpPesticides'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import CatchmentFollowUpHydro from './CatchmentFollowUpHydro'
import CatchmentFollowUpPiezo from './CatchmentFollowUpPiezo'
import QualityAction from 'quality/actions/QualityAction'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'

const CatchmentDashboardApp = () => {
    const dispatch = useDispatch()

    const {
        catchment,
        catchments,
        pluviometers,
        qualitometers,
        piezometers,
        hydrometricStations,

        thresholds,
        qualityThresholds,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        catchments: store.CatchmentReducer.catchments,
        pluviometers: store.PluviometryReducer.pluviometers,
        qualitometers: store.QualityReducer.qualitometersLight,
        piezometers: store.PiezometryReducer.piezometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,

        thresholds: store.QualityReducer.thresholds,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const startPeriod = useStartPeriod(getBeginingOfTheYear)
    const endPeriod = useEndPeriod(getEndOfTheYear)
    const nitratesThreshold = useApplicationSetting('CATCHMENT_NITRATES_THRESHOLD', setting => setting ? parseFloat(setting) : undefined)
    const excludeProducer = useApplicationSetting('CATCHMENT_PRODUCERS_EXCLUDED', setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])
    const pesticideThreshold1 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_1', setting => setting ? parseFloat(setting) : undefined)
    const pesticideThreshold2 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_2', setting => setting ? parseFloat(setting) : undefined)
    const specificThreshold = useApplicationSetting('CATCHMENT_SPECIFIC_THRESHOLD')

    const pesticides = useApplicationSetting('CATCHMENT_PESTICIDES', listStringParser)
    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)
    const agencies = useSandreList(SANDRE.AGENCES)

    const thresholdList = qualityThresholds.find(t => t.thresholdCode === specificThreshold)?.thresholds ?? []

    useEffect(() => {
        dispatch(QualityAction.fetchThresholds())
    }, [dispatch])

    useEffect(() => {
        if (specificThreshold && thresholds.length) {
            const parseId = parseInt(specificThreshold)
            const th = thresholds.find(t => t.code === parseId)
            if (th) {
                dispatch(QualityAction.fetchQualityThresholds([{ code: th.code, thresholdType: th.thresholdType }]))
            }
        }
    }, [dispatch, specificThreshold, thresholds])

    useTitle(() => [{
        title: i18n.catchments,
        href: 'catchment',
    }, {
        title: catchment.code || i18n.unknown,
        href: `station/catchment/${catchment.id}`,
    }, {
        title: i18n.dashboard,
        href: `station/catchment/${catchment.id}`,
    }], [catchment])

    useActions(() => {
        const ref = agencies.find(a => a.code === catchment.pond)?.reference
        return {
            arrowNav: getStationArrowNav('catchment', catchments, catchment.id, s => dispatch(push(`/station/catchment/${s.id}`))),
            links: ref ? [{
                href: `http://www.deb.developpement-durable.gouv.fr/telechargements/fiche.php?IG=${ref}_${catchment.code}`,
                label: 'MTES',
            }] : [],
        }
    }, [catchments, catchment, agencies])

    const hydrometricStationsLinked = useMemo(() => {
        return catchment.catchmentPoints?.map(point => {
            return hydrometricStations.find(q => q.code === point.codeWithoutDesignation)
        }).filter(q => !!q) || []
    }, [catchment.catchmentPoints, hydrometricStations])

    const piezometersLinked = useMemo(() => {
        return catchment.catchmentPoints?.map(point => {
            return piezometers.find(q => q.code === point.codeWithoutDesignation)
        }).filter(q => !!q) || []
    }, [catchment.catchmentPoints, qualitometers])

    const pluviometersLinked = useMemo(() => {
        return catchment.catchmentPoints?.map(point => {
            return pluviometers.find(q => q.code === point.codeWithoutDesignation)
        }).filter(q => !!q) || []
    }, [catchment.catchmentPoints, pluviometers])

    const qualitometersLinked = useMemo(() => {
        return catchment.catchmentPoints?.map(point => {
            return qualitometers.find(q => q.code === point.codeWithoutDesignation)
        }).filter(q => !!q) || []
    }, [catchment.catchmentPoints, qualitometers])

    const qualitoIds = useMemo(() => qualitometersLinked.map(q => q.id), [qualitometersLinked])

    const defaultSelectedPoint = useMemo(() => {
        const mainPointFound = catchment.catchmentPoints?.find(({ mainPoint }) => mainPoint)
        const code = mainPointFound?.codeWithoutDesignation
        return mainPointFound && qualitometers.find(q => q.code === code)?.id
    }, [catchment.catchmentPoints, qualitometers])

    return (
        <Grid2 container style={{ padding: '5 5 100 5' }} spacing={1}>
            <Grid2 size={12}>
                <Card>
                    <CatchmentForm catchment={catchment} />
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <CatchmentPointsTable catchmentPoints={catchment.catchmentPoints} invertedHeaderStyle />
            </Grid2>
            {
                !!qualitometersLinked.length && (
                    <>
                        <Grid2 size={12}>
                            <CardFollowUpNitrates
                                stationIds={qualitoIds}
                                startPeriod={startPeriod}
                                endPeriod={endPeriod}
                                producerExcluded={excludeProducer}
                                nitratesThreshold={nitratesThreshold}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <CardFollowUpPesticides
                                stationIds={qualitoIds}
                                defaultSelectedStation={defaultSelectedPoint}
                                startPeriod={startPeriod}
                                endPeriod={endPeriod}
                                producerExcluded={excludeProducer}
                                threshold1={pesticideThreshold1}
                                threshold2={pesticideThreshold2}
                                specificThresholds={thresholdList}
                                pesticides={pesticides}
                                listSumPesticides={listSumPesticides}
                            />
                        </Grid2>
                    </>
                )
            }
            {
                !!pluviometersLinked.length && (
                    <Grid2 size={12}>
                        <CatchmentFollowUpPluvio
                            pluviometersLinked={pluviometersLinked}
                        />
                    </Grid2>
                )
            }
            {
                !!hydrometricStationsLinked.length && (
                    <Grid2 size={12}>
                        <CatchmentFollowUpHydro
                            hydrometricStationsLinked={hydrometricStationsLinked}
                        />
                    </Grid2>
                )
            }
            {
                !!piezometersLinked.length && (
                    <Grid2 size={12}>
                        <CatchmentFollowUpPiezo
                            piezometersLinked={piezometersLinked}
                        />
                    </Grid2>
                )
            }
            {
                !!qualitometersLinked.length && (
                    <Grid2 size={12}>
                        <CatchmentAnnualEvolution />
                    </Grid2>
                )
            }
        </Grid2>
    )
}

export default CatchmentDashboardApp