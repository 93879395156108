import { createTheme } from '@mui/material'
import { frFR, enUS } from '@mui/material/locale'
import { darkBlue, greyBlue, lightBlue, mainBlue, secondaryBlue } from './ColorTheme'
import { getTextColorByBackground } from 'utils/ColorUtil'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const MAIN_BOX_SHADOW = '0px 2px 16px 0px rgb(0 0 0 / 28%)'
const MAIN_RADIUS = '4px'
const LARGE_RADIUS = '10px'

const ELLIPSIS_TEXT = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}

const Theme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
            grey: greyBlue,
            light: lightBlue,
        },
        secondary: {
            main: secondaryBlue,
        },
        third: {
            main: darkBlue,
        },
    },
    checkbox: {},
    svgIcon: {},
    components: {
        MuiCardContent: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    padding: ownerState.noPadding ? '0' : '10',
                    margin: '0',
                    ['&:last-child']: {
                        paddingBottom: ownerState.noPadding ? '0' : '10',
                    },
                }),
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    return ({
                        padding: '5 10 5 20',
                        minHeight: 40,
                        height: 40,
                        borderBottom: '1px solid #CCC',
                        backgroundColor: ownerState.color ?? '#FFF',
                        color: getTextColorByBackground(ownerState.color ?? '#FFF'),
                        // fontWeight: ownerState.disableTypography ? undefined : 'bold',
                        // fontSize: ownerState.disableTypography ? undefined : '1.4rem',
                    })
                },
            },
        },
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                TransitionProps: { unmountOnExit: true, timeout: 250 },
            },
            styleOverrides: {
                root: {
                    borderRadius: '5px',
                },
            },
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: (
                    // eslint-disable-next-line react/react-in-jsx-scope
                    <ExpandMoreIcon sx={{ fontSize: '30px', color: 'white', borderRadius: '50%', border: 'solid white 2px' }}/>
                ),
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    color: 'white',
                    backgroundColor: mainBlue,
                    minHeight: 40,
                    height: 40,
                    fontSize: '1.3rem',
                    fontWeight: 'bold',
                    borderRadius: ownerState.expanded ? '5px 5px 0 0' : '5px',
                    '&.Mui-focusVisible': {
                        backgroundColor: mainBlue,
                    },
                }),
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderRadius: '0 0 5px 5px',
                    padding: '5 10',
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: greyBlue,
                    color: 'white',
                    borderBottom: 'solid 1px grey',
                    fontSize: '20px',
                    lineHeight: '20px',
                    '&+.MuiDialogContent-root': {
                        paddingTop: '10px !important',
                    },
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTop: 'solid 1px grey',
                    padding: '8px 18px',
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: 'visible',
                    overflowY: 'hidden',
                },
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
                'aria-label': 'table',
            },
        },
        // MuiTableRow: {
        //     styleOverrides: {
        //         root: {
        //             '&:last-child td, &:last-child th': { border: 0 },
        //         },
        //     },
        // },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    borderColor: 'black',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    cursor: 'pointer',
                    textWrap: 'nowrap',
                },
                body: {
                    fontSize: '13px',
                    fontWeight: 'normal',
                    padding: '1 16',
                    backgroundColor: 'white',
                    textWrap: 'nowrap',
                },
                paddingNone: {
                    padding: '1 0',
                },
            },
            variants: [
                {
                    props: { variant: 'bodyIcon' },
                    style: {
                        padding: '1',
                        width: '32px',
                        cursor: 'pointer',
                    },
                },
                {
                    props: { variant: 'headerIcon' },
                    style: {
                        borderColor: 'black',
                        cursor: 'pointer',
                        paddingRight: '16px',
                    },
                },
            ],
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    padding: '0 20',
                    ['&:last-child']: {
                        padding: '0 20',
                    },
                },
                selectLabel: {
                    fontSize: '1.1rem',
                },
                select: {
                    fontSize: '1.1rem',
                },
                selectIcon: {
                    fontSize: '2rem',
                },
                displayedRows: {
                    fontSize: '1.1rem',
                },
                actions: {
                    '& button': {
                        '&:focus': {
                            backgroundColor: 'white',
                        },
                    },
                    '& svg': {
                        fontSize: '2rem',
                    },
                },
            },
        },
    },
}, navigator.language === 'fr-FR' ? frFR : enUS)

export { Theme, MAIN_BOX_SHADOW, MAIN_RADIUS, ELLIPSIS_TEXT, LARGE_RADIUS }