import { Grid } from '@mui/material'
import { WhiteCard } from 'components/styled/Card'
import React, { useEffect, useMemo } from 'react'
import i18n from 'simple-react-i18n'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import OperationAction from '../operation/actions/OperationAction'
import { countBy, minBy, range, round } from 'lodash'
import { getColorCircleElement, getColorFromPalette, getRGBColor } from 'utils/ColorUtil'
import { lightGrey } from 'utils/constants/ColorTheme'
import { getQualificationColor, getStatusColor } from 'utils/AnalyseUtils'
import { getBeginingOfTheYear, getEndOfTheYear, getYear } from 'utils/DateUtil'
import moment from 'moment'
import { push } from '@lagunovsky/redux-react-router'
import SmallPie from 'components/echart/SmallPie'
import useFetch from 'utils/customHook/useFetch'
import ApplicationConf from 'conf/ApplicationConf'
import useListIndexed from 'utils/customHook/useListIndexed'
import { INDICES_RANGE } from 'quality/constants/HydrobioConstant'
import PropTypes from 'prop-types'
import Icon from 'components/icon/Icon'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import useApplicationSetting, { listStringParser } from 'utils/customHook/useApplicationSetting'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { SimpleFilterField, SimpleFilterFunction } from 'components/datatable/SimpleSelectionTable'
import useBoolean from 'utils/customHook/useBoolean'
import { searchAllCharacters } from 'utils/StringUtil'

const LIST_INDEXES = ['5910', '7613', '5856', '2928', '7036', '7939', '1022', '8058', '8049', '7614', '6951', '5909', '5908', '5911', '5912', '8973', '8969', '1000', '1017', '9025', '8562', '8040', '8053']
const DEFAULT_INDEXES = ['5910', '7613', '5856', '2928', '7036', '7939']

const IndiceGraph = ({
    indices = [],
}) => {
    const {
        parameters,
    } = useSelector(store =>({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    const formattedIndicators = indices.map(i => {
        const indiceRange = INDICES_RANGE.find(ind => ind.code === `${i.parameter}`)
        return {
            name: indexedParameters[i.parameter]?.displayName ?? `${i.parameter}`,
            max: indiceRange?.max ?? 20,
            min: indiceRange?.min ?? 0,
        }
    })

    const options = {
        tooltip: {},
        radar: {
            shape: 'circle',
            radius: '110',
            name: {
                textStyle: {
                    color: '#fff',
                    backgroundColor: '#999',
                    borderRadius: 3,
                    padding: [3, 5],
                },
            },
            indicator: formattedIndicators,
        },
        series: [{
            name: i18n.hydrobioIndices,
            type: 'radar',
            areaStyle: { normal: {} },
            data: [
                {
                    value: indices.map(i => i.result),
                },
            ],
        }],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge
            lazyUpdate
            style={{ height: 300 }}
        />
    )
}

IndiceGraph.propTypes = {
    indices: PropTypes.arrayOf(PropTypes.shape({})),
}

const OperationsGraph = ({

}) => {
    const dispatch = useDispatch()
    const {
        hydrobioOperations,
        qualitometer,
    } = useSelector(store => ({
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        qualitometer: store.QualityReducer.qualitometer,
    }), shallowEqual)

    const years = range(getYear(minBy(hydrobioOperations, 'date')?.date), moment().year() + 1, 1)

    const numberByYear = countBy(hydrobioOperations, o => getYear(o.date))
    const data = years.map(year => numberByYear[year] || 0)

    const associatedStations = []
    const options = {
        series: [{
            type: 'bar',
            name: `${qualitometer.code || ''} ${i18n.operations}`,
            color: getColorFromPalette(0),
            data,
        },
        ...associatedStations,
        ],
        xAxis: [{
            type: 'category',
            data: years,
        }],
        yAxis: [{
            type: 'value',
            minInterval: 1,
            showSplitLine: true,
        }],
        grid: {
            top: '15%',
            left: '2%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
            height: 150,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                animation: false,
                label: {
                    backgroundColor: '#505765',
                },
            },
        },
    }

    return (
        <>
            <h6 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>{i18n.operationsHistory}</h6>
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{ height: 230 }}
                onEvents={{
                    click: ({ name: year }) => {
                        const startDate = getBeginingOfTheYear(year)
                        const endDate = getEndOfTheYear(year)
                        dispatch(push(`/station/quality/${qualitometer.id}/hydrobioMonitoring?startDate=${startDate}&endDate=${endDate}`))
                    },
                }}
            />
        </>
    )
}

const SituationHydrobioPanel = ({

}) => {
    const dispatch = useDispatch()
    const {
        hydrobioOperations,
        qualitometer,
        qualifications,
        statusProps,
        accountUser,
        parameters,
    } = useSelector(store => ({
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        qualitometer: store.QualityReducer.qualitometer,
        qualifications: store.QualityReducer.qualifications,
        statusProps: store.QualityReducer.status,
        accountUser: store.AccountReducer.accountUser,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const selectedIndexes = useApplicationSetting('SELECTED_INDEXES', listStringParser)

    const {
        value: isAdminOpen,
        setTrue: openAdmin,
        setFalse: closeAdmin,
    } = useBoolean(false)


    const indexes = useMemo(() => {
        const filteredIndexes = parameters.filter(p => LIST_INDEXES.includes(p.code))
        return filteredIndexes.map(i => ({
            id: i.code,
            code: i.code,
            name: i.name,
            labelSearch: searchAllCharacters(`${i.name}#${i.code}`),
        }))
    }, [parameters])

    const opts = useMemo(() => {
        return {
            method: 'POST',
            body: {
                stations: [qualitometer.id],
                parameters: selectedIndexes.length ? selectedIndexes : DEFAULT_INDEXES,
            },
        }
    }, [qualitometer.id, selectedIndexes])

    const {
        data: indices = [],
    } = useFetch(ApplicationConf.qualitometer.lastYearIndices(), opts)

    useEffect(() => {
        if (!hydrobioOperations.length) {
            dispatch(OperationAction.fetchQualitometerHydrobioOperations(qualitometer.id))
        }
    }, [dispatch, hydrobioOperations.length, qualitometer.id])

    const countQualification = countBy(hydrobioOperations, 'qualification')
    const dataQualifications = Object.keys(countQualification).map(key => {
        const idQualification = parseInt(key)
        const qualification = qualifications.find(q => q.code === idQualification)
        const name = qualification?.name || i18n.notDefined

        const percentage = hydrobioOperations.length ? `${round(countQualification[key] * 100 / hydrobioOperations.length)}%` : '0%'

        const color = qualification ? getRGBColor(getQualificationColor(key)) : lightGrey

        return {
            value: countQualification[key],
            name,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n.qualification} (${percentage})</br>
                        ${getColorCircleElement(params.color)}${name}: ${params.value} ${i18n.of} ${hydrobioOperations.length} ${i18n.operations}`
                },
            },
        }
    })

    const countStatus = countBy(hydrobioOperations, 'status')
    const dataStatus = Object.keys(countStatus).map(key => {
        const idStatus = parseInt(key)
        const status = statusProps.find(q => q.code === idStatus)
        const name = status?.name || i18n.notDefined

        const percentage = hydrobioOperations.length ? `${round(countStatus[key] * 100 / hydrobioOperations.length)}%` : '0%'

        const color = status ? getRGBColor(getStatusColor(key)) : lightGrey

        return {
            value: countStatus[key],
            name,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n.status} (${percentage})</br>
                        ${getColorCircleElement(params.color)}${name}: ${params.value} ${i18n.of} ${hydrobioOperations.length} ${i18n.operations}`
                },
            },
        }
    })

    const actions = accountUser.isAdmin === '1' ? [{ onClick: openAdmin, iconName: 'settings', color: 'black' }] : []

    return !!hydrobioOperations.length && (
        <WhiteCard
            round
            title={i18n.hydrobioSituation}
            actions={actions}
        >
            <Grid container>
                <Grid container item xs={12}>
                    <Grid item xs={10}>
                        {indices.length !== 0 && <IndiceGraph indices={indices} />}
                        {indices.length === 0 && (
                            <Grid className='text-align-center' style={{ fontSize: 14.3, color: 'black', verticalAlign: 'middle' }}>
                                <Icon style={{ fontSize: 50 }}>cloud_off</Icon>
                                <Grid>{i18n.noResults}</Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <SmallPie
                            name={i18n.qualifications}
                            data={dataQualifications}
                        />
                        <SmallPie
                            name={i18n.status}
                            data={dataStatus}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <OperationsGraph />
                </Grid>
            </Grid>
            <SelectionTableModal
                isOpen={isAdminOpen}
                onClose={closeAdmin}
                onValidate={list => {
                    const newApplicationSetting = [
                        { parameter: 'SELECTED_INDEXES', value: list.join(',') },
                    ]
                    dispatch(AdministrationAction.updateSieauParameters(newApplicationSetting)).finally(closeAdmin)
                }}
                title={i18n.selectIndexesToAdd}

                listData={indexes}
                defaultSelectionList={selectedIndexes}

                listHeaders={['code', 'name']}
                listTitle={i18n.nonSelectedIndexes}
                selectionListTitle={i18n.selectedIndexes}
                maxHeightTable={'53vh'}

                filterField={SimpleFilterField}
                filterFunction={SimpleFilterFunction}
            />
        </WhiteCard>
    )
}

export default SituationHydrobioPanel