/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Step, StepLabel, Stepper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Icon from 'components/icon/Icon'
import { hasBooleanValue, hasValue } from 'utils/NumberUtil'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { ButtonMUI } from 'components/styled/Buttons'

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '15px',
        fontWeight: 'bold',
        minHeight: 40,
        maxHeight: 40,
        '&.Mui-expanded': {
            minHeight: 45,
            maxHeight: 45,
        },
    },
    label: {
        '&.MuiStepLabel-alternativeLabel': {
            marginTop: '3px !important',
        },
    },
}))

const StepperDialog = ({
    steps = [],
    open = false,
    defaultStep = 0,
    title = '',
    closeDialog = () => {},
    children = () => {},
    contentStyle = {},
    renderDeleteButton = () => {},
    renderSaveButton = () => {},
    sx = {},
    maxWidth = 'lg',
    moreSaveButtons = false,
    bypassValidateSteps = false,
}) => {
    const classes = useStyles()

    const [step, setStep] = useState(defaultStep)
    const [validateSteps, setValidateSteps] = useState(bypassValidateSteps ? steps?.map(s => s.constant) : [])

    const isLastStep = () => step === steps[steps.length-1]?.constant

    const nextStep = () => {
        if (!bypassValidateSteps) {
            setValidateSteps([ ...validateSteps, step ])
        }

        setStep(s => steps.find(st => st.constant === s)?.nextStep ?? s + 1)
    }

    const resetStep = () => {
        if (!bypassValidateSteps) {
            setValidateSteps([])
        }

        setStep(0)
    }

    useUpdateEffect(resetStep, [open])

    const filteredSteps = steps.filter(s => !s.hidden)
    const activeStep = filteredSteps.findIndex(s => s.constant === step)

    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={open}
            PaperProps={{
                sx: {
                    minHeight: '90vh',
                    maxHeight: '90vh',
                    overflowY: 'hidden',
                    ...sx,
                },
            }}
        >
            <DialogTitle style={{ backgroundColor: '#4f88b5', color: 'white', margin: '0', padding: '0', borderBottom: 'solid 1px grey' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '5 20' }}>
                    <Grid item >
                        <h5>{title}</h5>
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={() => closeDialog(resetStep)} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' style={{ backgroundColor: 'white', paddingTop: '5px' }}>
                    <Grid item xs={11}>
                        <Stepper
                            alternativeLabel
                            activeStep={activeStep}
                            style={{ zoom: '2' }}
                        >
                            {filteredSteps.map((s, idx) => (
                                <Step
                                    key={idx}
                                    className='clickable'
                                    onClick={() => validateSteps.includes(s.constant) && setStep(s.constant)}
                                >
                                    <StepLabel classes={classes}>{s.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ backgroundColor: '#f0f0f0', padding: '3rem', ...contentStyle }}>
                {children(step, nextStep, resetStep)}
            </DialogContent>
            <DialogActions style={{ borderTop: 'solid 1px grey' }}>
                <Grid container justifyContent='space-between'>
                    <Grid container item xs={moreSaveButtons ? 4 : 3} justifyContent='flex-start'>
                        {renderDeleteButton(step)}
                    </Grid>
                    <Grid container item xs={moreSaveButtons ? 2 : 3} justifyContent='center'>
                        <ButtonMUI
                            onClick={() => {
                                setStep(s => steps.find(st => st.constant === s)?.previousStep ?? s - 1)
                            }}
                            disabled={step === 0 || steps[step].canGoBack === false}
                            variant='outlined'
                            color='primary'
                        >
                            {i18n.previous}
                        </ButtonMUI>
                    </Grid>
                    <Grid container item xs={moreSaveButtons ? 2 : 3} justifyContent='center'>
                        <ButtonMUI
                            onClick={() => {
                                if (hasValue(steps[step].onNext)) {
                                    steps[step].onNext(nextStep)
                                } else {
                                    nextStep()
                                }
                            }}
                            disabled={hasBooleanValue(steps[step].nextAvailable) ? !steps[step].nextAvailable : isLastStep()}
                            variant='outlined'
                            color='primary'
                        >
                            {steps[step].nextLabel || i18n.next}
                        </ButtonMUI>
                    </Grid>
                    <Grid container item xs={moreSaveButtons ? 4 : 3} justifyContent='flex-end'>
                        {renderSaveButton(step, resetStep)}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

StepperDialog.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        constant: PropTypes.number,
        nextAvailable: PropTypes.bool,
        onNext: PropTypes.func,
        nextStep: PropTypes.number,
        previousStep: PropTypes.number,
    })).isRequired,
    closeDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    defaultStep: PropTypes.number,
    title: PropTypes.string,
    children: PropTypes.func.isRequired,
    contentStyle: PropTypes.object,
    renderDeleteButton: PropTypes.func,
    renderSaveButton: PropTypes.func,
    sx: PropTypes.object,
    maxWidth: PropTypes.string,
    moreSaveButtons: PropTypes.bool,
    bypassValidateSteps: PropTypes.bool,
}

export default StepperDialog
