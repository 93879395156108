import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import i18n from 'simple-react-i18n'
import {
    H_CAMPAIGN_QUALITO_DASHBOARD,
    H_CAMPAIGN_QUALITO_TRACKING,
} from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import ParameterAction from '../../../referencial/components/parameter/actions/ParameterAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import QualityAction from '../../actions/QualityAction'

const QualityCampaignApp = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    useEffect(() => {
        dispatch(ParameterAction.fetchParameters())
        dispatch(QualityAction.fetchQualitometersLight())
        dispatch(ContributorAction.fetchContributors())
        dispatch(CityAction.fetchCities())
        dispatch(ParameterAction.fetchSelectionsWithParam())
        dispatch(ReferencialAction.fetchSandreCodes())

        return () => {
            dispatch(CampaignAction.resetCampaign())
        }
    }, [])

    useEffect(() => {
        if (id !== 'new' && id !== 'duplicate') {
            dispatch(CampaignAction.fetchCampaign('quality', id))
            dispatch(CampaignAction.fetchCampaignStation('quality', id))
            dispatch(CampaignAction.fetchCampaignProgress('quality', id))
            dispatch(CampaignAction.fetchCampaignParameters(id))
            dispatch(CampaignAction.fetchCampaignPlannings(id))
            dispatch(CampaignAction.fetchEDILABOFile(id))
        }
    }, [id])

    const navLinks = useMemo(() => {
        return [
            {
                href: `/quality/campaign/${id}/dashboard`,
                icons: 'dashboard',
                name: i18n.dashboard,
                habilitation: H_CAMPAIGN_QUALITO_DASHBOARD,
            },
            {
                href: `/quality/campaign/${id}/tracking`,
                icons: 'date_range',
                name: i18n.tracking,
                habilitation: H_CAMPAIGN_QUALITO_TRACKING,
            },
            {
                href: `/quality/campaign/${id}/file`,
                icons: 'description',
                name: i18n.files,
                habilitation: H_CAMPAIGN_QUALITO_TRACKING,
            },
        ].filter(l => componentHasHabilitations(l.habilitation))
    }, [id])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Outlet />
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default QualityCampaignApp
