import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { styled } from '@mui/styles'
import iconInstallation from 'assets/pictures/markers/SVG/map_installation.svg'
import iconPiezo from 'assets/pictures/markers/SVG/map_piezo.svg'
import iconQualito from 'assets/pictures/markers/SVG/map_qualito.svg'
import iconPluvio from 'assets/pictures/markers/SVG/map_pluvio.svg'
import iconHydro from 'assets/pictures/markers/SVG/map_hydro.svg'
import Table from 'components/datatable/Table'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { exportFile } from 'utils/ExportDataUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import { DefaultDialogTitle } from 'components/styled/Dialog'

const getLocalisationFromStations = (qualito, piezo, inst, pluvio, hydro) => {
    if (hasValue(qualito?.x) && hasValue(qualito?.y) && hasValue(qualito?.projection)) {
        return {
            x: { value: qualito.x, cellType: 'number' },
            y: { value: qualito.y, cellType: 'number' },
            projection: { value: qualito.projection, cellType: 'number' },
        }
    }
    if (hasValue(piezo?.x) && hasValue(piezo?.y) && hasValue(piezo?.projection)) {
        return {
            x: { value: piezo.x, cellType: 'number' },
            y: { value: piezo.y, cellType: 'number' },
            projection: { value: piezo.projection, cellType: 'number' },
        }
    }
    if (hasValue(inst?.x) && hasValue(inst?.y) && hasValue(inst?.projection)) {
        return {
            x: { value: inst.x, cellType: 'number' },
            y: { value: inst.y, cellType: 'number' },
            projection: { value: inst.projection, cellType: 'number' },
        }
    }
    if (hasValue(pluvio?.x) && hasValue(pluvio?.y) && hasValue(pluvio?.projection)) {
        return {
            x: { value: pluvio.x, cellType: 'number' },
            y: { value: pluvio.y, cellType: 'number' },
            projection: { value: pluvio.projection, cellType: 'number' },
        }
    }
    if (hasValue(hydro?.x) && hasValue(hydro?.y) && hasValue(hydro?.projection)) {
        return {
            x: { value: hydro.x, cellType: 'number' },
            y: { value: hydro.y, cellType: 'number' },
            projection: { value: hydro.projection, cellType: 'number' },
        }
    }
    return {}
}

const getCityFromStations = (cities, qualito, piezo, inst, pluvio, hydro) => {
    if (qualito?.townCode) {
        return {
            cityCode: { value: qualito.townCode, cellType: 'right' },
            cityLabel: getLabel(cities, qualito.townCode, 'name'),
        }
    }
    if (piezo?.townCode) {
        return {
            cityCode: { value: piezo.townCode, cellType: 'right' },
            cityLabel: getLabel(cities, piezo.townCode, 'name'),
        }
    }
    if (inst?.townCode) {
        return {
            cityCode: { value: inst.townCode, cellType: 'right' },
            cityLabel: getLabel(cities, inst.townCode, 'name'),
        }
    }
    if (pluvio?.townCode) {
        return {
            cityCode: { value: pluvio.townCode, cellType: 'right' },
            cityLabel: getLabel(cities, pluvio.townCode, 'name'),
        }
    }
    if (hydro?.townCode) {
        return {
            cityCode: { value: hydro.townCode, cellType: 'right' },
            cityLabel: getLabel(cities, hydro.townCode, 'name'),
        }
    }
    return {}
}

const getCityLabelFromStation = (cities, qualito, piezo, inst, pluvio, hydro) => {
    if (qualito?.townCode) {
        return getLabel(cities, qualito.townCode, 'labelWithCode')
    }
    if (piezo?.townCode) {
        return getLabel(cities, piezo.townCode, 'labelWithCode')
    }
    if (inst?.townCode) {
        return getLabel(cities, inst.townCode, 'labelWithCode')
    }
    if (pluvio?.townCode) {
        return getLabel(cities, pluvio.townCode, 'labelWithCode')
    }
    if (hydro?.townCode) {
        return getLabel(cities, hydro.townCode, 'labelWithCode')
    }
    return ''
}

const Img = styled('img')({
    width: '18px',
    height: '20px',
    cursor: 'pointer',
    margin: '0px 3px 0px 3px',
})

const CatchmentPointsTable = ({
    catchmentPoints = [],
    setCatchment = () => { },
    readMode = true,
    invertedHeaderStyle = false,
}) => {
    const {
        contributors,
        catchment,
        piezometers,
        qualitometers,
        installations,
        pluviometers,
        hydrometricStations,
        cities,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        catchment: store.CatchmentReducer.catchment,
        piezometers: store.PiezometryReducer.piezometersLight,
        qualitometers: store.QualityReducer.qualitometersLight,
        installations: store.InstallationReducer.installationsLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)
    const [code, setCode] = useState('')
    const [owner, setOwner] = useState()
    const [mainPoint, setMainPoint] = useState(false)
    const [index, setIndex] = useState()

    const catchmentPointsFormated = catchmentPoints.map(point => {
        const piezo = piezometers.find(p => p.code === point.codeWithoutDesignation)
        const qualito = qualitometers.find(q => q.code === point.codeWithoutDesignation)
        const inst = installations.find(i => i.code === point.codeWithoutDesignation)
        const pluvio = pluviometers.find(p => p.code === point.codeWithoutDesignation)
        const hydro = hydrometricStations.find(h => h.code === point.codeWithoutDesignation)
        return {
            code: point.code,
            buildingOwner: getLabel(contributors, point.owner, 'labelDisplay', 'id'),
            mainPointLabel: point.mainPoint ? i18n.yes : i18n.no,
            city: getCityLabelFromStation(cities, qualito, piezo, inst, pluvio),
            nullValue: (
                <p style={{ textAlign: 'center', width: '100px' }}>
                    {!!piezo && <Img src={iconPiezo} onClick={() => dispatch(push(`/station/piezometry/${piezo.id}`))} {...sieauTooltip(i18n.piezometer, null, 'bottom')}/>}
                    {!!qualito && <Img src={iconQualito} onClick={() => dispatch(push(`/station/quality/${qualito.id}`))} {...sieauTooltip(i18n.qualitometer, null, 'bottom')}/>}
                    {!!inst && <Img src={iconInstallation} onClick={() => dispatch(push(`/station/installation/${inst.id}`))} {...sieauTooltip(i18n.installation, null, 'bottom')} />}
                    {!!pluvio && <Img src={iconPluvio} onClick={() => dispatch(push(`/station/pluviometry/${pluvio.id}`))} {...sieauTooltip(i18n.pluviometer, null, 'bottom')} />}
                    {!!hydro && <Img src={iconHydro} onClick={() => dispatch(push(`/station/hydrometry/${hydro.id}`))} {...sieauTooltip(i18n.hydrometricStation, null, 'bottom')} />}
                </p>
            ),
            owner: point.owner,
            mainPoint: point.mainPoint,
        }
    })

    const exportAction = {
        iconName: 'file_download',
        tooltip: i18n.export,
        color: invertedHeaderStyle ? 'black' : 'white',
        onClick: () => {
            const exportDataFormated = catchmentPoints.map(point => {
                const piezo = piezometers.find(p => p.code === point.codeWithoutDesignation)
                const qualito = qualitometers.find(q => q.code === point.codeWithoutDesignation)
                const inst = installations.find(i => i.code === point.codeWithoutDesignation)
                const pluvio = pluviometers.find(p => p.code === point.codeWithoutDesignation)
                const hydro = hydrometricStations.find(h => h.code === point.codeWithoutDesignation)
                return {
                    code: point.code,
                    ...getCityFromStations(cities, qualito, piezo, inst, pluvio),
                    buildingOwner: getLabel(contributors, point.owner, 'labelDisplay', 'id'),
                    mainPointLabel: point.mainPoint ? i18n.yes : i18n.no,
                    ...getLocalisationFromStations(qualito, piezo, inst, pluvio),
                    piezometer: piezo ? 'X' : '',
                    qualitometer: qualito ? 'X' : '',
                    installation: inst ? 'X' : '',
                    pluviometer: pluvio ? 'X' : '',
                    hydrometricStation: hydro ? 'X' : '',
                }
            })
            const exportData = exportDataFormated.length ? [{ ...exportDataFormated[0], headers: ['code', 'cityCode', 'cityLabel', 'buildingOwner', 'mainPointLabel', 'x', 'y', 'projection', 'piezometer', 'qualitometer', 'installation', 'pluviometer'] }, ...exportDataFormated.slice(1)] : []
            exportFile({
                data: exportData,
                exportType: 'xlsx',
                titleFile: `${catchment.code || catchment.name || catchment.aacNum || ''}_${i18n.catchmentPoints}`,
            })
        },
    }

    const actions = !readMode ? [exportAction, {
        iconName: 'note_add',
        tooltip: i18n.add,
        color: invertedHeaderStyle ? 'black' : 'white',
        onClick: () => setIsOpen(true),
        'data-cy': 'new_point',
    }] : [exportAction]

    const onClose = () => {
        setIsOpen(false)
        setCode('')
        setOwner(undefined)
        setMainPoint(false)
        setIndex(undefined)
    }

    const onValidate = () => {
        const newPoint = {
            code,
            owner,
            mainPoint,
        }
        if (hasValue(index)) {
            setCatchment(prev => ({ ...prev, catchmentPoints: [...catchmentPoints.slice(0, index), newPoint, ...catchmentPoints.slice(index + 1)] }))
        } else {
            setCatchment(prev => ({ ...prev, catchmentPoints: [...catchmentPoints, newPoint] }))
        }
        onClose()
    }

    return (
        <>
            <Table
                title={i18n.catchmentPoints}
                data={catchmentPointsFormated}
                paging
                actions={actions}
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers: ['code', 'city', 'buildingOwner', 'mainPointLabel', 'nullValue'] }}
                alterable={!readMode}
                onAlter={(p, i) => {
                    setCode(p.code)
                    setOwner(p.owner)
                    setMainPoint(p.mainPoint)
                    setIndex(i)
                    setIsOpen(true)
                }}
                deletable={!readMode}
                onDelete={(_, i) => setCatchment(prev => ({ ...prev, catchmentPoints: [...catchmentPoints.slice(0, i), ...catchmentPoints.slice(i + 1)] }))}
                condensed
                sortable
                id='catchment_points_table'
                invertedHeaderStyle={invertedHeaderStyle}
            />
            <Dialog
                maxWidth='lg'
                onClose={onClose}
                open={isOpen}
            >
                <DefaultDialogTitle
                    title={i18n.catchmentPoint}
                    onClose={onClose}
                />
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Grid2 container spacing={1} alignItems='center'>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.identifier}
                                        value={code}
                                        maxLength={25}
                                        onChange={setCode}
                                        data-cy='point_code'
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Select
                                        options={contributors}
                                        label={i18n.buildingOwner}
                                        nullLabel='&nbsp;'
                                        keyLabel='labelDisplay'
                                        value={owner}
                                        onChange={setOwner}
                                        data-cy='owner'
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Checkbox
                                        label={i18n.mainPointLabel}
                                        checked={mainPoint}
                                        onChange={setMainPoint}
                                        data-cy='main_point'
                                    />
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onValidate} variant='contained' data-cy='validate_point'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

CatchmentPointsTable.propTypes = {
    catchmentPoints: PropTypes.arrayOf(PropTypes.shape({})),
    setCatchment: PropTypes.func,
    readMode: PropTypes.bool,
    invertedHeaderStyle: PropTypes.bool,
}

export default CatchmentPointsTable