/* eslint-disable max-nested-callbacks */
import { Button, Dialog, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material'
import DisplayedValue from 'components/forms/DisplayedValue'
import { push } from '@lagunovsky/redux-react-router'
import { groupBy, keys, orderBy, round } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import Icon from '../../../components/icon/Icon'
import { PATH_ALERT_FOLLOW, PATH_STATION } from '../../../home/constants/RouteConstants'
import { STATION_TYPE_NAME, STATION_QUALITOMETER_NAMES } from '../../../station/constants/StationConstants'
import { getDate, getMiniHour, getYearOrString } from '../../../utils/DateUtil'
import EventsTab from './tabPopin/EventsTab'
import ThresholdsTab from './tabPopin/ThresholdsTab'
import PiezometryExploitedPanel from '../../../piezometry/components/stationDashboard/PiezometryExploitedPanel'
import { MEASURE_COTE } from '../../../piezometry/constants/PiezometryConstants'
import { execByType, getLinks } from '../../../utils/StationUtils'
import HydroChartPanel from '../../../hydrometry/components/stationDashboard/HydroChartPanel'
import PluviometerChartPanel from '../../../pluviometry/components/stationDashboard/PluviometerChartPanel'
import VigilancePanel from '../../../home/components/vigilance/VigilancePanel'
import PrefectoralArrestPanel from '../../../home/components/prefectoralArrest/PrefectoralArrestPanel'
import { hasValue } from '../../../utils/NumberUtil'
import { getTextColorByBackground, getThresholdColorCode } from 'utils/ColorUtil'
import InstallationChartPanel from 'installation/components/installations/components/InstallationChartPanel'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import TideGaugeDetails from 'hydrometry/components/stationDashboard/TideGaugeDetails'
import { TIDE_GAUGE_TYPE } from 'hydrometry/constants/HydrometryConstants'
import ThresholdPreviList from './tabPopin/thresholdPrevi/ThresholdPreviList'
import LastObservationsPanel from 'hydrometry/components/observation/LastObservationsPanel'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import MUICampaignCard from 'campaign/components/MUICampaignCard'

const Campaign = ({
    selectedSiteCampaigns = [],
    selectedSite = {},
}) => {
    const groups = groupBy(selectedSiteCampaigns, c => getYearOrString(c.beginningApplication, i18n.thisYear))
    return orderBy(keys(groups), key => key, ['desc']).map((key, idx) => {
        const orderedCampaigns = orderBy(groups[key], ['isArchived', 'statut'], ['asc'])
        return (
            <AccordionMUI defaultExpanded round sx={{ marginTop: idx !== 0 && '0.5rem' }}>
                <AccordionSummaryMUI round>
                    <span className='bold'>
                        {i18n.campaigns} {key}
                    </span>
                </AccordionSummaryMUI>
                <AccordionDetailsMUI sx={{ padding: '0.5rem' }}>
                    {orderedCampaigns.map((campaign, cIdx) => (
                        <>
                            {cIdx !== 0 && (
                                <Grid item xs={12} sx={{ padding: '0.75rem 0' }}>
                                    <Divider />
                                </Grid>
                            )}
                            <MUICampaignCard
                                campaign={campaign}
                                stationType={selectedSite.typeName}
                                isNew
                            />
                        </>
                    ))}
                </AccordionDetailsMUI>
            </AccordionMUI>
        )
    })
}

Campaign.propTypes = {
    selectedSiteCampaigns: PropTypes.arrayOf(PropTypes.shape({})),
    selectedSite: PropTypes.arrayOf(PropTypes.shape({})),
}

const CardIps = ({
    station = {},
}) => {
    const {
        lastMeasure,
        color,
        markerIcon,
    } = station

    return (
        <div style={{ border: `3px solid ${color}`, borderRadius: '12px', padding: '3px', fontSize: '16px' }}>
            <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item xs={1.5}>
                    <img src={markerIcon?.startsWith('<svg') ? `data:image/svg+xml;utf8,${markerIcon}` : markerIcon} style={{ width: '40px', height: '50px' }} />
                </Grid>
                <Grid container item xs={10} sx={{ paddingRight: '0.2rem' }}>
                    {lastMeasure.map(lm => {
                        const lastMeasureDate = lm.measureDate || lm.date
                        return (
                            <Grid container item xs={12} justifyContent='space-between'>
                                <Grid item>
                                    {hasValue(lastMeasureDate) && `${getDate(lastMeasureDate)} - ${getMiniHour(lastMeasureDate)}`}
                                </Grid>
                                <Grid container item sx={{ width: 'fit-content' }}>
                                    <Grid item>
                                        {lm.title || ''}
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '0.5rem' }}>
                                        {hasValue(lm.value) && `${round(lm.value, 3)} ${lm.unit || 'm'}`}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </div>
    )
}

CardIps.propTypes = {
    station: PropTypes.shape({
        color: PropTypes.string,
        markerIcon: PropTypes.string,
        lastMeasure: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            measureDate: PropTypes.number,
            value: PropTypes.number,
        })),
    }),
}

const Threshold = ({
    threshold = {},
    lastMeasure = {},
    stationType,
}) => {
    const {
        piezometryDataTypes,
        hydrometryDataTypes,
        pluviometryDataTypes,
    } = useSelector(store => ({
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const {
        htmlColor,
        color,
        date,
        name,
        isOverrunThreshold,
        dataType,
    } = threshold

    const dataTypePiezo = [...getHardPiezoDataTypes(), ...piezometryDataTypes]
    const dataTypeHydro = [...getHardHydroDataTypes(), ...hydrometryDataTypes]

    const label = execByType(stationType, {
        piezometry: () => dataTypePiezo.find(h => h.id === parseInt(dataType))?.label || i18n.bruteChronic,
        hydrometry: () => dataTypeHydro.find(h => h.id === parseInt(dataType))?.label || i18n.height,
        pluviometry: () => pluviometryDataTypes.find(h => h.id === parseInt(dataType))?.label || i18n.rain,
        default: () => i18n.unknown,
    })

    const bgColor = htmlColor || getThresholdColorCode(color)

    return (
        <div className='padding-top-1 padding-bottom-1'>
            <div style={{ borderRadius: '5px', backgroundColor: bgColor, textAlign: 'center', padding: '5px', fontSize: '18px', color: getTextColorByBackground(bgColor) }}>
                {`${label} ${getDate(date)} ( ${hasValue(lastMeasure.value) ? round(lastMeasure.value, 3) : ''} ${isOverrunThreshold === '1' ? ' m >' : ' m <'} ${name})`}
            </div>
        </div>
    )
}

Threshold.propTypes = {
    threshold: PropTypes.shape({
        color: PropTypes.number,
        date: PropTypes.number,
        name: PropTypes.string,
        isOverrunThreshold: PropTypes.string,
    }),
    lastMeasure: PropTypes.shape({
        measureDate: PropTypes.number,
        value: PropTypes.number,
    }),
    stationType: PropTypes.string,
}

const AlertMapPopin = ({
    onClose = () => { },
    siteData = {},
    onReload = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        accountUser,
        citiesIndex,
        hydrogeologicalEntities,
        stationTypes,
        stationWatermass,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        citiesIndex: store.CityReducer.citiesIndex,
        hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
        stationTypes: store.ReferencialReducer.stationTypes,
        stationWatermass: store.StationReducer.stationWatermass,
    }), shallowEqual)

    const [selectedSite, setSelectedSite] = useState({})
    const [selectedSiteThreshold, setSelectedSiteThreshold] = useState([])
    const [selectedSiteEvents, setSelectedSiteEvents] = useState([])
    const [selectedSiteCampaigns, setSelectedSiteCampaigns] = useState([])

    const [links, setLinks] = useState([])
    const [linksAnchorEl, setLinksAnchorEl] = useState()

    useEffect(() => {
        setSelectedSite(siteData.selectedSite)
        setSelectedSiteThreshold(siteData.selectedSiteThreshold)
        setSelectedSiteEvents(siteData.selectedSiteEvents?.filter(e => e.eventType !== 'T'))
        setSelectedSiteCampaigns(siteData.selectedSiteCampaigns)
    }, [siteData])

    useEffect(() => {
        getLinks(siteData.selectedSite, {
            hydrogeologicalEntities,
            stationTypes: [siteData.selectedSite.typeName] || stationTypes,
            installation: siteData.selectedSite,
            stationWatermass,
        }).then(setLinks)
        if (siteData.selectedSite?.id && selectedSite.typeName === STATION_TYPE_NAME.hydrometry) {
            dispatch(HydrometryAction.fetchHydrometryObservations(siteData.selectedSite.id))
        }
        return () => {
            dispatch(HydrometryAction.resetHydrometryObservations())
        }
    }, [selectedSite.typeName, siteData.selectedSite])

    const closePopin = () => {
        setSelectedSite({})
        onClose()
    }

    const code = selectedSite.code && `[${selectedSite.code}]` ||''
    const name = selectedSite.name && ` ${selectedSite.name}` || ''
    const cityLabel = selectedSite.townCode && citiesIndex[selectedSite.townCode]?.labelWithCode || ''

    const chartPanel = execByType(selectedSite.typeName, {
        piezometry: () => <PiezometryExploitedPanel id={ selectedSite.id } noAdditionnalData displayCote={MEASURE_COTE.DEPTH} depthMode showTitle={false} useFromSituation />,
        hydrometry: () => <HydroChartPanel id={selectedSite.id} showTitle={false} isPopin />,
        pluviometry: () => <PluviometerChartPanel id={selectedSite.id}/>,
        installation: () => <InstallationChartPanel stationId={selectedSite.id} stationCode={selectedSite.code} graphHeight={window.innerHeight / 4} showTitle={false} inPopup />,
    })

    const getModuleFollowUpRoute = (m) => {
        switch (m) {
            case STATION_TYPE_NAME.piezometry:
                return 'piezometricFollowUp'
            case STATION_TYPE_NAME.hydrometry:
                return 'hydrometricFollowUp'
            case STATION_TYPE_NAME.pluviometry:
                return 'pluviometricFollowUp'
            case STATION_TYPE_NAME.installation:
                return 'dashboard'
            default:
                return ''
        }
    }

    const getColorNameInstTag = (threshold) => {
        if (!threshold) {
            return { name: i18n.monitoring, color: '#008000' }
        } else if (threshold.name?.length > 0) {
            return { name: threshold.name, color: threshold.color || 'black' }
        }
        return { name: i18n.unknownThreshold, color: '#B0B0B0' }
    }

    const getInstTag = (thresholds) => {
        const threshold = thresholds[0]
        const { color: backgroundColor, name: thresholdName } = getColorNameInstTag(threshold)
        return (
            <div
                style={{
                    backgroundColor,
                    color: getTextColorByBackground(backgroundColor),
                    borderRadius: '5px',
                    padding: '0.5rem 1rem',
                    margin: '10px 0',
                    fontSize: '16px',
                }}
            >
                {thresholdName}
            </div>
        )
    }

    const closeLinks = () => setLinksAnchorEl()

    return (
        <Dialog
            onClose={closePopin}
            fullWidth
            maxWidth='xl'
            open={!!selectedSite.id}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between'>
                    <Grid container item xs={10} alignItems='center'>
                        <Grid item>
                            <img src={selectedSite.markerIcon?.startsWith('<svg') ? `data:image/svg+xml;utf8,${selectedSite.markerIcon}` : selectedSite.markerIcon} className='left responsive-img margin-right-2' style={{ width: '32px', height: '40px' }}/>
                        </Grid>
                        <Grid item sx={{ marginLeft: '1rem' }}>
                            <span className='font-size-20 bold'>{`${code}${name}${cityLabel && `, ${cityLabel}` || ''}`}</span>
                        </Grid>
                        <Grid item xs={1} sx={{ marginLeft: '1rem' }}>
                            <Icon
                                fontSize='small'
                                style={{
                                    position: 'inherit',
                                    marginTop: 1,
                                    color: 'white',
                                }}
                                tooltip={(
                                    <Grid container sx={{ maxWidth: '20rem' }}>
                                        <Grid item xs={12}>
                                            <DisplayedValue
                                                label={i18n.creation}
                                                value={selectedSite.creationDate ? getDate(selectedSite.creationDate) : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DisplayedValue
                                                label={i18n.nature}
                                                value={selectedSite.natureLabel || (selectedSite.stationType ? STATION_QUALITOMETER_NAMES[parseInt(selectedSite.stationType)].name : '')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DisplayedValue
                                                label={i18n.localisation}
                                                value={selectedSite.address || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DisplayedValue
                                                label={i18n.comment}
                                                value={selectedSite.comment || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            >
                                info
                            </Icon>
                        </Grid>
                    </Grid>
                    <Grid container item xs={2} alignItems='center' justifyContent='flex-end'>
                        {!!links.length && (
                            <Grid item>
                                <Typography
                                    component='span'
                                    onClick={e => setLinksAnchorEl(e.currentTarget)}
                                    sx={{
                                        marginRight: selectedSite.jobExecutionId && '2rem',
                                        color: '#fff',
                                        cursor: 'pointer',
                                        lineHeight: 'normal',
                                        fontSize: '1.1rem',
                                    }}
                                >
                                    {i18n.links}
                                </Typography>
                                <Menu
                                    anchorEl={linksAnchorEl}
                                    open={Boolean(linksAnchorEl)}
                                    onClose={closeLinks}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    sx={{
                                        '& .MuiMenuItem-root': {
                                            padding: '0.75rem',
                                        },
                                    }}
                                >
                                    {links.map(link => (
                                        <MenuItem onClick={() => window.open(link.href, '_blank')} disabled={link.disable}>
                                            {!!link.img && (
                                                <img className='w-h-30' src={link.img} style={{ marginRight: '5px' }}/>
                                            )}
                                            <b>{link.label || ''}</b>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        )}
                        <Grid item>
                            {selectedSite.jobExecutionId && <Icon size='small' icon='wifi' tooltip={'Open Data'} />}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <div className='row no-margin scrollable-card' style={{ height: '70vh' }}>
                    <div className='row no-margin'>
                        <div className='col s9 padding-bottom-1 padding-top-1'>
                            <Card>
                                {chartPanel}
                            </Card>
                            {selectedSite.typeName !== STATION_TYPE_NAME.installation && (
                                <ThresholdsTab
                                    thresholds={selectedSiteThreshold}
                                    station={selectedSite}
                                    onReload={onReload}
                                    whiteCard
                                />
                            )}
                            <LastObservationsPanel />
                        </div>
                        <div className='col s3'>
                            <div className='padding-top-1 padding-bottom-1'>
                                {hasValue(selectedSite.id) && !!selectedSite.lastMeasure?.length && (
                                    <CardIps station={selectedSite} />
                                )}
                                {selectedSite.typeName !== STATION_TYPE_NAME.installation ? selectedSite.trendThreshold?.filter(threshold => selectedSite.lastMeasure?.some(lm => `${lm.dataType}` === threshold.dataType)).map(threshold => (
                                    <Threshold
                                        threshold={threshold}
                                        lastMeasure={selectedSite.lastMeasure?.find(lm => `${lm.dataType}` === threshold.dataType)}
                                        stationType={selectedSite.typeName}
                                    />
                                )) : getInstTag(selectedSite.thresholds)}
                                {selectedSite.typeName !== STATION_TYPE_NAME.installation ? (
                                    <ThresholdPreviList />
                                ) : null}
                            </div>
                            <PrefectoralArrestPanel
                                townCode={(selectedSite.townCode)}
                            />
                            <VigilancePanel
                                townCode={(selectedSite.townCode)}
                            />
                            {(selectedSite.typeName === STATION_TYPE_NAME.hydrometry && selectedSite.siteType === TIDE_GAUGE_TYPE) && (
                                <div className='margin-top-1'>
                                    <TideGaugeDetails stationId={selectedSite.id} mobile />
                                </div>
                            )}
                            {!!selectedSiteEvents.length && (
                                <div className='padding-top-1'>
                                    <EventsTab
                                        events={selectedSiteEvents}
                                        station={selectedSite}
                                        onReload={onReload}
                                        showTitle={false}
                                    />
                                </div>
                            )}
                            {
                                selectedSiteCampaigns.length > 0 && (
                                    <div className='padding-top-1'>
                                        <Campaign
                                            selectedSiteCampaigns={selectedSiteCampaigns}
                                            selectedSite={selectedSite}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </DialogContentMUI>
            <DialogActionsMUI>
                {selectedSite.typeName !== STATION_TYPE_NAME.installation ? (
                    <div style={{ marginRight: 'auto', marginLeft: '0px' }}>
                        <Button onClick={() => dispatch(push(`${PATH_ALERT_FOLLOW}/${selectedSite.code}/${selectedSite.typeName}`))} variant='contained'>
                            {i18n.overlayGraphic}
                        </Button>
                    </div>
                ) : ''}
                <Button onClick={closePopin} variant='outlined'>
                    {i18n.close}
                </Button>
                {accountUser.isAdmin === '1' && (
                    <Button onClick={() => dispatch(push(`${PATH_STATION}/${selectedSite.typeName}/${selectedSite.id}/${getModuleFollowUpRoute(selectedSite.typeName)}`))} variant='contained'>
                        {selectedSite.typeName === STATION_TYPE_NAME.installation ? i18n.accessToDashboard : i18n.accessToFollowUp}
                    </Button>
                )}
            </DialogActionsMUI>
        </Dialog>
    )
}

AlertMapPopin.propTypes = {
    siteData: PropTypes.shape({}),
    onClose: PropTypes.func,
    onReload: PropTypes.func,
}

export default AlertMapPopin