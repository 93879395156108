import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import StationAction from 'station/actions/StationAction'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import DtoObservatoryFollowData from 'station/dto/DtoObservatoryFollowData'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { countBy, groupBy, keys, uniqWith } from 'lodash'
import ProgressCard from 'components/card/ProgressCard'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import DtoObservatoryFollowResult from 'station/dto/DtoObservatoryFollowResult'
import { INDICATORS_COLORS, THRESHOLD_COLORS_CODES } from 'utils/constants/ColorTheme'
import UnitLinkedVolumes from './UnitLinkedVolumes'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { filterLinkedStations } from 'utils/StationUtils'

const UnitIndicatorsChart = ({
    indicators = [],
}) => {
    const groupedIndicators = {
        [i18n.notDetermined]: [],
        [i18n.normal]: [],
        [i18n.vigilance]: [],
        [i18n.crisis]: [],
        [i18n.noData]: [],
        ...groupBy(indicators, 'value'),
    }

    const getIndicatorColor = (key) => {
        if (key === i18n.notDetermined) {
            return INDICATORS_COLORS.BLACK
        } else if (key === i18n.crisis) {
            return INDICATORS_COLORS.RED
        } else if (key === i18n.vigilance) {
            return INDICATORS_COLORS.YELLOW
        } else if (key === i18n.noData) {
            return INDICATORS_COLORS.GREY
        }
        return INDICATORS_COLORS.BLUE
    }

    const formattedData = keys(groupedIndicators).map(key => {
        const data = groupedIndicators[key]
        return {
            value: data.length,
            name: `${data.length} ${key}`,
            itemStyle: {
                color: getIndicatorColor(key),
            },
        }
    })

    const formatTooltip = ({ marker, data: { name = '' } }) => `${marker} ${name}`

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: formatTooltip,
        },
        legend: {
            orient: 'vertical',
            top: 'center',
            right: '10%',
            itemWidth: 28,
            itemHeight: 17,
            textStyle: {
                width: 300,
                overflow: 'break',
                fontSize: 13,
                padding: [0, 0, 0, 10],
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['35%', '85%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                },
                labelLine: {
                    show: false,
                },
                data: formattedData,
                right: '55%',
            },
        ],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: '100%' }}
        />
    )
}

UnitIndicatorsChart.propTypes = {
    indicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowData)),
}

const UnitIndicatorsPanel = ({
    indicators = [],
    productionUnits = [],
}) => {
    const {
        linkedPiezosPrel,
        productionUnitsAssociations,
    } = useSelector(store => ({
        linkedPiezosPrel: store.ProductionUnitReducer.linkedPiezosPrel,
        productionUnitsAssociations: store.ProductionUnitReducer.productionUnitsAssociations,
    }), shallowEqual)

    const getFormattedIndicators = (unitIndicators) => {
        if (!unitIndicators.length) {
            return { value: i18n.notDetermined, color: INDICATORS_COLORS.BLACK }
        } else if (unitIndicators.some(d => [
            'red',
            'indianred',
            'darkmagenta',
            THRESHOLD_COLORS_CODES.RED.toLowerCase(),
            THRESHOLD_COLORS_CODES.INDIANRED.toLowerCase(),
            THRESHOLD_COLORS_CODES.DARK_MAGENTA.toLowerCase(),
        ].includes(d.color?.toLowerCase() || '') || (d?.value?.toLowerCase() || '')?.includes('alerte'))) {
            return { value: i18n.crisis, color: INDICATORS_COLORS.RED }
        } else if (unitIndicators.some(d => [
            'yellow',
            'lightyellow',
            'orange',
            THRESHOLD_COLORS_CODES.YELLOW.toLowerCase(),
            THRESHOLD_COLORS_CODES.LIGHT_YELLOW.toLowerCase(),
            THRESHOLD_COLORS_CODES.ORANGE.toLowerCase(),
        ].includes(d.color?.toLowerCase() || '') || (d?.value?.toLowerCase() || '')?.includes('vigilance'))) {
            return { value: i18n.vigilance, color: INDICATORS_COLORS.YELLOW }
        } else if (unitIndicators.find(d => ['grey', 'gray'].includes(d.color?.toLowerCase()))) {
            return { value: i18n.noData, color: INDICATORS_COLORS.GREY }
        }
        return { value: i18n.normal, color: INDICATORS_COLORS.BLUE }
    }

    const formattedUnits = useMemo(() => productionUnits.map(unit => {
        const unitIndicators = indicators.find(indic => indic.productionUnit === unit.name)?.data || []
        const filteredLinks = filterLinkedStations(productionUnitsAssociations).filter(s => s.code === unit.code)
        const uniqLinks = uniqWith(filteredLinks, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
        const linkedIndicators = uniqLinks.flatMap(link => indicators.find(indic => indic.id === link.stationLinkedId)?.data || [])

        return new DtoObservatoryFollowData(getFormattedIndicators([...unitIndicators, ...linkedIndicators]))
    }), [indicators, productionUnitsAssociations, productionUnits])

    const formattedUnitsCrisis = useMemo(() => {
        return productionUnits.flatMap(unit => {
            const unitIndicators = indicators.find(indic => indic.productionUnit === unit.name)?.data || []
            const filteredLinks = filterLinkedStations(productionUnitsAssociations).filter(s => s.code === unit.code)
            const uniqLinks = uniqWith(filteredLinks, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
            const linkedIndicators = uniqLinks.flatMap(link => indicators.find(indic => indic.id === link.stationLinkedId)?.data || [])

            return [...unitIndicators, ...linkedIndicators]
        }).filter(indic => {
            const value = (indic?.value || '')?.toLowerCase()
            return [
                'red',
                'indianred',
                'darkmagenta',
                THRESHOLD_COLORS_CODES.RED.toLowerCase(),
                THRESHOLD_COLORS_CODES.INDIANRED.toLowerCase(),
                THRESHOLD_COLORS_CODES.DARK_MAGENTA.toLowerCase(),
                'yellow',
                'lightyellow',
                'orange',
                THRESHOLD_COLORS_CODES.YELLOW.toLowerCase(),
                THRESHOLD_COLORS_CODES.LIGHT_YELLOW.toLowerCase(),
                THRESHOLD_COLORS_CODES.ORANGE.toLowerCase(),
            ].includes(indic?.color?.toLowerCase() || '') || value.includes('alerte') || value.includes('vigilance')
        })
    }, [productionUnits, indicators, productionUnitsAssociations])

    const groupedIndicatorsCrisis = countBy(formattedUnitsCrisis, d => d?.value.split(' : ')?.[0])

    return (
        <Grid container sx={{ height: '100%' }} justifyContent='space-around'>
            {!!formattedUnits.length && (
                <>
                    <Grid item xs={4.5}>
                        <StyledFieldSet style={{ height: '100%', maxWidth: '500px', margin: 0, padding: '0 1rem' }}>
                            <StyledLegend>{i18n.statuts}</StyledLegend>
                            <UnitIndicatorsChart indicators={formattedUnits} />
                        </StyledFieldSet>
                    </Grid>
                    {!!formattedUnitsCrisis.length && (
                        <Grid container item xs={2} alignItems='center' sx={{ fontWeight: '600' }}>
                            <StyledFieldSet style={{ width: '100%', height: '100%', margin: 0, padding: '0 1rem' }}>
                                <StyledLegend>{i18n.overruns}</StyledLegend>
                                <Grid container alignItems='center' sx={{ height: '100%' }}>
                                    {keys(groupedIndicatorsCrisis).map(key => (
                                        <Grid container>
                                            <Grid item xs={10}>
                                                {key}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {groupedIndicatorsCrisis[key]}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </StyledFieldSet>
                        </Grid>
                    )}
                    {!!linkedPiezosPrel.length && (
                        <Grid item xs={4.5}>
                            <StyledFieldSet style={{ height: '100%', maxWidth: '500px', margin: 0, padding: '0 1rem' }}>
                                <StyledLegend>{i18n.annualVolumesWithdrawn}</StyledLegend>
                                <UnitLinkedVolumes />
                            </StyledFieldSet>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    )
}

UnitIndicatorsPanel.propTypes = {
    indicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    productionUnits: PropTypes.arrayOf(PropTypes.shape({})),
}

const UnitStats = ({
    productionUnits = [],
}) => {
    const {
        productionUnitsAssociations,
    } = useSelector(store => ({
        productionUnitsAssociations: store.ProductionUnitReducer.productionUnitsAssociations,
    }), shallowEqual)

    const [indicators, setIndicators] = useState([])

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => {
        if (!productionUnits.length) {
            return []
        }
        const associatedPiezos = productionUnitsAssociations.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.piezometer).map(({ stationLinkedId }) => stationLinkedId)
        const associatedHydros = productionUnitsAssociations.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry).map(({ stationLinkedId }) => stationLinkedId)
        const associatedPluvios = productionUnitsAssociations.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.pluviometry).map(({ stationLinkedId }) => stationLinkedId)
        const associatedQualitos = productionUnitsAssociations.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.quality).map(({ stationLinkedId }) => stationLinkedId)

        return [
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedPiezos, STATION_TYPE_NAME.piezometer)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators])),
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedHydros, STATION_TYPE_NAME.hydrologicalStation)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators])),
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedPluvios, STATION_TYPE_NAME.pluviometer)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators])),
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedQualitos, STATION_TYPE_NAME.qualitometer)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators])),
            dispatch(ProductionUnitAction.fetchLinkedPiezosPrel(productionUnits.map(uaf => uaf.id))),
        ]
    }, [])

    return !isLoaded ? (
        <Grid container>
            <Grid item xs={12}>
                <ProgressCard progress={progress} withMessage />
            </Grid>
        </Grid>
    ) : (
        <UnitIndicatorsPanel
            indicators={indicators}
            productionUnits={productionUnits}
        />
    )
}

UnitStats.propTypes = {
    productionUnits: PropTypes.arrayOf(PropTypes.shape({})),
}

const ProductionUnitStats = ({
    fullWidth = false,
    productionUnits,
}) => {
    const {
        defaultProductionUnits,
    } = useSelector(store => ({
        defaultProductionUnits: store.ProductionUnitReducer.productionUnits,
    }), shallowEqual)

    const dispatch = useDispatch()

    const productionUnitsFiltered = productionUnits || defaultProductionUnits

    const { isLoaded } = useProgressDispatch(() => [
        dispatch(ProductionUnitAction.fetchAllProductionUnitsAssociations()),
    ], [])

    return !!isLoaded && (
        <Grid container>
            <Grid
                container
                item
                xs={fullWidth ? 12 : 10.5}
                justifyContent='space-between'
                alignItems='center'
                sx={{
                    backgroundColor: 'white',
                    padding: '1rem',
                    height: '15rem',
                    borderRadius: '5px',
                    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)',
                }}
            >
                <UnitStats productionUnits={productionUnitsFiltered} />
            </Grid>
        </Grid>
    )
}

ProductionUnitStats.propTypes = {
    fullWidth: PropTypes.bool,
    productionUnits: PropTypes.arrayOf(PropTypes.shape({})),
}

export default ProductionUnitStats