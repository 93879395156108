import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Table from '../../components/datatable/Table'
import { push } from '@lagunovsky/redux-react-router'
import { groupBy, isNil, isUndefined, maxBy, orderBy, uniqBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getLabel } from '../../utils/StoreUtils'
import ProgressBar from '../../components/progress/ProgressBar'
import {
    getLocalizationPicto,
    getStatusAndResult,
    calculateAverage,
    searchMaxAnalysis,
    filterValid,
    filterResult,
    filterQuantification,
    searchMinAnalysis,
    calculateThresholdResult,
    searchP90Analysis,
    searchP90Value,
    searchMinValue,
    searchMaxValue,
} from '../../utils/AnalyseUtils'
import { getDate } from '../../utils/DateUtil'
import { nbPerPageLabel } from '../../referencial/constants/ReferencialConstants'
import SampleModal from '../../quality/components/qualityResearch/SampleModal'
import { exportFile } from '../../utils/ExportDataUtil'
import Card from '../card/Card'
import Icon from '../icon/Icon'
import { getI18nTitleDataLength } from '../../utils/StringUtil'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import { sieauTooltip } from 'utils/FormUtils'
import { RESEARCH_TYPE } from 'quality/constants/QualityConstants'
import { darkBlue } from 'utils/constants/ColorTheme'
import useListIndexed from 'utils/customHook/useListIndexed'
import useBoolean from 'utils/customHook/useBoolean'
import PaginatedList from 'components/list/PaginatedList'
import { ParameterGraphModal } from 'quality/components/qualityComponents/ParameterGraph'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'

const analysisHeaders = ['labo', 'date', 'statusAndResult', 'unit', 'nullValue']
const indicesHeaders = ['date', 'statusAndResult', 'unit', 'nullValue']

const CollapsibleTable = ({
    displayAnalysis = {},
    analysis = [],
    researchType,
    isOnlyOneParameter = false,
    thresholds = [],

    openSampleModal = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        parameters,
        units,
        settings,
        contributors,
        qualifications,
        status,
        laboratories,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        settings: store.AdministrationReducer.settings,
        contributors: store.ContributorReducer.contributors,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        laboratories: store.ContributorReducer.laboratories,
    }), shallowEqual)

    const parametersIndexed = useListIndexed(parameters, 'code')
    const unitsIndexed = useListIndexed(units, 'code')
    const contributorsIndexed = useListIndexed(contributors, 'id')

    const {
        value: isOpen,
        setFalse: close,
        setTrue: open,
    } = useBoolean(false)

    const headers = useMemo(() => {
        return researchType === RESEARCH_TYPE.INDICES ? indicesHeaders : analysisHeaders
    }, [researchType])

    const formattedAnalysis = useMemo(() => {
        return analysis.map(a => {
            return {
                labo: { value: contributorsIndexed[a.labo]?.labelDisplay ?? '' },
                date: { value: getDate(a.analysisDate) },
                statusAndResult: { value: getStatusAndResult(a, a.value, a.color, true) },
                unit: { value: unitsIndexed[a.unit]?.symbol },
                nullValue: { value: getLocalizationPicto(a.localization) },
                id: a.id,
            }
        })
    }, [analysis, contributorsIndexed, unitsIndexed])

    const nbParameter = uniqBy(analysis, 'parameter').length

    const qualitometer = qualitometers.find(q => q.id === analysis[0]?.qualitometer)

    const validAnalysis = filterValid(filterResult(analysis))
    const maxAnalysis = searchMaxAnalysis(analysis)
    const averageValue = calculateAverage(analysis, settings)

    const nbAnalysis = analysis.length
    const nbQuantification = filterQuantification(validAnalysis).length

    const exportAnalysis = () => {
        const data = analysis.map(a => ({
            stationCode: qualitometer.code,
            stationName: qualitometer.name,
            date: getDate(a.analysisDate),
            parameterCode: { value: a.parameter, cellType: 'right' },
            parameter: getLabel(parameters, a.parameter),
            unitCode: { value: a.unit, cellType: 'right' },
            unit: getLabel(units, a.unit, 'symbol'),
            result: { value: a.value, color: a.color, cellType: 'right' },
            status: getLabel(status, a.status),
            qualification: getLabel(qualifications, a.qualification),
            labo: getLabel(laboratories, a.labo),
            x: { value: qualitometer.x, format: '0.0000000000', cellType: 'number' },
            y: { value: qualitometer.y, format: '0.0000000000', cellType: 'number' },
            projection: { value: qualitometer.projection, format: '0', cellType: 'number' },
        }))
        const dataWithHeaders = data.length ? [
            { ...data[0], headers: Object.keys(data[0]) },
            ...data.slice(1),
        ] : []
        exportFile({
            data: dataWithHeaders,
            exportType: 'xlsx',
            titleFile: `${qualitometer.code}_${qualitometer.name}`,
        })
    }

    return (
        <>
            <Accordion disableGutters TransitionProps={{ unmountOnExit: true, timeout: 250 }} style={{ borderRadius: '0px', borderTop: 'solid 1px #DDD' }}>
                <AccordionSummary
                    expandIcon={
                        <Icon
                            size='small'
                            icon='expand_more'
                            style={{
                                color: darkBlue,
                            }}
                        />
                    }
                >
                    <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
                        <Grid container item xs={3} direction='column'>
                            <span>{qualitometer?.code}</span>
                            <span>{qualitometer?.name}</span>
                        </Grid>
                        <Grid container item xs={2} direction='column'>
                            <span>
                                {getDate(displayAnalysis.analysisDate)}
                            </span>
                            <span className={displayAnalysis.color}>
                                {displayAnalysis.remark === '7' && i18n.trace || !isUndefined(displayAnalysis.value) && `${displayAnalysis.value} ${unitsIndexed[displayAnalysis.unit]?.symbol ?? ''}` || ''}
                            </span>
                        </Grid>
                        <Grid container item xs={4} direction='column'>
                            {isOnlyOneParameter && (
                                <>
                                    <span>
                                        {parametersIndexed[displayAnalysis.parameter]?.labelWithCode ?? ''}
                                    </span>
                                    <span>
                                        {i18n.max}: {`${maxAnalysis?.value ?? ''} ${unitsIndexed[maxAnalysis?.unit]?.symbol ?? ''}`}
                                    </span>
                                    <span>
                                        {i18n.average}: {!isNil(averageValue) && !isNaN(averageValue) ? `${averageValue} ${unitsIndexed[displayAnalysis.unit]?.symbol ?? ''}` : ''}
                                    </span>
                                </>
                            )
                            }
                            {!isOnlyOneParameter && (
                                <span>{`${nbParameter} ${nbParameter < 2 ? i18n.parameter : i18n.parameters}`}</span>
                            )
                            }
                        </Grid>
                        <Grid item xs={2}>
                            <ProgressBar
                                progress={nbAnalysis !== 0 ? nbQuantification * 100 / nbAnalysis : 0}
                                tooltip={`${nbQuantification} ${i18n.quantificationsRatio} ${nbAnalysis} ${i18n.results}`}
                            />
                        </Grid>
                        <Grid item xs={1} sx={{ paddingRight: '15px' }}>
                            <Icon
                                icon='info'
                                style={{
                                    color: darkBlue,
                                    fontSize: '1.5rem',
                                }}
                                onClick={e => {
                                    e.stopPropagation()
                                    dispatch(push(`/station/quality/${qualitometer?.id}`))
                                }}
                                tooltip={i18n.accessToTheFile}
                            />
                            <Icon
                                icon='file_download'
                                style={{
                                    color: darkBlue,
                                    fontSize: '1.5rem',
                                }}
                                onClick={e => {
                                    e.stopPropagation()
                                    exportAnalysis()
                                }}
                                tooltip={i18n.export}
                            />
                            <Icon
                                icon='insert_chart'
                                style={{
                                    color: darkBlue,
                                    fontSize: '1.5rem',
                                }}
                                onClick={e => {
                                    e.stopPropagation()
                                    open()
                                }}
                                tooltip={i18n.graph}
                            />
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                    <Table
                        showTitle={false}
                        condensed
                        sortable
                        data={formattedAnalysis}
                        color
                        paging
                        nbPerPageLabel={nbPerPageLabel}
                        onClick={({ id }) => openSampleModal(id)}
                        customHeaders={{
                            statusAndResult: `${formattedAnalysis.length} ${getI18nTitleDataLength(i18n.result, i18n.results, formattedAnalysis.length)}`,
                        }}
                        type={{ headers }}
                    />
                </AccordionDetails>
            </Accordion>
            <ParameterGraphModal
                isOpen={isOpen}
                closeGraph={close}
                analysis={analysis}
                thresholds={thresholds}
                hideActions={!isOnlyOneParameter}
            />
        </>
    )
}

CollapsibleTable.propTypes = {
    displayAnalysis: PropTypes.shape({
        // DtoAnalysisLight,
        // calculateThresholdResult,
    }),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis ou DtoAnalysisLight
        // calculateThresholdResult
    })),
    researchType: PropTypes.string,
    isOnlyOneParameter: PropTypes.bool,
    openSampleModal: PropTypes.func,
}

const CartographyAnalysisPanel = ({
    stationsPanelTitle = '',
    thresholds = [],
    analysis = [],
    researchType,
    sortingType,
}) => {
    const {
        settings,
        qualitometers,
        units,
        qualifications,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        qualitometers: store.QualityReducer.qualitometersLight,
        units: store.UnitReducer.units,
        qualifications: store.QualityReducer.qualifications,
    }), shallowEqual)

    const isOnlyOneParameter = useMemo(() => {
        const uniqParam = uniqBy(analysis, 'parameter')
        return uniqParam.length === 1
    }, [analysis])

    const {
        value: isOpen,
        setFalse: close,
        setTrue: open,
    } = useBoolean(false)
    const [selectedAnalysis, setSelectedAnalysis] = useState()

    const groupByStations = useMemo(() => {
        const orderedAnalysis = orderBy(analysis, 'analysisDate')
        return groupBy(orderedAnalysis, 'qualitometer')
    }, [analysis])
    const stationsIds = Object.keys(groupByStations).map(id => parseInt(id))

    const getDisplayedAnalysis = listAnalysis => {
        const validAnalysis = filterValid(filterResult(listAnalysis))
        if (sortingType === '1') {
            return searchMaxAnalysis(validAnalysis)
        } else if (sortingType === '2') {
            return searchMinAnalysis(validAnalysis)
        } else if (sortingType === '3') {
            const average = calculateAverage(validAnalysis, settings)
            const analyse = validAnalysis[0]
            const averageAnalyse = {
                result: average,
                parameter: analyse?.parameter,
                unit: analyse?.unit,
                fraction: analyse?.fraction,
                support: analyse?.support,
                qualitometer: analyse?.qualitometer,
            }
            return {
                ...averageAnalyse,
                ...calculateThresholdResult(averageAnalyse, thresholds),
            }
        } else if (sortingType === '4') {
            return searchP90Analysis(validAnalysis)
        }
        return maxBy(listAnalysis, 'analysisDate')
    }

    const analyse = useMemo(() => {
        return analysis.find(a => a.id === selectedAnalysis)
    }, [analysis, selectedAnalysis])

    const exportStations = () => {
        const data = stationsIds.map(id => {
            const qualitometer = qualitometers.find(q => q.id === id)
            const stationAnalysis = groupByStations[id]
            const displayAnalysis = getDisplayedAnalysis(stationAnalysis)

            const validAnalysis = filterValid(filterResult(stationAnalysis))

            const p90 = searchP90Value(validAnalysis)
            const min = searchMinValue(validAnalysis)
            const max = searchMaxValue(validAnalysis)
            const average = calculateAverage(validAnalysis, settings)

            return {
                stationCode: qualitometer.code,
                stationName: qualitometer.name,
                cityCode: { value: qualitometer.townCode, cellType: 'right' },
                lastAnalysis: { value: getDate(displayAnalysis.analysisDate), format: 'dd/MM/yyy', cellType: 'date' },
                result: { value: displayAnalysis.value, color: displayAnalysis.color, cellType: 'right' },
                min: { value: min, cellType: 'right' },
                max: { value: max, cellType: 'right' },
                average: { value: average, cellType: 'right' },
                percentile90: { value: p90, cellType: 'right' },
                codeUnit: { value: displayAnalysis.unit, cellType: 'right' },
                unit: getLabel(units, displayAnalysis.unit, 'symbol'),
                qualification: getLabel(qualifications, displayAnalysis.qualification),
                x: { value: qualitometer.x, format: '0.0000000000', cellType: 'number' },
                y: { value: qualitometer.y, format: '0.0000000000', cellType: 'number' },
                projection: { value: qualitometer.projection, format: '0', cellType: 'number' },
            }
        })
        const dataWithHeaders = data.length ? [
            { ...data[0], headers: Object.keys(data[0]) },
            ...data.slice(1),
        ] : []
        exportFile({
            data: dataWithHeaders,
            exportType: 'xlsx',
            titleFile: i18n.research,
        })
    }

    const actions = [{
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: exportStations,
    }]

    if (analysis.length === 0) {
        return (
            <h5 className='center-align'>{i18n.noAssociatedStations}</h5>
        )
    }

    return (
        <div>
            <Card title={`${stationsPanelTitle} - ${stationsIds.length} ${i18n.stations}`} actions={actions}>
                <Grid container>
                    <Grid container xs spacing={2} sx={{ padding: '0 16' }}>
                        <Grid item xs={3}>
                            <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                {i18n.stations}
                            </span>
                        </Grid>
                        <Grid item xs={2}>
                            <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                {i18n.date}
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            {
                                isOnlyOneParameter && (
                                    <span style={{ fontWeight: 'bold', fontSize: '17px' }} {...sieauTooltip(i18n.validatedStat)}>
                                        {i18n.statistics}
                                    </span>
                                )
                            }
                            {
                                !isOnlyOneParameter && (
                                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                        {i18n.parameter}
                                    </span>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <span style={{ fontWeight: 'bold', fontSize: '17px' }} {...sieauTooltip(i18n.validatedStat)}>
                                {i18n.quantifications}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid item xs='auto'>
                        <div style={{ width: '26px' }} />
                    </Grid>
                    <PaginatedList
                        nbElements={stationsIds.length}
                        nbElementsByPage={7}
                    >
                        {
                            (startId, endId) => {
                                const subList = stationsIds.slice(startId, endId)
                                return subList.map(id => {
                                    const stationAnalysis = groupByStations[id]
                                    const displayAnalysis = getDisplayedAnalysis(stationAnalysis)
                                    return (
                                        <Grid item xs={12} key={id}>
                                            <CollapsibleTable
                                                displayAnalysis={displayAnalysis}
                                                analysis={stationAnalysis}
                                                researchType={researchType}
                                                isOnlyOneParameter={isOnlyOneParameter}
                                                thresholds={thresholds}
                                                openSampleModal={analyseId => {
                                                    setSelectedAnalysis(analyseId)
                                                    open()
                                                }}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        }
                    </PaginatedList>
                </Grid>
            </Card>
            <SampleModal
                isOpen={isOpen}
                close={close}
                analyse={analyse}
            />
        </div>
    )
}

CartographyAnalysisPanel.propTypes = {
    stationsPanelTitle: PropTypes.string,
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis ou DtoAnalysisLight
        // calculateThresholdResult
    })),
    researchType: PropTypes.number,
    sortingType: PropTypes.string,
}

export default CartographyAnalysisPanel