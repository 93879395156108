/* eslint-disable max-nested-callbacks */
/* eslint-disable consistent-return */
import { Button, Grid } from '@mui/material'
import Card from 'components/card/Card'
import MultiGridTableV2 from 'components/datatable/virtualizedTable/MultiGridTableV2'
import SimpleTabList from 'components/list/SimpleTabList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { push } from '@lagunovsky/redux-react-router'
import { groupBy, maxBy, orderBy, uniq, uniqBy } from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import { exportFile, formatData } from 'utils/ExportDataUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import Icon from '../../../components/icon/Icon'
import CartographyPanel from '../../../components/map/CartographyPanel'
import ImportXmlQualityPopup from '../../../import/components/popup/ImportXmlQualityPopup'
import { enumerateBetweenDates, getDate } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { getMarkerIcon } from '../../utils/CampaignUtils'
import WaitAction from 'wait/WaitAction'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import ExportFileModal from 'components/modal/ExportFileModal'
import ExportAction from 'export/actions/ExportAction'
import { DATA_DISPLAY, USED_TIME, formatCell, formatHeaderDate, getColor, getMissingMonthAnalysis, getMissingPlanningAnalysis, getNbData, getProgressColor, getProgressMonth, getProgressPlanning } from 'campaign/utils/QualityTrackingUtils'
import { getLocalizationLabel } from 'utils/AnalyseUtils'

const moment = extendMoment(Moment)
moment.locale('fr')

const LIST = 'LIST'
const CARTO = 'CARTO'

const OperationAccordion = ({
    operation = {},
    analysis = [],
    parameters = [],
}) => {
    const dispatch = useDispatch()

    const {
        campaignParameters,
        parametersProps,
        qualitometers,
    } = useSelector(store => ({
        campaignParameters: store.CampaignReducer.campaignParameters,
        parametersProps: store.ParameterReducer.parameters,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const orderedParameters = useMemo(() => {
        return orderBy(parameters, 'code')
    }, [parameters])

    const accordionColor = useMemo(() => {
        const nbAnalysis = analysis.length
        if (nbAnalysis >= campaignParameters.length) {
            return 'green'
        } else if (nbAnalysis < campaignParameters.length && nbAnalysis !== 0) {
            return 'orange'
        } else if (nbAnalysis === 0) {
            return 'red'
        }
        return 'blue'
    }, [analysis.length, campaignParameters.length])

    return (
        <AccordionMUI defaultExpanded={false}>
            <AccordionSummaryMUI className={accordionColor}>
                <Grid container alignItems='center'>
                    <Grid container item xs={10}>
                        <Grid
                            item
                            onClick={e => {
                                e.stopPropagation()
                                dispatch(push(`/station/quality/${operation.qualitometer}/operation/${operation.id}`))
                            }}
                        >
                            {getDate(operation.date)}
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {`${analysis.length}/${campaignParameters.length}`}
                    </Grid>
                    <Grid item xs={1}>
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon='file_download'
                            onClick={e => {
                                e.stopPropagation()
                                const data = orderedParameters.map(({ code }) => {
                                    const isPresent = analysis.some(a => a.parameter === code)
                                    const parameter = parametersProps.find(p => p.code === code)
                                    return {
                                        codeParameter: code,
                                        parameter: parameter?.displayName || '',
                                        analyzedParameters: isPresent ? i18n.yes : i18n.no,
                                    }
                                })
                                const qualitometer = qualitometers.find(q => q.id === operation.qualitometer)
                                exportFile({
                                    data: data.length ? [
                                        { ...data[0], headers: ['codeParameter', 'parameter', 'analyzedParameters'] },
                                        ...data.slice(1),
                                    ] : [],
                                    exportType: 'xlsx',
                                    titleFile: `${qualitometer?.code || operation.qualitometer}_${i18n.operation}_${i18n.fromDate}_${getDate(operation.date)}`,
                                })
                            }}
                        />
                    </Grid>
                </Grid>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI style={{ padding: '3 10' }}>
                <Grid container columns={18}>
                    {
                        orderedParameters.map(({ code, label }) => {
                            const isPresent = analysis.some(a => a.parameter === code)
                            return (
                                <>
                                    <Grid item xs={8}>
                                        {label}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {
                                            isPresent && (
                                                <Icon
                                                    icon={'check'}
                                                    style={{ color: 'green' }}
                                                />
                                            )
                                        }
                                        {
                                            !isPresent && (
                                                <Icon
                                                    icon={'close'}
                                                    style={{ color: 'red' }}
                                                />
                                            )
                                        }
                                    </Grid>
                                </>
                            )
                        })
                    }
                </Grid>
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

OperationAccordion.propTypes = {
    operation: PropTypes.instanceOf(DtoOperation),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    parameters: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        label: PropTypes.string,
    })),
}

const PopinOperation = ({
    isOpen = false,
    closePopin = () => {},
    operations = [],
    analysis = [],

    usedTime = USED_TIME.DAY,
}) => {
    const {
        campaignParameters,
        parameters,
    } = useSelector(store => ({
        campaignParameters: store.CampaignReducer.campaignParameters,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')

    const groupAnalysis = groupBy(analysis, 'operation')

    const orderedOperations = orderBy(operations, 'date')

    const filteredParameters = useMemo(() => {
        const formatedParameters = campaignParameters.map(({ parameterCode }) => {
            const label = parameters.find(p => p.code === parameterCode)?.labelWithCode || ''
            return {
                code: parameterCode,
                label,
                searchValue: searchAllCharacters(`${parameterCode}${label}`),
            }
        })
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValueFormat ? formatedParameters.filter(p => p.searchValue.includes(searchValueFormat)) : formatedParameters
    }, [campaignParameters, parameters, searchValue])

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {`${operations.length > 1 ? i18n.operations : i18n.operation}: ${formatHeaderDate(operations[0]?.date, usedTime)}`}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closePopin} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ paddingTop: 10 }}>
                <Grid container spacing={0.5}>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.search}
                            value={searchValue}
                            onChange={setSearchValue}
                        />
                    </Grid>
                    {
                        orderedOperations.map(operation => {
                            return (
                                <Grid item xs={12}>
                                    <OperationAccordion
                                        operation={operation}
                                        analysis={groupAnalysis[operation.id]}
                                        parameters={filteredParameters}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </DialogContentMUI>
        </DialogMUI>
    )
}

PopinOperation.propTypes = {
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    usedTime: PropTypes.oneOf([USED_TIME.DAY, USED_TIME.WEEK, USED_TIME.MONTH]),
}

const TableStation = ({
    usedData = DATA_DISPLAY.OPERATION,
    usedTime = USED_TIME.DAY,

    startDate,
    endDate,
    operations = [],
    analysis = [],
    listDate = [],
}) => {
    const dispatch = useDispatch()

    const {
        campaign,
        campaignStations,
        campaignParameters,
        campaignPlannings,
        qualitometers,
        cities,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        campaignStations: store.CampaignReducer.campaignStations,
        campaignParameters: store.CampaignReducer.campaignParameters,
        campaignPlannings: store.CampaignReducer.campaignPlannings,
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const {
        value: isOpen,
        setTrue: openPopin,
        setFalse: closePopin,
    } = useBoolean(false)
    const [selectedOperations, setSelectedOperations] = useState([])
    const [selectedAnalysis, setSelectedAnalysis] = useState([])

    const data = useMemo(() => {
        const groupStationAnalysis = groupBy(analysis, 'qualitometer')
        return campaignStations.map(cs => {
            const qualitometer = qualitometers.find(q => q.id == cs.stationId)
            if (!qualitometer) {
                return
            }

            const stationOperations = operations.filter(o => o.qualitometer === qualitometer.id)
            const stationAnalysis = groupStationAnalysis[cs.stationId] ?? []
            const groupOperations = groupBy(stationOperations, o => formatHeaderDate(o.date, usedTime))
            const groupDateAnalysis = groupBy(stationAnalysis, a => formatHeaderDate(a.sampleDate, usedTime))

            const dateData = listDate.reduce((acc, date) => {
                const {
                    [date]: operationByDate = [],
                } = groupOperations
                const validatedAnalysis = groupDateAnalysis[date] ?? []
                const uniqAnalysis = uniqBy(validatedAnalysis, 'parameter')
                acc[date] = {
                    value: formatCell(campaignParameters, operationByDate, uniqAnalysis, usedData),
                    classNameColor: getColor(campaignParameters, uniqAnalysis),
                    onClick: !operationByDate.length ? undefined : () => {
                        setSelectedOperations(operationByDate)
                        setSelectedAnalysis(validatedAnalysis)
                        openPopin()
                    },
                }
                return acc
            }, {})

            const stationName = `[${qualitometer.code}] ${getLabel(cities, qualitometer.townCode)} - ${qualitometer.name || ''}`

            const {
                total,
                progress,
                progressAnalysis,
            } = campaignPlannings.length ? getProgressPlanning(campaignPlannings, campaignParameters, stationOperations, stationAnalysis, qualitometer.id) : getProgressMonth(campaignParameters, stationOperations, stationAnalysis, startDate, endDate)

            return {
                id: qualitometer.id,
                station: {
                    value: stationName,
                    tooltip: stationName,
                    classNameColor: getProgressColor(progress, total, progressAnalysis),
                    onClick: () => dispatch(push(`/station/quality/${qualitometer.id}/suiviPC?campaign=${campaign.id}&startDate=${startDate}${endDate ? `&endDate=${endDate}` : ''}`)),
                },
                executed: { value: `${progress}/${total}` },
                [usedData]: { value: getNbData(stationOperations, stationAnalysis, usedData) },
                ...dateData,
            }
        }).filter(d => !!d)
    }, [campaign.id, campaignParameters, campaignPlannings, campaignStations, cities, dispatch, endDate, operations, listDate, openPopin, qualitometers, startDate, usedData, usedTime])

    return (
        <>
            <MultiGridTableV2
                data={data}
                headers={['station', 'executed', usedData, ...listDate]}
                customHeaders={{
                    station: {
                        value: (
                            <>
                                {i18n.station}
                                <span style={{ fontWeight: 'normal', fontSize: 12, paddingLeft: 5 }}>
                                    {`(${data.length} ${data.length > 1 ? i18n.stations : i18n.station})`}
                                </span>
                            </>
                        ),
                    },
                }}
                fixedColumnCount={3}
                bodyHeight='100%'
            />
            <PopinOperation
                isOpen={isOpen}
                closePopin={closePopin}
                operations={selectedOperations}
                analysis={selectedAnalysis}
                usedTime={usedTime}
            />
        </>
    )
}

TableStation.propTypes = {
    usedData: PropTypes.oneOf([DATA_DISPLAY.OPERATION, DATA_DISPLAY.ANALYSIS]),
    usedTime: PropTypes.oneOf([USED_TIME.DAY, USED_TIME.WEEK, USED_TIME.MONTH]),

    startDate: PropTypes.number,
    endDate: PropTypes.number,
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    listDate: PropTypes.arrayOf(PropTypes.string),
}

const CartoStation = ({

}) => {
    const {
        campaignStations,
        qualitometers,
    } = useSelector(store => ({
        campaignStations: store.CampaignReducer.campaignStations,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const stations = campaignStations.map(cs => {
        const qualitometer = qualitometers.find(q => q.id == cs.stationId)
        if (!qualitometer) {
            return
        }
        return {
            ...qualitometer,
            markerIcon: getMarkerIcon('quality'),
        }
    }).filter(s => !!s)

    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            componentType={'quality'}
            stationsPoints={stations}
            stationsPanelTitle={i18n.quality}
            height={750}
        />
    )
}

const WarningStationLinkPopin = ({
    operations = [],
}) => {
    const dispatch = useDispatch()
    const {
        campaign,
        campaignStations,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        campaignStations: store.CampaignReducer.campaignStations,
    }), shallowEqual)

    const {
        value: isOpen,
        setFalse: closePopin,
    } = useBoolean(() => operations.some(o => !campaignStations.some(cs => cs.stationId === o.qualitometer)))

    return (
        <DialogMUI
            open={isOpen}
            maxWidth='sm'
            PaperProps={{
                sx: {
                    minHeight: 'auto',
                    maxHeight: 'auto',
                },
            }}
        >
            <DialogContentMUI style={{ paddingTop: 10 }}>
                <span style={{ fontSize: '1.5rem' }}>
                    {i18n.someOperationsAreNotLink}
                </span>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container >
                    <Grid item xs={6}>
                        <Button
                            onClick={() => {
                                dispatch(WaitAction.waitStart())
                                dispatch(CampaignAction.unlinkOperations(campaign.id)).then(() => {
                                    dispatch(CampaignAction.fetchQualityCampaignOperation(campaign.id)).then(() => {
                                        closePopin()
                                        dispatch(WaitAction.waitStop())
                                    })
                                })
                            }}
                            variant='contained'
                        >
                            {i18n.unlinkOperations}
                        </Button>
                    </Grid>
                    <Grid item container xs={6} spacing={1} justifyContent='flex-end'>
                        <Grid item>
                            <Button
                                onClick={closePopin}
                                variant='outlined'
                            >
                                {i18n.no}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => {
                                    dispatch(WaitAction.waitStart())
                                    dispatch(CampaignAction.linkOperations(campaign.id)).then(() => {
                                        dispatch(CampaignAction.fetchCampaignStation('quality', campaign.id)).then(() => {
                                            closePopin()
                                            dispatch(WaitAction.waitStop())
                                        })
                                    })
                                }}
                                variant='contained'
                            >
                                {i18n.yes}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

WarningStationLinkPopin.propTypes = {
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
}

const ExportModal = ({
    isOpen = false,
    close = () => {},

    analysis = [],
    operations = [],
    usedData,
    usedTime,
    listDate = [],
}) => {
    const dispatch = useDispatch()

    const {
        campaign,
        campaignStations,
        campaignParameters,
        campaignPlannings,
        qualitometers,
        cities,
        supports,
        fractions,
        units,
        parameters,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        campaignStations: store.CampaignReducer.campaignStations,
        campaignParameters: store.CampaignReducer.campaignParameters,
        campaignPlannings: store.CampaignReducer.campaignPlannings,
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
        supports: store.SupportReducer.supports,
        fractions: store.FractionReducer.fractions,
        units: store.UnitReducer.units,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const startDate = moment(campaign.beginningApplication, 'DD/MM/YYYY').valueOf()
    const endDate = hasValue(campaign.endingApplication) ? moment(campaign.endingApplication, 'DD/MM/YYYY').valueOf() : maxBy(operations, 'date')?.date

    const onExportTable = (exportFormat) => {
        const groupStationAnalysis = groupBy(analysis, 'qualitometer')
        const data = campaignStations.map(cs => {
            const qualitometer = qualitometers.find(q => q.id == cs.stationId)
            if (!qualitometer) {
                return
            }

            const stationAnalysis = groupStationAnalysis[cs.stationId] ?? []
            const groupDateAnalysis = groupBy(stationAnalysis, a => formatHeaderDate(a.sampleDate, usedTime))

            const qualitoOperations = operations.filter(o => o.qualitometer === qualitometer.id)
            const groupOperations = groupBy(qualitoOperations, o => formatHeaderDate(o.date, usedTime))
            const dateData = listDate.reduce((acc, date) => {
                const {
                    [date]: operationByDate = [],
                } = groupOperations
                const validatedAnalysis = groupDateAnalysis[date] ?? []
                const uniqAnalysis = uniqBy(validatedAnalysis, 'parameter')
                acc[date] = {
                    value: formatCell(campaignParameters, operationByDate, uniqAnalysis, usedData),
                    color: getColor(campaignParameters, uniqAnalysis),
                }
                return acc
            }, {})
            const stationName = `[${qualitometer.code}] ${getLabel(cities, qualitometer.townCode)} - ${qualitometer.name || ''}`
            const {
                total,
                progress,
                progressAnalysis,
            } = campaignPlannings.length ? getProgressPlanning(campaignPlannings, campaignParameters, qualitoOperations, stationAnalysis, qualitometer.id) : getProgressMonth(campaignParameters, qualitoOperations, stationAnalysis, startDate, endDate)
            return {
                id: qualitometer.id,
                station: {
                    value: stationName,
                    color: getProgressColor(progress, total, progressAnalysis),
                },
                executed: `${progress}/${total}`,
                [usedData]: getNbData(qualitoOperations, stationAnalysis, usedData),
                ...dateData,

            }
        }).filter(d => !!d)

        const dataWithHeaders = data.length ? [
            { ...data[0], headers: ['station', 'executed', usedData, ...listDate] },
            ...data.slice(1),
        ] : []

        dispatch(ExportAction.export(formatData(dataWithHeaders), exportFormat, i18n.tracking))
    }

    const onExportResultsMissing = (exportFormat) => {
        const groupStationAnalysis = groupBy(analysis, 'qualitometer')
        const groupStationOperation = groupBy(operations, 'qualitometer')
        const data = campaignStations.flatMap(cs => {
            const qualitometer = qualitometers.find(q => q.id == cs.stationId)
            if (!qualitometer) {
                return []
            }

            const stationAnalysis = groupStationAnalysis[cs.stationId] ?? []
            const stationOperations = groupStationOperation[cs.stationId] ?? []

            const missingAnalysis = !campaignPlannings.length ?
                getMissingPlanningAnalysis(qualitometer, campaignPlannings, stationOperations, stationAnalysis) :
                getMissingMonthAnalysis(startDate, endDate, campaignParameters, operations, analysis)

            return missingAnalysis.map(a => ({
                stationCode: qualitometer.code,
                stationName: qualitometer.name,
                startDate: getDate(a.startDate),
                endDate: getDate(a.endDate),
                parameterCode: a.parameterCode,
                parameter: getLabel(parameters, a.parameterCode),
                codeUnit: a.unitCode,
                unit: getLabel(units, a.unitCode),
                supportCode: a.supportCode,
                support: getLabel(supports, a.supportCode),
                fractionCode: a.fractionCode,
                fraction: getLabel(fractions, a.fractionCode),
                place: getLocalizationLabel(a.placeCode),
            }))
        })
        const dataWithHeaders = data.length ? [
            { ...data[0], headers: Object.keys(data[0]) },
            ...data.slice(1),
        ] : []

        dispatch(ExportAction.export(formatData(dataWithHeaders), exportFormat, i18n.tracking))
    }
    return (
        <ExportFileModal
            open={isOpen}
            onClose={close}
            data={[
                {
                    name: i18n.resultsTable,
                    formats: [{
                        type: i18n.excelFile,
                        callback: () => onExportTable('xlsx'),
                    }, {
                        type: i18n.csvFile,
                        callback: () => onExportTable('csv'),
                    }],
                },
                {
                    name: 'Résultat manquant',
                    formats: [{
                        type: i18n.excelFile,
                        callback: () => onExportResultsMissing('xlsx'),
                    }, {
                        type: i18n.csvFile,
                        callback: () => onExportResultsMissing('csv'),
                    }],
                },
            ]}
        />
    )
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    usedData: PropTypes.oneOf([DATA_DISPLAY.ANALYSIS, DATA_DISPLAY.OPERATION]),
    usedTime: PropTypes.oneOf([USED_TIME.DAY, USED_TIME.WEEK, USED_TIME.MONTH]),
    listDate: PropTypes.arrayOf(PropTypes.string),
}

const CampaignTrackingOperation = ({
    operations = [],
    analysis = [],
}) => {
    const dispatch = useDispatch()

    const {
        campaign,
        campaignStations,
        qualitometers,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        campaignStations: store.CampaignReducer.campaignStations,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const {
        value: isOpen,
        setTrue: openImport,
        setFalse: closePopin,
    } = useBoolean(false)

    const {
        value: isExportOpen,
        setFalse: closeExport,
        setTrue: openExport,
    } = useBoolean(false)

    const [usedData, setUsedData] = useState(DATA_DISPLAY.OPERATION)
    const [usedTime, setUsedTime] = useState(USED_TIME.MONTH)
    const [displayOnlyData, setDisplayOnlyData] = useState(false)

    const startDate = moment(campaign.beginningApplication, 'DD/MM/YYYY').valueOf()
    const endDate = hasValue(campaign.endingApplication) ? moment(campaign.endingApplication, 'DD/MM/YYYY').valueOf() : maxBy(operations, 'date')?.date

    const filteredOperations = useMemo(() => {
        const filterStartDate = operations.filter(o => o.date >= startDate)
        return endDate ? filterStartDate.filter(o => o.date < endDate) : filterStartDate
    }, [operations, endDate, startDate])

    const filteredAnalysis = useMemo(() => {
        const groupOperations = groupBy(filteredOperations, o => `${o.qualitometer}#${o.id}`)
        return analysis.filter(a => !!groupOperations[`${a.qualitometer}#${a.operation}`]?.length)
    }, [analysis, filteredOperations])

    const listDate = useMemo(() => {
        if (displayOnlyData) {
            const operationDates = filteredOperations.map(o => formatHeaderDate(o.date, usedTime))
            return uniq(operationDates)
        }
        const rangeDate = enumerateBetweenDates(startDate, endDate, usedTime)
        const orderedDate = orderBy(rangeDate, date => date.valueOf())
        return orderedDate.map(date => formatHeaderDate(date, usedTime))
    }, [displayOnlyData, endDate, filteredOperations, startDate, usedTime])

    useActions(() => {
        return {
            import: openImport,
            export: openExport,
        }
    }, [])

    return (
        <Grid container sx={{ padding: '5 10' }} spacing={2}>
            <Grid item xs={10.5}>
                <Card>
                    <Grid container sx={{ padding: '10' }} alignItems='center'>
                        <Grid item xs={4}>
                            <Checkbox
                                checked={displayOnlyData}
                                onChange={setDisplayOnlyData}
                                label={i18n.showOnlyOperationsAndAnalysis}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant={usedData === DATA_DISPLAY.OPERATION ? 'contained' : 'outlined'} onClick={() => setUsedData(DATA_DISPLAY.OPERATION)}>
                                {i18n.operation}
                            </Button>
                            <Button variant={usedData === DATA_DISPLAY.ANALYSIS ? 'contained' : 'outlined'} onClick={() => setUsedData(DATA_DISPLAY.ANALYSIS)}>
                                {i18n.oneAnalysis}
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant={usedTime === USED_TIME.DAY ? 'contained' : 'outlined'} onClick={() => setUsedTime(USED_TIME.DAY)}>
                                {i18n.day}
                            </Button>
                            <Button variant={usedTime === USED_TIME.WEEK ? 'contained' : 'outlined'} onClick={() => setUsedTime(USED_TIME.WEEK)}>
                                {i18n.week}
                            </Button>
                            <Button variant={usedTime === USED_TIME.MONTH ? 'contained' : 'outlined'} onClick={() => setUsedTime(USED_TIME.MONTH)}>
                                {i18n.month}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12} style={{ marginTop: -60, height: '75vh' }}>
                <SimpleTabList
                    defaultTab={LIST}
                    tabs={[
                        {
                            constant: LIST,
                            label: i18n.table,
                            icon: 'list',
                        },
                        {
                            constant: CARTO,
                            label: i18n.map,
                            icon: 'map',
                        },
                    ]}
                >
                    {
                        tab => (
                            <>
                                {
                                    tab === LIST && (
                                        <TableStation
                                            usedData={usedData}
                                            usedTime={usedTime}

                                            startDate={startDate}
                                            endDate={endDate}
                                            operations={filteredOperations}
                                            analysis={filteredAnalysis}
                                            listDate={listDate}
                                        />
                                    )
                                }
                                {
                                    tab === CARTO && (
                                        <CartoStation />
                                    )
                                }
                            </>
                        )
                    }
                </SimpleTabList>
                <ImportXmlQualityPopup
                    campaignId={campaign.id}
                    callbackImport={() => dispatch(CampaignAction.fetchQualityCampaignOperation(campaign.id))}
                    stations={campaignStations.map(({ stationId }) => qualitometers.find(({ id }) => id === stationId)?.code).filter(c => !!c)}
                    isOpen={isOpen}
                    onClose={closePopin}
                />
                <WarningStationLinkPopin />
                <ExportModal
                    isOpen={isExportOpen}
                    close={closeExport}

                    operations={filteredOperations}
                    analysis={filteredAnalysis}
                    usedData={usedData}
                    usedTime={usedTime}
                    listDate={listDate}
                />
            </Grid>
        </Grid>
    )
}

CampaignTrackingOperation.propTypes = {
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
}

export default CampaignTrackingOperation
