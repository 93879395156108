import moment from 'moment'
import React from 'react'
import i18n from 'simple-react-i18n'
import Button from '../components/forms/Button'
import ProgressBar from '../components/progress/ProgressBar'
import { contentsPath } from '../conf/basepath'
import { EXPORT_JOB_STATUS } from '../export/constants/ExportConstants'
import { shortenHumanize } from './DateUtil'
import { getCurrentLang } from './LangUtils'

const getStatusLabel = (status) => {
    switch (status) {
        case EXPORT_JOB_STATUS.WAITING:
            return i18n.waiting
        case EXPORT_JOB_STATUS.IN_PROGRESS:
            return i18n.inProgress
        case EXPORT_JOB_STATUS.FINISHED:
            return i18n.success
        default:
            return i18n.error
    }
}

const getStatusColor = (status) => {
    switch (status) {
        case EXPORT_JOB_STATUS.WAITING:
            return 'grey'
        case EXPORT_JOB_STATUS.IN_PROGRESS:
            return 'blue'
        case EXPORT_JOB_STATUS.FINISHED:
            return 'green'
        default:
            return 'red'
    }
}

const getStatusIcon = (status, size = 50) => {
    const icon = (() => {
        switch (status) {
            case EXPORT_JOB_STATUS.WAITING:
                return 'local_cafe'
            case EXPORT_JOB_STATUS.IN_PROGRESS:
                return 'sync'
            case EXPORT_JOB_STATUS.FINISHED:
                return 'done'
            default:
                return 'error'
        }
    })()
    const color = getStatusColor(status)
    return <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color, fontSize: `${size}px`, width: `${size}px`, height: `${size}px` }}>{ icon }</i>
}

const getDuration = (creationDate) => shortenHumanize((moment.utc(moment().diff(moment(creationDate))).valueOf() || 0), { language: getCurrentLang()[0] })

const getLoadingBar = (status, progress, filePath) => {
    if (status === EXPORT_JOB_STATUS.FINISHED) {
        return <Button icon='file_download' title={i18n.download} href={`${contentsPath}${filePath}`} />
    } else if (status === EXPORT_JOB_STATUS.ERROR) {
        return i18n.error
    }
    return <ProgressBar progress={progress} withMessage/>
}

export { getStatusIcon, getStatusColor, getStatusLabel, getDuration, getLoadingBar }

