import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Job from '../../../import/dto/DtoJob'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import JobAlert from './JobAlert'
import { MEASURE_COTE } from '../../../piezometry/constants/PiezometryConstants'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../store/AppStore'


class AlertPiezo extends Component {
    componentDidMount() {
        this.props.fetchAllPiezometers()
        this.props.fetchAllPiezometerThreshold(MEASURE_COTE.DEPTH)
        if (!this.props.piezometryDataTypes.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
    }

    render() {
        const formatThresholds = this.props.piezometerThresholds.map(t => {
            const dt = this.props.piezometryDataTypes.find(d => d.id === parseInt(t.dataType ?? '-1'))
            const sign = t.isOverrunThreshold === '1' ? '>' : '<'
            return {
                ...t,
                title: `${t.title} (${dt?.label ?? ''} ${sign} ${t.value} ${dt?.unit ?? ''})`,
                key: `${t.dataType ?? '-1'}:${t.id}`,
            }
        })
        return (
            <JobAlert
                stationType='piezometry'
                job={this.props.job}
                isEditMode={this.props.isEditMode}
                stations={this.props.piezometers}
                thresholds={formatThresholds}
                onChangeEmails={this.props.onChangeEmails}
                onChangeContactsIds={this.props.onChangeContactsIds}
                onChangeStation={this.props.onChangeStation}
                onChangeAlertTypes={this.props.onChangeAlertTypes}
            />
        )
    }
}

AlertPiezo.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    piezometers: PropTypes.arrayOf(PropTypes.object),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.object),
    fetchAllPiezometers: PropTypes.func,
    fetchAllPiezometerThreshold: PropTypes.func,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    piezometers: store.PiezometryReducer.piezometers.map(({ id, code, name }) => ({
        id,
        code,
        name: `[${code}] ${name}`,
    })),
    piezometerThresholds: store.PiezometerStationReducer.allPiezometerThresholds,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})

const mapDispatchToProps = {
    fetchAllPiezometers: PiezometryAction.fetchAllPiezometers,
    fetchAllPiezometerThreshold: PiezometerStationAction.fetchAllPiezometerThreshold,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertPiezo)