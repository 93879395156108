import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { StyledFieldSet } from '../../../../components/StyledElements'
import { Grid, Button, DialogActions, Box } from '@mui/material'
import PropTypes from 'prop-types'
import Input from '../../../../components/forms/Input'
import PerimeterDto from '../../../dto/PerimeterDto'
import Select from '../../../../components/forms/Select'
import {
    tempPerimeterStation,
} from '../../../temp/TempPerimetersData'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import StepperDialog from '../../../../components/modal/StepperDialog'
import { ButtonMUI } from '../../../../components/styled/Buttons'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import StationMapDashboardPanel from '../../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import Icon from '../../../../components/icon/Icon'
import { PERIMETERS_STATES_COMPLETE, PERIMETERS_STATES_INCOMPLETE } from '../../constants/PerimetersConstants'
import PerimetersThunk from '../../../thunk/PerimetersThunk'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import PerimetersOwnersTable from '../../perimeters/components/PerimetersOwnersTable'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { compact, countBy, keys, orderBy } from 'lodash'
import ProgressCard from 'components/card/ProgressCard'
import PerimeterStatus from '../../../../components/PerimeterStatus'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import { WhiteCard } from 'components/styled/Card'
import useSandreList from 'utils/customHook/useSandreList'

const STEP = {
    DESCRIPTION: 0,
    PARCELS: 1,
    EVENTS: 2,
    DOCUMENTS: 3,
    MAP: 4,
}

const PARCELS_HEADER = ['city', 'code', 'owners', 'hypothecaryState']
const OPERATORS_HEADER = ['name', 'address', 'sauHa']
const CITIES_HEADER = ['city', 'code', 'nbParcels']
const EVENTS_HEADER = []
const DOCUMENTS_HEADER = []

const AddOperatorDialog = ({
    open = false,
    onClose = () => {},
    readMode = true,
}) => {
    const [name, setName] = useState()
    const [firstname, setFirstname] = useState()
    const [sauHa, setSauHa] = useState()
    const [birthdate, setBirthdate] = useState()
    const [placeOfBirth, setPlaceOfBirth] = useState()
    const [address, setAddress] = useState()

    return (
        <DialogMUI
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 1.5rem' }}>
                    <Grid item>
                        {i18n.addOperator}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet style={{ padding: '1.5rem' }}>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.name}
                                value={name}
                                onChange={setName}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.firstname}
                                value={firstname}
                                onChange={setFirstname}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.sauHa}
                                value={sauHa}
                                onChange={setSauHa}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.birthdate}
                                value={birthdate}
                                onChange={setBirthdate}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                title={i18n.placeOfBirth}
                                value={placeOfBirth}
                                onChange={setPlaceOfBirth}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Input
                                title={i18n.address}
                                value={address}
                                onChange={setAddress}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActions>
                <Grid container item xs={12} justifyContent='space-between'>
                    <Grid item>
                        <Button onClick={onClose} variant='outlined'>
                            {i18n.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => {}} variant='contained' color='primary'>
                            {i18n.save}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </DialogMUI>
    )
}

AddOperatorDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    readMode: PropTypes.bool,
}

const AddParcelDialog = ({
    open = false,
    onClose = () => {},

    perimeterFolder,
    perimeter = {},

    readMode = true,
}) => {
    const [parcelCode, setParcelCode] = useState()
    const [parcelSection, setParcelSection] = useState()
    const [parcelArea, setParcelArea] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        if (!open) {
            setParcelCode(undefined)
            setParcelSection(undefined)
            setParcelArea(undefined)
        }
    }, [open])

    return (
        <DialogMUI
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20px' }}>
                    <Grid item>
                        {i18n.addParcel}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet style={{ padding: '30px 50px' }}>
                    <Grid container item spacing={2} sx={{
                        paddingTop: '10px',
                    }}
                    >
                        <Grid item xs={4}>
                            <Input
                                title={i18n.section}
                                value={parcelSection}
                                onChange={setParcelSection}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                title={i18n.parcel}
                                value={parcelCode}
                                onChange={setParcelCode}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                title={i18n.area}
                                value={parcelArea}
                                onChange={setParcelArea}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActions>
                <Button onClick={onClose} variant='outlined'>
                    { i18n.cancel }
                </Button>
                <Button onClick={() => {
                    if (perimeter && parcelCode && parcelSection && parcelArea) {
                        dispatch(PerimetersThunk.addParcel({
                            perimeterFolderId: perimeterFolder,
                            perimeterId: perimeter,
                            parcel: parcelCode,
                            section: parcelSection,
                            area: parseFloat(parcelArea),
                        }))

                        onClose()

                        setParcelCode(undefined)
                        setParcelSection(undefined)
                        setParcelArea(undefined)
                    }
                }} variant='contained' color='primary'
                >
                    { i18n.save }
                </Button>
            </DialogActions>
        </DialogMUI>
    )
}

AddParcelDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,

    perimeterFolder: PropTypes.number,
    perimeter: PropTypes.instanceOf(PerimeterDto),

    readMode: PropTypes.bool,
}

const DescriptionPerimeterStep = ({
    name,
    setName = () => {},
    type,
    setType = () => {},
    zoning,
    setZoning = () => {},

    readMode = true,
}) => {
    const perimeterTypes = useSandreList('PERIMETRES.TYPE')

    const {
        parcels,
        citiesIndex,
    } = useSelector(store => ({
        parcels: store.PerimetersReducer.parcels,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const parcelCounts = countBy(parcels, 'city')
    const parcelsCities = Object.keys(parcelCounts).map(city => ({
        city: citiesIndex[city]?.name ?? '',
        code: city,
        nbParcels: parcelCounts[city],
    }))

    return (
        <>
            <StyledFieldSet style={{ padding: '1.5rem', marginBottom: '15px' }}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12}>
                        <Input
                            title={i18n.name}
                            value={name}
                            onChange={setName}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            options={perimeterTypes}
                            label={i18n.type}
                            nullLabel='&nbsp;'
                            value={type}
                            noSort
                            onChange={setType}
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.zoning}
                            value={zoning}
                            onChange={setZoning}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                </Grid>
            </StyledFieldSet>
            <Table
                title={i18n.cities}
                data={parcelsCities}
                actions={compact([{
                    iconName: 'download',
                    tooltip: i18n.export,
                    color: 'black',
                    onClick: () => exportFile({
                        data: parcelsCities.map(p => ({
                            city: { value: p.city },
                            code: { value: p.code },
                            nbParcels: { value: p.nbParcels },
                            headers: CITIES_HEADER,
                        })),
                        exportType: 'xlsx',
                        titleFile: i18n.cities,
                    }),
                }])}
                paging
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers: CITIES_HEADER }}
                condensed
                sortable
                invertedHeaderStyle
                round
            />
        </>
    )
}

DescriptionPerimeterStep.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    type: PropTypes.string,
    setType: PropTypes.func,
    zoning: PropTypes.string,
    setZoning: PropTypes.func,

    readMode: PropTypes.bool,
}

const ParcelsPerimeterStep = ({
    perimeterFolder,
    perimeterId,
    parcel,
    setParcel = () => {},
    section,
    setSection = () => {},
    parcelNumber,
    setParcelNumber = () => {},
    parcelArea,
    setParcelArea = () => {},
    parcelCity,
    setParcelCity = () => {},
    parcelAddress,
    setParcelAddress = () => {},

    parcelPrivateOwners = [],
    parcelCompanyOwners = [],

    readMode = true,
}) => {
    const {
        parcels,
        parcelData,
        cities,
    } = useSelector(store => ({
        parcels: store.PerimetersReducer.parcels,
        parcelData: store.PerimetersReducer.parcelData,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [addOperatorPopupOpen, setAddOperatorPopupOpen] = useState(false)

    return (
        <Grid item xs={12}>
            {!parcel.id ? (
                <ParcelsTable
                    perimeterFolder={perimeterFolder}
                    perimeter={perimeterId}
                    parcels={parcels}
                    setParcel={setParcel}
                    readMode={readMode}
                />
            ) : keys(parcelData).length ? (
                <>
                    <Grid container justifyContent='space-evenly' alignItems='center'>
                        <Grid item>
                            <Button
                                variant='contained'
                                onClick={() => setParcel(parcels[parcel.previousParcel])}
                            >
                                {i18n.previous}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                onClick={() => setParcel({})}
                            >
                                {i18n.backToTheList}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                onClick={() => setParcel(parcels[parcel.nextParcel])}
                            >
                                {i18n.nextPlot}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ padding: '1.5rem 1rem 1rem' }} rowSpacing={2}>
                        <Grid item xs={12}>
                            <StyledFieldSet style={{ padding: '1.5rem' }}>
                                <Grid container rowSpacing={1}>
                                    <Grid container item xs={12} columnSpacing={2}>
                                        <Grid item xs={4}>
                                            <Input
                                                title={i18n.section}
                                                value={section}
                                                onChange={setSection}
                                                clearFunction
                                                disabled={readMode}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Input
                                                title={i18n.number}
                                                value={parcelNumber}
                                                onChange={setParcelNumber}
                                                clearFunction
                                                disabled={readMode}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Input
                                                title={i18n.subdivision}
                                                value={''}
                                                clearFunction
                                                disabled={readMode}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select
                                                options={cities}
                                                label={i18n.city}
                                                nullLabel='&nbsp;'
                                                value={parcelCity}
                                                onChange={setParcelCity}
                                                noSort
                                                disabled={readMode}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                title={i18n.lieuDit}
                                                value={parcelAddress}
                                                onChange={setParcelAddress}
                                                clearFunction
                                                disabled={readMode}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Input
                                                title={i18n.area}
                                                value={parcelArea}
                                                onChange={setParcelArea}
                                                clearFunction
                                                disabled={readMode}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Input
                                                title={i18n.classOfDirt}
                                                value={''}
                                                clearFunction
                                                disabled={readMode}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        </Grid>
                        <Grid container item xs={12}>
                            <PerimetersOwnersTable
                                privateOwners={parcelPrivateOwners}
                                companyOwners={parcelCompanyOwners}
                                readMode={readMode}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} sx={{ border: 'solid 1px black', borderRadius: '5px' }}>
                                <Table
                                    title={i18n.operators}
                                    data={[]}
                                    actions={compact([{
                                        iconName: 'download',
                                        tooltip: i18n.export,
                                        color: 'black',
                                        onClick: () => exportFile({
                                            data: [],
                                            exportType: 'xlsx',
                                            titleFile: i18n.owners,
                                        }),
                                    }, !readMode && {
                                        iconName: 'note_add',
                                        tooltip: i18n.add,
                                        color: 'black',
                                        onClick: () => {
                                            setAddOperatorPopupOpen(true)
                                        },
                                    }])}
                                    paging
                                    nbPerPageLabel={nbPerPageLabel}
                                    type={{ headers: OPERATORS_HEADER }}
                                    condensed
                                    sortable
                                    invertedHeaderStyle
                                    round
                                />
                            </Grid>
                        </Grid>
                        <AddOperatorDialog
                            open={addOperatorPopupOpen}
                            onClose={() => setAddOperatorPopupOpen(false)}
                            readMode={readMode}
                        />
                    </Grid>
                </>
            ) : (
                <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                    <ProgressCard indeterminate />
                </Grid>
            )}
        </Grid>
    )
}

ParcelsPerimeterStep.propTypes = {
    perimeterFolder: PropTypes.number,
    perimeterId: PropTypes.number,
    setPerimeter: PropTypes.func,

    parcel: PropTypes.shape({
        id: PropTypes.number,
        previousParcel: PropTypes.number,
        nextParcel: PropTypes.number,
    }),
    setParcel: PropTypes.func,
    section: PropTypes.shape({}),
    setSection: PropTypes.func,
    parcelNumber: PropTypes.shape({}),
    setParcelNumber: PropTypes.func,
    parcelArea: PropTypes.shape({}),
    setParcelArea: PropTypes.func,

    parcelPrivateOwners: PropTypes.arrayOf(PropTypes.shape({})),
    parcelCompanyOwners: PropTypes.arrayOf(PropTypes.shape({})),

    readMode: PropTypes.bool,
}

const ParcelsTable = ({
    perimeterFolder,
    perimeter,
    parcels = [],
    setParcel,
    readMode,
}) => {
    const {
        sandreCodes,
        citiesIndex,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [addParcelPopupOpen, setAddParcelPopupOpen] = useState(false)
    const [deleteParcelId, setDeleteParcelId] = useState()

    const parcelsData = useMemo(() => {
        const parcelsFormatted = parcels.map(p => {
            const ownersLength = p.privateOwners + p.companyOwners

            return {
                ...p,
                nullValue: (
                    <PerimeterStatus
                        color={ownersLength ? 'green' : 'orange'}
                    />
                ),
                hypothecaryState: p.hypothecary ? i18n.entered : '',
                owners: ownersLength,
                city: citiesIndex[p.city]?.name ?? '',
                code: `${p.section ? `${p.section}-` : ''}${p.code ?? ''}`,
                status: ownersLength ? PERIMETERS_STATES_COMPLETE : PERIMETERS_STATES_INCOMPLETE,
            }
        })
        return [
            ...orderBy(parcelsFormatted.filter(({ status }) => status === PERIMETERS_STATES_INCOMPLETE), 'code'),
            ...orderBy(parcelsFormatted.filter(({ status }) => status === PERIMETERS_STATES_COMPLETE), 'code'),
        ]
    }, [parcels, perimeter?.status, sandreCodes])

    const exportData = () => {
        exportFile({
            data: parcels.length ? parcels.map(p => ({
                identifier: { value: p.id },
                city: { value: '' },
                code: { value: `${p.section}-${p.code}` },
                owners: { value: (p.privateOwners + p.companyOwners) },
                hypothecaryState: { value: p.hypothecary ? i18n.entered : '' },
                headers: ['identifier', ...PARCELS_HEADER],
            })) : [],
            exportType: 'xlsx',
            titleFile: i18n.parcels,
        })
    }

    return (
        <>
            <WhiteCard round noMargin cardStyle={{ border: 'solid 1px black' }}>
                <Table
                    title={i18n.parcels}
                    data={parcelsData}
                    actions={[{
                        iconName: 'download',
                        tooltip: i18n.export,
                        color: 'black',
                        onClick: () => exportData(),
                    }, !readMode && {
                        iconName: 'note_add',
                        tooltip: i18n.add,
                        color: 'black',
                        onClick: () => setAddParcelPopupOpen(true),
                    }]}
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    type={{ headers: ['nullValue', ...PARCELS_HEADER] }}
                    onClick={setParcel}
                    deletable={!readMode}
                    onDelete={(p) => {
                        setDeleteParcelId(p.id)
                    }}
                    condensed
                    sortable
                    invertedHeaderStyle
                    round
                />
            </WhiteCard>
            <ConfirmModal
                isOpen={!!deleteParcelId}
                title={i18n.sureDeleteParcel}
                onValidate={() => {
                    dispatch(PerimetersThunk.deleteParcel({
                        perimeterFolderId: perimeterFolder,
                        perimeterId: perimeter,
                        parcelId: deleteParcelId,
                    }))
                    setDeleteParcelId(undefined)
                }}
                onClose={() => setDeleteParcelId(undefined)}
            />
            <AddParcelDialog
                open={addParcelPopupOpen}
                onClose={() => setAddParcelPopupOpen(false)}
                perimeterFolder={perimeterFolder}
                perimeter={perimeter}
                readMode={readMode}
            />
        </>
    )
}

ParcelsTable.propTypes = {
    perimeterFolder: PropTypes.number,
    perimeter: PropTypes.number,
    parcels: PropTypes.arrayOf(PropTypes.object),
    parcel: PropTypes.object,
    setParcel: PropTypes.func,
    readMode: PropTypes.bool,
}

const PerimetersStepper = ({
    perimeterFolderId,
    perimeter = {},
    setPerimeter = () => {},
    readMode = true,
}) => {
    const {
        parcels,
        parcelData,
        perimetersFolder,
    } = useSelector(store => ({
        parcels: store.PerimetersReducer.parcels,
        parcelData: store.PerimetersReducer.parcelData,
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
    }), shallowEqual)

    const [name, setName] = useState()
    const [zoning, setZoning] = useState()
    const [type, setType] = useState()

    const [parcel, setParcel] = useState({})

    const [section, setSection] = useState()
    const [parcelNumber, setParcelNumber] = useState()
    const [parcelArea, setParcelArea] = useState()
    const [parcelCity, setParcelCity] = useState()
    const [parcelAddress, setParcelAddress] = useState()
    const [parcelPrivateOwners, setParcelPrivateOwners] = useState([])
    const [parcelCompanyOwners, setParcelCompanyOwners] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        setName(perimeter.name)
        setZoning(perimeter.zoning)
        setType(perimeter.perimeterType)
    }, [perimeter])

    useEffect(() => {
        if (perimeter.id && perimeter.id !== -1) {
            dispatch(PerimetersThunk.getParcels({
                folder: perimeter.folder,
                id: perimeter.id,
            }))
        }
    }, [perimeter])

    useEffect(() => {
        setSection(undefined)
        setParcelNumber(undefined)
        setParcelArea(undefined)
        setParcelCity(undefined)
        setParcelAddress(undefined)
        setParcelPrivateOwners([])
        setParcelCompanyOwners([])

        if (perimeter.id && parcel.id) {
            dispatch(PerimetersThunk.getParcel({
                perimeterFolder: perimeter.folder,
                perimeterId: perimeter.id,
                id: parcel.id,
            }))
        }
    }, [perimeter, parcel.id])

    useEffect(() => {
        setSection(parcelData.section)
        setParcelNumber(parcelData.parcel)
        setParcelArea(parcelData.area)
        setParcelCity(parcelData.city)
        setParcelAddress(parcelData.address)
        setParcelPrivateOwners(parcelData.privateOwners)
        setParcelCompanyOwners(parcelData.companyOwners)
    }, [parcelData])

    return (
        <>
            <StepperDialog
                bypassValidateSteps
                steps={[{
                    label: i18n.descriptif,
                    constant: STEP.DESCRIPTION,
                }, {
                    label: (
                        <Box
                            sx={{
                                backgroundColor: parcels.find(p => !p.privateOwners&& !p.companyOwners) && 'orange',
                                padding: '0 0.5rem',
                                borderRadius: MAIN_RADIUS,
                            }}
                        >
                            {i18n.parcels}
                        </Box>
                    ),
                    constant: STEP.PARCELS,
                }, {
                    label: i18n.events,
                    constant: STEP.EVENTS,
                }, {
                    label: i18n.documents,
                    constant: STEP.DOCUMENTS,
                }, {
                    label: i18n.map,
                    constant: STEP.MAP,
                }].filter(s => !!s)}
                open={!!perimeter.id}
                title={`${perimetersFolder.find(p => p.id === perimeterFolderId)?.name} - ${perimeter.name}`}
                closeDialog={() => setPerimeter({})}
                renderSaveButton={() => (
                    <ButtonMUI
                        onClick={() => {
                            if (parcel && parcelData) {
                                dispatch(PerimetersThunk.updateParcel({
                                    perimeterFolderId,
                                    perimeterId: perimeter.id,
                                    parcelId: parcelData.id,
                                    parcel: parcelNumber,
                                    section,
                                    area: parseFloat(parcelArea),
                                }))
                            }
                        }}
                        variant='contained'
                        disabled={readMode}
                    >
                        {i18n.register}
                    </ButtonMUI>
                )}
            >
                {step => (
                    <>
                        {step === STEP.DESCRIPTION && (
                            <DescriptionPerimeterStep
                                name={name}
                                setName={setName}
                                type={type}
                                setType={setType}
                                zoning={zoning}
                                setZoning={setZoning}

                                readMode={readMode}
                            />
                        )}
                        {step === STEP.PARCELS && (
                            <ParcelsPerimeterStep
                                perimeterFolder={perimeterFolderId}
                                perimeterId={perimeter.id}
                                setPerimeter={setPerimeter}

                                parcel={parcel}
                                setParcel={setParcel}
                                section={section}
                                setSection={setSection}
                                parcelNumber={parcelNumber}
                                setParcelNumber={setParcelNumber}
                                parcelArea={parcelArea}
                                setParcelArea={setParcelArea}
                                parcelCity={parcelCity}
                                setParcelCity={setParcelCity}
                                parcelAddress={parcelAddress}
                                setParcelAddress={setParcelAddress}

                                parcelPrivateOwners={parcelPrivateOwners}
                                parcelCompanyOwners={parcelCompanyOwners}

                                readMode={readMode}
                            />
                        )}
                        {step === STEP.EVENTS && (
                            <Box sx={{ border: 'solid 1px black', borderRadius: '5px' }}>
                                <Table
                                    title={i18n.events}
                                    data={[]}
                                    actions={[{
                                        iconName: 'download',
                                        tooltip: i18n.export,
                                        color: 'black',
                                        onClick: () => {},
                                    }, !readMode && {
                                        iconName: 'note_add',
                                        tooltip: i18n.add,
                                        color: 'black',
                                        onClick: () => {},
                                    }]}
                                    paging
                                    nbPerPageLabel={nbPerPageLabel}
                                    type={{ headers: EVENTS_HEADER }}
                                    condensed
                                    sortable
                                    invertedHeaderStyle
                                    round
                                />
                            </Box>
                        )}
                        {step === STEP.DOCUMENTS && (
                            <Box sx={{ border: 'solid 1px black', borderRadius: '5px' }}>
                                <Table
                                    title={i18n.documents}
                                    data={[]}
                                    actions={[{
                                        iconName: 'download',
                                        tooltip: i18n.export,
                                        color: 'black',
                                        onClick: () => {},
                                    }, !readMode && {
                                        iconName: 'note_add',
                                        tooltip: i18n.add,
                                        color: 'black',
                                        onClick: () => {},
                                    }]}
                                    paging
                                    nbPerPageLabel={nbPerPageLabel}
                                    type={{ headers: DOCUMENTS_HEADER }}
                                    condensed
                                    sortable
                                    invertedHeaderStyle
                                    round
                                />
                            </Box>
                        )}
                        {step === STEP.MAP && (
                            <StationMapDashboardPanel
                                noMarkerTooltip
                                station={tempPerimeterStation}
                                type={STATION_TYPE_NAME.piezometry}
                                className='margin-bottom-1 no-margin-top'
                            />
                        )}
                    </>
                )}
            </StepperDialog>
        </>
    )
}

PerimetersStepper.propTypes = {
    perimeterFolderId: PropTypes.number,
    perimeter: PropTypes.shape(PerimeterDto),
    setPerimeter: PropTypes.func,
    previousPerimeter: PropTypes.func,
    nextPerimeter: PropTypes.func,
    readMode: PropTypes.bool,
}

export default PerimetersStepper