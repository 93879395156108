import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import Job from '../../../import/dto/DtoJob'
import JobAlert from './JobAlert'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../store/AppStore'


class AlertHydro extends Component {
    componentDidMount() {
        this.props.fetchHydrometers()
        this.props.fetchHydrometricThresholds()
        if (!this.props.hydrometryDataTypes.length) {
            AppStore.dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
    }

    render() {
        const formatThresholds = this.props.hydrometryThresholds.map(t => {
            const dt = this.props.hydrometryDataTypes.find(d => d.id === parseInt(t.dataType ?? '4'))
            const sign = t.isOverrunThreshold === '1' ? '>' : '<'
            return {
                ...t,
                title: `${t.title} (${dt?.label ?? ''} ${sign} ${t.value} ${dt?.unit ?? ''})`,
                key: `${t.dataType ?? '4'}:${t.id}`,
            }
        })
        return (
            <JobAlert
                stationType='hydrometry'
                job={this.props.job}
                isEditMode={this.props.isEditMode}
                stations={this.props.hydrometers}
                thresholds={formatThresholds}
                onChangeEmails={this.props.onChangeEmails}
                onChangeContactsIds={this.props.onChangeContactsIds}
                onChangeStation={this.props.onChangeStation}
                onChangeAlertTypes={this.props.onChangeAlertTypes}
            />
        )
    }
}

AlertHydro.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    hydrometers: PropTypes.arrayOf(PropTypes.object),
    hydrometryThresholds: PropTypes.arrayOf(PropTypes.object),
    fetchHydrometers: PropTypes.func,
    fetchHydrometricThresholds: PropTypes.func,
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    hydrometers: store.HydrometryReducer.hydrometricStations.map(({ id, code, name }) => ({
        id,
        code: `${id}`,
        name: `[${code}] ${name}`,
    })),
    hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
})

const mapDispatchToProps = {
    fetchHydrometers: HydrometryAction.fetchHydrometricStations,
    fetchHydrometricThresholds: HydrometryAction.fetchHydrometricThresholds,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertHydro)