import { CampaignActionConstant } from 'campaign/reducers/CampaignReducer'
import { push } from '@lagunovsky/redux-react-router'
import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import fetch from 'isomorphic-fetch'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { PluviometryActionConstant } from 'pluviometry/reducers/PluviometryReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { ContactActionConstant } from 'referencial/components/contact/reducers/ContactReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import LogAction from '../../log/actions/LogAction'
import CentralAction from '../../materiel/components/central/actions/CentralAction'
import EquipmentAction from '../../materiel/components/equipment/actions/EquipmentAction'
import PowerSupplyAction from '../../materiel/components/powerSupply/actions/PowerSupplyAction'
import SensorAction from '../../materiel/components/sensor/actions/SensorAction'
import SimAction from '../../materiel/components/sim/actions/SimAction'
import TelecomAction from '../../materiel/components/telecom/actions/TelecomAction'
import VariousMaterielAction from '../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import {
    RECEIVE_PLUVIOMETRY_CAMPAIGN_STATIONS,
    RECEIVE_PLUVIOMETRY_CAMPAIGNS,
    RECEIVE_PLUVIOMETRY_CAMPAIGNS_PROGRESS,
} from '../../pluviometry/constants/PluviometryConstant'
import QualityAction from '../../quality/actions/QualityAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import FractionAction from '../../referencial/components/fraction/actions/FractionAction'
import NetworkAction from '../../referencial/components/network/actions/NetworkAction'
import ParameterAction from '../../referencial/components/parameter/actions/ParameterAction'
import SupportAction from '../../referencial/components/support/actions/SupportAction'
import UnitAction from '../../referencial/components/unit/actions/UnitAction'
import {
    checkAuth,
    checkError,
    genericDelete,
    genericPromise,
    genericPromise2,
    getAuthorization,
    getJson,
    promiseAllProgress,
} from '../../utils/ActionUtils'
import { DELETE_VISIT } from '../constants/CampaignConstants'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import { compact } from 'lodash'

const CampaignAction = {

    promiseCampaigns: stationType => fetch(ApplicationConf.campaign.campaigns(stationType), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    promiseCampaign: (stationType, id) => fetch(ApplicationConf.campaign.campaign(stationType, id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCampaign: (stationType, id) => dispatch => CampaignAction.promiseCampaign(stationType, id)
        .then(json => dispatch(CampaignActionConstant.campaign(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaign} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaign))
        }),

    promiseCampaignStations: stationType => fetch(ApplicationConf.campaign.campaignStations(stationType), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    promiseCampaignStation: (stationType, id) => fetch(ApplicationConf.campaign.campaignStation(stationType, id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCampaignStation: (stationType, id) => dispatch => CampaignAction.promiseCampaignStation(stationType, id)
        .then(json => dispatch(CampaignActionConstant.campaignStations(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.station} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.station))
        }),

    promiseCampaignsProgress: stationType => fetch(ApplicationConf.campaign.campaignsProgress(stationType), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    promiseCampaignProgress: (stationType, id) => fetch(ApplicationConf.campaign.campaignProgress(stationType, id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCampaignProgress: (stationType, id) => dispatch => CampaignAction.promiseCampaignProgress(stationType, id)
        .then(json => dispatch(CampaignActionConstant.campaignProgress(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaignsProgress} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaignsProgress))
        }),


    createCampaign: (campaign, stationType) => dispatch => fetch(ApplicationConf.campaign.campaigns(stationType), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(campaign),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.createError}${i18n.campaign} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError + i18n.campaign))
        }),

    updateCampaign: (campaign, id, stationType) => dispatch => fetch(ApplicationConf.campaign.campaign(stationType, id), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify(campaign),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then((json = {}) => {
            if (json.update > 0) {
                dispatch(CampaignAction.fetchCampaign(stationType, id))
                dispatch(ToastrAction.success(i18n.campaignSuccessUpdated))
            } else {
                throw new Error()
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError}${i18n.campaign} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaign))
        }),

    deleteCampaign: (stationType, id) => dispatch => fetch(ApplicationConf.campaign.campaign(stationType, id), {
        method: 'DELETE',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then((json = {}) => {
            if (json.delete > 0) {
                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                dispatch(push(`/${stationType}/campaign`))
            } else {
                throw new Error()
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.deleteError}${i18n.campaign} : ${err}`))
            dispatch(ToastrAction.error(i18n.deleteError + i18n.campaign))
        }),

    updateCampaignStations: (stations, stationType, id) => dispatch => fetch(ApplicationConf.campaign.campaignStation(stationType, id), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(stations),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(() => {
            dispatch(CampaignAction.fetchCampaignStation(stationType, id))
            dispatch(CampaignAction.fetchCampaignProgress(stationType, id))
            dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError}${i18n.stations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.stations))
        }),

    promiseCampaignMateriels: id => fetch(ApplicationConf.materiel.campaignMateriels(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCampaignMateriels: id => dispatch => CampaignAction.promiseCampaignMateriels(id)
        .then((json = {}) => {
            dispatch(CampaignActionConstant.campaignMateriels(json))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.materiels} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.materiels))
        }),

    loadCampaignMaterielsApp: (id, progressCallback = () => { }) => dispatch => promiseAllProgress([
        CentralAction.promiseCentrals(),
        PowerSupplyAction.promisePowerSupplies(),
        SensorAction.promiseSensors(),
        SimAction.promiseSims(),
        TelecomAction.promiseTelecoms(),
        VariousMaterielAction.promiseVariousMateriels(),
        EquipmentAction.promiseEquipments(),
        CentralAction.promiseCentralTypes(),
        PowerSupplyAction.promisePowerSupplyTypes(),
        SensorAction.promiseSensorTypes(),
        SimAction.promiseSimTypes(),
        TelecomAction.promiseTelecomTypes(),
        VariousMaterielAction.promiseVariousMaterielTypes(),
        EquipmentAction.promiseEquipmentTypes(),
        CampaignAction.promiseCampaignMateriels(id),
        CentralAction.promiseCentralLastSituation(),
        PowerSupplyAction.promisePowerSuppliesLastSituation(),
        SensorAction.promiseSensorLastSituation(),
        TelecomAction.promiseTelecomLastSituation(),
        SimAction.promiseSimsLastSituations(),
        VariousMaterielAction.promiseVariousMaterielsLastSituations(),
        EquipmentAction.promiseEquipmentsLastSituations(),
    ], progressCallback).then(jsonTab => {
        dispatch(CentralAction.receiveCentrals(jsonTab[0]))
        dispatch(PowerSupplyAction.receivePowerSupplies(jsonTab[1]))
        dispatch(SensorAction.receiveSensors(jsonTab[2]))
        dispatch(SimAction.receiveSims(jsonTab[3]))
        dispatch(TelecomAction.receiveTelecoms(jsonTab[4]))
        dispatch(VariousMaterielAction.receiveVariousMateriels(jsonTab[5]))
        dispatch(EquipmentAction.receiveEquipments(jsonTab[6]))
        dispatch(CentralAction.receiveCentralTypes(jsonTab[7]))
        dispatch(PowerSupplyAction.receivePowerSupplyTypes(jsonTab[8]))
        dispatch(SensorAction.receiveSensorTypes(jsonTab[9]))
        dispatch(SimAction.receiveSimTypes(jsonTab[10]))
        dispatch(TelecomAction.receiveTelecomTypes(jsonTab[11]))
        dispatch(VariousMaterielAction.receiveVariousMaterielTypes(jsonTab[12]))
        dispatch(EquipmentAction.receiveEquipmentTypes(jsonTab[13]))
        dispatch(CampaignActionConstant.campaignMateriels(jsonTab[14]))
        dispatch(CentralAction.receiveCentralsLastSituations(jsonTab[15]))
        dispatch(PowerSupplyAction.receivePowerSupplyLastSituations(jsonTab[16]))
        dispatch(SensorAction.receiveSensorLastSituations(jsonTab[17]))
        dispatch(TelecomAction.receiveTelecomLastSituations(jsonTab[18]))
        dispatch(SimAction.receiveSimLastSituations(jsonTab[19]))
        dispatch(VariousMaterielAction.receiveVariousMaterielLastSituations(jsonTab[20]))
        dispatch(EquipmentAction.receiveEquipmentLastSituations(jsonTab[21]))
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    loadCampaignDashboard: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        ContactAction.promiseContacts(),
        ContributorAction.promiseContributors(),
    ], progressCallback).then(jsonTab => {
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
        dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[1]))
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    resetCampaign: () => dispatch => dispatch(CampaignActionConstant.reset()),


    // piezo
    fetchPiezometryCampaigns: () => dispatch => CampaignAction.promiseCampaigns('piezometry')
        .then(json => dispatch(PiezometryActionConstant.receivePiezometryCampaigns(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaigns} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaigns))
        }),

    fetchPiezometryCampaignsProgress: () => dispatch => CampaignAction.promiseCampaignsProgress('piezometry')
        .then(json => dispatch(PiezometryActionConstant.receivePiezometryCampaignsProgress(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaignsProgress} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaignsProgress))
        }),

    fetchPiezometryCampaignStations: () => dispatch => CampaignAction.promiseCampaignStations('piezometry')
        .then(json => dispatch(PiezometryActionConstant.receivePiezometryCampaignsStations(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaignStations} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaignStations))
        }),

    promisePiezometryCampaignEvent: id => fetch(ApplicationConf.piezometer.campaignEvent(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchPiezometryCampaignEvent: id => dispatch => CampaignAction.promisePiezometryCampaignEvent(id)
        .then(json => dispatch(CampaignActionConstant.campaignEvents(json))),

    fetchQualityCampaignOperation: id => dispatch => fetch(ApplicationConf.qualitometer.operationCampaign(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => dispatch(CampaignActionConstant.campaignOperations(json))),

    loadPiezometryCampaigns: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        ContactAction.promiseContacts(),
        PiezometryAction.promisePiezometersLight(),
        CampaignAction.promiseCampaigns('piezometry'),
        CampaignAction.promiseCampaignsProgress('piezometry'),
    ], progressCallback).then(jsonTab => {
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
        dispatch(PiezometryActionConstant.receiveAllPiezometersLight(jsonTab[1]))
        dispatch(PiezometryActionConstant.receivePiezometryCampaigns(jsonTab[2]))
        dispatch(PiezometryActionConstant.receivePiezometryCampaignsProgress(jsonTab[3]))
    }).catch((err) => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    loadPiezometryCampaignDashboard: (id, progressCallback = () => { }) => dispatch => promiseAllProgress(compact([
        ContactAction.promiseContacts(),
        PiezometryAction.promisePiezometersAccessibilities(),
        ContributorAction.promiseContributors(),
        id !== 'new' && id !== 'duplicate' && CampaignAction.promisePiezometryCampaignEvent(id),
    ]), progressCallback).then(jsonTab => {
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
        dispatch(PiezometryActionConstant.receiveAllPiezometersAccessibilities(jsonTab[1]))
        dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[2]))
        if (id !== 'new' && id !== 'duplicate') {
            dispatch(CampaignActionConstant.campaignEvents(jsonTab[3]))
        }
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),


    // pluvio

    receivePluviometryCampaigns: campaigns => ({ type: RECEIVE_PLUVIOMETRY_CAMPAIGNS, campaigns }),

    fetchPluviometryCampaigns: () => dispatch => CampaignAction.promiseCampaigns('pluviometry')
        .then(json => dispatch(CampaignAction.receivePluviometryCampaigns(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaigns} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaigns))
        }),

    receivePluviometryCampaignStations: campaignStations => ({ type: RECEIVE_PLUVIOMETRY_CAMPAIGN_STATIONS, campaignStations }),

    fetchPluviometryCampaignStations: () => dispatch => CampaignAction.promiseCampaignStations('pluviometry')
        .then(json => dispatch(CampaignAction.receivePluviometryCampaignStations(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaignStations} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaignStations))
        }),


    receivePluviometryCampaignsProgress: campaignsProgress => ({ type: RECEIVE_PLUVIOMETRY_CAMPAIGNS_PROGRESS, campaignsProgress }),

    fetchPluviometryCampaignEvent: id => dispatch => fetch(ApplicationConf.pluviometry.campaignEvents(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => dispatch(CampaignActionConstant.campaignEvents(json))),

    loadPluviometryCampaigns: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        ContactAction.promiseContacts(),
        // PluviometryAction.promisePluviometers(),
        CampaignAction.promiseCampaigns('pluviometry'),
        CampaignAction.promiseCampaignsProgress('pluviometry'),
    ], progressCallback).then(jsonTab => {
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
        // dispatch(PluviometryAction.receivePluviometers(jsonTab[1]))
        dispatch(PluviometryActionConstant.receivePluviometryCampaigns(jsonTab[1]))
        dispatch(PluviometryActionConstant.receivePluviometryCampaignsProgress(jsonTab[2]))
    }).catch((err) => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),


    // qualito

    fetchQualityCampaigns: () => dispatch => CampaignAction.promiseCampaigns('quality')
        .then(json => dispatch(QualityActionConstant.receiveQualityCampaigns(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaigns} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaigns))
        }),

    fetchQualityCampaignStations: () => dispatch => CampaignAction.promiseCampaignStations('quality')
        .then(json => dispatch(QualityActionConstant.receiveQualityCampaignStations(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaignStations} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaignStations))
        }),


    loadQualityCampaigns: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        ContactAction.promiseContacts(),
        QualityAction.promiseQualitometersLight(),
        CampaignAction.promiseCampaigns('quality'),
        CampaignAction.promiseCampaignsProgress('quality'),
    ], progressCallback).then(jsonTab => {
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
        dispatch(QualityActionConstant.receiveQualitometersLight(jsonTab[1]))
        dispatch(QualityActionConstant.receiveQualityCampaigns(jsonTab[2]))
        dispatch(QualityActionConstant.receiveQualityCampaignsProgress(jsonTab[3]))
    }).catch((err) => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    loadQualityCampaignDashboard: (progressCallback = () => { }) => dispatch => {
        return promiseAllProgress([
            ContactAction.promiseContacts(),
            ContributorAction.promiseContributors(),
            ParameterAction.promiseParameters(),
            NetworkAction.promiseNetworks(),
            QualityAction.promiseQualityNetworkLinks(),
            FractionAction.promiseFractions(),
            SupportAction.promiseSupports(),
            UnitAction.promiseUnits(),
        ], progressCallback).then(jsonTab => {
            dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
            dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[1]))
            dispatch(ParameterAction.receiveParameters(jsonTab[2]))
            dispatch(NetworkAction.receiveNetworks(jsonTab[3]))
            dispatch(QualityActionConstant.receiveQualityNetworkLinks(jsonTab[4]))
            dispatch(FractionAction.receiveFractions(jsonTab[5]))
            dispatch(SupportAction.receiveSupports(jsonTab[6]))
            dispatch(UnitAction.receiveUnits(jsonTab[7]))
        }).catch(err => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },

    promiseEDILABOFile: id => fetch(ApplicationConf.campaign.edilaboFiles(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchEDILABOFile: id => dispatch => CampaignAction.promiseEDILABOFile(id)
        .then(json => dispatch(CampaignActionConstant.edilaboFiles(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.EDILABO} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.EDILABO))
        }),

    promiseCampaignFiles: id => fetch(ApplicationConf.campaign.campaignFiles(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCampaignFiles: id => dispatch => CampaignAction.promiseCampaignFiles(id)
        .then(json => {
            dispatch(CampaignActionConstant.campaignFiles(json))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.files} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
        }),

    promiseCampaignParameters: id => fetch(ApplicationConf.campaign.campaignParameter(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCampaignParameters: id => dispatch => CampaignAction.promiseCampaignParameters(id)
        .then(json => dispatch(CampaignActionConstant.campaignParameters(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.parameters} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.parameters))
        }),

    updateCampaignParameters: (id, parameters) => dispatch => fetch(ApplicationConf.campaign.campaignParameter(id), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(parameters),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(() => {
            dispatch(CampaignAction.fetchCampaignParameters(id))
            dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError}${i18n.parameters} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.parameters))
        }),

    promiseCampaignPlannings: id => fetch(ApplicationConf.campaign.campaignPlanning(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCampaignPlannings: id => dispatch => CampaignAction.promiseCampaignPlannings(id)
        .then(json => dispatch(CampaignActionConstant.campaignPlannings(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.plannings} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.plannings))
        }),

    updateCampaignPlannings: (id, plannings) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.campaign.campaignPlanning(id), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(plannings),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(WaitAction.waitStop())
                dispatch(CampaignAction.fetchCampaignPlannings(id))
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                return true
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.updateError}${i18n.plannings} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.plannings))
            })
    },


    // hydro
    fetchHydrometryCampaigns: () => dispatch => CampaignAction.promiseCampaigns('hydrometry')
        .then(json => dispatch(HydrometryActionConstant.receiveHydrometryCampaigns(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaigns} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaigns))
        }),

    fetchHydrometryCampaignStations: () => dispatch => CampaignAction.promiseCampaignStations('hydrometry')
        .then(json => dispatch(HydrometryActionConstant.receiveHydrometryCampaignStations(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaignStations} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaignStations))
        }),

    fetchHydrometryCampaignEvent: id => dispatch => fetch(ApplicationConf.hydrometricStation.campaignEvents(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => dispatch(CampaignActionConstant.campaignEvents(json))),

    loadHydrometryCampaigns: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        ContactAction.promiseContacts(),
        CampaignAction.promiseCampaigns('hydrometry'),
        CampaignAction.promiseCampaignsProgress('hydrometry'),
    ], progressCallback).then(jsonTab => {
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
        dispatch(HydrometryActionConstant.receiveHydrometryCampaigns(jsonTab[1]))
        dispatch(HydrometryActionConstant.receiveHydrometryCampaignsProgress(jsonTab[2]))
    }).catch((err) => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    fetchInstallationCampaigns: () => dispatch => CampaignAction.promiseCampaigns('installation')
        .then(json => dispatch(InstallationActionConstant.INSTALLATION_CAMPAIGNS(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaigns} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaigns))
        }),

    fetchInstallationCampaignStations: () => dispatch => CampaignAction.promiseCampaignStations('installation')
        .then(json => dispatch(InstallationActionConstant.INSTALLATION_CAMPAIGN_STATIONS(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.campaignStations} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.campaignStations))
        }),

    fetchInstallationCampaignEvent: id => dispatch => fetch(ApplicationConf.installation.campaignEvents(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => dispatch(CampaignActionConstant.campaignEvents(json))),

    loadInstallationCampaigns: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        ContactAction.promiseContacts(),
        CampaignAction.promiseCampaignsInstallationWithStats(),
        CampaignAction.promiseCampaignsActions(),
        CityAction.promiseCities(),
    ], progressCallback).then(jsonTab => {
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
        dispatch(CampaignActionConstant.campaignsInstallationWithStats(jsonTab[1]))
        dispatch(CampaignActionConstant.campaignsActions(jsonTab[2]))
        dispatch(CityAction.receiveCities(jsonTab[3]))
    }).catch((err) => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    fetchAllCampaigns: () => dispatch => Promise.all([
        CampaignAction.promiseCampaigns('piezometry'),
        CampaignAction.promiseCampaigns('pluviometry'),
        CampaignAction.promiseCampaigns('hydrometry'),
        CampaignAction.promiseCampaigns('quality'),
        CampaignAction.promiseCampaigns('installation'),
    ]).then(jsonTab => {
        dispatch(PiezometryActionConstant.receivePiezometryCampaigns(jsonTab[0]))
        dispatch(CampaignAction.receivePluviometryCampaigns(jsonTab[1]))
        dispatch(HydrometryActionConstant.receiveHydrometryCampaigns(jsonTab[2]))
        dispatch(QualityActionConstant.receiveQualityCampaigns(jsonTab[3]))
        dispatch(CampaignAction.receiveInstallationCampaigns(jsonTab[4]))
    }),

    promiseCampaignVisits: idCampaign => genericPromise(ApplicationConf.campaign.campaignVisits(idCampaign)),

    fetchCampaignVisits: idCampaign => dispatch => {
        return CampaignAction.promiseCampaignVisits(idCampaign)
            .then((json = []) => {
                dispatch(CampaignActionConstant.campaignVisits(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    deleteVisit: (idCampaign, idInstallation) => genericDelete(ApplicationConf.campaign.deleteVisit(idCampaign, idInstallation), DELETE_VISIT),

    promiseCampaignsInstallationWithStats: (url = '') => genericPromise(ApplicationConf.campaign.allCampaignsInstallationWithStats(url)),

    promiseCampaignsActions: (url = '') => genericPromise(ApplicationConf.campaign.allCampaignsActions(url)),

    fecthCampaignsActions: url => dispatch => {
        return CampaignAction.promiseCampaignsActions(url)
            .then((json = []) => {
                dispatch(CampaignActionConstant.campaignsActions(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    fetchCampaignsInstallationWithStats: url => dispatch => {
        return CampaignAction.promiseCampaignsInstallationWithStats(url)
            .then((json = []) => {
                dispatch(CampaignActionConstant.campaignsInstallationWithStats(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    unlinkOperations: id => dispatch => genericPromise2(ApplicationConf.campaign.unlink(id), { method: 'PUT' })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError))
        }),

    linkOperations: id => dispatch => genericPromise2(ApplicationConf.campaign.link(id), { method: 'PUT' })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError))
        }),

    getStationsParametersCampaign: id => dispatch => Promise.all([
        CampaignAction.promiseCampaignStation('quality', id),
        CampaignAction.promiseCampaignParameters(id),
    ])
        .then((jsonTab = []) => {
            const [stations, parameters] = jsonTab
            return {
                stations,
                parameters,
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError))
        }),
}


export default CampaignAction
