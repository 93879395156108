import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Row from '../../components/react/Row'
import Button from '../../components/forms/Button'
import SimpleDatePicker from '../../components/forms/SimpleDatePicker'
import DtoStation from '../dto/DtoStation'
import DtoParametrageDataType from '../../piezometry/dto/DtoParametrageDataType'
import Select from '../../components/forms/Select'
import { getMeasureCoteList } from '../../utils/JobUtils'
import RadioButtons from '../../components/forms/RadioButtons'
import {
    getOptionCumul,
    getOptionCumulPerso,
    getQualificationSelectOptions,
    getStatusSelectOptions,
} from '../../utils/StatusUtil'
import {
    PERSONALIZED_GROUPING,
} from '../../utils/constants/MeasureConstants'
import { isNil } from 'lodash'
import NumberField from '../../components/forms/NumberField'
import { STATION_TYPE_NAME } from '../constants/StationConstants'
import { Grid } from '@mui/material'
import Checkbox from '../../components/forms/Checkbox'
import Input from '../../components/forms/Input'

const StationsExcelExportPanel = ({
    onChange = () => {},
    onChangeFilter = () => {},
    onApplyExport = () => {},
    selectedStations = [],
    tmpFilter = {},
    dataTypes = [],
    selectedDataType = 0,
    displayCote,
    stationType = '',
}) => {
    const [isPersonalizedGrouping, setIsPersonalizedGrouping] = useState(false)
    const [optionCumulSelected, setOptionCumulSelected] = useState(undefined)
    const [nbDays, setNbDays] = useState(undefined)

    const onChangeFilterGroup = (v) => {
        setOptionCumulSelected(undefined)
        setNbDays(undefined)
        setIsPersonalizedGrouping(v === PERSONALIZED_GROUPING)
        onChangeFilter({ group: v })
    }

    const updateFilter = (group, days) => {
        setOptionCumulSelected(group)
        setNbDays(days)
        if (!isNil(group) && !isNil(days)) {
            onChangeFilter({ group: `${group}_${days * 24}` }) // back prend des heures donc x24 pour 1j
        }
    }

    const optionsCumul = useMemo(getOptionCumul, [])
    const optionsCumulPerso = useMemo(getOptionCumulPerso, [])

    return (
        <Grid padding={'0.5rem'}>
            <fieldset>
                <legend className='italic-label padding-right-1 padding-left-1'>{i18n.exportCriterias}</legend>
                <Row>
                    <Select
                        col={5}
                        label={i18n.dataTypes}
                        options={dataTypes}
                        onChange={v => onChange({ dataType: v })}
                        value={selectedDataType}
                        keyValue='id'
                        keyLabel='label'
                        nullLabel={i18n.allData}
                    />
                    <SimpleDatePicker
                        col={2}
                        value={tmpFilter.startDate}
                        label={i18n.startDate}
                        onChange={v => onChangeFilter({ startDate: v })}
                    />
                    <SimpleDatePicker
                        col={2}
                        value={tmpFilter.endDate}
                        label={i18n.endDate}
                        onChange={v => onChangeFilter({ endDate: v })}
                    />
                    <Button
                        col={3}
                        title={`${i18n.selectStations} (${selectedStations.length})`}
                        onClick={() => onChange({ selectStationIsOpen: true })}
                    />
                </Row>
                <Row className='valign-wrapper'>
                    {
                        stationType === STATION_TYPE_NAME.piezometry && (
                            <RadioButtons
                                col={2}
                                elements={getMeasureCoteList()}
                                selected={displayCote}
                                onChange={v => onChange({ displayCote: v })}
                                title={i18n.ratingExpression}
                                disabled={selectedDataType !== -1}
                            />
                        )
                    }
                    {
                        ([STATION_TYPE_NAME.piezometry, STATION_TYPE_NAME.hydrometry, STATION_TYPE_NAME.pluviometry].includes(stationType)) && (
                            <Select
                                label={i18n.modeGrouping}
                                options={optionsCumul}
                                value={tmpFilter?.group?.startsWith('CUMUL') ? PERSONALIZED_GROUPING : tmpFilter.group}
                                onChange={v => onChangeFilterGroup(v)}
                                col={2}
                            />
                        )
                    }
                    {
                        isPersonalizedGrouping && (
                            <>
                                <Select
                                    label={i18n.regrouping}
                                    options={optionsCumulPerso}
                                    value={optionCumulSelected}
                                    onChange={v => updateFilter(v, nbDays)}
                                    col={2}
                                    obligatory
                                />
                                <NumberField
                                    col={1}
                                    min={1}
                                    title={i18n.nbDays}
                                    value={ nbDays }
                                    onChange={v => updateFilter(optionCumulSelected, v)}
                                    obligatory
                                />
                            </>
                        )
                    }
                    {
                        ([STATION_TYPE_NAME.piezometry, STATION_TYPE_NAME.hydrometry, STATION_TYPE_NAME.pluviometry].includes(stationType)) && (
                            <>
                                <Select
                                    label={i18n.status}
                                    options={getStatusSelectOptions()}
                                    value={tmpFilter.status}
                                    onChange={v => onChangeFilter({ status: isNil(v) ? undefined : [v] })}
                                    col={2}
                                />
                                <Select
                                    label={i18n.qualification}
                                    options={getQualificationSelectOptions()}
                                    value={tmpFilter.qualifications}
                                    onChange={v => onChangeFilter({ qualifications: isNil(v) ? undefined : [v] })}
                                    col={2}
                                />
                            </>
                        )
                    }
                    <Checkbox col={3} checked={tmpFilter.withStatusQualificationCodes} onChange={v => onChangeFilter({ withStatusQualificationCodes: v })} label='Exporter les codes statut/Qualification' />

                </Row>
                <Row>
                    <Input
                        title={i18n.defineDirectory}
                        value={tmpFilter.exportDir}
                        onChange={v => onChangeFilter({ exportDir: v })}
                        col={3}
                    />
                    <div className='col s6' />
                    <Button
                        col={3}
                        title={i18n.apply}
                        onClick={onApplyExport}
                        disabled={ isPersonalizedGrouping ? isNil(optionCumulSelected) || isNil(nbDays) : false }
                    />
                </Row>
            </fieldset>
        </Grid>
    )
}


StationsExcelExportPanel.propTypes = {
    onChange: PropTypes.func,
    onChangeFilter: PropTypes.func,
    selectedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    tmpFilter: PropTypes.shape({
        startDate: PropTypes.number,
        endDate: PropTypes.number,
    }),
    onApplyExport: PropTypes.func,
    dataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    selectedDataType: PropTypes.number,
    displayCote: PropTypes.number,
    stationType: PropTypes.string,
}

export default StationsExcelExportPanel
