import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DtoAssociation from './dto/DtoAssociation'
import Table from '../../components/datatable/Table'
import AppStore from 'store/AppStore'
import { push } from '@lagunovsky/redux-react-router'
import { arrayOf, getLabel, getObjectLabel, getPropType, objectOf } from '../../utils/StoreUtils'
import { groupBy, orderBy } from 'lodash'
import { nbPerPageLabelShort } from '../../referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import {
    STATION_NAME_ASSOCIATION,
    STATION_QUALITOMETER_NAMES,
    STATION_TYPE,
} from '../../station/constants/StationConstants'
import { getSiteUrl } from '../../utils/mapUtils/SiteTypes'
import Checkbox from '../../components/forms/Checkbox'
import Card from '../card/Card'
import AdministrationAction from '../../administration/actions/AdministrationAction'

import { getI18nTitleDataLength, i18nize } from '../../utils/StringUtil'
import CityDto from '../../referencial/components/city/dto/CityDto'
import { INSTALLATION_TYPE, INSTALLATION_TYPES } from '../../installation/constants/InstallationConstants'
import { setStationPointScale, setStationsOpacity } from './mapTreatments/AddingLayers'
import { execByType } from '../../utils/StationUtils'
import ListComponentCheckbox from '../list/tableList/ListComponentCheckbox'
import CMSEventDto from '../../events/dto/CMSEventDto'
import { connect } from 'react-redux'
import { hasValue } from 'utils/NumberUtil'
import SettingDto from 'administration/dto/SettingDto'
import { homeListOfSelectedStations } from 'carto/constants/CartoConstants'
import AccountAction from 'account/actions/AccountAction'
import moment from 'moment'
import { getLogin } from 'utils/SettingUtils'

const nameStationTypes = ['QUALITOMETER', 'PLUVIOMETER', 'PIEZOMETER', 'HYDROMETRIC_STATION', 'PRODUCTION_UNIT', 'DISTRIBUTION_UNIT', 'INSTALLATION', 'CONTACT', 'CONTRIBUTOR', 'other']

class CartographyStationsPanel extends Component {
    state = {
        stations: [],
        dataLoaded: false,
    }

    componentDidMount() {
        const { login } = this.props

        this.props.fetchSettings(login).then(() => {
            this.setStationsState()
        })
    }

    getNewStationParameter = (stationTypeName) => {
        const { stationsPoints } = this.props
        const { stations } = this.state
        if (stationTypeName === 'QUALITOMETER') {
            return stations.some(s => s.includes('3_')) ?
                stations.filter(s => !s.includes('3_'))
                : [
                    ...stations.filter(s => !s.includes('3_')),
                    ...Object.keys(
                        groupBy(
                            stationsPoints.filter(s => s.typeName === 'quality')
                            , 'stationType',
                        ))
                        .map(stationType => `3_${stationType}`),
                ]
        } else if (stationTypeName === 'INSTALLATION') {
            return stations.some(s => s.includes('7_')) ?
                stations.filter(s => !s.includes('7_'))
                : [
                    ...stations.filter(s => !s.includes('7_')),
                    ...Object.keys(
                        groupBy(
                            stationsPoints.filter(s => s.typeName === 'installation')
                            , 'installationType',
                        ))
                        .map(stationType => `7_${stationType}`),
                ]
        } else if (stations.includes(stationTypeName) && stationTypeName.includes('3_')) {
            return stations.includes(stationTypeName) ?
                stations.filter(s => s !== stationTypeName)
                : [
                    ...stations,
                    stationTypeName,
                ]
        } else if (stations.includes(stationTypeName) && stationTypeName.includes('7_')) {
            return stations.includes(stationTypeName) ?
                stations.filter(s => s !== stationTypeName)
                : [
                    ...stations,
                    stationTypeName,
                ]
        }
        return stations.includes(stationTypeName) ?
            stations.filter(s => s !== stationTypeName)
            : [
                ...stations,
                stationTypeName,
            ]
    }

    saveCartographyStations = (stationTypeName) => {
        const { login = getLogin(), settings } = this.props
        const newStations = this.getNewStationParameter(stationTypeName)
        const stationsString = newStations.join() || ''

        const filterSettings = settings.filter(o => o.parameter !== homeListOfSelectedStations)
        const newSettings = [ ...filterSettings, {
            login,
            parameter: homeListOfSelectedStations,
            updateDate: moment().valueOf(),
            value: stationsString,
        }]

        this.setState({ stations: newStations })
        this.props.updateSettingUser(login, newSettings)
    }

    getSelectedStationsParameter = () => {
        const { settings } = this.props
        const selectedStationsParameter = (settings.find(s => s.parameter === homeListOfSelectedStations) || {}).value
        return selectedStationsParameter
    }

    getStationsStations = () => {
        const selectedStationsParameter = this.getSelectedStationsParameter()
        if (selectedStationsParameter?.length >= 0) {
            return selectedStationsParameter
        }
        return null
    }

    setStationsState = () => {
        const stringStations = this.getStationsStations()
        const stations = stringStations === null ? nameStationTypes : stringStations.length > 0 ? stringStations.split(',') : []
        this.setState({ stations, dataLoaded: true })
    }

    getLayerType = (typeName) => {
        return STATION_NAME_ASSOCIATION[typeName]
    }

    handleChangeVisible(typeName, v) {
        const { stationsPoints } = this.props
        if (this.props.onChangeVisible) {
            const layerType = this.getLayerType(typeName)
            execByType(typeName, {
                quality: () => {
                    const qualityTypes = groupBy(
                        stationsPoints.filter(s => s.typeName === 'quality')
                        , 'stationType',
                    )
                    this.props.onChangeVisible(Object.keys(qualityTypes).map(qualitoType => `3_${qualitoType}`), v)
                    this.props.setSelectedSearchValues(this.props.checkboxesType, Object.keys(qualityTypes).reduce((acc, qualitoType) => ({ ...acc, [`3_${qualitoType}`]: { enabled: v } }), {}))
                },
                installation: () => {
                    const installationTypes = groupBy(
                        stationsPoints.filter(s => s.typeName === 'installation')
                        , 'installationType',
                    )
                    this.props.onChangeVisible(Object.keys(installationTypes).map(installationType => `7_${installationType}`), v)
                    this.props.setSelectedSearchValues(this.props.checkboxesType, Object.keys(installationTypes).reduce((acc, installationType) => ({ ...acc, [`7_${installationType}`]: { enabled: v } }), {}))
                },
                default: () => {
                    if (layerType) {
                        this.props.onChangeVisible([layerType])
                        this.props.setSelectedSearchValues(this.props.checkboxesType, {
                            [layerType]: { enabled: v },
                        })
                    }
                },
            })
            this.saveCartographyStations(layerType)
        }
    }

    getQualitometersByStationTypes = (qualitometers) => {
        const groupedQualitos = groupBy(qualitometers, 'stationType')
        const tables = Object.keys(groupedQualitos).map(stationType => {
            const foundType = Object.keys(STATION_TYPE).find(t => STATION_TYPE[t] == stationType)
            return {
                title: (
                    <div className='col s12' >
                        <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1'>
                            <div className='col s2 offset-s1 center-align'>
                                <div className='iconeTypeResearchWrapper'>
                                    <img src={getSiteUrl(`QUALITOMETER${foundType ? `_${foundType}` : ''}`).img} className='responsive-img iconeTypeResearch' />
                                </div>
                            </div>
                            <div className='col s9 no-padding'>
                                <h6>
                                    <b className='font-size-16'>{`${getLabel(STATION_QUALITOMETER_NAMES, stationType)} (${groupedQualitos[stationType].length})`}</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                ),
                checkbox: (
                    <div className='padding-top-1 row no-margin valign-wrapper' onClick={(e) => {
                        e.preventDefault()
                        const newValue = !this.props.visible[`3_${stationType}`]
                        const qualitoLayer = newValue ? { 3: { enabled: true } } : {}
                        setStationsOpacity(groupedQualitos[stationType], newValue ? 1 : 0)
                        this.props.onChangeVisible([`3_${stationType}`, newValue && !this.props.visible[3] ? '3' : null])
                        this.props.setSelectedSearchValues(this.props.checkboxesType, {
                            [`3_${stationType}`]: { enabled: newValue },
                            ...qualitoLayer,
                        })
                        this.saveCartographyStations(`3_${stationType}`)
                    }}
                    >
                        <Checkbox checked={this.props.visible[`3_${stationType}`]} />
                    </div>
                ),
                component: (
                    <Table
                        data={groupedQualitos[stationType]}
                        sortable
                        paging
                        nbPerPageLabel={nbPerPageLabelShort}
                        showTitle={false}
                        smallPaging
                        onLineOver={(station) => setStationPointScale(station, true)}
                        onLineOut={(station) => setStationPointScale(station, false)}
                        type={this.props.tableHeaders ? { headers: this.props.tableHeaders } : new DtoAssociation({})}
                        onClick={this.props.onListElementClick || ((s) => AppStore.dispatch(push(`/station/quality/${s.id}`)))}
                    />
                ),
            }
        })
        return <ListComponentCheckbox tables={tables} accordion />
    }

    getInstallationsByStationTypes = (installations) => {
        const groupedInstallations = groupBy(installations, 'installationType')
        const tables = Object.keys(groupedInstallations).map(stationType => {
            const foundType = Object.keys(INSTALLATION_TYPE).find(t => INSTALLATION_TYPE[t] == stationType)
            return {
                title: (
                    <div className='col s12' >
                        <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1'>
                            <div className='col s2 offset-s1 center-align'>
                                <div className='iconeTypeResearchWrapper'>
                                    <img src={getSiteUrl(`INSTALLATION${foundType ? `_${foundType}` : ''}`)} className='responsive-img iconeTypeResearch' />
                                </div>
                            </div>
                            <div className='col s9 no-padding'>
                                <h6>
                                    <b className='font-size-16'>{`${getLabel(i18nize(INSTALLATION_TYPES), stationType) || i18n.installation} (${groupedInstallations[stationType].length})`}</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                ),
                checkbox: (
                    <div className='padding-top-1 row no-margin valign-wrapper' onClick={(e) => {
                        e.preventDefault()
                        const newValue = !this.props.visible[`7_${stationType}` || '-1']
                        const installationLayer = newValue ? { 7: { enabled: true } } : {}
                        setStationsOpacity(groupedInstallations[stationType], newValue ? 1 : 0)
                        this.props.onChangeVisible([`7_${stationType}` || '-1', newValue && !this.props.visible[7] ? '7' : null])
                        const obj = {
                            [`7_${stationType}` || '-1']: { enabled: newValue },
                            ...installationLayer,
                        }
                        this.props.setSelectedSearchValues(this.props.checkboxesType, obj)
                        this.saveCartographyStations(`7_${stationType}` || '-1')
                    }}
                    >
                        <Checkbox checked={this.props.visible[`7_${stationType}` || '-1']} />
                    </div>
                ),
                component: (
                    <Table data={groupedInstallations[stationType]} sortable paging nbPerPageLabel={nbPerPageLabelShort} showTitle={false} smallPaging
                        onLineOver={(station) => setStationPointScale(station, true)} onLineOut={(station) => setStationPointScale(station, false)}
                        type={this.props.tableHeaders ? { headers: this.props.tableHeaders } : new DtoAssociation({})}
                        onClick={this.props.onListElementClick || ((s) => AppStore.dispatch(push(`/station/installation/${s.id}`)))}
                    />
                ),
            }
        })
        return <ListComponentCheckbox tables={tables} accordion />
    }

    getCheckedType = typeName => {
        const { visible } = this.props
        switch (typeName) {
            case 'installation':
                return Object.keys(visible).filter(key => key.includes('7_') && visible[key]).length
            case 'quality':
                return Object.keys(visible).filter(key => key.includes('3_') && visible[key]).length
            default:
                return visible[this.getLayerType(typeName)]
        }
    }

    getTableList = (groupedStations) => {
        const tables = Object.keys(groupedStations).map(typeName => {
            const titleValues = (() => {
                switch (typeName) {
                    case 'quality':
                        return {
                            singleTitle: i18n.qualitometer,
                            pluralTitle: i18n.qualitometers,
                            icon: getSiteUrl('QUALITOMETER'),
                        }
                    case 'piezometry':
                        return {
                            singleTitle: i18n.piezometer,
                            pluralTitle: i18n.piezometers,
                            icon: getSiteUrl('PIEZOMETER'),
                        }
                    case 'hydrometry':
                        return {
                            singleTitle: i18n.hydrometricStation,
                            pluralTitle: i18n.hydrometricStations,
                            icon: getSiteUrl('HYDROMETRIC_STATION'),
                        }
                    case 'pluviometry':
                        return {
                            singleTitle: i18n.pluviometer,
                            pluralTitle: i18n.pluviometers,
                            icon: getSiteUrl('PLUVIOMETER'),
                        }
                    case 'installation':
                        return {
                            singleTitle: i18n.installation,
                            pluralTitle: i18n.installations,
                            icon: getSiteUrl('INSTALLATION'),
                        }
                    case 'productionUnit':
                        return {
                            singleTitle: i18n.productionUnit,
                            pluralTitle: i18n.productionUnit,
                            icon: getSiteUrl('PRODUCTION_UNIT'),
                        }
                    case 'contact':
                        return {
                            singleTitle: i18n.contact,
                            pluralTitle: i18n.contacts,
                            icon: getSiteUrl('CONTACT'),
                        }
                    case 'contributor':
                        return {
                            singleTitle: i18n.contributor,
                            pluralTitle: i18n.contributors,
                            icon: getSiteUrl('CONTRIBUTOR'),
                        }
                    default:
                        return undefined
                }
            })()

            const stations = orderBy(groupedStations[typeName].map(s => ({ ...s, name: hasValue(s.name?.value) ? s.name?.value : s.name || '', code: s.code || s.id, city: getObjectLabel(this.props.citiesIndex[s.townCode || s.cityCode], 'labelWithCode') })), s => s.city ? s.city : 'ZZZ')

            const onClickElement = ((s) => {
                if (typeName === 'contact' || typeName === 'contributor') {
                    return AppStore.dispatch(push(`/referencial/${s.typeName}/${s.id}`))
                }
                return AppStore.dispatch(push(`/station/${s.typeName}/${s.id}`))
            })

            const component = (() => {
                switch (typeName) {
                    case 'quality':
                        return this.getQualitometersByStationTypes(stations)
                    case 'installation':
                        return this.getInstallationsByStationTypes(stations)
                    default:
                        return (
                            <Table data={stations} sortable paging nbPerPageLabel={nbPerPageLabelShort} showTitle={false}
                                onLineOver={(station) => setStationPointScale(station, true)} onLineOut={(station) => setStationPointScale(station, false)}
                                type={this.props.tableHeaders ? { headers: this.props.tableHeaders } : new DtoAssociation({})}
                                onClick={this.props.onListElementClick || ((s) => onClickElement(s))}
                            />
                        )
                }
            })()
            if (!titleValues) {
                return {}
            }
            return {
                title: (
                    <div className='col s12' >
                        <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1'>
                            <div className='col s2 center-align'>
                                <div className='iconeTypeResearchWrapper'>
                                    <img src={titleValues.icon} className='responsive-img iconeTypeResearch' />
                                </div>
                            </div>
                            <div className='col s10 no-padding'>
                                <h6 className='bold font-size-16'>
                                    {`${groupedStations[typeName].length === 1 ? titleValues.singleTitle : titleValues.pluralTitle} (${groupedStations[typeName].length})`}
                                </h6>
                            </div>
                        </div>
                    </div>
                ),
                checkbox: (
                    <div
                        className='padding-top-1 row no-margin valign-wrapper'
                        onClick={(e) => {
                            e.preventDefault()
                            this.handleChangeVisible(typeName, !this.getCheckedType(typeName))
                        }}
                    >
                        <Checkbox checked={this.getCheckedType(typeName)} onClick={v => this.handleChangeVisible(typeName, v)} />
                    </div>
                ),
                component,
            }
        })
        return <ListComponentCheckbox tables={tables} accordion />
    }

    getTitle = (length) => {
        const title = getI18nTitleDataLength(i18n.availableStation, i18n.availableStations, length)
        return (<Card className='padding-top-1 inline-block width-100' title={`${length} ${title}`}/>)
    }

    render() {
        const { dataLoaded } = this.state
        const { noStationsPanelTitle } = this.props
        if (dataLoaded) {
            if (this.props.stationsPoints.length) {
                const groupedStations = groupBy(this.props.stationsPoints, 'typeName')
                const nbStations = this.props.stationsPoints.length
                return (
                    <div style={{ position: 'static' }}>
                        { !noStationsPanelTitle && this.getTitle(nbStations)}
                        <div className='padding-top-1' style={{ position: 'static' }}/>
                        {this.getTableList(groupedStations)}
                    </div>
                )
            }
            return (
                <div className='col s12'>
                    <h5 className='center-align'>{i18n.noAssociatedStations}</h5>
                </div>
            )
        }
        return null
    }
}

CartographyStationsPanel.propTypes = {
    stationsPoints: getPropType('qualitometers'),
    noStationsPanelTitle: PropTypes.bool,
    onChangeVisible: PropTypes.onChangeVisible,
    visible: PropTypes.shape({
        6: PropTypes.bool,
        2: PropTypes.bool,
        7: PropTypes.bool,
        1: PropTypes.bool,
        4: PropTypes.bool,
        5: PropTypes.bool,
        3: PropTypes.bool,
        '7_0': PropTypes.bool,
        '7_1': PropTypes.bool,
        '7_2': PropTypes.bool,
        '7_3': PropTypes.bool,
        '7_4': PropTypes.bool,
        '7_5': PropTypes.bool,
        '7_6': PropTypes.bool,
        '7_7': PropTypes.bool,
        '7_8': PropTypes.bool,
        '7_9': PropTypes.bool,
        '7_10': PropTypes.bool,
        '7_11': PropTypes.bool,
        '7_12': PropTypes.bool,
        '7_13': PropTypes.bool,
        '7_14': PropTypes.bool,
        '7_16': PropTypes.bool,
        '7_17': PropTypes.bool,
        '7_18': PropTypes.bool,
        '7_20': PropTypes.bool,
        '7_-1': PropTypes.bool,
        '3_0': PropTypes.bool,
        '3_1': PropTypes.bool,
        '3_2': PropTypes.bool,
        '3_3': PropTypes.bool,
        '3_4': PropTypes.bool,
        '3_5': PropTypes.bool,
        '3_6': PropTypes.bool,
        '3_7': PropTypes.bool,
        '3_8': PropTypes.bool,
        contact: PropTypes.bool,
        CONTACT: PropTypes.bool,
        contributor: PropTypes.bool,
        CONTRIBUTOR: PropTypes.bool,
    }),
    citiesIndex: objectOf(CityDto),
    tableHeaders: PropTypes.arrayOf(PropTypes.string),
    onListElementClick: PropTypes.func,
    checkboxesType: PropTypes.string,
    pollutionPanel: PropTypes.bool,
    cmsEvents: arrayOf(CMSEventDto),
    setSelectedSearchValues: PropTypes.func,
    fetchSettings: PropTypes.func,
    updateSettingUser: PropTypes.func,
    updateSetting: PropTypes.func,
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    login: PropTypes.string,
}

const mapStateToProps = store => {
    return {
        settings: store.AdministrationReducer.userSettings,
    }
}

const mapDispatchToProps = {
    setSelectedSearchValues: AdministrationAction.setSelectedSearchValues,
    fetchSettings: AdministrationAction.fetchSettings,
    updateSettingUser: AccountAction.updateSettings,
    updateSetting: AdministrationAction.updateSetting,
}

export default connect(mapStateToProps, mapDispatchToProps)(CartographyStationsPanel)
