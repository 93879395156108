import { Button, Grid } from '@mui/material'
import CardToMuchData from 'components/card/CardToMuchData'
import Input from 'components/forms/Input'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { groupBy, keyBy, orderBy, round, sum, sumBy, take, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import OperationAction from 'quality/actions/OperationAction'
import QualitometerAction from 'quality/actions/QualitometerAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useAbortController from 'utils/customHook/useAbortController'
import useBoolean from 'utils/customHook/useBoolean'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import DtoQualityThreshold from '../../../station/components/suivipc/qualitometer/dto/DtoQualityThreshold'
import {
    calculateTaxonThresholdResult,
    calculateThresholdResult,
    calculateValue,
    filterThresholdLevel,
    getLocalizationPicto, getStatusAndResult,
} from '../../../utils/AnalyseUtils'
import { getDate, getFullDate, getHour, getYear } from '../../../utils/DateUtil'
import { formatData } from '../../../utils/ExportDataUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getStateLabels } from '../../../utils/QualityUtils'
import { getLabel } from '../../../utils/StoreUtils'
import DtoQualitometerState from '../../dto/states/DtoQualitometerState'
import pdf from 'assets/pictures/export/pdf.png'
import { push } from '@lagunovsky/redux-react-router'
import DtoAnalysis from 'quality/dto/analyse/DtoAnalysis'
import useActions from 'utils/customHook/useActions'
import ExportFileModal from 'components/modal/ExportFileModal'
import ExportAction from 'export/actions/ExportAction'
import DtoSearchIndices from 'quality/dto/DtoSearchIndices'
import DtoSearchHydrobio from 'quality/dto/taxons/DtoSearchHydrobio'
import useListIndexed from 'utils/customHook/useListIndexed'
import TooltipAnalyse from '../qualityComponents/TooltipAnalyse'
import AnalysisPopin from '../operation/components/analysis/AnalysisPopin'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import useSandreParameters from 'utils/customHook/useSandreParameters'

const maxNbElements = 25000

const DetailIndicesTable = ({
    indices = [],
    researchCriterias = {},
}) => {
    const dispatch = useDispatch()

    const {
        units,
        parameters,
        typeEnvironmentModels,
        supports,
    } = useSelector(store => ({
        units: store.UnitReducer.units,
        parameters: store.ParameterReducer.parameters,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    const parametersIndex = useMemo(() => keyBy(parameters, 'code'), [parameters])
    const unitsIndex = useMemo(() => keyBy(units, 'id'), [units])
    const indexedSupports = useListIndexed(supports, 'code')

    const {
        value: displayAll,
        setTrue: forceDisplay,
    } = useBoolean(false)

    const nbAllElement = indices.length

    const indicesFiltered = useMemo(() => displayAll ? indices : take(indices, maxNbElements), [indices, displayAll])

    const indicesFormated = useMemo(() => {
        return indicesFiltered.map(indice => {
            const value = calculateValue({ remark: indice.remarkCode, result: indice.result })
            return {
                stationCode: { value: indice.stationCode },
                stationName: { value: indice.stationName },
                sampleDate: { value: getFullDate(indice.startDate), cellType: 'right' },
                parameter: { value: parametersIndex[indice.parameter]?.labelWithCode },
                statusAndResult: {
                    value: getStatusAndResult(indice, value, 'white', true),
                    sortValue: indice.result,
                },
                unit: { value: unitsIndex[indice.unit]?.symbol },
                support: { value: indexedSupports[indice.support]?.name },
                nullValue: { value: getLocalizationPicto(indice.localization) },
                result: { value },
            }
        })
    }, [indicesFiltered, parametersIndex, unitsIndex])

    useActions(() => {
        return {
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
        }
    }, [])

    const exportIndices = extension => {
        const dataWithHeaders = indicesFormated.length ? [
            { ...indicesFormated[0], headers: ['stationCode', 'stationName', 'sampleDate', 'parameter', 'result', 'unit'] },
            ...indicesFormated.slice(1),
        ] : []
        dispatch(ExportAction.export(formatData(dataWithHeaders), extension, i18n.indicesDetail))
    }

    const exportTypes = useMemo(() => {
        const models = typeEnvironmentModels.map(model => ({
            name: model,
            formats: [{
                type: i18n.excelFile,
                callback: () => dispatch(ExportAction.exportResearchModel({ ...researchCriterias, stations: researchCriterias.stationsIds }, model)),
            }],
        }))
        return [
            {
                name: i18n.resultsTable,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => exportIndices('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => exportIndices('csv'),
                }],
            },
            {
                name: `${i18n.exportFull} CSV`,
                formats: [{
                    type: i18n.csvFile,
                    callback: () => {
                        dispatch(ExportAction.exportResearchFullCSV(researchCriterias))
                        closeExportModal()
                    },
                }],
            },
            ...models,
        ]
    }, [dispatch, researchCriterias, typeEnvironmentModels, indicesFormated])

    return (
        <>
            {nbAllElement > maxNbElements && !displayAll && (
                <CardToMuchData nbElements={nbAllElement} forceDisplay={forceDisplay} maxNbElements={maxNbElements} />
            )}
            <Table
                title={i18n.results}
                data={indicesFormated}
                sortable
                color
                type={{ headers: ['stationCode', 'stationName', 'sampleDate', 'parameter', 'statusAndResult', 'unit', 'support', 'nullValue'] }}
                condensed
                paging
                nbPerPageLabel={nbPerPageLabel}
            />
            <ExportFileModal
                open={isExportModalOpen}
                onClose={closeExportModal}
                data={exportTypes}
            />
        </>
    )
}

DetailIndicesTable.propTypes = {
    indices: PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchIndices)),
    researchCriterias: PropTypes.shape({}),
}

const DetailStatesTable = ({
    states = [],
    researchCriterias = {},
}) => {
    const dispatch = useDispatch()
    const {
        cities,
        qualitometers,
        typeEnvironmentModels,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        qualitometers: store.QualityReducer.qualitometersLight,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    const citiesIndex = useMemo(() => keyBy(cities, 'id'), [cities])
    const qualitometersIndex = useMemo(() => keyBy(qualitometers, 'id'), [qualitometers])

    const allYears = orderBy(uniq(states.map(s => moment(s.startDate).year())), x => x)
    const headers = ['station', 'city', 'state', ...allYears.map(year => `${year}`)]
    const stateLabels = useMemo(getStateLabels, [])

    const stateGroup = groupBy(states, s => `${s.stateId}:${s.qualitometer}`)
    const statesFormated = orderBy(Object.keys(stateGroup).map(key => {
        const [, qualitometerId] = key.split(':').map(id => parseInt(id))
        const group = stateGroup[key]
        const years = group.reduce((acc, state) => {
            acc[getYear(state.startDate)] = {
                ...(stateLabels[state.value] || { classNameColor: 'white' }),
                tooltip: state.comment,
            }
            return acc
        }, {})
        const station = qualitometersIndex[qualitometerId]
        return {
            station: { value: station ? `[${station.code || ''}]${station.name || ''}` : '' },
            city: { value: station ? citiesIndex[station.townCode]?.labelWithCode : '' },
            state: { value: group[0].stateName },
            order: group[0].order,
            ...years,
        }
    }), 'order')

    useActions(() => {
        return {
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
        }
    }, [])

    const exportStates = extension => {
        const dataWithHeaders = statesFormated.length ? [
            { ...statesFormated[0], headers },
            ...statesFormated.slice(1),
        ] : []
        dispatch(ExportAction.export(formatData(dataWithHeaders), extension, i18n.statesDetail))
    }

    const exportTypes = useMemo(() => {
        const models = typeEnvironmentModels.map(model => ({
            name: model,
            formats: [{
                type: i18n.excelFile,
                callback: () => dispatch(ExportAction.exportResearchModel(researchCriterias, model)),
            }],
        }))
        return [
            {
                name: i18n.resultsTable,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => exportStates('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => exportStates('csv'),
                }],
            },
            {
                name: `${i18n.exportFull} CSV`,
                formats: [{
                    type: i18n.csvFile,
                    callback: () => {
                        dispatch(ExportAction.exportResearchFullCSV(researchCriterias))
                        closeExportModal()
                    },
                }],
            },
            ...models,
        ]
    }, [dispatch, researchCriterias, typeEnvironmentModels, statesFormated])

    return (
        <>
            <Table
                title={i18n.results}
                data={statesFormated}
                sortable
                color
                type={{ headers }}
                condensed
                paging
                nbPerPageLabel={nbPerPageLabel}
            />
            <ExportFileModal
                open={isExportModalOpen}
                onClose={closeExportModal}
                data={exportTypes}
            />
        </>
    )
}

DetailStatesTable.propTypes = {
    states: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerState)),
    researchCriterias: PropTypes.shape({}),
}

const DetailAnalysisPopin = ({
    isOpen = false,
    closePopin = () => {},
    analysis = {},
}) => {
    const dispatch = useDispatch()

    const parametersSandre = useSandreParameters()

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        cities,
        contacts,
        producers,
        contributors,
        units,
        parameters,
        qualifications,
        remarks,
        status,
        supports,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contacts: store.ContactReducer.contacts,
        producers: store.ContributorReducer.producers,
        contributors: store.ContributorReducer.contributors,
        units: store.UnitReducer.units,
        parameters: store.ParameterReducer.parameters,
        qualifications: store.QualityReducer.qualifications,
        remarks: store.OperationReducer.remarks,
        status: store.QualityReducer.status,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    const [operation, setOperation] = useState()
    const [qualitometer, setQualitometer] = useState()

    useEffect(() => {
        setOperation()
        setQualitometer()
        if (hasValue(analysis.operation) && hasValue(analysis.qualitometer)) {
            initController()
            OperationAction.getOperation(analysis.operation, analysis.qualitometer, controllerRef.current.signal).then(setOperation)
            QualitometerAction.getQualitometer(analysis.qualitometer, controllerRef.current.signal).then(setQualitometer)
        }
        return () => {
            controllerRef.current.abort()
        }
    }, [analysis.operation, analysis.qualitometer])

    const samplePoint = qualitometer?.link_samplePoints.find(p => p.idPoint === operation?.point)?.name

    return (
        <DialogMUI
            maxWidth='xl'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: '60vh',
                    maxHeight: '60vh',
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.resultDetails}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closePopin} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <StyledFieldSet>
                            <StyledLegend>
                                {i18n.station}
                            </StyledLegend>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Input disabled title={i18n.stationCode} value={qualitometer?.code}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <Input disabled title={i18n.creationDate} value={qualitometer ? getDate(qualitometer.creation) : ''} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input disabled title={i18n.closeDate} value={qualitometer ? getDate(qualitometer.close) : ''} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input disabled title={i18n.city} value={getLabel(cities, qualitometer?.townCode)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input disabled title={i18n.contact} value={getLabel(contacts, qualitometer?.contactCode)} />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledFieldSet>
                            <StyledLegend>
                                {i18n.sample}
                            </StyledLegend>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Input disabled title={i18n.date} value={operation ? getDate(operation.date) : ''} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input disabled title={i18n.hour} value={operation ? getHour(operation.date) : ''} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input disabled title={i18n.analysisNumber} value={operation?.analysisNumber} />
                                </Grid>
                                <Grid item xs={2}>
                                    {
                                        operation?.producerRef && (
                                            <img
                                                style={{
                                                    paddingRight: '10px',
                                                    float: 'right',
                                                    cursor: 'pointer',
                                                    height: '46px',
                                                }}
                                                src={pdf}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Input disabled title={i18n.producer} value={getLabel(producers, operation?.producer)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input disabled title={i18n.laboratory} value={getLabel(contributors, analysis?.labo)} />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledFieldSet>
                            <StyledLegend>
                                {i18n.result}
                            </StyledLegend>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Input disabled title={i18n.date} value={getDate(analysis?.analysisDate)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input disabled title={i18n.support} value={getLabel(supports, operation?.support)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input disabled title={i18n.qualification} value={getLabel(qualifications, analysis?.qualification)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input disabled title={i18n.status} value={getLabel(status, analysis?.status)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input disabled title={i18n.parameter} value={getLabel(parameters, analysis?.parameter)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input disabled title={i18n.result} value={parametersSandre[analysis?.parameter]?.find(ps => ps.code === analysis.result)?.name ?? analysis?.result} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input disabled title={i18n.unit} value={getLabel(units, analysis?.unit)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input disabled title={i18n.samplePoint} value={samplePoint} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input disabled title={i18n.remark} value={getLabel(remarks, analysis?.remark)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input disabled title={i18n.quantificationLimit} value={analysis?.lq} />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='right'>
                    <Grid item>
                        <Button variant='contained' onClick={() => dispatch(push(`/station/quality/${analysis?.qualitometer}`))}>
                            {i18n.accessToTheFile}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

DetailAnalysisPopin.propTypes = {
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    analysis: PropTypes.instanceOf(DtoAnalysis),
}

const DetailAnalysisTable = ({
    analysis = [],
    thresholds = [],
    thresholdLevel,
    researchCriterias = {},
}) => {
    const dispatch = useDispatch()
    const {
        qualitometers,
        cities,
        units,
        contributors,
        parameters,
        qualifications,
        remarks,
        status,
        fractions,
        supports,
        typeEnvironmentModels,
        departments,
        accountUser,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
        units: store.UnitReducer.units,
        contributors: store.ContributorReducer.contributors,
        parameters: store.ParameterReducer.parameters,
        qualifications: store.QualityReducer.qualifications,
        remarks: store.OperationReducer.remarks,
        status: store.QualityReducer.status,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        departments: store.HomeReducer.departments,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    const qualitometersIndex = useMemo(() => keyBy(qualitometers, 'id'), [qualitometers])
    const contributorsIndex = useMemo(() => keyBy(contributors, 'id'), [contributors])
    const parametersIndex = useMemo(() => keyBy(parameters, 'code'), [parameters])
    const unitsIndex = useMemo(() => keyBy(units, 'id'), [units])
    const citiesIndex = useMemo(() => keyBy(cities, 'id'), [cities])
    const supportsIndex = useMemo(() => keyBy(supports, 'id'), [supports])
    const fractionsIndex = useMemo(() => keyBy(fractions, 'id'), [fractions])

    const {
        value: displayAll,
        setTrue: forceDisplay,
    } = useBoolean(false)

    const formattedAnalysis = useMemo(() => {
        const formatted = analysis.map(a => ({ ...a, ...calculateThresholdResult(a, thresholds) }))
        return hasValue(thresholdLevel) ? formatted.filter(a => filterThresholdLevel(a, thresholdLevel)) : formatted
    }, [analysis, thresholdLevel, thresholds])

    const nbAllElement = formattedAnalysis.length

    const {
        value: isDetailOpen,
        setTrue: openDetailPopin,
        setFalse: closeDetailPopin,
    } = useBoolean(false)
    const {
        value: isOpen,
        setTrue: openPopin,
        setFalse: closePopin,
    } = useBoolean(false)
    const [selectedAnalysis, setSelectedAnalysis] = useState()

    const formattedData = useMemo(() => {
        const subListAnalysis = displayAll ? formattedAnalysis : take(formattedAnalysis, maxNbElements)
        return subListAnalysis.map(a => {
            const unit = unitsIndex[a.unit] || {}
            const contributor = a.labo ? contributorsIndex[a.labo] : {}
            const labo = contributor?.mnemonique || contributor?.name || ''
            const parameter = parametersIndex[a.parameter]
            const remark = getLabel(remarks, a.remark)
            const station = qualitometersIndex[a.qualitometer]
            const city = station?.townCode ? citiesIndex[station.townCode] : null
            return {
                info: {
                    qualitometerId: a.qualitometer,
                    operationId: a.operation,
                    sampleId: a.sample,
                    analysisId: a.id,
                },
                stationCode: { value: station?.code },
                city: { value: city?.name || '' },
                stationName: { value: station?.name },
                analysisDate: { value: getFullDate(a.analysisDate) },
                parameter: { value: parameter?.labelWithCode },
                statusAndResult: {
                    value: getStatusAndResult(a, a.value, a.color),
                    setTooltip: () => (
                        <TooltipAnalyse
                            title={parameter?.shortLabelWithCode || ''}
                            analyse={a}
                            threshold={a.threshold}
                        />
                    ),
                    sortValue: a.result,
                },
                unit: { value: unit ? unit.symbol : '' },
                support: { value: supportsIndex[a.support]?.name },
                fraction: { value: fractionsIndex[a.fraction]?.name },
                remark: { value: remark },
                sampleDate: { value: getDate(a.sampleDate) },
                labo: { value: labo },
                nullValue: { value: getLocalizationPicto(a.localization) },
            }
        })
    }, [citiesIndex, contributorsIndex, displayAll, formattedAnalysis, fractionsIndex, parametersIndex, qualitometersIndex, remarks, supportsIndex, unitsIndex])

    useActions(() => {
        return {
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
            controlAnalysis: researchCriterias,
        }
    }, [researchCriterias])

    const exportTypes = useMemo(() => {
        const models = typeEnvironmentModels.map(model => ({
            name: model,
            formats: [{
                type: i18n.excelFile,
                callback: () => {
                    if (nbAllElement > 20000) {
                        dispatch(ExportAction.exportResearchModelWithTmpExport(researchCriterias, model))
                    } else {
                        dispatch(ExportAction.exportResearchModel(researchCriterias, model))
                    }
                },
            }],
        }))

        // const onExport = (extension) => {
        //     const data = formattedAnalysis.map(a => {
        //         const unit = unitsIndex[a.unit] || {}
        //         const contributor = a.labo ? contributorsIndex[a.labo] : {}
        //         const labo = contributor?.mnemonique || ''
        //         const parameter = parametersIndex[a.parameter]
        //         // const parameterLabel = parameter?.shortLabel || parameter?.name
        //         const qualification = qualifications.find(q => q.code == a.qualification)
        //         const remark = getLabel(remarks, a.remark)
        //         const statusLabel = getLabel(status, a.status)
        //         const station = qualitometersIndex[a.qualitometer]
        //         const city = station?.townCode ? citiesIndex[station.townCode] : null
        //         const department = city ? departments.find(d => d.code === city.departmentNumber) : undefined
        //         return {
        //             stationCode: station?.code,
        //             cityCode: station?.townCode,
        //             city: city?.name,
        //             stationName: station?.name,
        //             sampleDate: a.sampleDate ? getDate(a.sampleDate) : '',
        //             // localizationCode,
        //             // samplePoint,
        //             labo,
        //             analysisDate: a.analysisDate ? getDate(a.analysisDate) : '',
        //             codeParameter: a.parameter,
        //             parameterLabel: parameter?.name,
        //             result: a.result,
        //             codeUnit: a.unit,
        //             unit: unit?.symbol,
        //             supportCode: a.support,
        //             support: supportsIndex[a.support]?.name,
        //             fractionCode: a.fraction,
        //             fraction: fractionsIndex[a.fraction]?.name,
        //             remarkCode: a.remark,
        //             remark,
        //             statusCode: a.status,
        //             status: statusLabel,
        //             qualificationCode: a.qualification,
        //             qualification: qualification?.name,
        //             localization: getLocalizationLabel(a.localization),
        //             x: station?.x,
        //             y: station?.y,
        //             projection: station?.projection,
        //             ld: a.ld,
        //             lq: a.lq,
        //             defaultLd: parameter.ld,
        //             defaultLq: parameter.lq,
        //             department: department?.name,
        //         }
        //     })
        //     const dataWithHeaders = data.length ? [
        //         { ...data[0], headers: Object.keys(data[0]) },
        //         ...data.slice(1),
        //     ] : []
        //     dispatch(ExportAction.export(formatData(dataWithHeaders), extension, i18n.sampleDetail))
        // }

        return [
            {
                name: i18n.resultsTable,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => {
                        dispatch(ExportAction.exportResearchFullCSV(researchCriterias, 'xlsx'))
                        closeExportModal()
                    },
                }, {
                    type: i18n.csvFile,
                    callback: () => {
                        dispatch(ExportAction.exportResearchFullCSV(researchCriterias, 'csv'))
                        closeExportModal()
                    },
                }],
            },
            ...models,
        ]
    }, [citiesIndex, contributorsIndex, departments, dispatch, formattedAnalysis, fractionsIndex, parametersIndex, qualifications, qualitometersIndex, remarks, researchCriterias, status, supportsIndex, typeEnvironmentModels, unitsIndex, nbAllElement])

    return (
        <>
            {nbAllElement > maxNbElements && !displayAll && (
                <CardToMuchData nbElements={nbAllElement} forceDisplay={forceDisplay} maxNbElements={maxNbElements} />
            )}
            <Table
                title={i18n.results}
                data={formattedData}
                sortable
                onClick={({ info }) => {
                    const analyse = analysis.find(a => a.qualitometer === info.qualitometerId && a.id === info.analysisId && a.sample === info.sampleId)
                    setSelectedAnalysis(analyse)
                    openDetailPopin()
                }}
                color
                type={{ headers: ['stationCode', 'city', 'stationName', 'analysisDate', 'parameter', 'statusAndResult', 'unit', 'support', 'fraction', 'remark', 'sampleDate', 'labo', 'nullValue'] }}
                condensed
                paging
                alterable={accountUser.isAdmin === '1'}
                onAlter={({ info }) => {
                    const analyse = analysis.find(a => a.qualitometer === info.qualitometerId && a.id === info.analysisId && a.sample === info.sampleId)
                    setSelectedAnalysis(analyse)
                    openPopin(info)
                }}
                nbPerPageLabel={nbPerPageLabel}
            />
            <DetailAnalysisPopin
                isOpen={isDetailOpen}
                closePopin={closeDetailPopin}
                analysis={selectedAnalysis}
            />
            <AnalysisPopin
                isOpen={isOpen}
                onClose={closePopin}
                onValidate={(res = {}, newAnalysis) => {
                    if (res.update > 0) {
                        closePopin()
                        dispatch(QualityActionConstant.updateSearchAnalysis(newAnalysis))
                    }
                }}

                analysisId={selectedAnalysis?.id}
                stationId={selectedAnalysis?.qualitometer}
                sampleId={selectedAnalysis?.sample}
                operationId={selectedAnalysis?.operation}
            />
            <ExportFileModal
                open={isExportModalOpen}
                onClose={closeExportModal}
                data={exportTypes}
            />
        </>
    )
}

DetailAnalysisTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    thresholdLevel: PropTypes.string,
    researchCriterias: PropTypes.shape({}),
}

const HydrobioPopin = ({
    isOpen = false,
    closePopin = () => {},
    hydrobio = {},
}) => {
    const dispatch = useDispatch()

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        cities,
        contacts,
        producers,
        contributors,
        qualifications,
        remarks,
        status,
        supports,
        taxonsList,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contacts: store.ContactReducer.contacts,
        producers: store.ContributorReducer.producers,
        contributors: store.ContributorReducer.contributors,
        qualifications: store.QualityReducer.qualifications,
        remarks: store.OperationReducer.remarks,
        status: store.QualityReducer.status,
        supports: store.SupportReducer.supports,
        taxonsList: store.TaxonReducer.taxons,
    }), shallowEqual)

    const [operation, setOperation] = useState()
    const [qualitometer, setQualitometer] = useState()

    useEffect(() => {
        setOperation()
        setQualitometer()
        if (hasValue(hydrobio.operation) && hasValue(hydrobio.qualitometer)) {
            initController()
            OperationAction.getOperation(hydrobio.operation, hydrobio.qualitometer, controllerRef.current.signal).then(setOperation)
            QualitometerAction.getQualitometer(hydrobio.qualitometer, controllerRef.current.signal).then(setQualitometer)
        }
        return () => {
            controllerRef.current.abort()
        }
    }, [hydrobio.operation, hydrobio.qualitometer])

    const phases = sum([hydrobio.enumerationA, hydrobio.enumerationB, hydrobio.enumerationC, hydrobio.enumerationC2].filter(e => !!e)) || 0
    const taxonName = taxonsList.find(t => t.code === hydrobio?.taxon)?.labelWithCode || ''

    return (
        <DialogMUI
            maxWidth='xl'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: '60vh',
                    maxHeight: '60vh',
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.resultDetails}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closePopin} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <StyledFieldSet>
                            <StyledLegend>
                                {i18n.station}
                            </StyledLegend>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Input title={i18n.stationCode} value={qualitometer?.code}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <Input title={i18n.creationDate} value={qualitometer ? getDate(qualitometer.creation) : ''} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input title={i18n.closeDate} value={qualitometer ? getDate(qualitometer.close) : ''} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input title={i18n.city} value={getLabel(cities, qualitometer?.townCode)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input title={i18n.contact} value={getLabel(contacts, qualitometer?.contactCode)} />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledFieldSet>
                            <StyledLegend>
                                {i18n.sample}
                            </StyledLegend>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Input title={i18n.date} value={operation ? getDate(operation.date) : ''} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.hour} value={operation ? getHour(operation.date) : ''} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input title={i18n.analysisNumber} value={operation?.analysisNumber} />
                                </Grid>
                                <Grid item xs={2}>
                                    {
                                        operation?.producerRef && (
                                            <img
                                                style={{
                                                    paddingRight: '10px',
                                                    float: 'right',
                                                    cursor: 'pointer',
                                                    height: '46px',
                                                }}
                                                src={pdf}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Input title={i18n.producer} value={getLabel(producers, operation?.producer)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input title={i18n.laboratory} value={getLabel(contributors, hydrobio?.labo)} />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledFieldSet>
                            <StyledLegend>
                                {i18n.result}
                            </StyledLegend>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Input title={i18n.date} value={getDate(hydrobio?.operationDate)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input title={i18n.support} value={getLabel(supports, operation?.support)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.qualification} value={getLabel(qualifications, hydrobio?.qualification)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.status} value={getLabel(status, hydrobio?.status)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input title={i18n.taxon} value={taxonName} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.enumerations} value={phases} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.remark} value={getLabel(remarks, hydrobio?.remark)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.phaseA} value={hydrobio?.enumerationA || 0} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.phaseB} value={hydrobio?.enumerationB || 0} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.phaseC} value={hydrobio?.enumerationC || 0} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input title={i18n.phaceCbis} value={hydrobio?.enumerationC2 || 0} />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='right'>
                    <Grid item>
                        <Button variant='contained' onClick={() => dispatch(push(`/station/quality/${hydrobio?.qualitometer}`))}>
                            {i18n.accessToTheFile}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

HydrobioPopin.propTypes = {
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    hydrobio: PropTypes.instanceOf(DtoSearchHydrobio),
}

const HYDROBIO_HEADER = ['stationCode', 'stationName', 'sampleDate', 'taxon', 'phaseA', 'phaseB', 'phaseC', 'phaceCbis', 'enumeration', 'abundance']

const DetailHydrobioTable = ({
    hydrobioList = [],
    researchCriterias = {},
    thresholds = [],
    thresholdLevel,
}) => {
    const dispatch = useDispatch()
    const {
        qualitometers,
        typeEnvironmentModels,
        taxonsList,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        taxonsList: store.TaxonReducer.taxons,
    }), shallowEqual)

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    const qualitometersIndex = useMemo(() => keyBy(qualitometers, 'id'), [qualitometers])
    const taxonsListIndex = useMemo(() => keyBy(taxonsList, 'id'), [taxonsList])

    const {
        value: displayAll,
        setTrue: forceDisplay,
    } = useBoolean(false)

    const nbAllElement = hydrobioList.length

    const hydrobiosFiltered = useMemo(() => displayAll ? hydrobioList : take(hydrobioList, maxNbElements), [hydrobioList, displayAll])

    const formattedHydrobios = useMemo(() => {
        const formatted = hydrobiosFiltered.map(t => ({
            ...t,
            ...calculateTaxonThresholdResult(t, thresholds),
        }))
        return hasValue(thresholdLevel) ? formatted.filter(a => filterThresholdLevel(a, thresholdLevel)) : formatted
    }, [hydrobiosFiltered, thresholdLevel, thresholds])

    const {
        value: isOpen,
        setTrue: openPopin,
        setFalse: closePopin,
    } = useBoolean(false)
    const [selectedHydrobio, setselectedHydrobio] = useState()

    const formattedData = useMemo(() => {
        const groupedByOperation = groupBy(formattedHydrobios, l => `${l.qualitometer}#${l.operation}`)
        return formattedHydrobios.map(a => {
            const station = qualitometersIndex[a.qualitometer]
            const taxon = taxonsListIndex[a.taxon]

            // const operationHydrobios = uniqBy(groupedByOperation[a.operation], 'taxon')
            const operationsPhases = sumBy(groupedByOperation[`${a.qualitometer}#${a.operation}`], 'result')

            const abundanceCalculated = operationsPhases && (a.result * 100) / operationsPhases
            const abundance = abundanceCalculated ? `${round(abundanceCalculated, 2)}%` : i18n.incalculable
            return {
                info: {
                    qualitometerId: a.qualitometer,
                    operationId: a.operation,
                    taxonId: a.taxon,
                },
                stationCode: { value: station?.code },
                stationName: { value: station?.name },
                sampleDate: { value: getFullDate(a.operationDate) },
                taxon: { value: taxon?.labelWithCode },
                phaseA: { value: a.enumerationA || 0 },
                phaseB: { value: a.enumerationB || 0 },
                phaseC: { value: a.enumerationC || 0 },
                phaceCbis: { value: a.enumerationC2 || 0 },
                enumeration: { value: a.result, classNameColor: a.color },
                abundance: { value: abundance },
            }
        })
    }, [qualitometersIndex, formattedHydrobios, taxonsListIndex])

    useActions(() => {
        return {
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
        }
    }, [])

    const exportStates = extension => {
        const dataWithHeaders = formattedData.length ? [{ ...formattedData[0], headers: HYDROBIO_HEADER }, ...formattedData.slice(1)] : []
        dispatch(ExportAction.export(formatData(dataWithHeaders), extension, i18n.taxonsDetail))
    }

    const exportTypes = useMemo(() => {
        const models = typeEnvironmentModels.map(model => ({
            name: model,
            formats: [{
                type: i18n.excelFile,
                callback: () => dispatch(ExportAction.exportResearchModel(researchCriterias, model)),
            }],
        }))
        return [
            {
                name: i18n.resultsTable,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => exportStates('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => exportStates('csv'),
                }],
            },
            {
                name: `${i18n.exportFull} CSV`,
                formats: [{
                    type: i18n.csvFile,
                    callback: () => {
                        dispatch(ExportAction.exportResearchFullCSV(researchCriterias))
                        closeExportModal()
                    },
                }],
            },
            ...models,
        ]
    }, [dispatch, researchCriterias, typeEnvironmentModels, formattedData])

    return (
        <>
            {nbAllElement > maxNbElements && !displayAll && (
                <CardToMuchData nbElements={nbAllElement} forceDisplay={forceDisplay} maxNbElements={maxNbElements} />
            )}
            <Table
                title={i18n.results}
                data={formattedData}
                sortable
                onClick={({ info }) => {
                    const hydrobio = hydrobioList.find(a => a.qualitometer === info.qualitometerId && a.operation === info.operationId && a.taxon === info.taxonId)
                    setselectedHydrobio(hydrobio)
                    openPopin()
                }}
                color
                type={{ headers: HYDROBIO_HEADER }}
                condensed
                paging
                nbPerPageLabel={nbPerPageLabel}
            />
            <HydrobioPopin
                isOpen={isOpen}
                closePopin={closePopin}
                hydrobio={selectedHydrobio}
            />
            <ExportFileModal
                open={isExportModalOpen}
                onClose={closeExportModal}
                data={exportTypes}
            />
        </>
    )
}

DetailHydrobioTable.propTypes = {
    hydrobioList: PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchHydrobio)),
    researchCriterias: PropTypes.shape({}),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    thresholdLevel: PropTypes.string,
}

export {
    DetailStatesTable,
    DetailIndicesTable,
    DetailAnalysisTable,
    DetailHydrobioTable,
}