import ApplicationConf from 'conf/ApplicationConf'
import { chunk, flatten } from 'lodash'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise2 } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import LogAction from 'log/actions/LogAction'

const OperationAction = {
    getOperation: (id, stationId, signal) => genericPromise2(ApplicationConf.operation.get(id, stationId), { signal }),
    getOperations: (id, signal) => genericPromise2(ApplicationConf.qualitometer.operations(id), { signal }),
    getAllOperation: (stationIds, signal) => () => {
        const operationPromise = (() => {
            if (stationIds) {
                const groupSize = Math.min(Math.floor(stationIds.length / 20), 150)
                return chunk(stationIds, groupSize > 0 ? groupSize : 1).map(group => genericPromise2(ApplicationConf.qualitometer.groupOperations(group), { signal }))
            }
            return [genericPromise2(ApplicationConf.operation.operations(), { signal })]
        })()
        return Promise.all(operationPromise).then(jsonTab => {
            return flatten(jsonTab)
        }).catch(() => [])
    },
    getQualityCampaignOperation: (campaignId) => genericPromise2(ApplicationConf.qualitometer.operationCampaign(campaignId)),
    operationsListSpecific: (id, signal) => genericPromise2(ApplicationConf.qualitometer.getOperationsListSpecific(id), { signal }),
    getOperationFilesWithRefs: (refs, signal) => genericPromise2(ApplicationConf.qualitometer.getOperationFilesWithRefs(), { signal, body: refs, method: 'POST' }),
    promiseAllOperationFiles: (idStation, signal) => genericPromise2(ApplicationConf.files.allOperationFiles(idStation), { signal }),
    operationsValidation: (operations) => dispatch => genericPromise2(ApplicationConf.operation.validation(), {
        method: 'PUT',
        body: operations,
    })
        .then((json = []) => {
            if (json.update > 0) {
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                return true
            }
            throw new Error('no data updated')
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),
}

export default OperationAction